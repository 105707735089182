import { Component, OnInit } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute } from '@angular/router';
import { ServerURLS } from '../../../../environments/environment';
import { ScFileExporterService } from 'src/app/services/export/sc-file-exporter.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  sourceAddress:any;
  destAddress:any;
  quoteItems: any;
  orderId: string;
  order: any;
  quoteDocument: any;
  sourceInfo: any;
  destInfo: any;
  getTraceOrder: any;
  successMsg: string;
  errMsg: string;
  quoteId: string;
  isCancelSubmitted: string;
  cancelErrMsg: string;
  custId:string
  yrcDocs: any;
  constructor(private scHttpService: ScHttpService,public scAuthService: ScAuthService, private route: ActivatedRoute, private exporterService: ScFileExporterService ) { }

  ngOnInit() {
    this.orderId =  this.route.snapshot.paramMap.get('order-id');

    if(this.orderId){

     // this.getCustIdByOrderId();
      this.getOrderDetails();
      this.loadQuoteItems();
      
    }
    
  }

  getOrderDetails( ): void {

    let url ="";
    url = ServerURLS.getOrderDetails.action + "?id=" + this.orderId;

    // if(custId != null){
    //   url += "&cust-id=" + custId
    // }
    let promise1 = this.scHttpService.getHttpService(url,'');
    promise1.then( value => {

      this.order = value["data"];

      if(this.order){
       
        this.quoteId = this.order["quoteId"];
        this.getSourceAddress(this.order["sourceAddr"]);
        this.getDestAddress(this.order["destAddr"]);

        if( this.order["carrierCode"] == "YRC" || this.order["carrierCode"] == "ICS" || this.order["carrierCode"] == "WT"){
          this.getYrcDocuments();
        }

        if(value["data"]["cancelStatus"]){
          this.isCancelSubmitted = value["data"]["cancelStatus"];

          if( this.isCancelSubmitted.length > 100 ){
            this.isCancelSubmitted = "Status could not be fetched currently from the carrier. Please contact Admin";
          }
        }

        if( (this.order.stts.length > 100) ){
          this.order.stts = "Status could not be fetched currently from the carrier. Please contact Admin";
        }
      }
     
    });

  }

  // getCustIdByOrderId(): void {

  //   let promise1 = this.scHttpService.getHttpService( ServerURLS.getCustIdByOrderId.action + "?order-id=" + this.orderId,'');
  //   promise1.then( value => {
  //     this.custId = value["data"];

  //     if(this.custId){
  //       this.getOrderDetails( this.custId );
  //     }
      
  //   });

  // }

  loadQuoteItems(): void {

    let url = ServerURLS.getOrderQuoteItems.action ;
    url += "?id=" + this.orderId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.quoteItems = value["data"];
    });
    
  }

  getYrcDocuments(): void {

    let url = ServerURLS.fetchYRCDocs.action ;
    url += "?id=" + this.orderId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.yrcDocs = value["data"];
    });
    
  }

  getSourceAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      this.sourceInfo = this.sourceAddress;
    
    });

  }

  getDestAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.destAddress = value["data"];
      this.destInfo = this.destAddress;
    });

  }

  traceOrder(carrierServiceName ): void {

      let promise1 = null;
      let url = ServerURLS.getTraceOrder.action + "?order-id=" + this.orderId;
      if(carrierServiceName != null && carrierServiceName != ''){
        url+= "&carrier-service=" +carrierServiceName;
      }

      // if(this.scAuthService.isAdmnAccess()){
      //    url+= "&cust-id=" +this.custId;
      // }
      promise1 = this.scHttpService.getHttpService( url ,'');
      
      promise1.then( value => {
        this.getTraceOrder = value["data"];
        this.errMsg = '';
        this.successMsg = value["message"];
        this.getOrderDetails();
  
        }).catch((error) => {
          this.errMsg = error.message;
          this.successMsg = '';
        });
        
    }

  export(){
    this.exporterService.export('pdf', 'export', this.orderId);
  }

  cancelOrder() {

    let params = "?quote-id=" + this.quoteId +"&order-id=" + this.orderId;
    
    let promise = this.scHttpService.postHttpService(ServerURLS.cancelOrder.action + params, {});
    promise.then( (resolve) => {

        this.cancelErrMsg = '';

        let response: string = resolve["data"];

        if(response.startsWith("Unable")){
          this.cancelErrMsg = response;
          
        } else {
          this.isCancelSubmitted = response["data"];
        }
        this.getOrderDetails( );
      }).catch((error) => {
      
      });
    
     
  }

}
