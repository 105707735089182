export class Address {

    addressId:string;
    addrLine1:string;
    addrLine2:string;
    city:string;
    state:string;
    country:string;
    zipCode:string;
    addrTypeId: string;
    company:string;
    firstName:string;
    lastName:string;
    dept:string;
    email:string;
    phoneNumbr:string;
    alternatePhoneNumbr:string;
    fax:string;
    locType:string;
    hasAddrBook:boolean;
} 