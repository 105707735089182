import { Address } from '../../profile/address';
import { QuoteDelvryForm } from './quotedelvry';

export class QuoteLocation {

    sourceAddress: Address;
    destinationAddress: Address;
    scQuoteDelvryForm:QuoteDelvryForm;
    customFormTypeId: string;
    hasCustoms: boolean;
    customsShpmtValue:string;
    isClonedOrder: boolean;
    hasChangeInSourceAddr: boolean;
    hasChangeInDestAddr: boolean;

    constructor () {
        this.sourceAddress = new Address();
        this.destinationAddress = new Address();
    }
}