import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { EnvironmentConstants } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";

  loginForm: FormGroup;
 
  errorMessage: any;
  successMessage: any;
  authStatus: boolean;
  postLoginURL: string;

  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor(private router: Router, private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private scAuthService: ScAuthService,private modalService:NgbModal,private listDataService:ListDataServiceService,private commanService:ScCommonService) { }
  dashboardShareData:any;
  ngOnInit() {
    // console.log('hello')
    this.commanService.dashboardData.subscribe((data=>{
      this.dashboardShareData=data;
      // console.log(this.dashboardShareData.sideValue)
    }))
    this.dashboardShareData.sideValue=[]
    // console.log(this.dashboardShareData.sideValue)
    this.modalService.dismissAll()
    this.listDataService.removeAllListData()
    let isUserAlreadyLogin = this.scAuthService.isLoggedSession();
    // let url=window.location.href.split('#');
   
    if (isUserAlreadyLogin != '') {
      // let url=window.location.href.split('#');
      // let mainUrl=url[0].split('/');
      // // console.log(mainUrl);
      // // console.log('http://'+mainUrl[1]+'#/shipcarte/dashboard')
      // window.history.back();
      // window.location.href='https://'+mainUrl[1]+'#/shipcarte/dashboard'
      this.router.navigateByUrl('shipcarte/dashboard');
    }
    // this.scAuthService.clearAllStorage();
    this.postLoginURL = "shipcarte/dashboard";
    this.loadFormData();
  }


  loadFormData() {

    this.loginForm = this._formBuilder.group({

      email: ['', [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      password: ['', Validators.required]

    });

  }

  get f() { return this.loginForm.controls; }

  processing = false;
  procesing_img = EnvironmentConstants.IMAGE_LOADER;

  isAdminLogin: any;

  checkAdmin(event) {
    // console.log(event)
    this.isAdminLogin = event;
  }
  authUser() {

    this.processing = true;

    let formData_ = this.loginForm.getRawValue();

    let email = formData_.email;
    let password = formData_.password;

    let actorType = "";
    actorType = "admin";
    let authPromise = this.scAuthService.signinUser(email, password, '', actorType, this.postLoginURL);

    authPromise.then((resolve) => {
      let response = resolve;

      this.authStatus = response["data"];

      this.processing = false;
      if (this.authStatus) {
        this.successMessage = response["message"];
        this.router.navigateByUrl(this.postLoginURL);
      } else {
        throw new Error("No user object found");
      }

    }).catch((error) => {

      if (error.error) {
        this.errorMessage = error.error;
        // // console.log(this.errorMessage)

      } else {
        this.errorMessage = error;
        // // console.log(this.errorMessage)
      }

      this.processing = false;
    });

  }

}
