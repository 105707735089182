import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  verifyEmailForm: FormGroup;
  verifyOTPForm: FormGroup;
  resetPwdForm: FormGroup;

  errorMessage: any;
  successMessage: any;

  isEmailVerified: boolean ;
  isOTPVerified: boolean ;

  actorType: any;

  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
  
  resetSuccessMsg: any;

  constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService) {

   }

  ngOnInit() {
    this.loadFormData();
  }

  loadFormData(){

    this.verifyEmailForm = this._formBuilder.group({
      email: ['', [Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      actorType: ['', [Validators.required]],
      
   });
  }

  loadOTPFormData(){

    this.verifyOTPForm = this._formBuilder.group({
      email: this.verifyEmailForm.value.email,
      autzCode: ['', [Validators.required]],
      actorType: this.verifyEmailForm.value.actorType,
   });
  
  }

  loadResetFormData(){

    this.resetPwdForm = this._formBuilder.group({
      email: this.verifyEmailForm.value.email,
      actorType: this.verifyEmailForm.value.actorType,
      password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', Validators.required],

   }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  
  }

  get f() { return this.verifyEmailForm.controls; }
  get f1() { return this.verifyOTPForm.controls; }
  get f2() { return this.resetPwdForm.controls; }

  submitEmail() {
    
    let formData_ = this.verifyEmailForm.getRawValue();

    let promise = this.scHttpService.postHttpService(ServerURLS.passwordRequest.action, formData_);
    promise.then( (result) => {

      this.errorMessage = '';
      this.successMessage =  result['message'];

      this.isEmailVerified = true;

      this.verifyEmailForm.setValue({
        email: [formData_.email],
        actorType: [formData_.actorType]
      });
      
       this.actorType = formData_.actorType;
       this.loadOTPFormData();
     
    });
  
    promise.catch ( (result) => {
      this.errorMessage =  result['message'];
      this.isEmailVerified = false;
      this.successMessage = '';
    });
    
  }

    verifyAutzCode() {
    
        let formData_ = this.verifyOTPForm.getRawValue();

        let promise = this.scHttpService.postHttpService(ServerURLS.verifyAutzCode.action, formData_);
        
        promise.then( (result) => {

          this.errorMessage = '';
          this.successMessage =  result['message'];
          this.isOTPVerified = true;

          this.loadResetFormData();

        });
      
        promise.catch ( (result) => {
          this.errorMessage =  result['message'];
          this.successMessage = '';
          this.isOTPVerified =  false;
        });
      
    }

    resetForgotPassword() {
    
       let formData_ = this.resetPwdForm.getRawValue();
 
       let promise = this.scHttpService.putHttpService(ServerURLS.resetForgotPassword.action, formData_);

        promise.then( (result) => {
          this.errorMessage = '';
          this.resetSuccessMsg =  result['message'];
         
        });
      
        promise.catch ( (result) => {
          this.errorMessage =  result['message'];
          this.successMessage = '';
        });
        
     }
}

export function MustMatch(controlName: string, matchingControlName: string) {

  return (formGroup: FormGroup) => {

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }

}
