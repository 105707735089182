import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServerURLS } from 'src/environments/environment';
import { BankInfo } from './bankinfo';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.scss']
})
export class BankInfoComponent implements OnInit {

  applicantId: string;
  bankInfoForm: FormGroup;
  addrForm: FormGroup;

  errorMessage: any;
  successMessage: any;
  isSPincodeReleased: boolean;

  bankInfo: BankInfo;
  isAddrAvailable: boolean;

  PHONE_REGEXP = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  bankingInfo: any;
  isEdit: boolean;

  constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router) { 
    this.bankInfo = new BankInfo();
  }

  ngOnInit() {
    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit') != null;

    this.loadFormData();
    this.loadAddrFormData();

    if(this.applicantId && this.isEdit){
      this.initBankingInfo();
    }
    
  }

  loadFormData(){

    this.bankInfoForm = this._formBuilder.group({
      bankName: ['', Validators.required],
      contact: [],
      transit: [],
      account: [],
      tollFree: [],
      applicantId: [this.applicantId],
      uuid: []
   });

 }

  loadAddrFormData(){

    this.addrForm = this._formBuilder.group({

      zipCode: [this.bankInfo.bankAddress.zipCode, Validators.required],
      city: [{value: this.bankInfo.bankAddress.city, disabled: true}],
      state: [{value: this.bankInfo.bankAddress.state, disabled: true}],
      country: [{value: this.bankInfo.bankAddress.country, disabled: true}],
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.PHONE_REGEXP)]],
      fax: [''],
      addressId: []

    });

  }

  get f() { return this.bankInfoForm.controls; }
  get f1() { return this.addrForm.controls; }

  // Functionalty to load address  
  loadZipCodeData(event) {
    
      this.isSPincodeReleased = true;

      let pinCode =  event.srcElement.value;
      if( pinCode == "" || pinCode.length < 5) {
        this.addrForm.patchValue ({
          'zipCode': '',
          'city':'',
          'state': '',
          'country': '',
        });
        return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 

      this.bankInfo.bankAddress.zipCode = pinCode;
      this.bankInfo.bankAddress.city    = value["data"]["city"];
      this.bankInfo.bankAddress.state   = value["data"]["state"];
      this.bankInfo.bankAddress.country = value["data"]["country"].trim();

      this.addrForm.patchValue  ({
        'zipCode': pinCode,
        'city': this.bankInfo.bankAddress.city,
        'state': this.bankInfo.bankAddress.state,
        'country': this.bankInfo.bankAddress.country.trim(),
      });

      this.isAddrAvailable = true;
    
    });

    promise_.catch(error => { // on error and other than http code:200
      this.isAddrAvailable = false;
      this.errorMessage =  error['message'];

      this.addrForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
    });

  }

  addBankingInfo() {

    let formData = this.bankInfoForm.getRawValue();

    this.bankInfo = formData;
    this.bankInfo.bankAddress = this.addrForm.getRawValue();
    this.bankInfo.applicantId = this.applicantId;

    let bankInfoId = formData.uuid;

    let promise : any;
    if(bankInfoId){
      promise = this.scHttpService.putHttpService(ServerURLS.updateCreditApplicantBankInfo.action, this.bankInfo);

    } else {
      promise = this.scHttpService.postHttpService(ServerURLS.saveCreditApplicantBankInfo.action, this.bankInfo);
    }
      promise.then( (resolve) => {
        
        this.errorMessage = "";
        let response = resolve;
        this.successMessage = response["message"];

        let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
        this.router.navigateByUrl("shipcarte/creditv2/applicant/upload/document" + params);
        
      }).catch((error) => {
        this.errorMessage = error.message;
      });
      
  }

  initBankingInfo (): void {

    let url =  ServerURLS.getCreditApplicantBankInfo.action;

    let promise1 = this.scHttpService.getHttpService( url + "?applicant-id=" + this.applicantId, '');
    promise1.then( value => {
      this.bankingInfo = value["data"];

      if(this.bankingInfo){
        this.bankInfoForm.patchValue(this.bankingInfo);
        this.addrForm.patchValue(this.bankingInfo);

      }else {
        this.isEdit = false;
      }

    });

  }

}
