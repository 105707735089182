import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { Permission } from '../../commons/pojo/permission';
import { ServerURLS } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-applicant-list',
  templateUrl: './applicant-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./applicant-list.component.scss']
})
export class ApplicantListComponent implements OnInit {

  applicants:any;
  tempApplicants:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  tarckerId:any;
  permission: Permission;
  errorMsg:string;
  status:string;
  statusList :any
  isDataReady = false;
  email: any;
  closeResult: string;
  currencyRate: number;
  currencyRateHistory: any;
  loaderMessage: any;
  currentIndex:any;
  @ViewChild('loadContent', undefined) loadContent;
  approvedform: FormGroup;
  updatepaymentform: FormGroup;
  selectedFile:any;
  constructor(private _formBuilder: FormBuilder,private scHttpService: ScHttpService, private router: Router, private modalService: NgbModal) { 
    
    this.permission = new Permission();

    this.permission.write = true;
    this.permission.read = true;
    this.permission.action = true;

  }
   
  ngOnInit() {
    this.status = 'Submitted'
    this.loadApplicants(this.status);
    this.loadCreditApplicantStatus();
    // this.loadCurrencyRate();
    // this.loadCurrencyRateHistory();
    this.approvedform = this._formBuilder.group({
      paidAmount: ['', Validators.required],
      limitAction: [false, Validators.required],
      // decrement: [false, Validators.required],
    });
    this.updatepaymentform = this._formBuilder.group({
      paidAmount: ['', Validators.required],
      paymentId: ['', Validators.required],
      remark: ['', Validators.required],
      // file: [null]
    });
  }

  ngAfterViewInit() {
    this.loadApplicants(this.status);
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadApplicants(this.status);
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  reset(){
    this.email = null;
  }

  loadApplicants(status): void {

    this.loadCotent(this.loadContent);

    let url = ServerURLS.getCreditApplicants.action + "/" + ( this.page - 1 ) + "/" + this.pageSize +"?status=" +status;

      if( this.email ){
        url += "&email=" + this.email;
      }

      this.isDataReady =false;
      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {

        this.applicants = value["data"]["content"];
        localStorage.setItem("tempApplicants", JSON.stringify(value["data"]["content"]));
        this.pageSize = value["data"]["pageable"]["pageSize"];
        this.collectionSize = value["data"]["totalElements"];
        this.page = value["data"]["pageable"]["pageNumber"] + 1;
        this.noOfPages = value["data"]["totalPages"];

        let offset = value["data"]["pageable"]["offset"];
        this.startElementCount = offset + 1;
        this.endElementCount = offset + value["data"]["numberOfElements"];
        
        this.activePage = this.page;
        this.isDataReady = true;
        this.closeModal();

      });

    }
    
    reviewApplicant(id, status){

      let promise1 = this.scHttpService.postHttpService(ServerURLS.saveCreditApplicantSttsTracker.action + "?applicantId=" + id +"&status=" + status,'');
      promise1.then( (resolve) => {
        let response = resolve;
        this.tarckerId = response['data'];
        if(this.tarckerId) {

           this.router.navigateByUrl("/shipcarte/credit/applicant/view;applicant-id=" + id);
        } else {

          this.errorMsg = "Not able to review"
        }
      }).catch((error) => {
      
      });

    }

    searchEmail(key){   
      this.email = key;
    }

    loadCreditApplicantStatus(): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicantStatus.action,'');
      promise1.then( value => {
        this.statusList = value["data"];
      });
    
    }

    loadCurrencyRate(){
      this.loaderMessage = false;
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCurrencyRate.action,'');
      promise1.then( value => {
        this.currencyRate = value[0];
      });  
    }

    loadCurrencyRateHistory(){
      this.loaderMessage = "Fetching Rate Change History...";
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCurrencyRateHistory.action,'');
      promise1.then( value => {
        this.loaderMessage = false;
        this.currencyRateHistory = value['response'];
      });  
    }

    updateCurrencyRate(newRate){
      
      this.loaderMessage = false;
      if(!newRate) return;
      this.loaderMessage = "Updating...";
      let promise1 = this.scHttpService.putHttpService( ServerURLS.updateCurrencyRate.action, {
        "sourceCurrency": "USD",
        "conversionRate": newRate
      });

      promise1.then( value => {
        //this.currencyRateHistory = value['response'];
        this.currencyRate['conversionRate'] = newRate;
        this.loaderMessage = "Currency Rate Updated Successfully..."
        this.loadCurrencyRateHistory();
        
      });

    }

    getApplciantStaus(event){
      this.status = event.target.value;
      this.loadApplicants( this.status);
    }

    open(content,i) {
      this.currentIndex=i;
      this.reset();
      this.modalService.open(content,{size: 'lg',centered: true,ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    loadCotent(loadContent) {
      this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static' })
    }
   
    closeModal(){
      this.modalService.dismissAll();
    }

    onModelChange(i, old, event){
 
    }

    submitapproved(){
      var tempApplicants = JSON.parse(localStorage.getItem("tempApplicants"));
      
      var data = {
        "applicantId": this.applicants[this.currentIndex]['uuid'],
        "changedLimit": this.approvedform.value.paidAmount,
        "limitAction": this.approvedform.value.limitAction
      }
      let promise1 = this.scHttpService.putHttpService( ServerURLS.updateCreditApprovedLimit.action, data);
      promise1.then( value => {
        // this.isDataReady =true;
        //on success
        this.loadApplicants(this.status);
        this.loadCreditApplicantStatus();
      });
      this.closeModal();

    }
    onFileChanged(event) {

      const file = (event.target as HTMLInputElement).files[0];
       this.updatepaymentform.patchValue({
        file: file
       });
       this.updatepaymentform.get('file').updateValueAndValidity()
    }
    submitupdatepayment(){
      // const formdata: FormData = new FormData();
      // formdata.append("applicantId",this.applicants[this.currentIndex]['uuid']);
      // formdata.append("file", this.updatepaymentform.get('file').value);
      // formdata.append("paidAmount", this.updatepaymentform.get('paidAmount').value);
      // formdata.append("paymentId", this.updatepaymentform.get('paymentId').value);
      // formdata.append("remark", this.updatepaymentform.get('remark').value);
      // // console.log(formdata)
      var tempApplicants = JSON.parse(localStorage.getItem("tempApplicants"));
      var data = {
        "applicantId": this.applicants[this.currentIndex]['uuid'],
        "paidAmount": parseFloat(this.updatepaymentform.get('paidAmount').value).toFixed(2),
        "referenceId":this.updatepaymentform.get('paymentId').value,
        "remark":this.updatepaymentform.get('remark').value
      }
      let promise1 = this.scHttpService.putHttpService( ServerURLS.updateCreditRemainingLimit.action, data);
      this.closeModal();
      promise1.then( value => {
        //on success
        this.loadApplicants(this.status);
        this.loadCreditApplicantStatus();
      
      });

    }


    private getDismissReason(reason: any): string {
      this.loadApplicants(this.status);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
    
}
