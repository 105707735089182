import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { Permission } from '../../commons/pojo/permission';
import { ActivatedRoute, Router } from '@angular/router';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { ScCommonService } from '../../../services/common/sc-common.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./order-list.component.scss']
})

export class OrderListComponent implements OnInit {

  quotes:any;
  sourceAddress:any;
  destAddress:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  searchParam: string;
  carrierId: any;
  errorMsg:string;
  successMsg:string;
  orderNum: string;
  getTraceOrder: any;
  permission:Permission;
  iconMap: Map<any, any>;
  email: any;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  startDate: string;
  endDate: string;
  carriers: { name: string; code: string; }[];
  carrierCode: any;
  editRange: any;
  isErrorOrder: any;
  isDataReady = false;
  errorMessage: any;
  closeResult : string;

  @ViewChild('longContent', undefined) longContent;
  @ViewChild('loadContent', undefined) loadContent;

  statuses: { name: string; value: boolean; }[];
  constructor(private modalService: NgbModal,private scHttpService: ScHttpService, private route: ActivatedRoute,private router: Router, public scAuthService: ScAuthService,
              public commonService: ScCommonService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter ) { 
     this.permission = new Permission();
     this.permission.read = true;
     this.permission.write = true;
  }
  ngOnInit() {
    this.carrierId = this.route.snapshot.paramMap.get('carrier');
    this.orderNum = this.route.snapshot.paramMap.get('order-num');
    this.loadQuoteOrderList();
    this.loadIcons();
    this.carriers = [
      {name: 'Day & Ross', code: "DR"},
      {name: 'Polaris', code: "POL"},
      {name: 'YRC', code: "YRC"},
      {name: 'Canpar', code: "EP-CP"},
      {name: 'UPS', code: "EP-UPS"},
      {name: 'FEDEX', code: "EP-FED"},
      {name: 'LoomisExpress', code: "EP-LE"},
      {name: 'ICS Couriers', code: "ICS"},
      {name: 'Wardtrucking', code: "WT"}
    ];
    this.statuses = [
      {name: 'Success', value: false},
      {name: 'Failure', value: true}
    ];
  }
  ngAfterViewInit() {
    this.loadQuoteOrderList();
  }
  loadPage(page: number) {
    if (page !== this.activePage) {
      this.loadQuoteOrderList();
    }
  }
  loadIcons(){
    this.iconMap = new Map();
    this.iconMap.set("truck", "assets/img/sc-icons/Mask Group 48.svg") ; 
    this.iconMap.set("truck-ftl", "assets/img/sc-icons/Mask Group 49.svg") ; 
    this.iconMap.set("rail", "assets/img/sc-icons/Mask Group 45.svg") ; 
    this.iconMap.set("air", "assets/img/sc-icons/Mask Group 46.svg") ; 
    this.iconMap.set("courier", "assets/img/sc-icons/Mask Group 47.svg") ;   
}
  page = 1;
  pageSize = 10
  collectionSize = 5; 
  searchDate(){
    this.startDate = this.commonService.formatDate(this.fromDate);
    this.endDate = this.commonService.formatDate(this.toDate);
    //this.loadQuoteOrderList();
  }
  searchEmail(key){
    this.email = "";
    this.email = key;
    //this.loadQuoteOrderList();
  }
  setCarrierName(code){
    this.carrierCode = "";
    this.carrierCode = code;
   // this.loadQuoteOrderList();
  }
  chooseShipments(event){
    this.isErrorOrder = event;
    //this.loadQuoteOrderList();
  }
  searchOrder(key){
    this.searchParam = key;
    this.loadQuoteOrderList();
  }

  reset(){
    this.email = null;
    this.carrierCode = null;
    this.startDate = null;
    this.endDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.isErrorOrder = null;
  }

  loadQuoteOrderList(): void {
    this.loadCotent(this.loadContent);
    let url = "";
    if(this.scAuthService.isAdmnAccess() || this.scAuthService.isPartnerAccess() ){
      url = ServerURLS.getQuoteOrders_admin.action + ( this.page - 1 ) + "/" + this.pageSize + "?";
        if( this.email ){
          url += "&email=" + this.email;
        } 
        if( this.carrierCode){
            url += "&carrier-code=" + this.carrierCode;
        } 
        if (this.startDate ){
            url += "&from-date=" + this.startDate;
        }
        if (this.endDate){
          url += "&to-date=" + this.endDate;
        }
        if (this.isErrorOrder){
         url += "&is-error=" + this.isErrorOrder;
        }
        if(this.searchParam){
          url += "&searchKey=" + this.searchParam;
        }
    } else {
      url = ServerURLS.getQuoteOrders.action + ( this.page - 1 ) + "/" + this.pageSize+ "?";
      if(this.searchParam){
        url += "&searchKey=" + this.searchParam;
      }
      if(this.carrierId){
        url += "&carrierId=" + this.carrierId;
      }
      if(this.orderNum){
        url += "&orderNum=" + this.orderNum;
      }
    }
    this.isDataReady = false;
    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.quotes = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];
      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      this.activePage = this.page;
      this.isDataReady = true;
      this.closeModal();
      this.quotes.forEach(element => {
        this.getSourceAddress( element );
        this.getDestAddress( element );
      });
      
    }).catch((error) => {
      this.isDataReady = true;
      this.errorMessage = error.error;
      this.closeModal();
    });
  }
  getSourceAddress ( element ): void {
    let url =  ServerURLS.getAddressById.action + "?id=" + element["sourceAddr"];
    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      element ['source_addr'] = value["data"];
    });
  }
  getDestAddress ( element ): void {
    let url =  ServerURLS.getAddressById.action + "?id=" + element["destAddr"];
    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.destAddress = value["data"];
      element ['destination_addr'] = value["data"];
    });
  }
  repeatOrder (quoteId) {
    let authPromise = this.scHttpService.postHttpService(ServerURLS.repeatOrder.action+"?quoteId="+quoteId,'')
      authPromise.then( (resolve) => {
        let response = resolve;
        this.successMsg = response["message"];
        if(  this.successMsg  ){
          let id = response['data'];
          this.closeModal();
          this.router.navigateByUrl("shipcarte/quote/rates;quote-id=" + id);
        }
      }).catch((error) => {
        this.errorMsg = error.message;
      });
  }
  returnOrder (quoteId, orderId) {
    let authPromise = this.scHttpService.postHttpService(ServerURLS.repeatOrder.action+"?quoteId="+quoteId+"&order-id="+orderId,'')
      authPromise.then( (resolve) => {
        let response = resolve;
        this.successMsg = response["message"];
        if(  this.successMsg  ){
          let id = response['data'];
          this.router.navigateByUrl("shipcarte/quote/rates;quote-id=" + id);
        }
      }).catch((error) => {
        this.errorMsg = error.message;
      });
  }
  orderView(id, carrierServiceName){
     if(carrierServiceName == 'XPO Logistics Freight' || carrierServiceName == 'ABF' || carrierServiceName == 'ICS Couriers' || carrierServiceName == 'Wardtrucking'){
       this.router.navigateByUrl("shipcarte/order/view;order-id=" + id);
     } else {
      this.traceOrder(id,carrierServiceName);
    }
  }
  traceOrder(id,carrierServiceName): void {
    let url ="";
    url = ServerURLS.getTraceOrder.action + "?order-id=" + id;
    if(carrierServiceName != null && carrierServiceName != ''){
      url+= "&carrier-service=" +carrierServiceName
    }
    // if(this.scAuthService.isAdmnAccess()){
    //   url+= "&cust-id=" +custId;
    // }
    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.getTraceOrder = value["data"];
      if(this.getTraceOrder){
        this.router.navigateByUrl("shipcarte/order/view;order-id="+ id );
      }
    }).catch((error) => {
    });
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.searchDate();
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  open(content) {
    this.reset();
    this.modalService.open(content, {size: 'lg',centered: true,ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSm(longContent) {
    this.modalService.open(longContent,{ centered: true,backdropClass: 'light-blue-backdrop',windowClass: 'dark-modal',backdrop:'static'})
  }
  loadCotent(loadContent) {
    this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static'})
  }
  openFailure(failcontent) {
    this.modalService.open(failcontent, { size: 'lg',centered: true})
  }
  closeModal(){
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    this.loadQuoteOrderList()
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}