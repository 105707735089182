import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-address-book',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  address:any;

  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  isDataReady = false;
  @ViewChild('loadContent', undefined) loadContent;
  addrId: any;
  addrTagForm: any;
  errorMessage: string;
  resetSuccessMsg: string;
  tagName: any;

  constructor(private scHttpService: ScHttpService,  private modalService: NgbModal, private _formBuilder: FormBuilder) { 
    
  }

  ngOnInit( ) {

    //this.loadAddressBook();
    this.loadFormData();
  }

  ngAfterViewInit() {
    this.loadAddressBook();
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadAddressBook();
    }

  }

  loadFormData(){

    this.addrTagForm = this._formBuilder.group({
      tagName:['', Validators.required],
    });

  }

  get f() { return this.addrTagForm.controls; }

  page = 1;
  pageSize = 5;
  collectionSize = 5; 

  loadAddressBook(): void {

    this.loadCotent(this.loadContent);

      let url = ServerURLS.getAllAddressBook.action + "/" + ( this.page - 1 ) + "/" + this.pageSize ;

      this.isDataReady =false;

      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {

        this.address = value["data"]["content"];
        this.pageSize = value["data"]["pageable"]["pageSize"];
        this.collectionSize = value["data"]["totalElements"];
        this.page = value["data"]["pageable"]["pageNumber"] + 1;
        this.noOfPages = value["data"]["totalPages"];

        let offset = value["data"]["pageable"]["offset"];
        this.startElementCount = offset + 1;
        this.endElementCount = offset + value["data"]["numberOfElements"];
        
        this.activePage = this.page;

        this.isDataReady = true;
        this.closeModal();
      });

    }

    getAddress(id, addr): void {
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
      promise1.then( value => {
        // console.log( this.address);
        addr["moreInfo"]  = value["data"];
      });
  
    }

    addAddress(addAddr) {
      this.modalService.open(addAddr, {size: 'lg',centered: true,backdrop: 'static',ariaLabelledBy: 'modal-basic-title'});
    }
    
    loadCotent(loadContent) {
    this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static'})
    }

    closeModal(){
      this.modalService.dismissAll();
    } 

    cancel(e) {

      if (e) {
        this.modalService.dismissAll();
      }
  
    }

    addressTag(addrTag, addressId, tagName) {
      this.addrTagForm.reset();
      this.modalService.open(addrTag, { centered: true, ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      this.addrId = addressId;
      this.tagName = tagName;

      if(tagName){
        
        this.addrTagForm.patchValue ({
          'tagName': tagName
         } );

      }
    }

    updateAddressTag() {

      let form_data = this.addrTagForm.getRawValue();
      let tagName = form_data["tagName"];

      let promise = this.scHttpService.putHttpService(ServerURLS.updateAddressTag.action + "?addr-id=" + this.addrId + "&addr-name=" +tagName, '');
  
      promise.then((result) => {
        this.errorMessage = '';
        this.resetSuccessMsg = result['message'];
        this.closeModal();
        this.loadAddressBook();
      });
  
      promise.catch((result) => {
        this.errorMessage = result['message'];
        this.resetSuccessMsg = '';
      });
  
    }

    loadAddedAddress(e){
      if(e){
        this.loadAddressBook();
      }
    }
  }
