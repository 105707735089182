import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-insurance',
  templateUrl: './view-insurance.component.html',
  styleUrls: ['./view-insurance.component.scss']
})
export class ViewInsuranceComponent implements OnInit {

  @Input() public url;
  constructor(private modalService:NgbModal) { }

  ngOnInit(): void {
  }
  closePopUP(){
    this.modalService.dismissAll()
  }
}
