import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {
  orderData: any;
  totalCharges: any = 0.0;
  totalTaxes: any = 0.0;
  amountDue: any = 0.0;
  // @Input() data;
  data:any
  printBOL: boolean;
  formattedDate: any;
  charges: any;
  orderList: any = [];
  constructor(public activeModal: NgbActiveModal,  private commonService:ScCommonService,) { }

  ngOnInit(): void {
    this.printBOL = true;
    // console.log(this.data)
    this.data = this.orderList[0]  
    this.orderData = JSON.parse(sessionStorage.getItem("ordersIdsInInvoiceOrderDetail"));
    this.totalCharges=this.data.charged;
    this.totalTaxes=this.data.totalTaxes
    this.amountDue=this.data.amountDue
    if (this.commonService.printInvoiceFromInvoices === true) {
      this.sss();
      this.commonService.printInvoiceFromInvoices = false;
    }
  }

  closeModal() { this.activeModal.close(); }

  print(doc) {
    // console.log('print here');
    if (doc == 'bol') {
      this.printBOL = true;
    }
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  sss() {
    setTimeout(() => {
      window.print();
    }, 1000);
  }
  download() {
    let compData = this;
    html2canvas(document.querySelector('#content'), { useCORS: true }).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }
        pdf.save("invoice_" + compData['data']['invoiceNumber'] + '.pdf');
      };
    });

  }
}