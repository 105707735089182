import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from '../../../../environments/environment';
import { AuthService } from 'angular-6-social-login';
import { ScAuthService } from '../../../services/auth/sc-auth.service';

@Component({
  selector: 'app-case-view',
  templateUrl: './case-view.component.html',
  styleUrls: ['./case-view.component.scss']
})
export class CaseViewComponent implements OnInit {
  
  caseDocuments: any;
  caseIncidentData: any;
  caseId: string;
  caseStatus: any;
  errorMsg: any;
  description: any;
  isDisable: boolean;
  isApprove: boolean;

  constructor( private _formBuilder: FormBuilder, public scAuthService:ScAuthService,
    private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.caseId = this.route.snapshot.paramMap.get('case-id');

    if( this.caseId ){

      this.caseIncident();
      this.loadCaseDocuments();
      this.getCaseIncidentStatus();
    }

  }

  disable() {
    this.isDisable = true;
  }

  approve(){
    this.isApprove = true;
  }

  caseIncident(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCaseIncident.action + "?caseId=" + this.caseId,'');
    promise1.then( value => {
      this.caseIncidentData = value["data"];
     
    });

  }

  loadCaseDocuments(): void {
  
    let promise1 = this.scHttpService.getHttpService( ServerURLS.caseDocumentList.action + "?caseId=" + this.caseId, '');
    promise1.then( value => {
      this.caseDocuments = value["data"];
    });
    
  }

  changeStatus(status) {

    let params = "?case-id=" + this.caseId + "&status=" + status;
    if(this.description){
      params += "&comment=" + this.description;
    }
    let promise = this.scHttpService.postHttpService(ServerURLS.saveCaseStatusHistory.action + params, '');
  
    promise.then( (resolve) => {
      let response = resolve;
      if(status == "Approved" || status == "Rejected"){
        this.isApprove = false;
      }
      if(status == "Disable"){
        this.isDisable = false;
      }
      this.getCaseIncidentStatus();
      this.caseIncident();

    }).catch((error) => {
      this.errorMsg = error.message;
    });
    
  }

  getCaseIncidentStatus (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCaseStatus.action+"?case-id="+this.caseId,'');
    promise1.then( value => {
      this.caseStatus = value["data"];
    });
  }

}
