import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingsComponent implements OnInit {

  tabs = [
    { id: 1, tabName: "Standard Charges" },
    { id: 2, tabName: "Package Manager" },
    { id: 3, tabName: "User Management" }
  ];

  selectedTab: number = 1;

  ngOnInit(): void {
    this.setTabId()
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList()
    this.listDataService.removeAllDataOfQuoteHistory()
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList()
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();

  }
  constructor(
    private router: Router,
    private listDataService: ListDataServiceService
  ) {

  }
  ngOnDestroy() {
    let value = this.listDataService.getValueForUserDetailIsEnableValue();
    if (!value) {
      this.listDataService.removeAllDataOfSettingpackageManageer();
      this.listDataService.removeAllDataOfStandardChargeList();
      this.listDataService.removeAllDataSettingUserManagement()
    }
  }
  selectTab(id) {
    this.selectedTab = id;
    if (id == 1) {
      this.router.navigateByUrl('shipcarte/settings')

    } else if (id == 2) {
      this.router.navigateByUrl('shipcarte/settings/packageManager')

    }
    else if (id == 3) {
      this.router.navigateByUrl('shipcarte/settings/userManagement')

    }
  }

  setTabId() {
    let urlArray = window.location.href.split('/');
    if (urlArray[urlArray.length - 1] === 'userManagement') {
      this.selectedTab = 3
    } else if (urlArray[urlArray.length - 1] === 'standardCharges') {
      this.selectedTab = 1;
    } else if (urlArray[urlArray.length - 1] === 'packageManager') {
      this.selectedTab = 2
    }
  }

}
