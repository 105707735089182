import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ScAuthService } from './sc-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private authService: ScAuthService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    if (this.authService.isAuthenticated()) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url and return false
    this.router.navigate(['/shipcarte/signin'], { queryParams: { returnUrl: state.url }});
    return false;
}
  
}
