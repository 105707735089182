import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-order-document',
  templateUrl: './order-document.component.html',
  styleUrls: ['./order-document.component.scss']
})
export class OrderDocumentComponent implements OnInit {

  orderId: string;
  order: any;
  bolDocuments: any;

  fileSizeMap: Map<any, any>;
  customsDocuments: any;
  loadOfferDocuments: any;
  imageResponse:String;
  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, public scAuthService: ScAuthService) {
    this.fileSizeMap = new Map();
   }

  ngOnInit() {

    this.orderId =  this.route.snapshot.paramMap.get('order-id');

    if(this.orderId){
      this.getOrderDetails();
      this.getBOLDocuments();
      this.getCustomsDocuments();
      this.getLoadOfferDocuments();
      // this.getCustIdByOrderId();
    } 
   
  }

  // getCustIdByOrderId(): void {

  //   let promise1 = this.scHttpService.getHttpService( ServerURLS.getCustIdByOrderId.action + "?order-id=" + this.orderId,'');
  //   promise1.then( value => {
  //     let custId = value["data"];

  //     if(custId){
  //       this.getOrderDetails( custId );
  //       this.getBOLDocuments( custId );
  //       this.getCustomsDocuments( custId );
  //       this.getLoadOfferDocuments( custId );
  //     }
      
  //   });

  // }

  getOrderDetails(): void {

    let url = ServerURLS.getOrderDetails.action + "?id=" + this.orderId;

    // if( custId ){
    //   url += "&cust-id=" + custId;
    // }

    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.order = value["data"];
 
    });

  }

  getBOLDocuments(): void {

    let url = ServerURLS.getBOLDocuments.action + "?id=" + this.orderId;

    // if( custId ){
    //   url += "&cust-id=" + custId;
    // }

    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.bolDocuments = value["data"];
      
      this.bolDocuments.forEach(element => {
        this.convertToKB(element["docAssocId"], element["fileSize"]);
      });

    });

  }

  getCustomsDocuments( ): void {

    let url = ServerURLS.getCustomsDocuments.action + "?id=" + this.orderId;

    // if( custId ){
    //   url += "&cust-id=" + custId;
    // }

    let promise1 = this.scHttpService.getHttpService(url,'');
    promise1.then( value => {
      this.customsDocuments = value["data"];
      
      this.customsDocuments.forEach(element => {
        this.convertToKB(element["docAssocId"], element["fileSize"]);
      });

    });

  }

  getLoadOfferDocuments(): void {

    let url = ServerURLS.getLoadOfferDocuments.action + "?id=" + this.orderId;

    // if( custId ){
    //   url += "&cust-id=" + custId;
    // }
    
    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.loadOfferDocuments = value["data"];
      
      this.loadOfferDocuments.forEach(element => {
        this.convertToKB(element["docAssocId"], element["fileSize"]);
      });

    });

  }

  convertToKB(assocId, size){

    let fileSize = "";

    if (size < 1000) {
      size = size;
      fileSize = size + " bytes";

    } else if (size < 1000*1000) {
      size = size / 1000;
      fileSize = size + " KB";

    } else if (size < 1000*1000*1000) {
      size = size / 1000 / 1000;
      fileSize = size + " MB";

    } else {
      size = size / 1000 /1000 /1000;
      fileSize = size + " GB";
    }
   
    this.fileSizeMap.set(assocId, fileSize);
  }

  getDRSGetImagePdf(imageType){

    let params = "?order-id=" + this.orderId +"&image-type=" + imageType;

    let promise = this.scHttpService.getHttpService(ServerURLS.getDRSImagePDF.action + params, {});
    promise.then( (resolve) => {

      let response: string = resolve["data"];

      if(response){

        this.imageResponse =  response;
      }
      }).catch((error) => {
      
      });

  }

}
