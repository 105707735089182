import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { QuoteReqstForm } from './quoteForm';
import { ScStorageService } from '../../../services/storage/sc-storage.service';
import { Quote } from '../entity/quote';

import { Dimension } from '../entity/dimension';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
  
  providers: [NgbModalConfig, NgbModal]
})
export class QuoteFormComponent implements OnInit {

  shpmtModes:any;
  locationType:any;
  locationTypeServices:any;

  quoteForm:FormGroup;
  quoteItemForm:FormGroup;

  startLocationForm:FormGroup;
  endLocationForm:FormGroup;

  quoteItems:any;

  isAddrAvailable: boolean;
  isEndAddrAvailable: boolean;

  errMsg:String;

  locationId:any;
  startlocType:any;
  endlocType:any;
 
  endLocServices:any;
  quoteReqstForm: QuoteReqstForm;
  choosenStartServiceTypes:any; // Holds the start extra services
  isStartServiceChecked:any;

  choosenEndServiceTypes:any;// Holds the destination extra services
  isEndServiceChecked:any;
  successMsg :string;
  freightTypes:any;
  packageType:any;
  
  quote: Quote;
  searchZipcode: any;

  isFormProcessing: boolean;
  isQuoteAdded: boolean;
  quoteId: string;
  isStartPinEntered: boolean;
  isEndPinEntered: boolean;

  editQId: string;
  quoteCompletionRate: string;
  itemForm: FormGroup;

  isEditPage: string;
  shipmentType:any;
  shipmentPackaging:any;
  shpmtTypeEnable: boolean;
  isSPincodeReleased: boolean;
  isCPincodeReleased: boolean;
  dimensionMap: Map<any, any>;

  shpmtModeTemp: any;

  packagingType:string;
  shipmentPackagingType: Map<any, any>;
  shipmentModeName: Map<any, any>;
  shpmtMode:any
  min:any;
  max:any;
  packagingMap: Map<any, any>;
  shpmtPackTemp: any;


  constructor(private _formBuilder: FormBuilder,private scHttpService: ScHttpService, private storageService: ScStorageService, private router: Router, config: NgbModalConfig, 
    private modalService: NgbModal, private route: ActivatedRoute ) { 

     // customize default values of modals used by this component tree
    // config.backdrop = 'static';
    // config.keyboard = false;
    
    this.quote = new Quote ();
    this.quoteItems = [];

    this.quoteReqstForm = new QuoteReqstForm();

    this.choosenStartServiceTypes = new Set();
    this.isStartServiceChecked = new Array();

    this.choosenEndServiceTypes = new Set();
    this.isEndServiceChecked = new Array();

    this.isFormProcessing = false;

    this.dimensionMap = new Map();
    this.shipmentPackagingType = new Map();
    this.shipmentModeName = new Map();
    this.packagingMap = new Map();
    
  }

 /* openWindowCustomClass(content) {
    this.modalService.open(content);
  } */

  addItem() {

    this.packageTypeList();
    this.quoteItems.push({
     
      qty: 0,
      totalWeight: 0,
      length: 0,
      breadth: 0,
      height: 0,
      unit  : 0,
      freightClass: '',
      packaging: '',
      quoteItemId: ''

    });

    this.itemForm = this._formBuilder.group({

          qty: [], //Validators.required
          totalWeight: [], //Validators.required
          length: [], //Validators.required
          breadth: [], //Validators.required
          height: [], //Validators.required
          unit: ['inch'], //Validators.required
          freightClass  : [], //Validators.required
          packaging  : [], //Validators.required
          quoteItemId: [],
          showFreight : ''
      });

    this.itemFormArray.push(this.itemForm);
  }

  get itemFormArray() {
    return this.quoteItemForm.get('itemFormArray') as FormArray;
 }
  ngOnInit() {

    this.editQId = this.route.snapshot.paramMap.get('quote-id');
    this.isEditPage = this.route.snapshot.paramMap.get('is-edit');
    this.locationTypeList();
    this.shpmtModeList();
    this.freightTypeList();
    this.loadFormData(); // Quote
    this.loadAddrFormData(); // Start Address
    this.loadEndAddrFormData(); // End Address
    this.packageTypeList();
    //this.shipmentTypeList("");

    //Load item form
    this.loadQuoteItemData();
    this.shipmentPackagingList();

    if( !this.editQId ){
      this.addItem();
      this.packagingType = "Pallet";
    }

    if(this.editQId){

      this.loadQuoteCompletionRate();
      
      this.getQuote();
      this.getShipmentService();
      //this.getQuoteItems();
    }

  }

  loadQuoteCompletionRate() {

    if( this.route.snapshot.paramMap.get('completion-rate') != null) {

      this.quoteCompletionRate =  this.route.snapshot.paramMap.get('completion-rate');

      if( this.quoteCompletionRate != null && this.editQId != null ) {

        if( this.quoteCompletionRate == '10'){
          this.router.navigateByUrl("shipcarte/quote/results;quote-id="  + this.editQId);

        } else if( this.quoteCompletionRate == '25'){
          this.router.navigateByUrl("shipcarte/quote/location;quote-id=" + this.editQId);

        } else if( this.quoteCompletionRate == '35'){
          this.router.navigateByUrl("shipcarte/quote/document;quote-id=" + this.editQId);

        } else if( this.quoteCompletionRate == '50' || this.quoteCompletionRate == '95'){
          this.router.navigateByUrl("shipcarte/quote/payment;quote-id="  + this.editQId);
        } 
      }
    }
  }

  removeItem(quoteItem, index){
    
    if(this.quoteItems && quoteItem != null && index > -1){

        this.itemFormArray.removeAt(index);
        this.quoteItems.splice(index, 1);
    }
 
  }

  /**
   * Location type list
   */
 locationTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationTypes.action,'');
    promise1.then( value => {
      this.locationType = value["data"];
    });

  }

  /**
   * Shipment Mode list
   */
 shpmtModeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtModeList.action,'');
    promise1.then( value => {
      this.shpmtModes = value["data"];
      this.shpmtModes.forEach(element => {

        this.shipmentModeName.set(element.shpngModeId, element.shpmntCode);
      });

    });

  }

  /**
   * Feright type list
   */
  freightTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getFreightClassTypes.action,'');
    promise1.then( value => {
      this.freightTypes = value["data"];
    });

  }

  /**
   * Feright type list
   */
  packageTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getPackageTypes.action,'');
    promise1.then( value => {
      this.packageType = value["data"];

      this.packageType.forEach(element => {
        this.dimensionMap.set(element.packageId, element.width + ":" + element.length);
        this.packagingMap.set(element.packageId, element.name);
      });

    });

  }

  shipmentTypeList (id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShipmentTypes.action+"?shipmentModeId="+id,'');
    promise1.then( value => {
      this.shipmentType = value["data"];

      if(this.shipmentType != "" && this.shipmentType != null){
        this.shpmtTypeEnable = true ;
        this.quoteForm.get('shpmtTypeId').setValidators(Validators.required);

        if( this.shpmtModeTemp != null){
          this.quoteForm.patchValue ({
            'shpmtTypeId': this.shpmtModeTemp,
          });
        }

      } else {
        this.shpmtTypeEnable = false ;
        this.quoteForm.get('shpmtTypeId').clearValidators();
        this.quoteForm.patchValue ({
          'shpmtTypeId': null ,
        });
      }

    });

    // Method is used to pallet type
    this.getShipmentMode(id);

  }
   
  getShipmentMode(value){
    this.shpmtMode = this.shipmentModeName.get(value);
    this.shipmentPackagingList ();
   }

  shipmentPackagingList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShipmentPackaging.action,'');
    promise1.then( value => {
      this.shipmentPackaging = value["data"];

      if( ( this.shpmtPackTemp != undefined ) &&  ( this.shpmtPackTemp != null )){
        this.quoteForm.patchValue ({
          'shpmtPackagingTypeId': this.shpmtPackTemp,
        });
      }

      let list: any = [];
      if(  (this.shpmtMode == "LTL") || (this.shpmtMode == "FTL") ){

        this.shipmentPackaging.forEach(element => {
          if(element["packagingType"] == "Pallet"){
            list.push(element);
          }
          
        });
        this.shipmentPackaging = list;

        if(this.editQId){
          this.getQuoteItems(this.shpmtPackTemp, true);
        }

      } else {

         this.shipmentPackaging.forEach(element => {
         this.shipmentPackagingType.set(element.packagingId, element.packagingType);
       });

      }
    
    });

  }

  onChangePackaging(value){
   
    this.packagingType = "";
    this.packagingType = this.shipmentPackagingType.get(value);

    if(this.editQId){
      this.getQuoteItems(value, true);
    }

    this.itemFormArray.reset();
    let len = this.itemFormArray.length;
    let index = 0;
    let item = undefined;
    if(len > 0){
   
      index = len-1;
      item = this.itemFormArray.at(index);

      if( this.packagingType == 'Pak'){

           /*item.get('totalWeight').setValidators(Validators.required);
          item.get('qty').setValidators(Validators.required);*/

          this.setValidator(item, 'totalWeight');
          this.setValidator(item, 'qty');
          item.get('unit').setValue("lbs");
          
      } else if( this.packagingType == 'Pallet') {

          /*item.get('length'). setValidators(Validators.required);
          item.get('height').setValidators(Validators.required);
          item.get('breadth').setValidators(Validators.required);
          item.get('unit').setValidators(Validators.required);
          item.get('qty').setValidators(Validators.required);
          item.get('packaging').setValidators(Validators.required);
          item.get('totalWeight').setValidators(Validators.required);*/

          this.setValidator(item, 'length');
          this.setValidator(item, 'height');
          this.setValidator(item, 'breadth');
          this.setValidator(item, 'unit');
          this.setValidator(item, 'qty');
          this.setValidator(item, 'packaging');
          this.setValidator(item, 'totalWeight');
        
      } else if( this.packagingType == 'Parcel') {

         /* item.get('length').setValidators(Validators.required);
          item.get('height').setValidators(Validators.required);
          item.get('breadth').setValidators(Validators.required);
          item.get('qty').setValidators(Validators.required);
          item.get('totalWeight').setValidators(Validators.required);
          item.get('unit').setValidators(Validators.required);*/

          this.setValidator(item, 'length');
          this.setValidator(item, 'height');
          this.setValidator(item, 'breadth');
          this.setValidator(item, 'qty');
          this.setValidator(item, 'totalWeight');
          this.setValidator(item, 'unit');
      
      } 

      if ( this.packagingType != 'Pallet' ) {
        this.removeValidator(item, 'freightClass');
        this.removeValidator(item, 'packaging');
        
      } 
      
      if ( this.packagingType != 'Pallet' && this.packagingType != 'Parcel' && this.packagingType == 'Pak') {

        this.removeValidator(item, 'length');
        this.removeValidator(item, 'breadth');
        this.removeValidator(item, 'height');
       
      }  
      
      if ( this.packagingType != 'Pallet' && this.packagingType != 'Parcel' && this.packagingType != 'Pak') {
        this.removeValidator(item, 'qty');
        this.removeValidator(item, 'totalWeight');
        this.removeValidator(item, 'unit');
      }

      if ( this.packagingType == 'Letter') {
        this.removeValidator(item, 'length');
        this.removeValidator(item, 'breadth');
        this.removeValidator(item, 'height');
        this.removeValidator(item, 'freightClass');
        this.removeValidator(item, 'packaging');
        this.removeValidator(item, 'qty');
        this.removeValidator(item, 'totalWeight');
        this.removeValidator(item, 'unit');
      }

      if( this.packagingType == 'Pak'){
        this.min =  1;
        this.max = 3;
      }
    }

 
   }
  
   removeValidator(item, htmlFormField){
    
 
    let element =  item.get(htmlFormField);
    element.clearValidators();
    element.updateValueAndValidity();

  }

  //Using this method because validator was not updating when it is set
  setValidator(item, htmlFormField){
 
    let element =  item.get(htmlFormField);
    element.setValidators(Validators.required);
    element.updateValueAndValidity();

  }

   /**
    * Start Location type service based on location type
    */
  setStLocation(event,type){
    this.locationId = event.target.value;
    this.startlocType = type;
    this.locationStrtTypeServcsList(this.locationId,type);

  }

   /**
   * End Location type service based on location type
   */
  setEndLocation(event,type){

    this.locationId = event.target.value;
    this.endlocType = type;
    this.locationEndTypeServcsList(this.locationId,type);

  }

  setStartShipmentServices(values:any, checked:boolean){

    checked = !this.choosenStartServiceTypes.has(values.target.value);
    if( checked ) {
      this.choosenStartServiceTypes.add( values.target.value );
    } else {
      this.choosenStartServiceTypes.delete( values.target.value );
    }

  }

  setEndShipmentServices(values:any, checked:boolean){
   
    checked = !this.choosenEndServiceTypes.has(values.target.value);
    if( checked ) {
      this.choosenEndServiceTypes.add( values.target.value );
    } else {
      this.choosenEndServiceTypes.delete( values.target.value );
    }
    
  }

  locationStrtTypeServcsList (locationId,type): void {

    this.isStartServiceChecked = new Array();
    this.choosenStartServiceTypes.clear();

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationShpmtServcs.action +"?locTypeId="+locationId+"&locType="+type,'');
    promise1.then( value => {
     
      this.locationTypeServices = value["data"];
      
      if ( this.optedServices && this.locationTypeServices ){

        this.locationTypeServices.forEach(element => {
          if ( this.optedServices.includes ( element.assocId ) ) {
            let values= {};
            values['target'] = {};
            values['target']['value'] = element.assocId;
            element.checked = 'checked';
            this.setStartShipmentServices ( values, true);
          }

        });

       } 

    });
    
    //[checked]="hasES('StartShipment',shpmtServcs.assocId)"

  }

  locationEndTypeServcsList (id, type1): void {

    this.isEndServiceChecked = new Array();
    this.choosenEndServiceTypes.clear();

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationShpmtServcs.action +"?locTypeId="+id+"&locType="+type1,'');
    promise1.then( value => {
      this.endLocServices = value["data"];
      let list: any = [];

      if( this.packagingType && (this.packagingType == "Pak" || this.packagingType == "Parcel" || this.packagingType == "Letter" ) ){

        this.endLocServices.forEach(element => {
          if(element["serviceName"].includes("Signature")){
            list.push(element);
          }
        });

        this.endLocServices = list;
      }

      if ( this.optedServices && this.endLocServices ){
        
       this.endLocServices.forEach(element => {
         if ( this.optedServices.includes ( element.assocId ) ) {
           let values= {};
           values['target'] = {};
           values['target']['value'] = element.assocId;
           element.checked = 'checked';
           this.setEndShipmentServices ( values, true);
         }
       });

      } 
    });

  }

  loadAddrFormData(){

    this.startLocationForm = this._formBuilder.group({

     zipCode: [this.quote.sourceAddress.zipCode, Validators.required],
     city: {value: this.quote.sourceAddress.city, disabled: true},
     state: [{value: this.quote.sourceAddress.state, disabled: true}],
     country: [{value: this.quote.sourceAddress.country, disabled: true}],
     addrLine1:[''],
     addrLine2:[''],
     addrTypeId:['', Validators.required],

   });

 }

  loadEndAddrFormData(){

      this.endLocationForm = this._formBuilder.group({

      zipCode: [this.quote.destinationAddress.zipCode, Validators.required],
      city: {value: this.quote.destinationAddress.city, disabled: true},
      state: [{value: this.quote.destinationAddress.state, disabled: true}],
      country: [{value: this.quote.destinationAddress.country, disabled: true}],
      addrLine1:[''],
      addrLine2:[''],
      addrTypeId: ['',Validators.required],
    });

  }
  
  loadQuoteItemData() {
    this.quoteItemForm = this._formBuilder.group ({
      itemFormArray: this._formBuilder.array([
        
      ])
     
    });
  }

  loadFormData(){

     this.quoteForm = this._formBuilder.group({
        
        shpmtModeId:['',Validators.required],
        shpmtTypeId:[],
        shpmtPackagingTypeId:['',Validators.required],
        stextraServcType:['',],
        edextraServcType:['',],
    });
 }

 get f() { return this.quoteForm.controls; }
 get f1() { return this.startLocationForm.controls; }
 get f2() { return this.endLocationForm.controls; }
 get f3() { return this.itemForm.controls; }


 // Functionalty to load start address  
 loadZipCodeData(event) {
    this.isSPincodeReleased = true;
    let formData = this.startLocationForm.getRawValue();

    let pinCode =  event.srcElement.value;//formData["zipCode"];
    if( pinCode == "" || pinCode.length < 5) {
      this.startLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 
      this.quote.sourceAddress.zipCode = pinCode;
      this.quote.sourceAddress.city    = value["data"]["city"];
      this.quote.sourceAddress.state   = value["data"]["state"];
      this.quote.sourceAddress.country = value["data"]["country"].trim();

      this.startLocationForm.patchValue  ({
         'zipCode': this.quote.sourceAddress.zipCode,
         'city': this.quote.sourceAddress.city,
         'state': this.quote.sourceAddress.state,
         'country': this.quote.sourceAddress.country.trim(),
       });

       this.isAddrAvailable = true;
     
    });

    promise_.catch(error => { // on error and other than http code:200
      this.isAddrAvailable = false;
      this.errMsg =  error['message'];
  
      this.startLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
    });

  }
// Functionalty to load end address  
  loadEndLocZipCodeData(event) {
    this.isCPincodeReleased = true;
    let formData = this.endLocationForm.getRawValue();
    let pinCode =  event.srcElement.value; // formData["zipCode"];
    if( pinCode == "" || pinCode.length < 5) {
      this.endLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 
      this.quote.destinationAddress.zipCode = pinCode;
      this.quote.destinationAddress.city    = value["data"]["city"];
      this.quote.destinationAddress.state   = value["data"]["state"];
      this.quote.destinationAddress.country = value["data"]["country"].trim();

      this.endLocationForm.patchValue ({
        'zipCode': this.quote.destinationAddress.zipCode,
        'city': this.quote.destinationAddress.city,
        'state': this.quote.destinationAddress.state,
        'country': this.quote.destinationAddress.country.trim(),
        
      });
      
      this.isEndAddrAvailable = true;
     
    });

    promise_.catch(error => { // on error and other than http code:200
      this.isEndAddrAvailable = false;
      this.errMsg =  error['message'];
      this.endLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      
    });

  }

  saveQuote () {

    let form_data = this.quoteForm.getRawValue();
    this.quoteReqstForm = form_data;

    this.quote.sourceAddress.addrTypeId = this.startLocationForm.value['addrTypeId'];
   
    this.quote.destinationAddress.addrTypeId  = this.endLocationForm.value['addrTypeId'];

    this.quote.shipmentMode = this.quoteReqstForm.shpmtModeId;
    this.quote.shpmtTypeId = this.quoteReqstForm.shpmtTypeId;
    this.quote.shpmtPackagingTypeId = this.quoteReqstForm.shpmtPackagingTypeId;
    this.quote.hasSourceServicesOpted = (this.choosenStartServiceTypes.size > 0);
    this.quote.hasDestServicesOpted   = (this.choosenEndServiceTypes.size > 0);

    // concat totalExtraservices
    this.quoteReqstForm.extraServcType = this.choosenStartServiceTypes;

    // add destination services to the quote
    for (let service of this.choosenEndServiceTypes) {  
      this.quoteReqstForm.extraServcType.add( service ); 
    } 

    // since the localstorage doesn't store the set object conversion to array is must
    this.quote.extraServices = Array.from(this.quoteReqstForm.extraServcType.values()); 
    this.quote.shipmentMode  = this.quoteReqstForm.shpmtModeId;    

    let dimension = new Dimension ( this.quoteReqstForm.length, this.quoteReqstForm.breadth, this.quoteReqstForm.height, this.quoteReqstForm.unit );
    this.quote.items = this.quoteItemForm.value['itemFormArray'];
  
    //[0] = new Item( this.quoteReqstForm.packaging, this.quoteReqstForm.qty, this.quoteReqstForm.totalWeight, dimension, this.quoteReqstForm.freightClass);

    this.storageService.saveBucket("quote",  this.quote);
    this.isFormProcessing = true;

    let serviceCall :any;

    if(this.editQId){
      this.quote.quoteId = this.editQId;
      serviceCall = this.scHttpService.putHttpService(ServerURLS.updateQuote.action, this.quote);
    
    } else {
      serviceCall = this.scHttpService.postHttpService(ServerURLS.saveQuote.action, this.quote);
    }

    let authPromise = serviceCall;
      
      authPromise.then( (resolve) => {
        let response = resolve;
       
        this.errMsg = '';
        this.isFormProcessing = false;
        this.quoteId = response['data'];
        this.successMsg = response["message"];

        this.router.navigateByUrl("shipcarte/quote/results;quote-id=" + this.quoteId);
        
      }).catch((error) => {
        this.errMsg = error.message;
        this.successMsg = '';
        this.isFormProcessing = false;
        this.isQuoteAdded = false;
      });

    }

    viewQuote(){

      if(this.quoteId){
        this.router.navigateByUrl("shipcarte/quote/view;quote-id=" + this.quoteId);
      }
      
    }

    //Load quote Start and end location details in edit mode
    getQuote(): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuote.action + "?quoteId=" + this.editQId,'');
      promise1.then( value => {
        let response = value["data"];
  
        if(this.editQId){

          this.getSourceAddress(response["sourceAddr"]);
          this.getDestAddress(response["destAddr"]);

          this.startLocationForm.patchValue(response["sourceAddr"]);
          this.endLocationForm.patchValue(response["destAddr"]);
        }
       
      });
  
    }
  
    getSourceAddress(id): void {
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
      promise1.then( value => {
        let response = value["data"];

        if(response){
          this.startLocationForm.patchValue ({
            'zipCode': response["zipCode"],
            'city': response["city"],
            'state': response["state"],
            'country': response["country"].trim(),
            'addrTypeId': response["addrTypeId"]
          });
          this.locationStrtTypeServcsList(response["addrTypeId"],'start');

          this.quote.sourceAddress.zipCode = response["zipCode"];
          this.quote.sourceAddress.city    = response["city"];
          this.quote.sourceAddress.state   = response["state"];
          this.quote.sourceAddress.country = response["country"].trim();
          this.quote.sourceAddress.addressId = response["addressId"];
        }

      });
  
    }
  
    getDestAddress(id): void {
  
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
      promise1.then( value => {
        let response = value["data"];

        if(response){
          this.endLocationForm.patchValue ({
            'zipCode': response["zipCode"],
            'city': response["city"],
            'state': response["state"],
            'country': response["country"].trim(),
            'addrTypeId': response["addrTypeId"],
            'addressId': response["addressId"]
          });

          this.locationEndTypeServcsList(response["addrTypeId"],'end');
          
          this.quote.destinationAddress.zipCode = response["zipCode"];
          this.quote.destinationAddress.city    = response["city"];
          this.quote.destinationAddress.state   = response["state"];
          this.quote.destinationAddress.country = response["country"].trim();
          this.quote.destinationAddress.addressId = response["addressId"];
         
        }
       
      });
  
    }
    // end

    optedServices : any[];

    getShipmentService(): void {
  
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteService.action + "?quoteId=" + this.editQId,'');
      promise1.then( value => {
        let response = value["data"];
        
        if(response){
          this.quoteForm.patchValue ({
            'shpmtModeId': response["shipmentMode"],
            'shpmtTypeId': response["shpmtTypeId"],
            'shpmtPackagingTypeId': response["shpmtPackagingTypeId"],
            
            // 'stextraServcType': response["extraServices"],
            // 'edextraServcType': response["extraServices"],
          });

          this.optedServices = response["extraServices"];
          if(response["shpmtTypeId"] != null){

              this.shpmtModeTemp = response["shpmtTypeId"];
              this.shipmentTypeList(response["shipmentMode"]);

          }

          if(response["shpmtPackagingTypeId"] != null){

            this.shpmtPackTemp = response["shpmtPackagingTypeId"];
            this.shipmentPackagingList();

          }

        }

        this.getQuoteItems(this.shpmtPackTemp, false)
     
      });
  
    }

    hasES ( type, item ) {

      if( this.optedServices && this.optedServices.length > 0) {

        let values= {};
        values['target'] = {};
        values['target']['value'] = item;
        this.setStartShipmentServices(values, item)
        return this.optedServices.includes (item);

      } else {
        return false;
      }
      
    }

    // setStartShipmentServices(values:any, checked:boolean){
  
    //   if( checked ) {
    //     this.choosenStartServiceTypes.add( values.target.value );
  
    //   } else {
    //     this.choosenStartServiceTypes.delete( values.target.value );
    //   }
      
    // }

    getQuoteItems(packageId, isChangePackage): void {

      this.packagingType = this.shipmentPackagingType.get(packageId);
      let param = "?quote-id=" + this.editQId + "&package-type=" + packageId;
  
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteItemsDetail.action + param, '');
      promise1.then( value => {

        let response = value["data"];

        for (let i = 0; i < response.length; i++) {
          if(!isChangePackage){
            this.addItem();
          }
          this.itemForm.patchValue(response[i]);
        }

      });
  
    }

    orderReview(){
      this.router.navigateByUrl("shipcarte/order/review;quote-id=" + this.editQId);
    }
 
    makePayment(){
      this.router.navigateByUrl("shipcarte/quote/payment;quote-id=" + this.editQId);
    }

    setDimension( index ){
      let packageId = this.itemFormArray.at(index).value["packaging"];
  
      if(packageId){
  
        let dimension = this.dimensionMap.get(packageId);
  
        if(dimension  && !(dimension.includes(null))){
          this.itemFormArray.at(index).patchValue({
            'length': dimension.split(":")[1],
            'breadth': dimension.split(":")[0]
          });
        } else {
          this.itemFormArray.at(index).patchValue({
            'length': null,
            'breadth': null
          });

        } 
      }
  
      if( packageId ) {

        let packageName = this.packagingMap.get(packageId);
  
        if( packageName.includes("Pallet")){
          this.itemFormArray.at(index).get('freightClass').setValidators(Validators.required);
          // SG: Commenting this line as this is blocked the deployment
          this.itemFormArray.at(index).patchValue({
            'showFreight': true,
          });
  
          } else {
            this.itemFormArray.at(index).get("freightClass").clearValidators();
            this.itemFormArray.at(index).patchValue({
              'freightClass': null,
              'showFreight': false,
            });
          }
          this.itemFormArray.at(index).get("freightClass").updateValueAndValidity();
        }
        
    }
}
