import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from '../../../services/storage/sc-storage.service';
import { BillingInfo } from './biilinginfo';
import { ServerURLS } from '../../../../environments/environment';

@Component({
  selector: 'app-bill-info',
  templateUrl: './bill-info.component.html',
  styleUrls: ['./bill-info.component.scss']
})
export class BillInfoComponent implements OnInit {

  billingForm:FormGroup;
  addressForm:FormGroup;
  isSPincodeReleased:boolean;
  billingInfo:BillingInfo;
  isAddrAvailable :boolean;
  errMsg:string;
  paymentMethods:any;
  searchZipcode: any;
  applicantId:any;
  applicantData:any;
  isSameAsApplicant:boolean;
  isEdit:boolean;

  addrType: any;
  isApplicantAddr: boolean;
  billingAddr: any;

  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService, private storageService: ScStorageService, private router: Router, private route: ActivatedRoute ) {
      this.billingInfo  = new BillingInfo();
  } 

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit')!= null;

    this.loadpaymntMethods();

    if(this.isEdit){
      this.getBillingAddress();
    }

    this.loadAddrFormData();
    
  }

 loadAddrFormData(){

   this.addressForm = this._formBuilder.group({

   zipCode: [this.billingInfo.billingAddr.zipCode, Validators.required],
   city: {value: this.billingInfo.billingAddr.city, disabled: true},
   state: [{value: this.billingInfo.billingAddr.state, disabled: true}],
   country: [{value: this.billingInfo.billingAddr.country, disabled: true}],
   addrLine1: ['', Validators.required],
   addrLine2: [],
   name:[],
   addressId:[],
   billingId:[],
   isApplicantAddr:[]
 });

}

loadZipCodeData(event) {

  this.isSPincodeReleased = true;
  let pinCode =  event.srcElement.value;//formData["zipCode"];
  if( pinCode == "" || pinCode.length < 5) {
    this.addressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
    return ;
  }

  let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

  let promise_ = this.scHttpService.getHttpService(url, '');
   promise_.then(value => { 
    this.billingInfo.billingAddr.zipCode = pinCode;
    this.billingInfo.billingAddr.city    = value["data"]["city"];
    this.billingInfo.billingAddr.state   = value["data"]["state"];
    this.billingInfo.billingAddr.country = value["data"]["country"].trim();

    this.addressForm.patchValue  ({
       'zipCode': this.billingInfo.billingAddr.zipCode,
       'city': this.billingInfo.billingAddr.city,
       'state': this.billingInfo.billingAddr.state,
       'country': this.billingInfo.billingAddr.country.trim(),
     });

     this.isAddrAvailable = true;
   
  });

  promise_.catch(error => { // on error and other than http code:200
    this.isAddrAvailable = false;
    this.errMsg =  error['message'];

    this.addressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
  });

 }
 get f2() { return this.addressForm.controls; }

 loadpaymntMethods (): void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.quotePaymtMethods.action,'');
  promise1.then( value => {
    this.paymentMethods = value["data"];
  });

}

getApplicantAddress (): void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicant.action + "?applicant-id=" + this.applicantId, '');
  promise1.then( value => {
  
    let response = value["data"];
    this.applicantData = response;
    if(this.applicantData != null){
      this.addressForm.patchValue (value["data"]);
    }
  });

}

getApplicantAddrType(isCreditApplicant){  

    if(isCreditApplicant){
       this.getApplicantAddress();
       this.isSameAsApplicant = isCreditApplicant;
       this.addrType = "same";
       this.onChanges( this.addrType );

    } else {
       this.isSameAsApplicant = isCreditApplicant;
       
        if(this.isEdit && !this.isApplicantAddr){
          this.getBillingAddress();

        } else {
          this.addressForm.reset();
        }
       
       this.addrType = "diff";
       this.onChanges( this.addrType );
    }
}

saveBillInfo () {
 
  this.billingInfo.applicantId = this.applicantId;

  if( this.isSameAsApplicant){
    
     this.billingInfo.addrId = this.applicantData["addrId"];
     this.billingInfo.name = this.applicantData["name"];
  } else {
    
    let form_data = this.addressForm.getRawValue();
    this.billingInfo.name = this.addressForm.value['name'];
    this.billingInfo.billingAddr = form_data;
  }

  if( this.isEdit ){
    this.billingInfo.billingId = this.billingAddr['billingId'];
  }

  let serviceCall :any;
  serviceCall = this.scHttpService.postHttpService(ServerURLS.saveBillingInfo.action, this.billingInfo);

  let authPromise = serviceCall;
    
    authPromise.then( (resolve) => {
      let response = resolve;
      this.errMsg = '';

      let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
      this.router.navigateByUrl("shipcarte/credit/applicant/payment/info" + params);
  
    }).catch((error) => {
      this.errMsg = error.message;
    });

  }

  getBillingAddress (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditBillingAddress.action + "?applicant-id=" + this.applicantId,'');
    promise1.then( value => {

      this.billingAddr = value["data"];
      // console.log(this.billingAddr);
      if( this.billingAddr == null ){

        this.isEdit = false;
      }  else {
        this.isApplicantAddr = this.billingAddr["isApplicantAddr"];

        if( this.isApplicantAddr ){
          this.addrType = "same";
          this.isSameAsApplicant = true;
          this.getApplicantAddress ();
          this.onChanges( this.addrType );
  
        } else {
          this.addrType = "diff";
          this.onChanges( this.addrType );
        }
      
       if(this.billingAddr){
        this.addressForm.patchValue (value["data"]);
       }

      }
     
    });
  
  }

  onChanges( addrType ) {

      if (addrType == 'same') {
          this.addressForm.get('zipCode').disable();
          this.addressForm.get('addrLine1').disable();
          this.addressForm.get('addrLine2').disable();
          this.addressForm.get('name').disable();

      } else {
        this.addressForm.get('zipCode').enable();
        this.addressForm.get('addrLine1').enable();
        this.addressForm.get('addrLine2').enable();
        this.addressForm.get('name').enable();
      }
  }
 
}
