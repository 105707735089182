import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-distance-calculator',
  templateUrl: './distance-calculator.component.html',
  styleUrls: ['./distance-calculator.component.scss']
})
export class DistanceCalculatorComponent implements OnInit {

    addrDistForm: FormGroup;

    origin:any;
    destination:any;

    originAddr: any;
    destAddr: any;
    distanceData: any = [];

    errorMessage: any;
 
constructor( private router: Router, private _formBuilder: FormBuilder,private scHttpService: ScHttpService) {
  
 }

  ngOnInit() {

    this.loadFormData();
  }

  loadFormData(){

    this.addrDistForm = this._formBuilder.group({
     from: [''],
     to: [''],
     distance: [{ disabled: true}],
     duration: [{ disabled: true}]
	 
   });

 }
  
  reset(){
   this.distanceData = [];
   this.loadFormData();
  }
  
  cancel(){
    this.router.navigateByUrl("shipcarte/dashboard");
  }

  calculateDistance() {
   
    this.errorMessage = '';
    let formData = this.addrDistForm.getRawValue();
    this.origin = formData["from"];
    this.destination = formData["to"];

    let url = ServerURLS.geoDistanceDetails.action + "/" + this.origin + "/" + this.destination;

   let promise_ = this.scHttpService.getHttpService(url, '');

    promise_.then(value => { 

      this.distanceData = value["data"];

      if(value){

        this.originAddr = this.distanceData["originAddr"];
        this.destAddr = this.distanceData["destinationAddr"];

        this.addrDistForm.setValue ({
          'from': this.origin,
          'to': this.destination,
          'distance': this.distanceData["distance"],
          'duration': this.distanceData["duration"],
         
        });

      }
     
    });

    promise_.catch(error => { // on error and other than http code:200
      this.errorMessage =  error['message'];
    });

  }

}