import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/tutorials', pathMatch: 'full' },
  { path: 'shipcarte/tutorials', component: TutorialsComponent},

];

@NgModule({
  declarations: [TutorialsComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
  
  ]
})

export class TutorialsModule { }
