import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuotesComponent } from './quotes/quotes.component';
import { QuoteViewComponent } from './quote-view/quote-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteDocumentComponent } from './quote-document/quote-document.component';
import { QuoteLocationComponent } from './quote-location/quote-location.component';
import { QuotePaymentComponent } from './quote-payment/quote-payment.component';
import { ConfirmOrderComponent } from './confirm-order/confirm-order.component';
import { QuoteResultsComponent } from './quote-results/quote-results.component';
import { QuoteOrderListComponent } from './quote-order-list/quote-order-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/quote/save', component: QuoteFormComponent},
  { path: 'shipcarte/quote/list', component: QuotesComponent},
  { path: 'shipcarte/quote/view', component: QuoteViewComponent},
  { path: 'shipcarte/quote/location', component: QuoteLocationComponent},
  { path: 'shipcarte/quote/payment', component: QuotePaymentComponent},
  { path: 'shipcarte/quote/document', component: QuoteDocumentComponent},
  { path: 'shipcarte/quote/confirm/order', component: ConfirmOrderComponent},
  { path: 'shipcarte/quote/results', component: QuoteResultsComponent},
  { path: 'shipcarte/quote/order/list', component: QuoteOrderListComponent},

];

@NgModule({
  declarations: [QuoteFormComponent, QuoteOrderListComponent, QuotesComponent, QuoteViewComponent, QuoteDocumentComponent, QuoteLocationComponent, QuotePaymentComponent, ConfirmOrderComponent, QuoteResultsComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule

  ]
})
export class QuoteModule { }
