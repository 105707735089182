import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { Permission } from '../../commons/pojo/permission';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-my-case-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './my-case-list.component.html',
  styleUrls: ['./my-case-list.component.scss']
})
export class MyCaseListComponent implements OnInit {

  casesList: any;
  permission: Permission;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  isDataReady = false;
  @ViewChild('loadContent', undefined) loadContent;

  constructor(private scHttpService: ScHttpService, private modalService: NgbModal) { 
    
    this.permission = new Permission();

    this.permission.write = false;
    this.permission.read = false;
    this.permission.action = false;
  }

  ngOnInit() {
    ///this.loadCasesList();
  }

  ngAfterViewInit() {
    this.loadCasesList();
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadCasesList();
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  loadCasesList( ): void {

    this.loadCotent(this.loadContent);

    let url = ServerURLS.getAllCaseIncidents.action + "/" + ( this.page - 1 ) + "/" + this.pageSize;

    this.isDataReady =false;
    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.casesList = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;
      this.isDataReady = true;

      this.closeModal();
 

    });

  }

  loadCotent(loadContent) {
    this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal' })
  }

  closeModal(){
    this.modalService.dismissAll();
  } 
  
}
