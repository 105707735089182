import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditSubmitComponent } from './credit-submit/credit-submit.component';
import { ProcessingComponent } from './processing/processing.component';

@NgModule({
  declarations: [CreditSubmitComponent, ProcessingComponent],
  imports: [
    CommonModule,
  ],
  exports: [CreditSubmitComponent, ProcessingComponent]
})
export class ModalModule { }
