import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-standard-charges',
  templateUrl: './standard-charges.component.html',
  styleUrls: ['./standard-charges.component.scss']
})
export class StandardCharges implements OnInit {
  options = [
    { label: 'Extra Charge', value: 'Extra Charge' },
    { label: 'Fuel Surcharge', value: 'Fuel Surcharge', disabled: true },
    { label: 'Freight Rate', value: 'Freight Rate', disabled: true },
    { label: 'Tax Item', value: 'Tax Item', disabled: true }
  ];
  myForm: FormGroup;
  myStatus: FormGroup;
  searchKeyValue: any = ''
  amountasControl = new FormControl();
  amountasOptions = [
    { value: 'F', label: 'Base charge is a fixed dollar amount' },
    { value: 'PFR', label: 'Base charge is a percentage of freight rate(s)' },
    { value: 'PA', label: 'Base charge is a percentage of all the charges' }
  ];

  statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },

  ];
  @ViewChild('popContentIntransit', undefined) createStandardChargePopUp;
  @ViewChild('loadContent', undefined) loadContent;
  isDataLoading: any = '';
  isDataLoadingByScroll: any = ''
  page = 0;
  pageSize = 20;
  collectionSize = 0;
  noOfPages: any = 0;
  isDesc: boolean = false;
  sortBy: any;
  isEdit: boolean;
  isNotEdit: boolean;
  searchKey: string = '';
  addStandardChargeForm: FormGroup;
  listOfStandardCharges: any = [];
  chargeItemList: any = [];
  data: any = []

  constructor(
    private modelService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: ScHttpService,
    private toastr: ToastrService,
    private listDataService: ListDataServiceService
  ) {
  }

  ngOnInit() {
    // this.myForm = this.formBuilder.group({
    //   selectedOption: [this.options[0].value]
    // });

    // this.myStatus = this.formBuilder.group({
    //   status: [this.statusOptions[0].value]
    // });
    //   <option value="RRE">Related to Revenue and Expense</option>
    //                       <option value="RR">Related to Revenue Only</option>
    //                       <option value="RE">Related to Expense Only</option>
    this.chargeItemList = [
      {
        "value": "RRE",
        'description': 'Related to Revenue and Expense'
      }, {
        "value": 'RR',
        'description': 'Related to Revenue Only'
      },
      {
        "value": 'RE',
        'description': 'Related to Expense Only'
      }

    ]
    this.sortBy = {
      "code": "downOrder",
      "type": 'downOrder',
      "description": 'downOrder',
      "rate": 'downOrder',
      "maximum": 'downOrder',
      "applycom": 'downOrder',
      "applufs": "downOrder",
      "chargetype": "downOrder",
      "status": "downOrder",

    }
    this.addStandardChargeForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      selectedOption: ['Extra Charge', [Validators.required]],
      description: [''],
      amountas: ['', [Validators.required,]],
      baseCharge: ['', [Validators.required, Validators.pattern(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/)]],
      nextTobaseCharge: [{ value: 'N/A', disabled: true }],
      chargeType: ['', [Validators.required]],
      status: [this.statusOptions[0], [Validators.required]],
      includeChargeAmount: [''],
      calculateFuelSurcharge: ['']
    })
    let dataFromServiceFile = this.listDataService.getDataOfStandardChargelist()
    // console.log(dataFromServiceFile)
    if (dataFromServiceFile.dataList.length > 0) {
      this.searchKeyValue = dataFromServiceFile.searchKey
      this.page = dataFromServiceFile.pageCount
      this.listOfStandardCharges = dataFromServiceFile.dataList
    } else {
      this.getListOfStandardCharges(this.page, this.pageSize, this.searchKey);
    }

  }
  getListOfStandardCharges(page, pageSize, searchkey) {
    this.allDataLoaded=false
    this.isDataLoading = 'Loading Your Options, Please wait...'
    let url = ServerURLS.getListOfStandardChargesInAdminSetting.action + '/' + page + '/' + this.pageSize;
    if (this.searchKey != '') {
      url = url + '?searchQuery=' + this.searchKey;
    }

    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.allDataLoaded = false
      this.data = res.response;
      this.collectionSize = res.totalEntries;

      this.listOfStandardCharges = []
      this.data.forEach((element, index) => {
        let chargetype = ''
        // if(element.charge_type==='RRE'){
        //   chargetype='Related to Revenue and Expense'
        // }else if(element.charge_type==='RR'){
        //   chargetype='Related to Revenue Only'
        // }else if(element.charge_type==='RE'){
        //   chargetype='Related to Expense Only'
        // }

        for (let data of this.chargeItemList) {
          if (element.charge_type === data.value) {
            chargetype = data.description
          }
        }
        this.listOfStandardCharges.push({
          "charge_id": element.charge_id ? element.charge_id : '',
          "code": element.charge_code ? element.charge_code : '',
          "type": element.type_name ? element.type_name : '',
          'description': element.charge_desc ? element.charge_desc : '',
          'rate': element.base_rate ? element.base_rate : '',
          'maximum': element.max_rate ? element.max_rate : '',
          'amountas': element.amount_as ? element.amount_as : 'N/A',
          'applycom': element.is_commission_enable ? 'true' : 'false',
          'applufs': element.is_fsc_enable ? 'true' : 'false',
          'chargetype': chargetype ? chargetype : '',
          'status': element.is_active ? 'true' : 'false'
        })
        let data = {
          pageCount: this.page,
          pageSize: this.pageSize,
          dataList: this.listOfStandardCharges
        }
        this.listDataService.setDataofStandardChargeList(data)
      })

      this.isDataLoading = ''


    })
      .catch((err: any) => {
        this.isDataLoading = ''

        this.toastr.error("Something Went Wrong!")
      })



    // let data = [
    //   {
    //     "charge_id": "9f8a9a57-909d-4623-88c2-a1b34276b7a3",
    //     "charge_code": "#C6667",
    //     "type_name": "Extra Charge",
    //     "base_rate": "69",
    //     "max_rate": "",
    //     "charge_type": "RRE",
    //     "charge_desc": "RRE",
    //     "amount_as": "F",
    //     "is_fsc_enable": true,
    //     "is_commission_enable": true,
    //     "is_active": true
    //   },
    //   {
    //     "charge_id": "909d-4623-88c2-a1b34276b7a3",
    //     "charge_code": "#C6668",
    //     "type_name": "Extra Charge",
    //     "base_rate": "65",
    //     "max_rate": "",
    //     "charge_type": "RRE",
    //     "charge_desc": "XXX",
    //     "amount_as": "PA",
    //     "is_fsc_enable": false,
    //     "is_commission_enable": false,
    //     "is_active": true
    //   },
    //   {
    //     "charge_id": "4623-88c2-a1b34276b7a3-9f8a9a57-909d",
    //     "charge_code": "#C6669",
    //     "type_name": "Extra Charge",
    //     "base_rate": "66",
    //     "max_rate": "",
    //     "charge_type": "RRE",
    //     "charge_desc": "RRE",
    //     "amount_as": "PFR",
    //     "is_fsc_enable": true,
    //     "is_commission_enable": true,
    //     "is_active": false
    //   }
    // ]




  }
  dataIndex: any = -1;
  openAddNewStandardChargeModelAndToEditCharge(key, index) {

    if (key === 'new') {
      this.dataIndex = -1;
      this.isNotEdit = true;
      this.isEdit = false;
    } else if (key === 'edit') {
      let chargeItem = ''
      let amountas = '';
      this.dataIndex = index
      for (let data of this.chargeItemList) {

        if (data.description === this.listOfStandardCharges[index].chargetype) {
          chargeItem = data.value
        }

      }
      for (let data of this.amountasOptions) {
        if (data.value == this.listOfStandardCharges[index].amountas) {
          amountas = data.label
        }
      }
      // console.log(amountas)
      // console.log(this.listOfStandardCharges[index])
      this.addStandardChargeForm.patchValue({
        code: this.listOfStandardCharges[index].code ? this.listOfStandardCharges[index].code : '',
        selectedOption: this.listOfStandardCharges[index].type ? this.listOfStandardCharges[index].type : '',
        description: this.listOfStandardCharges[index].description ? this.listOfStandardCharges[index].description : "",
        amountas: amountas ? amountas : '',
        baseCharge: this.listOfStandardCharges[index].rate ? this.listOfStandardCharges[index].rate : '',
        nextTobaseCharge: this.listOfStandardCharges[index].maximum ? this.listOfStandardCharges[index].maximum : '',
        chargeType: chargeItem ? chargeItem : '',
        status: this.listOfStandardCharges[index].status === 'true' ? this.statusOptions[0] : this.statusOptions[1],
        includeChargeAmount: this.listOfStandardCharges[index].applycom === 'true' ? true : false,
        calculateFuelSurcharge: this.listOfStandardCharges[index].applufs === 'true' ? true : false
      })
      this.isEdit = true;
      this.isNotEdit = false;
    }

    this.modelService.open(this.createStandardChargePopUp, { size: 'lg', centered: true, backdrop: true, windowClass: 'createNewStandardChargePopUp' }).result.then((result) => {



    }, (reason) => {
      // console.log(reason)
      this.modelService.dismissAll();
      this.addStandardChargeForm.reset();
      this.addStandardChargeForm.patchValue({
        selectedOption: 'Extra Charge',
        status: this.statusOptions[0]
      })
    })
  }
  saveUpdateStandardCharge() {
    // console.log("Test")
    if (this.isNotEdit) {

      if (this.addStandardChargeForm.invalid) {
        this.addStandardChargeForm.markAllAsTouched();
        this.openAddNewStandardChargeModelAndToEditCharge('new', -1);
      } else {
        let data = {
          "charge_code": this.addStandardChargeForm.get('code').value ? this.addStandardChargeForm.get('code').value : "",
          "type_name": this.addStandardChargeForm.get('selectedOption').value ? this.addStandardChargeForm.get('selectedOption').value : "",
          "base_rate": this.addStandardChargeForm.get('baseCharge').value ? this.addStandardChargeForm.get('baseCharge').value : '',
          "max_rate": 'N/A',
          "charge_type": this.addStandardChargeForm.get("chargeType").value ? this.addStandardChargeForm.get('chargeType').value : "",
          "charge_desc": this.addStandardChargeForm.get('description').value ? this.addStandardChargeForm.get('description').value : "",
          "amount_as": this.addStandardChargeForm.get('amountas').value ? this.addStandardChargeForm.get('amountas').value.value : '',
          "is_fsc_enable": this.addStandardChargeForm.get('calculateFuelSurcharge').value ? this.addStandardChargeForm.get('calculateFuelSurcharge').value : false,
          "is_commission_enable": this.addStandardChargeForm.get('includeChargeAmount').value ? this.addStandardChargeForm.get('includeChargeAmount').value : false,
          "is_active": this.addStandardChargeForm.get('status').value.value == 'Active' ? true : false
        }
        // console.log(this.addStandardChargeForm.get('status').value)
        this.saveStandardChargeToDataBase(data);

      }

    } else if (this.isEdit) {

      if (this.addStandardChargeForm.invalid) {
        this.addStandardChargeForm.markAllAsTouched();
        this.openAddNewStandardChargeModelAndToEditCharge('edit', this.dataIndex);
      } else {
        // console.log(this.addStandardChargeForm)

        let dataa = this.amountasOptions.filter((data => data.label == this.addStandardChargeForm.get('amountas').value));
        // console.log(dataa)
        let data = {
          "charge_id": this.listOfStandardCharges[this.dataIndex].charge_id, //This will be only sent in case of update
          "charge_code": this.addStandardChargeForm.get('code').value ? this.addStandardChargeForm.get('code').value : "",
          "type_name": this.addStandardChargeForm.get('selectedOption').value ? this.addStandardChargeForm.get('selectedOption').value : "",
          "base_rate": this.addStandardChargeForm.get('baseCharge').value ? this.addStandardChargeForm.get('baseCharge').value : '',
          "max_rate": 'N/A',
          "charge_type": this.addStandardChargeForm.get("chargeType").value ? this.addStandardChargeForm.get('chargeType').value : "",
          "charge_desc": this.addStandardChargeForm.get('description').value ? this.addStandardChargeForm.get('description').value : "",
          "amount_as": dataa[0]['value'],
          "is_fsc_enable": this.addStandardChargeForm.get('calculateFuelSurcharge').value ? this.addStandardChargeForm.get('calculateFuelSurcharge').value : false,
          "is_commission_enable": this.addStandardChargeForm.get('includeChargeAmount').value ? this.addStandardChargeForm.get('includeChargeAmount').value : false,
          "is_active": this.addStandardChargeForm.get('status').value.value == 'Active' ? true : false
        }
        // console.log(data)

        this.saveStandardChargeToDataBase(data)

      }

    }
  }
  isDataProcessing: any = ''
  saveStandardChargeToDataBase(data) {
    this.addStandardChargeForm.get('code').disable();
    this.addStandardChargeForm.get('selectedOption').disable();
    this.addStandardChargeForm.get('baseCharge').disable();
    this.addStandardChargeForm.get('chargeType').disable();
    this.addStandardChargeForm.get('description').disable();
    this.addStandardChargeForm.get('calculateFuelSurcharge').disable();
    this.addStandardChargeForm.get('includeChargeAmount').disable();
    this.addStandardChargeForm.get('status').disable()
    this.isDataProcessing = 'Processing...'

    let url = ServerURLS.saveStandardChargeInAdminSetting.action;
    let promise = this.httpService.postHttpService(url, data)
    promise.then((res: any) => {
      this.page = 0
      this.addStandardChargeForm.reset()
      this.addStandardChargeForm.reset();
      this.addStandardChargeForm.patchValue({
        selectedOption: 'Extra Charge',
        status: this.statusOptions[0]
      })
      this.modelService.dismissAll()
      if (this.isNotEdit) {
        this.toastr.success("Charge saved successfully")
        this.getListOfStandardCharges(this.page, this.pageSize, this.searchKey)
      } else {
        // console.log(data)
        let obj = {
          amountas: data.amount_as,
          applufs: data.is_fsc_enable,
          applycom: data.is_commission_enable,
          charge_id: data.charge_id,
          chargetype: data.charge_type == 'RRE' ? 'Related to Revenue and Expense' : data.charge_type == 'RR' ? 'Related to Revenue Only' : data.charge_type == 'RE' ? 'Related to Expense Only' : '',
          code: data.charge_code,
          description: data.charge_desc,
          maximum: data.max_rate,
          rate: data.base_rate,
          status: data.is_active,
          type: data.type_name
        }
        this.enableForm()
        this.listDataService.updateStandardChargeData(obj)
        this.toastr.success("Data updated Successfully")
      }
      this.isDataProcessing = ''
    })
      .catch((err: any) => {
        this.isDataProcessing = ''
        this.enableForm();

        this.toastr.error(`${err.message ? err.message : "Something Went Wrong!"}`)
      })


  }
  enableForm() {
    this.addStandardChargeForm.get('code').enable();
    this.addStandardChargeForm.get('selectedOption').enable();
    this.addStandardChargeForm.get('baseCharge').enable();
    this.addStandardChargeForm.get('chargeType').enable();
    this.addStandardChargeForm.get('description').enable();
    this.addStandardChargeForm.get('calculateFuelSurcharge').enable();
    this.addStandardChargeForm.get('includeChargeAmount').enable();
    this.addStandardChargeForm.get('status').enable()
  }
  closeAddStandardChargePopUp() {
    this.modelService.dismissAll()
  }
  sort(property) {

    this.isDesc = !this.isDesc; //change the direction

    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "downOrder" : "upOrder";

    if (property == "rate") {
      this.listOfStandardCharges.sort(function (a, b) {
        if (parseFloat(a[property]) < parseFloat(b[property])) {
          return -1 * direction;
        }
        else if (parseFloat(a[property]) > parseFloat(b[property])) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
    else {
      this.listOfStandardCharges.sort(function (a, b) {

        if (a[property] == null) {
          a[property] = '';
        }
        if (b[property] == null) {
          b[property] = '';
        }
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * direction;
        }
        else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return 1 * direction;
        }

      });
    }
  };
  deleteStandardCharge(index) {
    this.openModel();

    let url = ServerURLS.deleteStandardChargesInAdminSettingById.action + '?standardChargeId=' + this.listOfStandardCharges[index]['charge_id'];
    let promise = this.httpService.deleteHttpService(url, '');
    promise.then((res: any) => {

      this.closeModel()
      this.toastr.success("Standard Charge Removed Successfully", '', { timeOut: 3000 })
      this.listDataService.removeStandardChargeItem(index)
    })
      .catch((err: any) => {
        this.closeModel()
        this.toastr.error(`${err.message ? err.message : 'Something Went'}`, '', { timeOut: 3000 })

      })
  }
  onChangeListLength(value) {
    this.getListOfStandardCharges(this.page, value, this.searchKey)
  }
  loadPage(page) {
    this.getListOfStandardCharges(page, this.pageSize, this.searchKey)
  }
  setSearchKey(value) {
    if (value.length >= 3) {
      this.page = 0
      this.listDataService.setSearchKeyOfStandardCharge(value)
      this.searchKey = value;
      this.applyFilter();
    } else if (value.length == 0) {
      this.listDataService.setSearchKeyOfStandardCharge('')
      this.searchKey = '';
      this.page=0
      this.applyFilter();
    }

  }
  applyFilter() {
    this.getListOfStandardCharges(this.page, this.pageSize, this.searchKey)
  }
  openModel() {
    // this.modelService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modelService.dismissAll();
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerStandardCharges(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerStandardCharges(e: any) {

    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      // console.log(window.innerHeight + window.scrollY, document.body.offsetHeight)
      const scrollableDiv = document.querySelector('.scrollable-div');
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {
        // console.log('hello')
        // console.log(this.allDataLoaded)
        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    // console.log("from stadndard")
    this.page = this.page + 1;

    let url = ServerURLS.getListOfStandardChargesInAdminSetting.action + '/' + this.page + '/' + this.pageSize;
    if (this.searchKey != '') {
      url = url + '?searchQuery=' + this.searchKey;
    }

    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isLoading = false;

      if (res) {
        this.data = res.response
        let listOfStandardCharges = []
        this.data.forEach((element, index) => {

          let chargetype = ''
          // if(element.charge_type==='RRE'){
          //   chargetype='Related to Revenue and Expense'
          // }else if(element.charge_type==='RR'){
          //   chargetype='Related to Revenue Only'
          // }else if(element.charge_type==='RE'){
          //   chargetype='Related to Expense Only'
          // }

          for (let data of this.chargeItemList) {
            if (element.charge_type === data.value) {
              chargetype = data.description
            }
          }

          this.listOfStandardCharges.push({
            "charge_id": element.charge_id ? element.charge_id : '',
            "code": element.charge_code ? element.charge_code : '',
            "type": element.type_name ? element.type_name : '',
            'description': element.charge_desc ? element.charge_desc : '',
            'rate': element.base_rate ? element.base_rate : '',
            'maximum': element.max_rate ? element.max_rate : '',
            'amountas': element.amount_as ? element.amount_as : 'N/A',
            'applycom': element.is_commission_enable ? 'true' : 'false',
            'applufs': element.is_fsc_enable ? 'true' : 'false',
            'chargetype': chargetype ? chargetype : '',
            'status': element.is_active ? 'true' : 'false'
          })

          let data = {
            pageCount: this.page,
            pageSize: this.pageSize,
            dataList: this.listOfStandardCharges
          }
          this.listDataService.setDataofStandardChargeList(data)
        })

        // this.listOfStandardCharges=this.listOfStandardCharges.concat(listOfStandardCharges)


      } else {
        this.allDataLoaded = true;
        this.isLoading = false
      }

    })
      .catch((err: any) => {


        this.isLoading = false
      })
  }
}
