import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuotePaymentForm } from '../../quote/quote-payment/quotepaymentform';
import { ActivatedRoute, Router } from '@angular/router';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ScStorageService } from '../../../services/storage/sc-storage.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { ServerURLS, EnvironmentConstants } from '../../../../environments/environment';
import { paymentGateWayCADForm } from './cadPaymtGatewayform';
import { paymentGateWayUSDForm } from './usdPaymtGateWayform';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['./quote-payment.component.scss']
})
export class QuotePaymentComponent implements OnInit {

  errMsg: String;
  successMsg: string;
  quoteId: string;
  paymtForm: FormGroup;
  locationForm: FormGroup;
  quotePayment: QuotePaymentForm;
  paymentMethods: any;
  quotePrice: any;
  paymentDetails: any;
  address: any;
  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  isPincodeReleased: boolean = false;

  loggedUserId: any;

  monerisConstants: any;
  pinCode: any;
  offerCode: String;
  isCouponCodeExists: boolean = false;

  paymentData: any;
  psStoreId: string;
  message: any;
  hasErrorFromPG: any;
  retryPaymentNumber: any;
  entity: any;

  sourceAddrId: string;
  destAddrId: string;
  addressData: any;

  public isNet30Cutomer: boolean;
  cadPaymentForm: paymentGateWayCADForm;
  usdPaymentForm: paymentGateWayUSDForm;

  net30PaymentError: any;
  errorMessage: any;

  constructor(private modalService: NgbModal,private route: ActivatedRoute, private storageService: ScStorageService, private router: Router, private _formBuilder: FormBuilder,
    private scHttpService: ScHttpService, public scAuthService: ScAuthService) {

    this.quotePayment = new QuotePaymentForm();
    this.cadPaymentForm = new paymentGateWayCADForm();
    this.usdPaymentForm = new paymentGateWayUSDForm();

    this.monerisConstants = {
      "USD": {
        "action": "https://esplusqa.moneris.com/DPHPP/index.php",
        "storeId": "7DNP2qa002",
        "hpp_key": "hp5YKNVM5RNC"
      },
      "CAD": {
        "action": "https://esqa.moneris.com/HPPDP/index.php",
        "storeId": "gwca025293",
        "hpp_key": "hpN6BNDE2XV5"
      }

    }
  }

  ngOnInit() {
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    this.message = this.route.snapshot.paramMap.get('message');
    this.hasErrorFromPG = this.route.snapshot.paramMap.get('payerror');

    if (this.hasErrorFromPG) {
      this.retryPaymentNumber = "-repeat-" + this.route.snapshot.paramMap.get('append');
    } else {
      this.retryPaymentNumber = ""
    }

    this.loggedUserId = this.scAuthService.getLoggedUserId();
    this.entity = this.storageService.getBucket("entity");

    this.getQuotePrice();
    this.loadFormData();
    this.paymntMethods();
    this.getQuotePaymentAddressId();
    this.getPaymentData();

    if (this.entity) {
      this.getQuote();
      this.initAddressData();
    }

  }

  viewQuote() {

    if (this.quoteId) {
      this.router.navigateByUrl("shipcarte/quote/view;quote-id=" + this.quoteId);
    }

  }

  loadFormData() {

    this.paymtForm = this._formBuilder.group({

      quoteId: [this.quoteId],
      paymtStts: ['SUCCESS'],
      paymtGatewayRefId: [],
      freightChrgs: [],
      total: [],
      paymts: [],
      amountDue: [],
      paymtMethod: [''],
      couponCode: [],
      sttsMsg: [],
      paymentId: [],
      isTerms: []
    });

    this.locationForm = this._formBuilder.group({
      zipCode: [this.quotePayment.addrId.zipCode, Validators.required],
      city: { value: this.quotePayment.addrId.city, disabled: true },
      state: [{ value: this.quotePayment.addrId.state, disabled: true }],
      country: [{ value: this.quotePayment.addrId.country, disabled: true }],
      addrLine1: ['', Validators.required],
      addrLine2: [''],
      firstName: ['', Validators.required], //,Validators.required
      lastName: [''],
      company: [''],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.PHONE_REGEXP)]],
      alternatePhoneNumber: [''],
      dept: [''],
      fax: [''],
      addressId: []
    });

  }

  get f() { return this.paymtForm.controls; }
  get l() { return this.locationForm.controls; }

  saveCADQuotePayment() {
// debugger;
    let form_data = this.locationForm.getRawValue();
    let form_data1 = this.paymtForm.getRawValue();
    this.quotePayment = form_data1;

    this.isNet30Cutomer = true;

    if (!this.quotePayment.isTerms) {

      alert("Please check the terms and conditions");
    } else {

      this.quotePayment.addrId = this.locationForm.getRawValue();

      //let url = "http://localhost:9098/payment-module/net30/receive/cad/ack"
      let url = EnvironmentConstants.CA_NET30_PAYMENT_WS; //"http://localhost:9098/payment-module/net30/receive/usd/ack"

      this.cadPaymentForm.responseOrderId = ( this.quotePayment.addrId.addressId ? this.quotePayment.addrId.addressId : '') + "|" + this.quoteId ;
      this.cadPaymentForm.chargeTotal = this.quotePrice.totalCost;
      this.cadPaymentForm.custId = this.loggedUserId;
      this.cadPaymentForm.billAddressOne = form_data.addrLine1;
      this.cadPaymentForm.billCompanyName = form_data.company;
      this.cadPaymentForm.billFirstName = form_data.firstName;
      this.cadPaymentForm.billLastName = form_data.lastName;
      this.cadPaymentForm.billStateOrProvince = form_data.state;
      this.cadPaymentForm.billCity = form_data.city;
      this.cadPaymentForm.billCountry = form_data.country;
      this.cadPaymentForm.billPostalCode = form_data.zipCode;
      this.cadPaymentForm.billPhone = form_data.phoneNumber;
      this.cadPaymentForm.email = form_data.email;

      this.cadPaymentForm.txnNum = "TXN-NET30";

      if (this.paymentDetails != null) {
        this.quotePayment.paymentId = this.paymentDetails.paymentId;
      }
      let promise = this.scHttpService.postHttpService(url, this.cadPaymentForm);

      promise.then((resolve) => {
        let response = resolve;
        this.errMsg = '';

        //this.successMsg = response["message"];

        if (response && response['ackId']) {
          this.router.navigateByUrl("/shipcarte/order/successful;tracker-id=" + response['ackId']);
        } else {
          this.errMsg = 'Something went wrong please contact admin';
        }

        //this.storageService.saveBucket("orderMetaInfo",  response["data"]);


      }).catch((error) => {
        // debugger;
        if(error.error) {
          this.errorMessage = error.error;
          
        } else {
          this.errorMessage = error.data;
        }
        
        if(error.errorId === '2403'){
          this.net30PaymentError = error.data;
          this.storageService.saveBucket("isNet30Customer", 0);
        }

        this.successMsg = '';
      });

    }

  }

  saveUSDQuotePayment() {

    let form_data = this.locationForm.getRawValue();
    let form_data1 = this.paymtForm.getRawValue();
    this.quotePayment = form_data1;
    this.isNet30Cutomer = true;

    if (!this.quotePayment.isTerms) {
      alert("Please check the terms and conditions");

    } else {

      this.quotePayment.addrId = this.locationForm.getRawValue();

     // // console.log(this.quotePayment.addrId)

      //debugger;
      let url = EnvironmentConstants.US_NET30_PAYMENT_WS; //"http://localhost:9098/payment-module/net30/receive/usd/ack"

      // this.usdPaymentForm.orderNo = this.quoteId;
      // this.usdPaymentForm.amount = this.quotePrice.totalCost;
      // this.usdPaymentForm.custId = this.loggedUserId;
      // this.usdPaymentForm.odBillAddress = form_data.addrLine1;
      // this.usdPaymentForm.odBillCompany = form_data.company;
      // this.usdPaymentForm.odBillFirstname = form_data.firstName;
      // this.usdPaymentForm.odBillLastname = form_data.lastName;
      // this.usdPaymentForm.odBillState = form_data.state;
      // this.usdPaymentForm.odBillCity = form_data.city;
      // this.usdPaymentForm.odBillCountry = form_data.country;
      // this.usdPaymentForm.odBillZipcode = form_data.zipCode;
      // this.usdPaymentForm.odBillPhone = form_data.phoneNumber;
      // this.usdPaymentForm.txnNum = "TXN-NET30";

      // if (this.paymentDetails != null) {
      //   this.quotePayment.paymentId = this.paymentDetails.paymentId;
      // }
      // this.usdPaymentForm.orderNo = this.quoteId;
      // let authPromise = this.scHttpService.postHttpService(url, this.usdPaymentForm);

      // authPromise.then((resolve) => {
      //   let response = resolve;
      //   this.errMsg = '';

      //   //this.successMsg = response["message"];
      //   this.storageService.saveBucket("orderMetaInfo", response);
      //   if (response && response['ackId']) {
      //     this.router.navigateByUrl("/shipcarte/order/successful;tracker-id=" + response['ackId']);

      //   } else {
      //     this.errMsg = 'Something went wrong. Please contact admin';
      //   }

        //this.storageService.saveBucket("orderMetaInfo",  response["data"]);

      this.cadPaymentForm.responseOrderId = ( this.quotePayment.addrId.addressId ? this.quotePayment.addrId.addressId : '') + "|" + this.quoteId ;
      this.cadPaymentForm.chargeTotal = this.quotePrice.totalCost;
      this.cadPaymentForm.custId = this.loggedUserId;
      this.cadPaymentForm.billAddressOne = form_data.addrLine1;
      this.cadPaymentForm.billCompanyName = form_data.company;
      this.cadPaymentForm.billFirstName = form_data.firstName;
      this.cadPaymentForm.billLastName = form_data.lastName;
      this.cadPaymentForm.billStateOrProvince = form_data.state;
      this.cadPaymentForm.billCity = form_data.city;
      this.cadPaymentForm.billCountry = form_data.country;
      this.cadPaymentForm.billPostalCode = form_data.zipCode;
      this.cadPaymentForm.billPhone = form_data.phoneNumber;
      this.cadPaymentForm.email = form_data.email;

      this.cadPaymentForm.txnNum = "TXN-NET30";

      if (this.paymentDetails != null) {
        this.quotePayment.paymentId = this.paymentDetails.paymentId;
      }
      let promise = this.scHttpService.postHttpService(url, this.cadPaymentForm);

      promise.then((resolve) => {
        let response = resolve;
        this.errMsg = '';

        if (response && response['ackId']) {
          this.router.navigateByUrl("/shipcarte/order/successful;tracker-id=" + response['ackId']);
        } else {
          this.errMsg = 'Something went wrong please contact admin';
        }

      }).catch((error) => {
        // debugger;
        if(error.error) {
          this.errorMessage = error.error;
          
        } else {
          this.errorMessage = error.data;
        }

        if(error.errorId === '2403'){
          this.net30PaymentError = error.data;
          this.storageService.saveBucket("isNet30Customer", 0);
        }

        this.successMsg = '';
      
      });

    }

  }
  loadZipCodeData(event) {

    this.isPincodeReleased = true;
    this.pinCode = event.srcElement.value;//formData["zipCode"];

    if (this.pinCode == "" || this.pinCode.length < 5) {
      this.locationForm.patchValue({
        'zipCode': '',
        'city': '',
        'state': '',
        'country': '',
      });
      return;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + this.pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => {

      this.quotePayment.addrId.city = value["data"]["city"];
      this.quotePayment.addrId.state = value["data"]["state"];
      this.quotePayment.addrId.country = value["data"]["country"];

      // let srcState =  this.quotePayment.addrId.state;
      // let len = srcState.trim().split(" ");
      // let state = srcState.replace( len[(len.length-1)]+"", "");
      // this.quotePayment.addrId.state = state.trim();

      this.locationForm.patchValue({
        'zipCode': this.pinCode,
        'city': this.quotePayment.addrId.city,
        'state': this.quotePayment.addrId.state,
        'country': this.quotePayment.addrId.country,
      });

    });

    promise_.catch(error => { // on error and other than http code:200
      //this.errMsg =  error['message'];
      this.locationForm.patchValue({
        'zipCode': '',
        'city': '',
        'state': '',
        'country': '',
      });
    });

  }

  paymntMethods(): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.quotePaymtMethods.action, '');
    promise1.then(value => {
      this.paymentMethods = value["data"];
    });

  }

  currencyUnit: any;
  getQuotePrice(): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getQuotePrice.action + "?id=" + this.quoteId, '');
    promise1.then(value => {
      

      this.quotePrice = value["data"];

      if (this.quotePrice) {

        this.currencyUnit = this.quotePrice.currencyUnit;
        //this.quotePrice.currencyUnit = 'CAD'; // Limittation: This needs to remove once timed out error got solution for USD payments

        if (!(this.quotePrice.isMuexpired) && this.quotePrice.isMuCostAllowed) {
          this.quotePrice.totalCost = parseFloat(this.quotePrice.appliedTotalCost).toFixed(2);

        } else {
          this.quotePrice.totalCost = parseFloat(this.quotePrice.totalCost).toFixed(2);
        }

      }

    });

  }

  getQuotePaymentAddressId(): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getQuotePaymentAddrId.action + "?id=" + this.quoteId, '');
    promise1.then(value => {
      this.paymentDetails = value["data"];

      if (this.paymentDetails != null) {
        this.getQuotePaymentAddressDetail(this.paymentDetails.addrId);
      }
    });

  }

  getQuotePaymentAddressDetail(id): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getAddressById.action + "?id=" + id, '');
    promise1.then(value => {
      this.address = value["data"];
      this.locationForm.patchValue(this.address)

      // Value setting for repeat order
      this.quotePayment.addrId.city = value["data"]["city"];
      this.quotePayment.addrId.state = value["data"]["state"];
      this.quotePayment.addrId.country = value["data"]["country"];

    });
  }

  loadCouponCode(event) {

    this.isCouponCodeExists = true;
    let offerCode = event.srcElement.value;
    if (offerCode != "") {
      this.getCouponCode(offerCode);
    } else {
      this.errMsg = '';
      this.offerCode = '';
    }

  }

  getCouponCode(code): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getCouponCode.action + "?coupon-code=" + code, '');
    promise1.then(value => {

      if (value["data"] != null) {

        this.offerCode = value["data"];
        this.errMsg = '';

      } else {
        this.errMsg = value["message"];
      }

    });

  }

  getPaymentData(): void {

    let params = "?quote-id=" + this.quoteId;
    let promise1 = this.scHttpService.getHttpService(ServerURLS.getPaymentActionUrl.action + params, '');

    promise1.then(value => {
      this.paymentData = value["data"];
    });

  }

  initAddressData(): void {

    let url = ServerURLS.getAddressDetails.action;

    let promise1 = this.scHttpService.getHttpService(url + "?id=" + this.entity.defaultAddrId, '');
    promise1.then(value => {
      this.addressData = value["data"];
    });

  }

  getQuote(): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getQuote.action + "?quoteId=" + this.quoteId, '');
    promise1.then(value => {
      let quote = value["data"];

      if (quote) {
        this.sourceAddrId = quote["sourceAddr"];
        this.destAddrId = quote["destAddr"];
      }
    });

  }

  getSourceAddress(): void {
    let promise1 = this.scHttpService.getHttpService(ServerURLS.getAddressById.action + "?id=" + this.sourceAddrId, '');
    promise1.then(value => {

      this.locationForm.patchValue({
        'zipCode': value["data"]["zipCode"],
        'city': value["data"]["city"],
        'state': value["data"]["state"],
        'country': value["data"]["country"],
        'addrLine1': value["data"]["addrLine1"],
        'addrLine2': value["data"]["addrLine2"],
        'company': value["data"]["company"],
        'firstName': value["data"]["firstName"],
        'lastName': value["data"]["lastName"],
        'phoneNumber': value["data"]["phoneNumber"],
        'dept': value["data"]["dept"]
      });

    });

  }

  getDestAddress(): void {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getAddressById.action + "?id=" + this.destAddrId, '');

    promise1.then(value => {

      this.locationForm.patchValue({
        'zipCode': value["data"]["zipCode"],
        'city': value["data"]["city"],
        'state': value["data"]["state"],
        'country': value["data"]["country"],
        'addrLine1': value["data"]["addrLine1"],
        'addrLine2': value["data"]["addrLine2"],
        'company': value["data"]["company"],
        'firstName': value["data"]["firstName"],
        'lastName': value["data"]["lastName"],
        'phoneNumber': value["data"]["phoneNumber"],
        'dept': value["data"]["dept"]
      });
    });
  }

  selectAddr(event) {

    let value: string = event.target.value;
    this.locationForm.reset();

    if (value.includes("default")) {

      this.locationForm.patchValue({
        'zipCode': this.addressData.zipCode,
        'city': this.addressData.city,
        'state': this.addressData.state,
        'country': this.addressData.country,
        'addrLine1': this.addressData.addrLine1,
        'addrLine2': this.addressData.addrLine2,
        'company': this.addressData.company,
        'firstName': this.entity.name,
        'lastName': this.entity.lastName,
        'phoneNumber': this.entity.phoneNumber,
        'dept': this.addressData.dept
      });

    } else if (value.includes("shipper")) {
      this.getSourceAddress();

    } else if (value.includes("consignee")) {
      this.getDestAddress();

    }

  }

  toggleMode(event) {

    let value = event.target.checked;
    if (value) {
      this.isNet30Cutomer = value;
      this.currencyUnit
    } else {
      this.isNet30Cutomer = value;
    }
  }

  addressSearch(addrSrch) {
    this.modalService.open(addrSrch, {size: 'lg',centered: true,backdrop: 'static',ariaLabelledBy: 'modal-basic-title'});
  }

  getAddress(e) {
    this.locationForm.patchValue(e);
  }

  payMoney(quotePrice){
    localStorage.setItem('quotePrice',quotePrice.totalCost)
    localStorage.setItem('quotecheckoutId',this.quoteId)
 
    // alert(JSON.stringify(quotePrice)+'\n')
    // return;
    this.router.navigate(['shipcarte/quote/quote-checkout']);
  }
  
}


