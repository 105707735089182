import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';

@Component({
  selector: 'app-freight-calculator',
  templateUrl: './freight-calculator.component.html',
  styleUrls: ['./freight-calculator.component.scss']
})
export class FreightCalculatorComponent implements OnInit {

  frieghtCalc:any;
  frieghtCalcData:any;
  selectedUnit: any;
  unitArray: any = [];
  itemForm: FormGroup;
  counter: number = 0;
  weightArray: { text: string; unit: string; }[];
  selectedWgt: any;
  measurmentSytem:{ name: string; value: string; }[];
  measure:string;
  length:any;
  breadth:any;
  height:any;
  totalWeight:any;

  constructor(private _formBuilder: FormBuilder,private router: Router,private scHttpService: ScHttpService) { }

  ngOnInit() {

    this.weightArray = [
      {text: 'lbs', unit: "lbs"},
      {text: 'kg', unit: "kg"}
    ];

    this.unitArray = [
      {text: 'in', unit: "inch"},
      {text: 'cm', unit: "centimeter"},
      {text: 'ft', unit: "feet"}
    ];

    this.loadFormData();
  }

  loadFormData(){

    this.itemForm = this._formBuilder.group({
      length: ['',Validators.required],
      breadth: ['',Validators.required],
      height: ['',Validators.required],
      totalWeight: ['',Validators.required],
      lengthUnit: ['',Validators.required],
      weightUnit: ['',Validators.required],
  });

  }

  get f3() { return this.itemForm.controls; }

  selectUnit(lengthUnit){
    this.selectedUnit = lengthUnit;
    this.itemForm.get('lengthUnit').setValue(lengthUnit);
  }

  selectWgt(unit){
    this.selectedWgt = unit;
    this.itemForm.get('weightUnit').setValue(unit);
  }

  page = 1;
  pageSize = 5;
  collectionSize = 5; 

  calculate(): void  {

    let formData = this.itemForm.getRawValue();
    let url = ServerURLS.getFreightCalc.action;
    let param = "?length=" + formData.length + "&breadth=" + formData.breadth + "&height=" + formData.height + "&totalWeight=" + formData.totalWeight +
               "&lengthUnit=" + formData.lengthUnit + "&weightUnit=" + formData.weightUnit;
    let promise1 = this.scHttpService.getHttpService(url + param, "");
    promise1.then( value => {
      this.frieghtCalcData = value["data"];
    });
  }

  cancel(){
    this.router.navigateByUrl("shipcarte/dashboard");
  }

}
