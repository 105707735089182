import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { StepOneComponent } from './step-one/step-one.component';
import { RouterModule, Routes } from '@angular/router';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { EnvironmentConstants } from 'src/environments/environment';
import { AgmDirectionModule } from 'agm-direction';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CarrierSelectionComponent } from './carrier-selection/carrier-selection.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { InsuranceComponent } from './insurance/insurance.component';
import { ConfirmOrderComponent } from './confirm-order/confirm-order.component';
import { CustomInfoNormalformComponent } from './custom-info-normalform/custom-info-normalform.component';
import { ContactShipperComponent } from './contact-shipper/contact-shipper.component';
import { CustomInfoSignatureComponent } from './custom-info-signature/custom-info-signature.component';
import { ShipperAddressEditComponent } from './shipper-address-edit/shipper-address-edit.component';
import { CustomPackageComponent } from './custom-package/custom-package.component';
import { NewCustomPackageComponent } from './custom-package/new-custom-package/new-custom-package.component';
import { EditCustomPackageComponent } from './custom-package/edit-custom-package/edit-custom-package.component';
import { PaymentComponent } from './payment/payment.component';
// import { NgxPayPalModule } from 'ngx-paypal';

const routes: Routes = [
  { path: 'shipcarte/getQuote', component: QuoteListComponent },
  {path: 'shipcarte/step-one', component: StepOneComponent},
  {path: 'shipcarte/step-one/:quoteId', component: StepOneComponent},
  { path: 'shipcarte/carrier-selection/:id', component: CarrierSelectionComponent },   
  { path: 'shipcarte/confirm-order/:quoteId', component: ConfirmOrderComponent },   
  {path:'shipcarte/payment/:quoteId',component:PaymentComponent},
  {path:'shipcarte/payment',component:PaymentComponent}
]

@NgModule({
  declarations: [QuoteListComponent, StepOneComponent, CarrierSelectionComponent, InsuranceComponent, ConfirmOrderComponent, CustomInfoNormalformComponent, ContactShipperComponent, CustomInfoSignatureComponent, ShipperAddressEditComponent,CustomPackageComponent, NewCustomPackageComponent,EditCustomPackageComponent, PaymentComponent],
  entryComponents : [
    InsuranceComponent,
    CustomInfoNormalformComponent,
    ContactShipperComponent,
    CustomInfoSignatureComponent,
    ShipperAddressEditComponent,
    CustomPackageComponent,
    NewCustomPackageComponent,
    EditCustomPackageComponent
  ],
  imports: [
    NgbPaginationModule,
    NgSelectModule, 
    // NgxPayPalModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    GooglePlaceModule,
    FormsModule,ReactiveFormsModule,
    AgmCoreModule.forRoot({apiKey: EnvironmentConstants.GooglePlacesApiKey
    }),
    AgmDirectionModule
  ],
})
export class GetQuoteModule { }
