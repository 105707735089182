import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { Permission } from '../../commons/pojo/permission';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-case-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent implements OnInit {
  displayData=true;
  casesList:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;

  permission: Permission;
  status: string;
  trackerId: any;
  errorMsg: string;
  statusList: any;

  isDataReady = false;
  @ViewChild('loadContent', undefined) loadContent;
  
  constructor(private scHttpService: ScHttpService, private router: Router, private modalService: NgbModal) { 
    
    this.permission = new Permission();

    this.permission.write = true;
    this.permission.read = true;
    this.permission.action = true;
  }

  ngOnInit() {
    this.status = 'Submitted';
    this.loadCaseStatus();
   // this.loadCasesList(this.status);
  }

  ngAfterViewInit() {
    this.loadCasesList(this.status);
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadCasesList(this.status);
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  loadCasesList( status): void {

    this.loadCotent(this.loadContent);

      let url = ServerURLS.getAllCaseIncidents.action + "/" + ( this.page - 1 ) + "/" + this.pageSize +"?status=" +status;

      this.isDataReady =false;
      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {
        this.displayData=false;
        this.casesList = value["data"]["content"];
        this.pageSize = value["data"]["pageable"]["pageSize"];
        this.collectionSize = value["data"]["totalElements"];
        this.page = value["data"]["pageable"]["pageNumber"] + 1;
        this.noOfPages = value["data"]["totalPages"];

        let offset = value["data"]["pageable"]["offset"];
        this.startElementCount = offset + 1;
        this.endElementCount = offset + value["data"]["numberOfElements"];
        
        this.activePage = this.page;
        this.isDataReady = true;

        this.closeModal();
    

      });

    }

    reviewCase(id, status){

      let promise1 = this.scHttpService.postHttpService(ServerURLS.saveCaseStatusHistory.action + "?case-id=" + id +"&status=" + status,'');
      promise1.then( (resolve) => {
        let response = resolve;
        this.trackerId = response['data'];
        if(this.trackerId) {

           this.router.navigateByUrl("/shipcarte/case/view;case-id=" + id);
        } else {

          this.errorMsg = "Not able to review"
        }
      }).catch((error) => {
      
      });

    }

    loadCaseStatus(): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllCaseStatus.action,'');
      promise1.then( value => {
        this.statusList = value["data"];
      });
    
    }

    getCaseStaus(event){
      this.status = event.target.value;
      this.loadCasesList(this.status);
     }

    loadCotent(loadContent) {
      // this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static' })
    }

    closeModal(){
      this.modalService.dismissAll();
    } 
}
