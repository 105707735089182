import { Injectable } from '@angular/core';

import { FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angular-6-social-login';
import { Socialusers } from './socialusers';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from '../http/sc-http.service';
import { resolve, reject } from 'q';

@Injectable({
  providedIn: 'root'
})
export class ScSocialAuthServiceService {

  
  socialusers=new Socialusers();  
  constructor(  public OAuth: AuthService, private scHttpService: ScHttpService ) { }

  public socialSignOut () {
    this.OAuth.signOut().then(data => {  
 
    });  
  }
  public socialSignIn(socialProvider: string) {  
    let socialPlatformProvider;  
    if (socialProvider === 'facebook') {  
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;  
    } else if (socialProvider === 'google') {  
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;  
    }  

    if( this.OAuth ) {
      this.OAuth.signIn(socialPlatformProvider).then(socialusers => {  
        // // console.log(socialProvider, socialusers);  
        // // console.log(socialusers);  
        this.socialusers = socialusers;
     
        //this.Savesresponse(socialusers);  
      });  
    }  else {
 
    }
    
  } 

  variablePromise(term:string) {
    let promise = new Promise((resolve, reject) => {
      resolve(term);
    });
    return promise;
  }

  responseData: any;

  public socialSignUp(socialProvider: string, formData): Promise<any> {  

    this.socialSignOut();

    let socialPlatformProvider;  
   
    if (socialProvider === 'facebook') {  
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;  
    } else if (socialProvider === 'google') {  
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;  
    }  

    if( this.OAuth ) {

      // let promise = new Promise((resolve, reject) => {

      
        this.OAuth.signIn(socialPlatformProvider).then(socialusers => {  
          // // console.log(socialProvider, socialusers);  
          // // console.log(socialusers);  
          this.socialusers = socialusers;

          if( this.socialusers ) {
          let url = '';
            let actorType = formData.actorType;
            formData = {
              "email": this.socialusers.email,
              "password": this.socialusers.name,
            }

 
        
            if( actorType == 'customer') {
              url+= ServerURLS.registerCustomer.action;
            } else if ( actorType == 'partner') {
              url+= ServerURLS.registerPartner.action;
            }
          
             let httpPromise = this.scHttpService.postHttpService(url, formData);
             //return httpPromise;
              httpPromise.then( (result) => {

             // resolve (result)
           
              this.responseData = result;
              //resolve(result);
              // resolve ( result['message'] );
              }).catch ( (result) => {
    
                this.responseData = result;
                //resolve(result);
              // resolve ( result['message'] );
              });
            
          
          }
          //this.Savesresponse(socialusers);  
        });  
   
       
       
    }  else {
      
    }
    return this.variablePromise(this.responseData); 
  } 
}
