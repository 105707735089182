import { Address } from '../../profile/address';

export class BankInfo{

    bankInfoId: string;
    applicantId: string;
    bankName: string;
    contact: string;
    transit: string;
    account: string;
    tollFree: string;
    bankAddress: Address;

    constructor(){
        this.bankAddress = new Address();
    }
}