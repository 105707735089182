import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierDashboardComponent } from './carrier-dashboard/carrier-dashboard.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'shipcarte/dashboard/carrier-dashboard', component: CarrierDashboardComponent}

];

@NgModule({
  declarations: [CarrierDashboardComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ]
})
export class DashboardV2Module { }
