import { Component, OnInit } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-carrier-view',
  templateUrl: './carrier-view.component.html',
  styleUrls: ['./carrier-view.component.scss']
})
export class CarrierViewComponent implements OnInit {

  carrierData: any;
  quoteId:String;
  carrierName:String;
  carriers: any;

  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, private scAuthService: ScAuthService) { }

  ngOnInit() {
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    //this.carrierName = 'EP';

   // this.carrierRequestResponse(this.carrierName);
    this.loadCarriers();

    // this.carriers = [
    //   {name: 'Day & Ross', code: "DR"},
    //   {name: 'Polaris', code: "POL"},
    //   {name: 'YRC', code: "YRC"},
    //   {name: 'Canpar', code: "EP-CP"},
    //   {name: 'UPS', code: "EP-UPS"}
    // ];
  }

  carrierRequestResponse (carrierName): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCarrierQuoteReqResponse.action + "?quote-id=" + this.quoteId+"&carrier-name="+carrierName,'');
    promise1.then( value => {
       this.carrierData = value["data"];
      });
  }

  selectCarreir(event){
    this.carrierName = event.target.value;
    if(this.carrierName) { 
      this.carrierRequestResponse(this.carrierName)
    } else {
      this.carrierData = [];
    }
  }

  loadCarriers(): void {

    let data = this.scAuthService.getLoggedUserData();
    let partnerCarrier = "";

    if(data){
      partnerCarrier = data["carrierId"];
    }

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllCarriers.action, '');

    promise1.then( value => {
      this.carriers = value["data"];

      let list: any = [];

      if(partnerCarrier){
        this.carriers.forEach(element => {

        if(element.carrierId == partnerCarrier)
          list.push(element);
        });

        this.carriers = list;
      }

    });
  }
  
}