import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { RouterModule, Routes } from '@angular/router';
import { QuoteDetailPageComponent } from './quote-details/quote-detail-page/quote-detail-page.component';
import { QuoteDetailHeaderComponent } from './quote-details/quote-detail-header/quote-detail-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes: Routes = [
  
  {
      path: 'shipcarte/quoteHistory/allQuotes',
      component: QuoteListComponent
  } ,
  {
      path: 'shipcarte/quoteHistory/allQuotes/view/:fromDate/:toDate',
      component: QuoteListComponent
  } ,
  {
      path: 'shipcarte/quoteHistory/allQuotes/view/draft/:fromDate/:toDate',
      component: QuoteListComponent
  } ,
  {
      path: 'shipcarte/quoteHistory/quoteDetails',
      component: QuoteDetailHeaderComponent,
      loadChildren: () => import('./quote-details/quote-details.module').then(m => m.QuoteDetailsModule)
  } 
]


@NgModule({
  declarations: [QuoteListComponent,QuoteDetailHeaderComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    NgbModule,
        FormsModule,
        ReactiveFormsModule
  ]
})
export class QuoteHistoryModule { }
