import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {

  constructor(
   private router:Router
  ) { }

  ngOnInit() {
  }

  route(){
    this.router.navigate(['/shipcarte/step-one']);
  }
  

}
