import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditApplicantFormComponent } from './credit-applicant-form/credit-applicant-form.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { BillInfoComponent } from './bill-info/bill-info.component';
import { CreditReferenceComponent } from './credit-reference/credit-reference.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { ViewComponent } from './view/view.component';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditApplicantDocumentComponent } from './credit-applicant-document/credit-applicant-document.component';
import { DownloadInvoiceComponent } from './download-invoice/download-invoice.component';

const routes: Routes =[
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/credit/applicant/add', component: CreditApplicantFormComponent},
  { path: 'shipcarte/credit/applicant/bank/info', component: BankInfoComponent},
  { path: 'shipcarte/credit/applicant/billing/info', component: BillInfoComponent},
  { path: 'shipcarte/credit/applicant/reference/info', component: CreditReferenceComponent},
  { path: 'shipcarte/credit/applicant/company/info', component: CompanyInfoComponent},
  { path: 'shipcarte/credit/applicant/payment/info', component: PaymentInfoComponent},
  { path: 'shipcarte/credit/applicant/view', component: ViewComponent},
  { path: 'shipcarte/credit/applicant/list', component: ApplicantListComponent},
  { path: 'shipcarte/credit/applicant/upload/document', component: CreditApplicantDocumentComponent},
  { path: 'shipcarte/credit/applicant/invoice', component: DownloadInvoiceComponent},
];

@NgModule({
  declarations: [CreditApplicantFormComponent, BankInfoComponent, BillInfoComponent, CreditReferenceComponent, CompanyInfoComponent, PaymentInfoComponent, ViewComponent,ApplicantListComponent, CreditApplicantDocumentComponent, DownloadInvoiceComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
    
  ]
})
export class CreditappModule { }
