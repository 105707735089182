import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AuthFormComponent } from './auth-form/auth-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { ScSocialAuthServiceService } from '../../services/social/sc-social-auth-service.service';
import { SetPasswordComponent } from './set-password/set-password.component';



const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/signin', component: SignInComponent},
  { path: 'shipcarte/signup', component: SignUpComponent},
  { path: 'shipcarte/user/login', component: AdminLoginComponent},
  { path: 'shipcarte/autz/actor', component: AuthFormComponent},
  { path: 'shipcarte/forgot/password', component: ForgotPasswordComponent},
  { path: 'shipcarte/set/password', component: SetPasswordComponent}

];

@NgModule({
  declarations: [SignInComponent, SignUpComponent, AdminLoginComponent, AuthFormComponent, ForgotPasswordComponent, SetPasswordComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
  ],
  providers: [ 
    ScSocialAuthServiceService
  ]  
})
export class AutnModule { }
