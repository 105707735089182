import { Component, OnInit } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherForm } from './voucherform';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-voucher-form',
  templateUrl: './voucher-form.component.html',
  styleUrls: ['./voucher-form.component.scss']
})
export class VoucherFormComponent implements OnInit {

  offerId: string;
  voucherform: FormGroup;

  errorMessage: any;
  successMessage: any;

  voucher: VoucherForm;
  voucherId: string;
  currentDate: any;

  constructor( private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe) { 
    this.voucher = new VoucherForm();
  }

  ngOnInit() {

    this.offerId = this.route.snapshot.paramMap.get('offer-id');
    this.voucherId = this.route.snapshot.paramMap.get('voucher-id');

    this.currentDate = this.datePipe.transform(new Date(),'yyyy-MM-ddThh:mm');

    if( this.voucherId ) {
       this.getVoucherDetails();
    }

    this.loadFormData();
  }

  loadFormData(){

     this.voucherform = this._formBuilder.group({
      description: ['', Validators.required],
      code: ['', Validators.required],
      noOfVouchers: ['', Validators.required],
      expiryDate: [],
      offerId: [this.offerId],
      uuid: [this.voucherId ? this.voucherId : null],

   });
   
 }

  get f() { return this.voucherform.controls; }

  addVoucherDetails() {

    this.voucher = this.voucherform.getRawValue();
    let promise : any;

    if(this.voucher.expiryDate){
      this.voucher.expiryDate = this.datePipe.transform(this.voucher.expiryDate,'yyyy-MM-dd hh:mm a');
    }

    if( this.voucherId ){
       promise = this.scHttpService.putHttpService(ServerURLS.updateVoucherDetail.action, this.voucher);

    } else {
      promise = this.scHttpService.postHttpService(ServerURLS.addVoucherDetail.action, this.voucher);
    }

    promise.then( (resolve) => {
      this.errorMessage = "";
      let response = resolve;
      this.successMessage = response["message"];
      this.router.navigateByUrl("shipcarte/offer/view;offer-id=" + this.offerId );
      
    }).catch((error) => {
      this.errorMessage = error.message;
    });
      
  }

  getVoucherDetails (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getVoucherDetail.action + "?voucherId=" + this.voucherId, '');
    promise1.then( value => {
      this.voucher = value["data"];

      if( this.voucher ){
        this.offerId = this.voucher.offerId;

        if(this.voucher.expiryDate){
          this.voucher.expiryDate = this.datePipe.transform(this.voucher.expiryDate,'yyyy-MM-ddThh:mm');
        }
        this.voucherform.patchValue(this.voucher);
      }

    });
  }

}
