import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Permission } from '../../commons/pojo/permission';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-case-history-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './case-history-list.component.html',
  styleUrls: ['./case-history-list.component.scss']
})
export class CaseHistoryListComponent implements OnInit {

  casesHistoryList: any;
  permission: Permission;
  caseId: any;
  isDataReady = false;
  @ViewChild('loadContent', undefined) loadContent;
  
  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, private modalService: NgbModal) { 
    
    this.permission = new Permission();

    this.permission.write = false;
    this.permission.read = false;
    this.permission.action = false;
  }

  ngOnInit() {
    this.caseId = this.route.snapshot.paramMap.get('case-id');
   // this.loadHistoryCasesList();
  }

  ngAfterViewInit() {
    this.loadHistoryCasesList();
  }

  loadHistoryCasesList(): void {

    this.loadCotent(this.loadContent);
    this.isDataReady =false;

      let url = ServerURLS.getAllCaseIncidentHistory.action + "?caseId=" + this.caseId;

      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {
        this.casesHistoryList = value["data"];

 
        this.isDataReady = true;
        this.closeModal();
      });

    }

    loadCotent(loadContent) {
      this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static' })
    }

    closeModal(){
      this.modalService.dismissAll();
    } 
}
