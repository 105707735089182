import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-info-normalform',
  templateUrl: './custom-info-normalform.component.html',
  styleUrls: ['./custom-info-normalform.component.scss']
})
export class CustomInfoNormalformComponent implements OnInit {
  customNormalForm: FormGroup;
  @Input() public customDropdownValues;
  @Input() public quoteId;
  contact:any;
  brInfo:any;
  brokerId:any;
  constructor(private activatedRoute: ActivatedRoute, public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }
  ngOnInit(): void {

    this.customNormalForm = this.formBuilder.group({
      brokerCompany:[ '', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
      ]],
      brokerName:[ '', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
      ]],
      brokerEmail: ['', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')
      ]],
      brokerPhone:['', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ]],
      
    });
    


  }
  closeModal() {
    this.activeModal.close();
  }
  get f(){

    return this.customNormalForm.controls;

}

  submit() {
    this.contact = {
      quoteId :this.quoteId,
      brokerId: this.brokerId ? this.brokerId : null,
      brokerCompany: this.customNormalForm.value.brokerCompany,
      brokerName: this.customNormalForm.value.brokerName,
      brokerPhone: this.customNormalForm.value.brokerPhone,
      brokerEmail: this.customNormalForm.value.brokerEmail,
    
    }
    this.activeModal.close(this.contact);
  }
}
