import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {

  quoteId:string;

  constructor(  private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
  }

  makePayment(){
    this.router.navigateByUrl("shipcarte/quote/payment;quote-id=" + this.quoteId);
  }
}
