import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { Options, CustomStepDefinition, LabelType } from 'ng5-slider';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, filter} from 'rxjs/operators';


@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {

  @Output() dataEvent = new EventEmitter();
  @Input() item: any;

  packageType: any;
  dimensionMap: Map<any, any>;
  packagingMap: Map<any, any>;
  selected: string;

  itemForm: FormGroup;
  packagingType: string;
  editQId: any;
  min: number;
  max: number;
  localVar:any;
  itemFormArray: any;
  errorMessage: string;

  freightTypes:any;
  freightMap: Map<any, any>;
  freightNameMap: Map<any, any>;

  rangeValue: number = 0;
  options: Options;

  freightArray: any = [];
  unitArray: any = [];
  selectedUnit: any;
  showItemList: boolean;
  items: any=  [];
  quoteId: any;

  counter: number = 0;
  freightRange: any;
  weightArray: { text: string; unit: string; }[];
  selectedWgt: any;
  measurmentSytem:{ name: string; value: string; }[];
  measure:string;

  isTypeLetter = false;

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  
  constructor( private modalService: NgbModal,  private router: Router, private scHttpService: ScHttpService, private _formBuilder: FormBuilder, 
    private storageService: ScStorageService, private route: ActivatedRoute) { 

    this.dimensionMap = new Map();
    this.packagingMap = new Map();
    
    this.freightNameMap = new Map();
    this.itemFormArray = [];
  }

  ngOnInit() {

    this.weightArray = [
      {text: 'lbs', unit: "lbs"},
      {text: 'kg', unit: "kg"}
    ];

    this.unitArray = [
      {text: 'in', unit: "inch"},
      {text: 'ft', unit: "feet"}
    ];

    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    let data = this.storageService.getBucket("shipment");

    if(data){
      this.packagingType = data;
    }
 
    if(this.packagingType == 'Pallet' || this.packagingType == 'Parcel'){
          
      this.measurmentSytem = [
          {name: 'Metric', value: "metric"},
          {name: 'Imperial', value: "imperial"},
        
        ];
    }

    this.loadQuoteItemData();
    this.packageTypeList();
    this.onChangePackaging();
    this.freightTypeList();

     if(this.item != null && this.item != undefined){
        this.getItem(this.item);

        //Default unit setting is inch
        this.selectUnit(this.item["unit"]);

        //Default weight unit setting is inch
        this.selectWgt(this.item["wghtUnit"]);

       } else {
        //Default unit setting is inch
        this.selectUnit("inch");
        
        //Default weight unit setting is inch
        this.selectWgt("lbs");
     }

     this.onValueChanges();
  }

  closeModal(){
    this.modalService.dismissAll();
  }

  selectMeasurement(key){

    this.measure = key;
    if( this.measure == 'metric'){
      this.unitArray = [
        {text: 'cm', unit: "cm"},
        {text: 'ft', unit: "feet"}
      ];
      this.selectedUnit = 'cm';

    } else {
      this.unitArray = [
        {text: 'in', unit: "inch"},
        {text: 'ft', unit: "feet"}
      ];

      this.selectedUnit = 'inch';
    }
    this.itemForm.get('unit').setValue(this.selectedUnit);
  }

  packageTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getPackageTypes.action,'');
    promise1.then( value => {
      this.packageType = value["data"];

      this.packageType.forEach(element => {
        this.dimensionMap.set(element.packageId, element.width + ":" + element.length);
        this.packagingMap.set(element.packageId, element.name);
      });

    });

  }

  loadQuoteItemData() {

    this.itemForm = this._formBuilder.group({
        qty: [],
        totalWeight: [],
        length: [],
        breadth: [],
        height: [],
        unit: ['inch'],
        wghtUnit: [],
        freightClass: [],
        packaging: [],
        quoteItemId: [],
        showFreight: '',
        freightRange: [],
        lengthType: ["imperial"]
    });
  }

  get f3() { return this.itemForm.controls; }

  onChangePackaging(){

      let item = undefined;
      item = this.itemForm;

      if( this.packagingType == 'Pak'){

          this.setValidator(item, 'totalWeight');
          this.setValidator(item, 'qty');
          this.setValidator(item,'wghtUnit');

          this.min =  1;
          this.max = 3;
          
      } else if ( this.packagingType == 'Pallet') {

          this.setValidator(item, 'length');
          this.setValidator(item, 'height');
          this.setValidator(item, 'breadth');
          this.setValidator(item, 'unit');
          this.setValidator(item, 'qty');
          this.setValidator(item, 'packaging');
          this.setValidator(item, 'totalWeight');
          this.setValidator(item, 'wghtUnit');
        
      } else if( this.packagingType == 'Parcel') {

          this.setValidator(item, 'length');
          this.setValidator(item, 'height');
          this.setValidator(item, 'breadth');
          this.setValidator(item, 'qty');
          this.setValidator(item, 'totalWeight');
          this.setValidator(item, 'unit');
          this.setValidator(item, 'wghtUnit');
      
      } else if (  this.packagingType == 'Letter') {

        this.removeValidator(item, 'length');
        this.removeValidator(item, 'breadth');
        this.removeValidator(item, 'height');
        this.removeValidator(item, 'freightClass');
        this.removeValidator(item, 'packaging');
        this.removeValidator(item, 'qty');
        this.removeValidator(item, 'totalWeight');
        this.removeValidator(item, 'unit');
        this.removeValidator(item, 'wghtUnit');

        item.get('length').setValue("1");
        item.get('breadth').setValue("1");
        item.get('height').setValue("1");
        item.get('totalWeight').setValue("2");
        item.get('wghtUnit').setValue("lbs");
        this.isTypeLetter =true;

      }

      if ( this.packagingType != 'Pallet' ) {
        this.removeValidator(item, 'freightClass');
        this.removeValidator(item, 'packaging');

        if( this.packagingType != 'Parcel' ) {

          if( this.packagingType == 'Pak' ) {
            this.removeValidator(item, 'length');
            this.removeValidator(item, 'breadth');
            this.removeValidator(item, 'height');

            item.get('length').setValue("1");
            item.get('breadth').setValue("1");
            item.get('height').setValue("1");

          } else {
            this.removeValidator(item, 'qty');
            this.removeValidator(item, 'totalWeight');
            this.removeValidator(item, 'unit');
            this.removeValidator(item, 'wghtUnit');
          }

        }
        
      } 

   }
  
   removeValidator(item, htmlFormField){
    let element = item.get(htmlFormField);
    element.clearValidators();
    element.updateValueAndValidity();
  }

  //Using this method because validator was not updating when it is set
  setValidator(item, htmlFormField){
    let element =  item.get(htmlFormField);
    element.setValidators(Validators.required);
    element.updateValueAndValidity();

  }
  
  setDimension( packagingId ){

    this.selected = packagingId;

    this.itemForm.get('packaging').setValue(packagingId);
    //this.itemForm.value['packaging'] = packagingId;
    let v = this.itemForm.value["length"];

    if(packagingId){

      let dimension = this.dimensionMap.get(packagingId);

      if(dimension && !(dimension.includes(null))){
        this.itemForm.patchValue({
          'length': dimension.split(":")[1],
          'breadth': dimension.split(":")[0]
        });
      } else {
        this.itemForm.patchValue({
          'length': null,
          'breadth': null
        });

      } 
    }

    if( packagingId ) {

      let packageName = this.packagingMap.get(packagingId);

      if( packageName.includes("Pallet")){
        this.itemForm.get('freightClass').setValidators(Validators.required);
        // SG: Commenting this line as this is blocked the deployment
        this.itemForm.patchValue({
          'showFreight': true,
        });

        } else {
          this.itemForm.get("freightClass").clearValidators();
          this.itemForm.patchValue({
            'freightClass': null,
            'showFreight': false,
          });
        }
        this.itemForm.get("freightClass").updateValueAndValidity();
      }
      
  }

  freightTypeList (): void {

    this.freightMap = new Map();
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getFreightClassTypes.action,'');
    promise1.then( value => {

      let freightTypes = value["data"];
      for (let index = 0; index < freightTypes.length; index++) {
      
        this.freightMap.set(freightTypes[index].freightType, freightTypes[index].freightId);
        this.freightNameMap.set(freightTypes[index].freightId, freightTypes[index].freightType);

        // if(freightTypes[index].freightType == "I dont know"){
        //   freightTypes.splice(index, 1);
        // }
        
        this.freightArray.push(freightTypes[index].freightType);
      }
      this.freightTypes = freightTypes;
      //this.setOptions();

    });

  }

  setOptions(){

    this.options = {
      stepsArray: this.freightArray.map((type: string): CustomStepDefinition => {
        return { value: this.typeToIndex(type) };
      }),
      translate: (value: number, label: LabelType): string => {
        return this.indexTotype(value);
      },
      showSelectionBar: true
    };

  }

  indexTotype(index: number): string {  
    return this.freightArray[index];
  }

  typeToIndex(type: string): number {
    return this.freightArray.indexOf(type);
  }

  getfreightType(event){

    this.itemForm.get('freightClass').setValue(null);
    let frieghtId: any;

    if(event.target.checked){
      frieghtId = this.freightMap.get(event.target.value);
      
    } else {
      frieghtId = null;
    }
    this.itemForm.get('freightClass').setValue(frieghtId);
  }

  sliderChange(val){
    
    this.itemForm.get('freightClass').setValue(null);
    let frieghtId: any;
    let index: any = this.rangeValue;

    if(val){
      frieghtId = this.freightMap.get(val);

    } else {
      frieghtId = null;
    }
    this.itemForm.get('freightClass').setValue(frieghtId);
  }

  selectUnit(unit){
    this.selectedUnit = unit;
    this.itemForm.get('unit').setValue(unit);
  }

  selectWgt(unit){
    this.selectedWgt = unit;
    this.itemForm.get('wghtUnit').setValue(unit);
  }

  increment(item){
    this.counter = Number(this.itemForm.get(item).value);

    this.counter = this.counter + 1;
    this.itemForm.get(item).setValue(this.counter);
  }

  decrement(item){
    this.counter = Number(this.itemForm.get(item).value);
    this.counter = this.counter - 1;

    if(this.counter <= 0 ){
      this.counter = 1;
    }

    this.itemForm.get(item).setValue(this.counter);
  }

  getItem( item ){

    //this.setOptions();
    this.selected = item.packaging;
    
    if(item.freightClass){
      this.itemForm.get('showFreight').setValue(true);
      this.itemForm.get('freightRange').setValue(item.freightClassType);

      // if(item.freightClass){
      //   item.freightClass = item.freightClass.split(",");
      //   this.itemForm.get('freightRange').setValue(item.freightClass[0]);
      // } 
    }
    this.selectMeasurement(item.lengthType);
    this.itemForm.patchValue(item);
  }

  createItem(){
      
      if(this.packagingType == 'Pallet' && !this.selected){
        this.errorMessage = "Please select packaging type";
  
      } else {
        this.itemFormArray = this.storageService.getBucket("items");
  
        if ( !this.itemFormArray ) {
          this.itemFormArray = [];
        }
  
        if(this.item != null && this.item != undefined){
          this.itemFormArray.splice(this.item.index, 1);
        } 
        this.itemFormArray.push(this.itemForm.value);
        
        this.storageService.saveBucket("items",  this.itemFormArray );
        this.dataEvent.emit(this.itemFormArray);
      }    
      this.closeModal();
    }

  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => term.length < 2 ? []
  //       : this.freightArray.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  //   )

    search = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.focus$;

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.freightArray
          : this.freightArray.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }

    onValueChanges(): void {
      this.itemForm.get('freightRange').valueChanges.subscribe(val=>{
        this.sliderChange(val);
      })
    }
 
}
