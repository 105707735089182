import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { ServerURLS } from '../../../../environments/environment';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})
export class AuthFormComponent implements OnInit {
  
  autzPhNumbrForm: FormGroup;
  autzOTP: FormGroup;

  errorMessage: any;
  successMessage: any;
  authStatus: boolean;
  successMesg: string;
  PHONE_REGEXP = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/;

  loggedUserId: any;
  loggedEntityType: any;
  profileForm: any;
  userData: any;
  type: string;

  constructor( private router: Router, private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private scAuthService: ScAuthService, private route: ActivatedRoute, private storageService: ScStorageService ) { }

  ngOnInit() {

    this.type =  this.route.snapshot.paramMap.get('type');
    this.userData = this.scAuthService.getLoggedUserData();
    
    this.loadFormData();
    this.loadOtpForm();
   
    this.loggedUserId = this.scAuthService.getLoggedUserId() ;
    this.loggedEntityType = this.scAuthService.getLoggedEntityType() ;

    this.initProfileData(this.loggedUserId);

  }

  loadFormData(){

    this.autzPhNumbrForm = this._formBuilder.group({
      phoneNumber: [,  [Validators.required]],
      countryCode: [,  [Validators.required]],
      loggedUserId :[this.scAuthService.getLoggedUserId()],
      actorType :[this.scAuthService.getLoggedEntityType()],

   });
  }
   loadOtpForm(){

    this.autzOTP = this._formBuilder.group({
      autzCode: ['',  [Validators.required]],
      loggedUserId :[this.scAuthService.getLoggedUserId()],
      actorType :[this.scAuthService.getLoggedEntityType()],
   });

 }

 get f() { return this.autzPhNumbrForm.controls; }
 get f1() { return this.autzOTP.controls; }


 authorizeContact() {
    
  let formData_ = this.autzPhNumbrForm.getRawValue();
  formData_.phoneNumber = formData_.countryCode + formData_.phoneNumber;

  let promise = this.scHttpService.postHttpService(ServerURLS.autzActor.action, formData_);
  promise.then( (result) => {
    this.successMessage =  result['message'];
    this.authStatus =  true;
  });

  promise.catch ( (result) => {
    this.errorMessage =  result['message'];
    this.successMessage = '';
    this.authStatus =  false;
  });
  
  }

  verifyOTP( type ) {
    
    let formData1_ = this.autzOTP.getRawValue();

    let action = "";

    if(type == 'phone'){
      action += ServerURLS.verfiyActor.action;

    } else if(type == 'email'){
      action += ServerURLS.verfiyEmailAutzcode.action;
    }

    let promise = this.scHttpService.postHttpService(action, formData1_);
    promise.then( (result) => {
      this.successMesg =  result['message'];
      this.authStatus =  true;
      this.userData = this.scAuthService.getLoggedUserData();

      if(type == 'phone' ){
        this.userData['isMobVerifd'] = true;
      } else if(type == 'email'){
        this.userData['isEmailVerifd'] = true;
      }
      this.storageService.saveBucket("entity", this.userData);
    });
  
    promise.catch ( (result) => {
      this.errorMessage =  result['message'];
      this.successMesg = '';
      this.authStatus =  false;
    });
     
    }

    cancel(){
      // this.router.navigateByUrl("/shipcarte/profile");
      this.router.navigateByUrl("/shipcarte/profilev2");
     }

     initProfileData (id): void {

      let url =  "" ;
  
      if( this.loggedEntityType =='customer'){
        url += ServerURLS.getCustomerDetails.action;
  
      } else if (  this.loggedEntityType =='partner'){
        url += ServerURLS.getPartnerDetails.action;
  
      } else if (  this.loggedEntityType == 'user' || this.loggedEntityType =='admin' ){
        url += ServerURLS.getUserDetails.action;
  
      } 
      
      let promise1 = this.scHttpService.getHttpService( url +"?id="+id,'');
      promise1.then( value => {
        this.profileForm = value["data"];
      });
  
    }
}
