import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, UrlHandlingStrategy } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormControlName, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ToastrService } from 'ngx-toastr';
import { url } from 'inspector';
import { DatePipe, formatDate } from '@angular/common';
import { start } from 'repl';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {
  sortBy: any;
  isDesc: any = false;
  searchKey: any = '';
  apiCall: Boolean = false;
  @ViewChild('endPicker', undefined) pickerEnd
  @ViewChild('startPicker', undefined) pickerStart
  @ViewChild('name', undefined) inputName;
  bols: any[];
  totalElements: any;
  totalPages = 1;
  currentPage: any;
  startDate: any = null;
  endDate: any = null;
  perPage = 250;
  dateFilterForm: FormGroup;
  dateParms: String;
  
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private httpService: ScHttpService,
    private commanService: ScCommonService,
    private toastService: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.apiCall = false;
    this.dateParms = '';
    this.dateFilterForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', Validators.required],
    });
    this.sortBy = {
      "extBolNo": "down",
      "carrierName": "down",
      "createDate": "down",
      "pickupDate": "down",
      "shipperCompanyName": "down",
      "orgCity": "down",
      "consigneeCompanyName": "down",
      "destCity": "down"
    }
    this.searchKey = "";
    this.currentPage = 1;
    this.perPage = 250;
    this.loadAllBolsWithFilter(this.currentPage - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
  }

  loadAllBolsWithFilter(page, search, startDate, endDate, items) {
    this.apiCall = true;
    const queryParamsObj: any = {};
    if ((startDate && !endDate)) {
      // // console.log('End Date is invalid.');
      this.toastr.error('End Date is invalid in search.', '', { timeOut: 3000 })
    } else if (!startDate && endDate) {
      // // console.log('Start Date is invalid.');
      this.toastr.error('Start Date is invalid in search.', '', { timeOut: 3000 })
    } else {
      if (search && startDate && endDate) {
        queryParamsObj.searchKey = search,
          queryParamsObj.startDate = startDate,
          queryParamsObj.endDate = endDate
      } else if (search && !startDate && !endDate) {
        queryParamsObj.searchKey = search
      } else if (!search && startDate && endDate) {
        queryParamsObj.startDate = startDate,
          queryParamsObj.endDate = endDate
      }
      const params = new URLSearchParams();
      for (const key in queryParamsObj) {
        if (queryParamsObj.hasOwnProperty(key)) {
          params.set(key, queryParamsObj[key]);
        }
      }
      const queryString = params.toString();
      let promise = this.httpService.getHttpService(ServerURLS.getBolListWithFilter.action + page + "/" + items + (queryString != '' ? '?' + queryString : ''), { withCredentials: true });
      promise.then((res) => {
        // console.log(res, 'response')
        this.bols = (res as any).response;
        this.totalElements = res['totalEntries'];
        this.totalPages = parseInt(res["totalPages"]);
        this.apiCall = false;
      }, error => {
        if (error['status'] === 401) {
          localStorage.clear();
          this.route.navigate(['/signin'])
        }
      })
    }
  }

  updateSearchKey(searchKey) {
    if (searchKey.length >= 3) {
      this.searchKey = searchKey;
      this.loadAllBolsWithFilter(this.currentPage - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
    } else if (searchKey.length === 0) {
      this.searchKey = '';
      this.loadAllBolsWithFilter(this.currentPage - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
    }
  }

  toggle(type) {
    if (type == 'start') {
      this.pickerStart.flatpickr.toggle()
      this.pickerStart.flatpickr.value = '';
    }
    else if (type == "end") {
      this.pickerEnd.flatpickr.toggle()
    }
  }

  apply() {
    const startDateControl = this.dateFilterForm.get('startDate');
    const endDateControl = this.dateFilterForm.get('endDate');

    if (startDateControl && endDateControl) {
      const startDateValue: NgbDate = startDateControl.value;
      const endDateValue: NgbDate = endDateControl.value;

      // Convert NgbDate values to Date objects
      const startDate = new Date(startDateValue.year, startDateValue.month - 1, startDateValue.day);
      const endDate = new Date(endDateValue.year, endDateValue.month - 1, endDateValue.day);

      // Format the Date objects using DatePipe
      this.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
      this.endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');

      // console.log(this.startDate, this.endDate);

      if (this.startDate && this.endDate) {
        const dateStart = Date.parse(this.startDate);
        const dateEnd = Date.parse(this.endDate);

        if (this.startDate.length > 0 && this.endDate.length > 0 && dateStart > dateEnd) {
          this.startDate = null;
          this.pickerStart.flatpickr.setDate(null);
          this.endDate = null;
          this.pickerEnd.flatpickr.setDate(null);
          this.dateFilterForm.reset();
          this.dateParms = '';
          // console.log('error');
          this.toastService.error('Please provide valid Dates.', '', {
            timeOut: 10000,
          });
          return;
        } else {
          this.dateParms = 'startDate=' + this.startDate + '&endDate=' + this.endDate;
        }
      }
    }

    this.loadAllBolsWithFilter(this.currentPage - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
  }

  createNewBOL() {
    this.route.navigate(['/quickBol/create-bol']);
  }

  sort(property) {
    this.isDesc = !this.isDesc;
    let direction = this.isDesc ? 1 : -1;
    this.sortBy[property] = !this.isDesc ? "down" : "up";
    this.bols.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  viewAction(load: string, extBolId: string) {
    if(load == 'view-bol'){
      this.route.navigate(['/quickBol/view-bol/summary/' + extBolId]);
    }else if (load == 'view-bol-doc'){
      this.route.navigate(['/quickBol/view-bol/documents/' + extBolId]);
    }
  }

  perPageItem(perPageRecord) {
    this.perPage = perPageRecord;
    this.loadAllBolsWithFilter(this.currentPage - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
  }

  loadPage(page) {
    this.loadAllBolsWithFilter(page - 1, this.searchKey, this.startDate, this.endDate, this.perPage);
  }

}