import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit, OnChanges  {

  @Input("isResultPage") isResultPage: boolean;
  @Input("costBreakup") costBreakup: any = [];

  sourceAddress:any;
  destAddress:any;

  pageNo: any;
  pageSize: any;
  quoteItems: any;
  quoteId: string;
  quote: any;
  quoteDocument: any;
  sourceExtraSevices: any;
  endExtraSevices: any;

  quoteLocData: any;
  quoteService: any;
  quoteShpmt: any;
  shipmentModeName: Map<any, any>;
  costBreakupList: any;
  
  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, private storageService: ScStorageService, private router: Router ) {
    this.shipmentModeName = new Map();
   }

  ngOnInit() {

    this.quoteId =  this.route.snapshot.paramMap.get('quote-id');
    this.quoteLocData = this.storageService.getBucket("quote");

    if(this.quoteId && !this.quoteLocData){ 
      
      this.getQuote();
      this.loadQuoteItems();
      this.getSourceExtraSevices();
      this.getDestExtraSevices();

    } else {

      this.sourceAddress = this.quoteLocData.sourceAddress;
      this.destAddress = this.quoteLocData.destinationAddress;

      this.quoteService = this.storageService.getBucket("quoteService");
      this.quoteShpmt = this.storageService.getBucket("shipment");
      this.shpmtModeList();

    }
    
  }

  getQuote(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuote.action + "?quoteId=" + this.quoteId,'');
    promise1.then( value => {

      this.quote = value["data"];
      this.storageService.saveBucket("shipment", this.quote.packaging);

      if(this.quote){
        this.getSourceAddress(this.quote["sourceAddr"]);
        this.getDestAddress(this.quote["destAddr"]);
      }
     
    });

  }

  loadQuoteItems(): void {

    this.pageNo = 0;
    this.pageSize = 20;

    let url = ServerURLS.getQuoteItems.action + "/" + this.pageNo + "/" + this.pageSize;
    url += "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.quoteItems = value["data"]["content"];
    
    });
    
  }

  getSourceExtraSevices(): void {

    let url = ServerURLS.getQuoteExtraService.action;
    url += "?quoteId=" + this.quoteId + "&locType=start";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceExtraSevices = value["data"];
    });

  }

  getDestExtraSevices(): void {

    let url = ServerURLS.getQuoteExtraService.action;
    url += "?quoteId=" + this.quoteId + "&locType=end";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.endExtraSevices = value["data"];
     
    });

  }

  getSourceAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      
    });

  }

  getDestAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.destAddress = value["data"];

      });
  }

  shpmtModeList (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtModeList.action,'');
    promise1.then( value => {
      let shpmtModes = value["data"];
      shpmtModes.forEach(element => {
        this.shipmentModeName.set(element.shpngModeId, element.shpngMode);
      });
  
    });
  
   }

   quotePage(){

     if(this.quoteId){
      this.router.navigateByUrl("shipcarte/quote/shipment;quote-id=" + this.quoteId);

     } else{
      this.router.navigateByUrl("shipcarte/quote/shipment");
     }
  }

  ngOnChanges(changes: SimpleChanges) {
 
    this.costBreakupList = [];
    for (let property in changes) {
        if (property === 'costBreakup') {
          this.costBreakupList = changes[property].currentValue;
        } 
    }
 }

}
