import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/quote/carriers', pathMatch: 'full' },
  { path: 'shipcarte/quote/carriers', component: CarrierListComponent},

];

@NgModule({
  declarations: [CarrierListComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class CarrierModule { }
