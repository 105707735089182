export class QuoteReqstForm{

    qty:number;
    ttlWght:string;
    length:number;
    breadth:number;
    height:number;
    unit:string;
    startLocTypeId:string;
    endLocTypeId:string;
    extraServcType:any;
    startLocationForm:Object;
    endLocationForm:Object
    shpmtModeId:string;
    shpmtTypeId:string;
    shpmtPackagingTypeId:string;
    packaging: string;
    freightClass: string;

}