import { Component, OnInit, Input } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-order-extra-services',
  templateUrl: './order-extra-services.component.html',
  styleUrls: ['./order-extra-services.component.scss']
})
export class OrderExtraServicesComponent implements OnInit {

  sourceExtraServices: any;
  endExtraServices: any;
  orderId:String;
  // @Input("sourceInfo") sourceInfo: any;
  // @Input("destInfo") destInfo: any;
  sourceInfo: any;
  order:any;
  destInfo: any;

  constructor( private scHttpService: ScHttpService, private route: ActivatedRoute, public scAuthService: ScAuthService ) { }

  ngOnInit() {

   
    this.orderId =  this.route.snapshot.paramMap.get('order-id');

    if(this.orderId){
      this.getCustIdByOrderId();
      this.getSourceExtraSevices();
      this.getDestExtraSevices();
    }
  }

  getCustIdByOrderId(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCustIdByOrderId.action + "?order-id=" + this.orderId,'');
    promise1.then( value => {
      let custId = value["data"];

      if(custId){
        this.getOrderDetails( custId );
      }
      
    });

  }

  getSourceExtraSevices(): void {

    let url = ServerURLS.getOrderExtraService.action;
    url += "?id=" + this.orderId + "&locType=start";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceExtraServices = value["data"];
    });

  }

  getDestExtraSevices(): void {

    let url = ServerURLS.getOrderExtraService.action;
    url += "?id=" + this.orderId + "&locType=end";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.endExtraServices = value["data"];
    });

  }

  getOrderDetails( custId ): void {

    let url = ServerURLS.getOrderDetails.action + "?id=" + this.orderId;

    if( custId ){
      url += "&cust-id=" + custId;
    }

    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.order = value["data"];

      if(this.order){
        this.getSourceAddress(this.order["sourceAddr"]);
        this.getDestAddress(this.order["destAddr"]);
      }
     
    });

  }

  getSourceAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.sourceInfo = value["data"];
    });

  }

  getDestAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.destInfo = value["data"]
    });

  }

}
