import { Component, Input, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';


@Component({
  selector: 'app-edit-custom-package',
  templateUrl: './edit-custom-package.component.html',
  styleUrls: ['./edit-custom-package.component.scss']
})
export class EditCustomPackageComponent implements OnInit {
  @Input() public editInfo;
  @Input() public shipmentMode;
  customDetailsForm : FormGroup;
  packageType="";
  shipmentModeEdit='';
  packageId='';

  constructor(public formBuilder: FormBuilder,public activeModal: NgbActiveModal, private httpService:ScHttpService) {
    this.customDetailsForm = this.formBuilder.group({
      measurementUnit:['imperial'],
      PackageName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      packageDesc:['',[Validators.required, Validators.minLength(2),Validators.maxLength(50),Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      length: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      width: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      height: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      weight: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
    });                                                                                                   
   }

  ngOnInit(): void {
  this.editFuction(); 
  }

  editFuction(){
    this.packageId=this.editInfo[0].package_id;
    this.packageType = this.editInfo[0].style_code;
    this.shipmentModeEdit=this.shipmentMode;

    this.customDetailsForm.patchValue({
      measurementUnit:this.editInfo[0].unit_mode,
      PackageName: this.editInfo[0].package_name,
      packageDesc:this.editInfo[0].package_description,
      length: this.editInfo[0].package_dimension['length'],
      width: this.editInfo[0].package_dimension['width'],
      height: this.editInfo[0].package_dimension['height'],
      weight: this.editInfo[0].package_dimension['weight'],
     })
  }


  increamentByOne( measureMentUnit) {
    if (measureMentUnit == "length") {
    ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "width") {
    ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "height") {
    ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "weight") {
    ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) + 1).toFixed(2))));
    }
    }

    decreamentByOne(measureMentUnit) {
      if (measureMentUnit == "length") {
        if (this.customDetailsForm.value.length && this.customDetailsForm.value.length > 1) {
        ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) - 1).toFixed(2))));
        }
      }
      else if (measureMentUnit == "weight") {
          if (this.customDetailsForm.value.weight && this.customDetailsForm.value.weight > 1) {
        ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) - 1).toFixed(2))));
        }
      }
      else if (measureMentUnit == "width") {
        if (this.customDetailsForm.value.width && this.customDetailsForm.value.width > 1) {
          ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) - 1).toFixed(2))));
          }
      }
      else if (measureMentUnit == "height") {
        if (this.customDetailsForm.value.height && this.customDetailsForm.value.height > 1) {
          ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) - 1).toFixed(2))));
          }
        }     
      }

      closeModal() { this.activeModal.close({success : false}); }

      onSubmit(){
        let inputData={
          shipment_mode_id:this.editInfo[0].shipment_mode_id,
          shipment_type_id :this.editInfo[0].shipment_type_id,
          style_code:this.packageType,
          package_name : this.customDetailsForm.value.PackageName,
          package_description : this.customDetailsForm.value.packageDesc,
          unit_mode :this.customDetailsForm.value.measurementUnit,          
          package_dimension:{
          length : this.customDetailsForm.value.length,
          width : this.customDetailsForm.value.width,
          height : this.customDetailsForm.value.height,
          weight : this.customDetailsForm.value.weight
          }
        }
        let url = ServerURLS.editCustomPackage.action + '?customPackageId=' +this.packageId;
        let promise = this.httpService.putHttpService(url,inputData);
        promise.then((res)=>{
                  this.customDetailsForm.reset();
                  this.activeModal.close({success : true}); 
        })
      }

      

}
