import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { QuoteDocument } from './quotedocument';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quote-document',
  templateUrl: './quote-document.component.html',
  styleUrls: ['./quote-document.component.scss']
})
export class QuoteDocumentComponent implements OnInit {

  docTypes: any;
  selectedFile: any;
  errorMessage: string;
  successMessage: any;
  document: QuoteDocument;
  quoteId: any;
  customBrokers: any;
  types: Map<any, any>;

  showMsg: string;
  shipmentForm: FormGroup;

  customErrorMessage: string;
  documentList: any;
  documentMap: Map<any, any>;
  shipmentDetail: any;
  editQId:string;
  isEdit:string;

  isCustomSelected: boolean;
  isBoLSelected: boolean;
  isOfferSelected: boolean;

  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService,private route: ActivatedRoute, private router: Router) {
    this.document = new QuoteDocument();
    this.documentMap = new Map();
    this.types = new Map();
   }

  ngOnInit() {

    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit');

    this.loadDocumentTypes();
    this.loadCustomBrokers();
    this.loadFormData();

    if(this.isEdit){
      this.loadShipmentDetail();
    }
   
  }

  loadDocumentTypes (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllDocumentTypes.action, '');
    promise1.then( value => {
      this.docTypes = value["data"];
     
      this.docTypes.forEach(element => {
        this.types.set(element.docmntType, element.uuid);
        this.getDocumentsByType( element.uuid );
      });
    });

  }

  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
  }

  uploadFile( docTypeId ){

      const formdata: FormData = new FormData();
      formdata.append('file', this.selectedFile);
      
      let fileURL = ServerURLS.uploadDocument.action;

      this.showMsg = docTypeId;

      if(this.selectedFile){
    
        this.errorMessage = "";
        formdata.append( 'quoteId', this.quoteId );
        formdata.append( 'docTypeId', docTypeId );

        let promise = this.scHttpService.postFileHttpService(fileURL, formdata);
        promise.then( (resolve) => {

          let response = resolve;
          this.getDocumentsByType( docTypeId );
          
          this.selectedFile = null;

        }).catch((error) => {
          this.errorMessage = error.message;
        });

      } else {
        this.errorMessage = "Please select file";
      }

  }

  loadCustomBrokers(): void {
  
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllCustomsBrokers.action, '');
    promise1.then( value => {
      this.customBrokers = value["data"];
    });
    
  }

  loadFormData(){

    this.shipmentForm = this._formBuilder.group({
      hasBOLDocmnt: ['true', Validators.required],
      brokerType: ['CSA', Validators.required],
      customsBrokerId: [''],
      phone: ['',Validators.pattern(this.PHONE_REGEXP)],
      email: ['', [Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      quoteId: [this.quoteId]
   });

 }

 get f() { return this.shipmentForm.controls; }

 updateShipmentDetail() {

    let formData = this.shipmentForm.getRawValue();

    if(formData.brokerType == "other"){
      formData.customsBrokerId = "other";
    } 

    if(formData.brokerType == "CSA" && (formData.customsBrokerId == null || formData.customsBrokerId == "")){
      this.customErrorMessage = "Select Customs Broker";

    } else {

      this.customErrorMessage = "";
      let promise = this.scHttpService.putHttpService(ServerURLS.updateQuoteShipmentDetail.action, formData);
    
      promise.then( (resolve) => {

        this.customErrorMessage = "";
        let response = resolve;
        this.successMessage = response["message"];

        this.router.navigateByUrl("shipcarte/order/review;quote-id=" + this.quoteId);
        
      }).catch((error) => {
        this.errorMessage = error.message;
      });

    }
  
  }

  getDocumentsByType( docType ): void {
    
    let url = ServerURLS.getUploadedDocuments.action + "?quoteId=" + this.quoteId + "&documentTypeId=" + docType;

    let promise1 = this.scHttpService.getHttpService(url ,'');
    promise1.then( value => {
      this.documentList = value["data"];
      this.documentMap.set(docType, this.documentList);
    });

  }

  removeDocument( docTypeId, docId ): void {

    let url = ServerURLS.removeDocument.action + "?documentId=" + docId;

    let promise1 = this.scHttpService.deleteHttpService(url ,'');
    promise1.then( value => {
      let response = value["message"];
      this.documentMap.delete(docId);
      this.getDocumentsByType( docTypeId );
    });

  }

  /**
   * Method is used to load shipment related details in edit mode
   */
  loadShipmentDetail(): void {

    let url = ServerURLS.getShipmentDetail.action + "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService(url ,'');
    promise1.then( value => {
      this.shipmentDetail = value["data"];
  
        if(this.shipmentDetail){

          this.shipmentForm.patchValue({
            hasBOLDocmnt: this.shipmentDetail["hasBOLDocmnt"]+"",
            insuranceReqrd: this.shipmentDetail["insuranceReqrd"]+"",
            brokerType: this.shipmentDetail["brokerType"],
            customsBrokerId: this.shipmentDetail["customsBrokerId"],
            phone: this.shipmentDetail["phone"],
            email: this.shipmentDetail["email"],
            quoteId: this.quoteId
        });

      }
      
    });

  }
  
  skip(){

    if(this.quoteId){
      this.router.navigateByUrl("shipcarte/quote/payment;quote-id=" + this.quoteId);
    }
    
  }
 
}
