import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-quote-view',
  templateUrl: './quote-view.component.html',
  styleUrls: ['./quote-view.component.scss']
})
export class QuoteViewComponent implements OnInit {

  sourceAddress:any;
  destAddress:any;

  pageNo: any;
  pageSize: any;
  quoteItems: any;
  quoteId: string;
  quote: any;
  quoteDocument: any;
  sourceExtraSevices: any;
  endExtraSevices: any;
  
  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, public scAuthService: ScAuthService ) { }

  ngOnInit() {
    this.quoteId =  this.route.snapshot.paramMap.get('quote-id');

    if(this.quoteId){

      this.getQuote();
      this.loadQuoteItems();
      this.getSourceExtraSevices();
      this.getDestExtraSevices();
      this.getQuoteDocument();
    }
    
  }

  getQuote(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuote.action + "?quoteId=" + this.quoteId,'');
    promise1.then( value => {
      this.quote = value["data"];

      if(this.quote){
        this.getSourceAddress(this.quote["sourceAddr"]);
        this.getDestAddress(this.quote["destAddr"]);
      }
     
    });

  }

  loadQuoteItems(): void {

    this.pageNo = 0;
    this.pageSize = 20;

    let url = ServerURLS.getQuoteItems.action + "/" + this.pageNo + "/" + this.pageSize;
    url += "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.quoteItems = value["data"]["content"];
    });
    
  }

  getSourceExtraSevices(): void {

    let url = ServerURLS.getQuoteExtraService.action;
    url += "?quoteId=" + this.quoteId + "&locType=start";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceExtraSevices = value["data"];
      
    });

  }

  getDestExtraSevices(): void {

    let url = ServerURLS.getQuoteExtraService.action;
    url += "?quoteId=" + this.quoteId + "&locType=end";

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.endExtraSevices = value["data"];
     
    });

  }

  getSourceAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      
    });

  }

  getDestAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.destAddress = value["data"];

      });

  }

  getQuoteDocument(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteDocument.action + "?quoteId=" + this.quoteId,'');
    promise1.then( value => {
      this.quoteDocument = value["data"];
     
    });

  }
  
}
