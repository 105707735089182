import { Component, OnInit, ViewChild } from '@angular/core';
import { QuoteDocument } from '../../quote/quote-document/quotedocument';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quote-document',
  templateUrl: './quote-document.component.html',
  styleUrls: ['./quote-document.component.scss']
})


export class QuoteDocumentComponent implements OnInit {

  docTypes: any;
  selectedFile: any;
  errorMessage: string;
  successMessage: any;
  document: QuoteDocument;
  quoteId: any;
  customBrokers: any;
  types: Map<any, any>;

  showMsg: string;
  shipmentForm: FormGroup;

  customErrorMessage: string;
  documentList: any;
  documentMap: Map<any, any>;
  shipmentDetail: any;
  editQId:string;
  isEdit:string;

  isCustomSelected: boolean;
  isBoLSelected: boolean;
  isOfferSelected: boolean;
  isBolComp:boolean=false;
  isCustomComp:boolean;
  customBrokerMap : Map<any,any>;

  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
  processing: boolean;
  brokers: any = [];
  
  pageType: string;
  isDisabled:boolean;
  
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  brokerType: { type: string; value: string; }[];
  brokerData: any;
  
  isDescCostAdded: boolean = false;
  
  constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService,private route: ActivatedRoute, private router: Router) {
     
      this.document = new QuoteDocument();
      this.documentMap = new Map();
      this.types = new Map();
      this.customBrokerMap = new Map();

   }

  ngOnInit() {
 
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit');
    this.pageType = "custom";

    this.brokerType = [
      {type: 'Custom Broker', value: "broker"},
      {type: 'CSA Shipments', value: "CSA"},
      {type: 'Other', value: "other"}
    ];

    this.loadDocumentTypes();
    this.loadCustomBrokers();
    this.loadFormData();

    //Default is custom broker
    this.setValdiation('broker');

    if(this.isEdit){
      this.loadShipmentDetail();
    }
   
  }

  loadDocumentTypes (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllDocumentTypes.action, '');
    promise1.then( value => {
      this.docTypes = value["data"];
     
      this.docTypes.forEach(element => {
        this.types.set(element.docmntType, element.uuid);
        this.getDocumentsByType( element.uuid );
      });
    });

  }

  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
  }

  uploadFile( docTypeId, type ){

      const formdata: FormData = new FormData();
      formdata.append('file', this.selectedFile);
      
      let fileURL = ServerURLS.uploadDocument.action;

      this.showMsg = docTypeId;

      if(this.selectedFile){
    
        this.errorMessage = "";
        formdata.append( 'quoteId', this.quoteId );
        formdata.append( 'docTypeId', docTypeId );

        let promise = this.scHttpService.postFileHttpService(fileURL, formdata);
        promise.then( (resolve) => {

          let response = resolve;
          this.getDocumentsByType( docTypeId );
          
          this.selectedFile = null;

        }).catch((error) => {
          this.errorMessage = error.message;
        });

      } else {
        //this.errorMessage = "Please select file";
        if(type == 'BOL'){

          this.isBolComp = true;

        } else if ( type == 'Custom'){

          this.isCustomComp = true;

        }
      }

  }

  loadCustomBrokers(): void {
  
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllCustomsBrokers.action, '');
    promise1.then( value => {
      this.customBrokers = value["data"];
      this.customBrokers.forEach(element => {
        this.customBrokerMap.set(element.brokerName, element.brokerId);
        this.brokers.push(element.brokerName);
      });

    });
    
  }

  loadFormData(){

    this.shipmentForm = this._formBuilder.group({
      hasBOLDocmnt: ['false', Validators.required],
      brokerType: ['broker'], //, Validators.required
      customsBrokerId: [],
      phone: [,Validators.pattern(this.PHONE_REGEXP)],
      email: [, [Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      brokerName: [],
      quoteId: [this.quoteId]
   });

 }

 get f() { return this.shipmentForm.controls; }

 setValdiation(value){
  let customDoc = undefined;
  customDoc = this.shipmentForm;

    customDoc.patchValue ({
      'brokerName': null,
      'email':null,
      'phone': null,
      'customsBrokerId': null,
    });
  
   if ( value == "broker"){
       this.setValidator(customDoc, 'customsBrokerId');
       this.setValidator(customDoc, 'email');
       this.setValidator(customDoc, 'phone');

    } else if( value == "other"){

      this.setValidator(customDoc, 'brokerName');
      this.setValidator(customDoc, 'email');
      this.setValidator(customDoc, 'phone');
      this.removeValidator(customDoc, 'customsBrokerId');

    } else if( value == "CSA"){

      this.removeValidator(customDoc, 'brokerName');
      this.removeValidator(customDoc, 'email');
      this.removeValidator(customDoc, 'phone');
      this.removeValidator(customDoc, 'customsBrokerId');

    }  else {

      this.removeValidator(customDoc, 'brokerName');
      this.removeValidator(customDoc, 'email');
      this.removeValidator(customDoc, 'phone');
      this.removeValidator(customDoc, 'customsBrokerId');
      
    }

 }
 setValidator(customDoc, htmlFormField){
    
  let element =  customDoc.get(htmlFormField);
  element.setValidators(Validators.required);
  element.updateValueAndValidity();

}

 removeValidator(customDoc, htmlFormField){
    
  let element =  customDoc.get(htmlFormField);
  element.clearValidators();
  element.updateValueAndValidity();

}

 updateShipmentDetail() {
   
    let formData = this.shipmentForm.getRawValue();

    if(formData.brokerType == "other"){
      formData.customsBrokerId = "other";
      
    } else if(formData.brokerType == "CSA"){
      formData.customsBrokerId = "CSA";
    }

    // if(formData.brokerType == "CSA" && (formData.customsBrokerId == null || formData.customsBrokerId == "")){
    //   this.customErrorMessage = "Select Customs Broker";

    // } else {
      this.customErrorMessage = "";
      this.processing = true;

      if(formData.brokerType == "broker"){
        formData.customsBrokerId = this.customBrokerMap.get(formData.customsBrokerId); //customsBrokerId contains name, fetching correspond id by name from Map
      }

 
      let promise = this.scHttpService.putHttpService(ServerURLS.updateQuoteShipmentDetail.action, formData);
   
      promise.then( (resolve) => {

        this.customErrorMessage = "";
        let response = resolve;
        this.successMessage = response["message"];
        this.processing = false;
        this.router.navigateByUrl("shipcarte/quote/make/payment;quote-id=" + this.quoteId);
        
      }).catch((error) => {
        this.errorMessage = error.message;
      });

    }
  
  getDocumentsByType( docType ): void {
    
    let url = ServerURLS.getUploadedDocuments.action + "?quoteId=" + this.quoteId + "&documentTypeId=" + docType;

    let promise1 = this.scHttpService.getHttpService(url ,'');
    promise1.then( value => {
      this.documentList = value["data"];
      this.documentMap.set(docType, this.documentList);
    });

  }

  removeDocument( docTypeId, docId ): void {

    let url = ServerURLS.removeDocument.action + "?documentId=" + docId;

    let promise1 = this.scHttpService.deleteHttpService(url ,'');
    promise1.then( value => {
      let response = value["message"];
      this.documentMap.delete(docId);
      this.getDocumentsByType( docTypeId );
    });

  }

  /**
   * Method is used to load shipment related details in edit mode
   */
  loadShipmentDetail(): void {

    let url = ServerURLS.getShipmentDetail.action + "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService(url ,'');
    promise1.then( value => {
      this.shipmentDetail = value["data"];
      
        if(this.shipmentDetail){

          this.shipmentForm.patchValue({
            hasBOLDocmnt: this.shipmentDetail["hasBOLDocmnt"]+"",
            insuranceReqrd: this.shipmentDetail["insuranceReqrd"]+"",
            brokerType: this.shipmentDetail["brokerType"],
            customsBrokerId: this.shipmentDetail["customsBrokerName"],
            phone: this.shipmentDetail["phone"],
            email: this.shipmentDetail["email"],
            quoteId: this.quoteId
        });

      }
      
    });

  }
  
  skip(){

    if(this.quoteId){
      this.router.navigateByUrl("shipcarte/quote/make/payment;quote-id=" + this.quoteId);
    }
    
  }

  getResponse( e ) {
    let data = e;

    for (let index = 0; index < data.size; index++) {
     
      if((data.value[index].costValue && data.value[index].itemDescr) && (data.value[index].costValue != "" || data.value[index].itemDescr != "")){
        this.isDescCostAdded = true;

      } else {
        this.isDescCostAdded = false;
      }

    }
   
  }

  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => term.length < 2 ? []
  //       : this.brokers.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  //   );

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.brokers
        : this.brokers.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
    
  }

  selectBroker(event) {
      
      let brokerName = event.target.value;
      let brokerId = this.customBrokerMap.get(event.srcElement.value);
      
      let url = ServerURLS.getCustomBroker.action + "?broker-id=" + brokerId;

      let promise_ = this.scHttpService.getHttpService(url, '');
      promise_.then(value => { 
        this.brokerData = value["data"];

 

        this.shipmentForm.patchValue  ({
          'email': this.brokerData.email ? this.brokerData.email : '',
          'phone': this.brokerData.phone ? this.brokerData.phone : ''
        });

        if(!this.brokerData.phone){
          this.removeValidator(this.shipmentForm, 'phone');
        }
        
      });

      promise_.catch(error => { // on error and other than http code:200
        //this.errMsg =  error['message'];
        this.shipmentForm.patchValue ({
          'email':'',
          'phone': ''
        });
      });
  }

} 
