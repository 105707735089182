import { Address } from '../../profile/address';

export class  BillingInfo{
   
    billingAddr: Address;
    billingId:string;
    applicantId:string;
    name: string;
    gstnNumber :string;
    bondNumber:string;
    paymentMethodId:string;
    expDate:string;
    creditCardNumber:string;
    natureOfBusiness:string;
    yearInBusiness:string; 
    accountPayableContact:string;
    ext:string;
    email:string;
    principalName:string;
    operationsContact:string;
    requestedCreditLimit:string;
    title:string;
    addrId:string;
    constructor () {
        this.billingAddr = new Address();

    }
}