import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  pwdPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

  errorMessage: string;
  resetSuccessMsg: any;
  entity: string;
  entityId: string;

  constructor(private router: Router, private _formBuilder: FormBuilder, private scHttpService: ScHttpService,  private scAuthService: ScAuthService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.entity =  this.route.snapshot.paramMap.get('entity');
    this.entityId =  this.route.snapshot.paramMap.get('entityId');

    this.loadFormData();    
  }

  loadFormData(){

    this.passwordForm = this._formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', Validators.required],
      autzEntityId :[this.entityId],

    }, {
      validator: MustMatch('password', 'confirmPassword')
   });

 }

 get f() { return this.passwordForm.controls; }

 resetPassword() {
    
    let formData_ = this.passwordForm.getRawValue();
    let promise = this.scHttpService.putHttpService(ServerURLS.resetPassword.action, formData_);

    promise.then( (result) => {
      this.errorMessage = '';
      this.resetSuccessMsg =  result['message'];
      
    });
  
    promise.catch ( (result) => {
      this.errorMessage =  result['message'];
      this.resetSuccessMsg = '';
    });
    
  }

  cancel(){

    if(this.entity == 'customer'){
      this.router.navigateByUrl("/shipcarte/customer/list");

    } else if(this.entity == 'partner'){
      // this.router.navigateByUrl("/shipcarte/partner/list");

    } else if(this.entity == 'user'){
      this.router.navigateByUrl("/shipcarte/user/list");
      
    }
    
   }

}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
