import { Component, OnInit } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ScHttpService } from 'src/app/services/http/sc-http.service';

@Component({
  selector: 'app-offer-view',
  templateUrl: './offer-view.component.html',
  styleUrls: ['./offer-view.component.scss']
})
export class OfferViewComponent implements OnInit {

  offerDetails: any;
  offerId: any;
  offerType: any;
  noOfPages: any;
  activePage: number;
  startElementCount: any;
  endElementCount: any;
  couponList: any;
  voucherList: any;

  constructor( private scHttpService: ScHttpService, private route: ActivatedRoute ) { }

  ngOnInit() {

    this.offerId = this.route.snapshot.paramMap.get('offer-id');
    this.getOfferDetails();
    this.loadCouponList();
    this.loadVouchersList();
  }

  getOfferDetails (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getOfferDetails.action +"?offer-id=" +this.offerId ,'');
    promise1.then( value => {
      this.offerDetails = value["data"];
      if( this.offerDetails ){
       this.offerType =  this.offerDetails.type.split(',')[1]
      }
    });}

    page = 1;
    pageSize = 10
    collectionSize = 5; 

  loadCouponList(): void {

    let url = ServerURLS.getCoupons.action + "/" + ( this.page - 1 ) + "/" + this.pageSize +"?offer-id=" + this.offerId;

    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.couponList = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;
    });
  }

  loadVouchersList(): void {

    let url = ServerURLS.getVouchers.action + "/" + ( this.page - 1 ) + "/" + this.pageSize +"?offer-id=" + this.offerId;

    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.voucherList = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;
    });
  }

}

