import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';
import { EmailDocumentComponent } from '../email-document/email-document.component';
import * as html2pdf from 'html2pdf.js';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { error } from 'console';

@Component({
  selector: 'app-bill-of-lading',
  templateUrl: './bill-of-lading.component.html',
  styleUrls: ['./bill-of-lading.component.scss']
})
export class BillOfLadingComponent implements OnInit {
  weightType: any = ''
  @Input() quoteIdForEmail;
  @Input() showIcons;
  @Input() invoiceData;
  documentIdForEmail: any;
  @Input() BOLd;
  resultData: any;
  dynamicDimension: any;
  declaredValue: number;
  totalItem: number;
  packagesArray: any = [];
  carrier:boolean=false
  totalPackageCountArray = []
  constructor(
    public common: ScCommonService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private el:ElementRef,
    private renderer:Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private docService: DocumentService,
    private httpService: ScHttpService
  ) { }

  ngOnInit() {
    this.resultData = this.common.bolResultData;
    // this.resultData = this.BOLd

    if (this.resultData['shipmentItems'][0]['wghtUnit'] === 'lbs') {
      this.weightType = 'Lbs'
    } else {
      this.weightType = 'Kg'
    }
    let url = ServerURLS.getAllServiceMapPackageType.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {

      res.forEach((element) => {

        if (element.description === 'Courier') {
          element.packagingDetails.map((element1) => {

            this.packagesArray.push({
              'packageName': element1.packagingType,
              'packageId': element1.packagingId
            })
          })
        } else if (element.description != 'Full Truckload (TL) Shipment') {
          element.packagingTypeDetails
            .map((element1) => {
              this.packagesArray.push({
                'packageName': element1.packagingName,
                'packageId': element1.packagingId

              })
            })
        }
      })
      // debugger
      let packageId = [];
      this.resultData.shipmentItems.map((element) => {
        packageId.push(element.packaging);
      })


      let uniquePackageId = [... new Set(packageId)]
      uniquePackageId.map((element) => {
        let count = 0;
        let qnty = this.resultData.shipmentItems.filter(data => data.packaging
          === element);

        qnty.map((element) => {
          count = count + element.qty;
        })
        // packageId.map((element1)=>{
        //   if(element===element1){
        //     count=count+1;
        //   }
        // })
        let packageName = this.packagesArray.filter(data => data.packageId === element);

        this.totalPackageCountArray.push({
          'packageName': packageName[0].packageName,
          'count': count
        })
      })

    }).catch((err: any) => {

    })

    this.BOLd = this.common.bolInvoiceDetails;
    if ( this.BOLd['carrierLogo'].substr(0,3)==='iVB' ||  this.BOLd['carrierLogo'].substr(0,3)==='/9j') {
      this.carrier = true;
    } else{
      this.carrier=false;
      // let carrierLogo=atob(this.BOLd['carrierLogo'])
      // setTimeout(()=>{
  
      //   const element = this.el.nativeElement.querySelector('#carrierLogoContainer'); // Replace 'targetElement' with your element's ID or selector
        
      //   this.renderer.setProperty(element, 'innerHTML', carrierLogo);
      // },1000)
    }
   
    this.dynamicDimension = this.common.dimensionBOL;
    this.declaredValue = this.common.declaredBol;
    this.totalItem = this.common.totalItemBol;

    this.docService.getData(this.quoteIdForEmail).subscribe(result => {
      this.documentIdForEmail = result['documentId'];
    }, error => {
      if (error.status == 401) {
        this.router.navigate(['/signin']);
      }
      this.toastr.error(error['error']["message"], 'Please try again.', {
        timeOut: 10000,
      });
    });
  }

  closeModal() {
    this.activeModal.close();
    this.modalService.dismissAll();
  }

  download() {
    let compData = this;
    // html2canvas(document.querySelector('#content'),{ useCORS: false } ).then(function(canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = function () {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save("BOL_"+compData.quoteId+".pdf");
    //   };
    // });
    var element = document.querySelector('#contentbol');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + compData.quoteIdForEmail + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  }

  dialogOpen(modelToOpn, action) {
    if (modelToOpn === "emailDoc") {
      if (localStorage.getItem("com_documentId_" + this.quoteIdForEmail) == null && action != "bol") {
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
        });
        return;
      }

      const modalRef = this.modalService.open(EmailDocumentComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId = action == "commercial" ? localStorage.getItem("com_documentId_" + this.quoteIdForEmail) : this.documentIdForEmail;
      modalRef.componentInstance.action = action;
      modalRef.result.then((result) => {
        if (result.success) {
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        } else {
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }
  }

}
