import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.scss']
})
export class CarrierListComponent implements OnInit {
  
  carriers:any;

  constructor(private scHttpService: ScHttpService) { }

  ngOnInit() {
    this.shpmtCarriers();
  }

  shpmtCarriers (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtCarriers.action,'');
    promise1.then( value => {
      this.carriers = value["data"];
    });

  }

}
