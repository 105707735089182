import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carrier-contract',
  templateUrl: './carrier-contract.component.html',
  styleUrls: ['./carrier-contract.component.scss']
})
export class CarrierContractComponent implements OnInit {

  @Input()CarrierData :any;
  @Input()loginUserData :any;
  @Input()charges :any
  @Input()carrierRateData :any;
  @Input()resultData :any;
  @Input()data :any;
  @Input()ProId :any;

  constructor( private modal : NgbModal) { }

  ngOnInit() {
    // console.log(this.CarrierData)
    // console.log(this.loginUserData)
    // console.log(this.charges)
    // console.log(this.carrierRateData)
    // console.log(this.resultData)
    // console.log(this.data)
    // console.log(this.ProId)
  }

  close(){
this.modal.dismissAll();
  }
  
}
