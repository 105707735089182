import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MergePdfService } from 'src/app/services/merge-pdf.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-download-label',
  templateUrl: './download-label.component.html',
  styleUrls: ['./download-label.component.scss']
})
export class DownloadLabelComponent implements OnInit {
  @Input() data;
  pdfLinksArray:any=[]
  mergedPdf: Uint8Array | null = null;
  constructor(private ser:NgbModal, private rt:Router ,private toastr: ToastrService,private http:HttpClient) { }

  ngOnInit(): void {
    this.pdfLinksArray=this.data.link;
  }
  getShipmentLabelUrl(i){
        // this.isLabelUrl=this.sanitizer.bypassSecurityTrustResourceUrl(result['link'])
        window.open(this.data['link'][i],'_blank').focus(); 
     
  }
  close(){
    this.ser.dismissAll();
    }
  async mergeAndDownloadPDFs() {
  }
 

   
}
