import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-quote-results',
  templateUrl: './quote-results.component.html',
  styleUrls: ['./quote-results.component.scss']
})
export class QuoteResultsComponent implements OnInit {

  isResultPage: boolean;
  carriers: any;
  carriers_result: any;
  value:string;
  quoteId: any;
  errMsg: string;
  successMsg: any;
  selectedCarrier:any;
  disabledCarrier = [];

  respMap: Map<any, any>;
  costMap: Map<any, any>;
  validCarriers: any;

  validCarrierLength: any = 0;

  incCount:any = 5;
  costBreakupList: any;

  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.isResultPage = true;
    this.respMap = new Map();
    this.costMap = new Map();
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');

    this.shpmtCarriers();
    this.operationalVariable ();
  }

  requiredCarriers: any = [];
  shpmtCarriers (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.carriers.action + "?quote-id=" + this.quoteId ,'');
    promise1.then( value => {

       this.carriers = value["data"]["carrierRates"];
       this.validCarriers = value["data"]["carriers"];

       //this.carriers_result = this.carriers; 
       this.carriers_result = [ ...this.carriers]; //Object.assign([], this.carriers);
       this.carriers.forEach(element => {

        let unit = "";
        if(element.curUnit.includes(",")){
          unit = element.curUnit.split(",")[0];

        } else {
          unit = element.curUnit;
        }

        if(unit && unit.includes("UNKNOWN")){
          unit = "CAD";
        }

        this.costMap.set(element.carrierReqId, ( element.totalCost + " " + unit ));

        if(element.carrierRespStts == 2){
          this.respMap.set(element.carrierName, element.carrierMsg);
        }
        
        this.validCarriers.forEach(element1 => {
          if(element1.includes(element.carrierCode)){
            this.requiredCarriers.push(element);
            this.validCarrierLength = this.validCarrierLength + 1;
          }
         
        });
      });

      this.carriers = [ ...this.requiredCarriers];
    });

  }

  getCostBreakupList ( carrierId, carrierReqId ): void {

    let url = ServerURLS.getCostBreakupList.action;
    url += "?quote-id=" + this.quoteId + "&carrier-id=" + carrierId + "&carrier-req-id=" + carrierReqId;
 
    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.costBreakupList = value["data"];
    });

  }

  onSelect(carrierId, carrierReqId){
    this.getCostBreakupList(carrierId, carrierReqId);
  }

  showMore(key){
    this.incCount = key;
    if(this.carriers.length > this.incCount){
      this.incCount += 5;
    } 
  }

  operationalVariable (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.operationalVarValue.action+"?key=ORDER_INFO_TRACKING_CARE_NUMBER",'');
    promise1.then( value => {
      this.value = value["data"];
    });

  }

  updateShipment( shpmtId ): void {

    let url = ServerURLS.updateShpmt.action + "?quoteId=" + this.quoteId +"&shpmtId="+shpmtId;

    let authPromise = this.scHttpService.putHttpService(url ,'');
    authPromise.then( (resolve) => {
      let response = resolve;
     
      this.errMsg = '';
      this.successMsg = response["message"];
      this.router.navigateByUrl("shipcarte/quote/v2-location;quote-id=" + this.quoteId);

    }).catch((error) => {
      this.errMsg = error.message;
      this.successMsg = '';
    });

  }

  sortRates(property){

    let isAsec = undefined;

    isAsec =  property.split("-")[1] == '0';
    let carrierProperty =  property.split("-")[0];

    let array = [ ...this.carriers_result ];

    if( isAsec){
       array.sort((a, b) => ( this.isNullorEmpty( a[carrierProperty] ) || this.isNullorEmpty( b[carrierProperty] ) ) ? 0 : (a[carrierProperty] > b[carrierProperty])  ? 1: -1)
    } else{
       array.sort((a, b) => ( this.isNullorEmpty( a[carrierProperty] ) || this.isNullorEmpty( b[carrierProperty] ) ) ? 0 : (a[carrierProperty] < b[carrierProperty])  ? 1: -1)
    }
    this.carriers = [ ...array];

  }

  public isNullorEmpty(content) {
    return ( content == null || content == undefined );
    // this.listCopy = Object.assign([], this.list);
  }
}
