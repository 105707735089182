import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { CarrierInvoiceReceiptComponent } from './carrier-invoice-receipt/carrier-invoice-receipt.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { CarrierProcessingEdit } from './carrier-processing-edit/carrier-processing-edit.component';
import { AllInvoiceModule } from './allinvoice/allinvoice.module';
import { AllInvoiceComponent } from './allinvoice/allinvoice.component';
 
 

 
const routes: Routes = [
    // {  
    //     path: '',
    //     component: AllInvoiceComponent,
    //     loadChildren:()=>import('./allinvoice/allinvoice.module').then(m=>m.AllInvoiceModule)
    // },
    { path: 'shipcarte/allinvoices/carrierinvoicereceipt', component: CarrierInvoiceReceiptComponent },
    { path: 'shipcarte/allinvoices/carrierprocessingEdit', component: CarrierProcessingEdit },
 
    {
        path: 'shipcarte/allinvoices',
        component: AllInvoiceComponent,
        loadChildren:()=>import('./allinvoice/allinvoice.module').then(m=>m.AllInvoiceModule)
    },

];

@NgModule({
    declarations: [
        AllInvoiceComponent,
        CarrierInvoiceReceiptComponent,
        CarrierProcessingEdit,
         
 ],


    imports: [
        RouterModule.forRoot(routes),
        CommonModule, FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule  
    ]
})
export class InvoiceModule { }
