import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-commercial-invoice',
  templateUrl: './commercial-invoice.component.html',
  styleUrls: ['./commercial-invoice.component.scss']
})
export class CommercialInvoiceComponent implements OnInit {

  @Input() showIcons;
  BOLd:any;
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  constructor(public common:ScCommonService,public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.resultData=this.common.bolResultData;
    this.BOLd=this.common.bolInvoiceDetails;
    this.dynamicDimension=this.common.dimensionBOL;
    this.declaredValue=this.common.declaredBol;
    this.totalItem=this.common.totalItemBol;
  }

  closeModal() { this.activeModal.close(); }

}
