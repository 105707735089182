import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { QuotePaymentForm } from './quotepaymentform';
import { ServerURLS } from 'src/environments/environment';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { QuotePrice } from './quoteprice';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['../quote-location/quote-location.component.scss', './quote-payment.component.scss']
})
export class QuotePaymentComponent implements OnInit {

  errMsg:String;
  successMsg :string;
  quoteId: string;
  paymtForm: FormGroup;
  locationForm: FormGroup;
  quotePayment:QuotePaymentForm;
  paymentMethods: any;
  quotePrice: QuotePrice;
  paymentDetails: any;
  address: any;
  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  isPincodeReleased:boolean=false;

  loggedUserId: any;

  monerisConstants: any;
  pinCode: any;

  constructor( private route: ActivatedRoute, private storageService: ScStorageService, private router: Router,private _formBuilder: FormBuilder, 
     private scHttpService: ScHttpService, private scAuthService: ScAuthService) { 
    this.quotePayment = new  QuotePaymentForm();
    
   this.monerisConstants = {
      "USD": {
        "action": "https://esplusqa.moneris.com/usmpg/admin/hpp_config/index.php",
        "storeId": "7DNP2qa002",
        "hpp_key": "hp5YKNVM5RNC"
      },
      "CAD": {
        "action": "https://esqa.moneris.com/mpg/admin/hppv3_config/index.php",
        "storeId": "gwca025293",
        "hpp_key": "hpN6BNDE2XV5"
      }
      
    }
  }

  ngOnInit() {
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');

    this.loggedUserId = this.scAuthService.getLoggedUserId();
    this.loadFormData();
    this.paymntMethods ();
    this.getQuotePrice();
    this.getQuotePaymentAddressId ();

  }

  viewQuote(){

    if(this.quoteId){
      this.router.navigateByUrl("shipcarte/quote/view;quote-id=" + this.quoteId);
    }
    
  }

  loadFormData(){

    this.paymtForm = this._formBuilder.group({

       quoteId:[this.quoteId],
       paymtStts:['SUCCESS'],
       paymtGatewayRefId:[],
       freightChrgs: [],
       total: [],
       paymts: [],
       amountDue: [],
       paymtMethod: [''],
       couponCode : [],
       sttsMsg  : [],
       paymentId:[],
       isTerms:[]
    });

    this.locationForm = this._formBuilder.group({
      zipCode: [this.quotePayment.addrId.zipCode, Validators.required],
      city: {value: this.quotePayment.addrId.city, disabled: true},
      state: [{value: this.quotePayment.addrId.state, disabled: true}],
      country: [{value: this.quotePayment.addrId.country, disabled: true}],
      addrLine1:['', Validators.required],
      addrLine2:[''],
      firstName: ['',Validators.required], //,Validators.required
      lastName: [''],
      company: [''],
      phoneNumber: ['',[Validators.required,  Validators.pattern(this.PHONE_REGEXP)]],
      alternatePhoneNumber: [''],
      dept:[''],
      fax: [''],
    });

  }

  get f() { return this.paymtForm.controls; }
  get l() { return this.locationForm.controls; }

  saveQuotePayment () {

    let form_data = this.paymtForm.getRawValue();
    this.quotePayment = form_data;
    //// console.log(this.quotePayment.isTerms)
    if(!this.quotePayment.isTerms){

       alert("Please check the terms and conditions");
    } else {

      this.quotePayment.addrId = this.locationForm.getRawValue();

      this.quotePayment.total = this.quotePrice.totalCost;
      this.quotePayment.freightChrgs = this.quotePrice.totalCost;
      this.quotePayment.paymts = this.quotePrice.totalCost;
      this.quotePayment.amountDue = this.quotePrice.totalCost;
  
      if( this.paymentDetails != null){
        this.quotePayment.paymentId = this.paymentDetails.paymentId;
      }
    
      let authPromise = this.scHttpService.postHttpService(ServerURLS.saveQuotePayment.action, this.quotePayment);
        
        authPromise.then( (resolve) => {
          let response = resolve;
          this.errMsg = '';
          this.successMsg = response["message"];
  
          this.storageService.saveBucket("orderMetaInfo",  response["data"]);
          this.router.navigateByUrl("shipcarte/quote/confirm/order");
  
        }).catch((error) => {
          this.errMsg = error.error;
          this.successMsg = '';
        });
  
    }
   
    }

    loadZipCodeData(event) {
      
      this.isPincodeReleased = true;
      this.pinCode =  event.srcElement.value;//formData["zipCode"];
  
      if( this.pinCode == "" || this.pinCode.length < 5) {
        this.locationForm.patchValue ({
          'zipCode': '',
          'city':'',
          'state': '',
          'country': '',
        });
        return ;
      }
  
      let url = ServerURLS.geoLocationDetails.action + "/" + this.pinCode;
  
      let promise_ = this.scHttpService.getHttpService(url, '');
      promise_.then(value => { 

        this.quotePayment.addrId.city    = value["data"]["city"];
        this.quotePayment.addrId.state   = value["data"]["state"];
        this.quotePayment.addrId.country = value["data"]["country"];

        // let srcState =  this.quotePayment.addrId.state;
        // let len = srcState.trim().split(" ");
        // let state = srcState.replace( len[(len.length-1)]+"", "");
        // this.quotePayment.addrId.state = state.trim();
  
        this.locationForm.patchValue  ({
           'zipCode': this.pinCode,
           'city': this.quotePayment.addrId.city,
           'state': this.quotePayment.addrId.state,
           'country': this.quotePayment.addrId.country,
         });
  
      });
  
      promise_.catch(error => { // on error and other than http code:200
        //this.errMsg =  error['message'];
        this.locationForm.patchValue ({
          'zipCode': '',
          'city':'',
          'state': '',
          'country': '',
        });
      });
  
    }

    paymntMethods (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.quotePaymtMethods.action,'');
      promise1.then( value => {
        this.paymentMethods = value["data"];
      });
  
    }

    getQuotePrice (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuotePrice.action +"?id=" +this.quoteId,'');
      promise1.then( value => {
        this.quotePrice = value["data"];
      });
  
    }

    getQuotePaymentAddressId (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuotePaymentAddrId.action +"?id=" +this.quoteId,'');
      promise1.then( value => {
        this.paymentDetails = value["data"];
        if(this.paymentDetails != null ){
          this.getQuotePaymentAddressDetail(this.paymentDetails.addrId);
        }
      });
  
    }

    getQuotePaymentAddressDetail (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action +"?id=" +id,'');
      promise1.then( value => {
        this.address = value["data"];
        this.locationForm.patchValue (this.address)

        // Value setting for repeat order
        this.quotePayment.addrId.city    = value["data"]["city"];
        this.quotePayment.addrId.state   = value["data"]["state"];
        this.quotePayment.addrId.country = value["data"]["country"];

      });
    }
}
