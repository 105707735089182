import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {
  @Input() public quoteId;
  @Input() public popUpConditions;
  @Input() public isMarshApplicable;
  amount = 0;
  currency: any = ''
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    // console.log(this.popUpConditions)
    this.amount = this.popUpConditions.currency.split(' ')[0];
    this.currency = this.popUpConditions.currency.split(' ')[1];
    // console.log(this.popUpConditions)
    // console.log(this.isMarshApplicable)
  }
  closeModal() { this.activeModal.close({ success: false }); }

  optInsaurance() {
    this.activeModal.close({ success: true });
  }

}
