import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-info-signature',
  templateUrl: './custom-info-signature.component.html',
  styleUrls: ['./custom-info-signature.component.scss']
})
export class CustomInfoSignatureComponent implements OnInit {
  customForm: FormGroup;
  sign:any;
  @Input() public allDropdownValues;
  @Input() public quoteId;
  @Input() public customDropdownValues;
  custom:any;
  brInfo:any;
  brokerId:any;
  brokerName:any;
  constructor(private activatedRoute: ActivatedRoute, public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { } 

  ngOnInit(): void {
    this.customForm = this.formBuilder.group({
      contentsType: ['',Validators.required],
      contentsExplanation: [''],
      customsCertified: ['',Validators.required],
      customsSigner: [''],
      restrictionType: ['',Validators.required],
      restrictionDescription:[''],
      nonDeliveryOption:['',Validators.required]
    });
  }
 
  

  submit() {
    this.custom = 
      {
      "quoteId":this.quoteId,
      "contentsType":this.customForm.value.contentsType,
      "contentsExplanation": this.customForm.value.contentsExplanation,
      "customsCertified": this.customForm.value.customsCertified,
      "customsSigner": this.brokerId,
      "restrictionType": this.customForm.value.restrictionType,
      "restrictionDescription":this.customForm.value.restrictionDescription,
      "nonDeliveryOption":this.customForm.value.nonDeliveryOption,
      "brokerName":this.brokerName
    }
    
    this.activeModal.close(this.custom);
  }

  closeModal() { 
    this.activeModal.close(); 
  }

  updateSignPreview(sign){
    this.sign = sign;
  }
  changeContentsType(){
    if (this.customForm.value.contentsType == 'OTHER') {
      this.customForm.controls["contentsExplanation"].setValidators([Validators.required])
      this.customForm.controls["contentsExplanation"].updateValueAndValidity()
    }else{
      this.customForm.controls["contentsExplanation"].clearValidators()
      this.customForm.controls["contentsExplanation"].updateValueAndValidity()
    }
  }
  changecustomsCertified(){
    if (this.customForm.value.customsCertified) {
      this.customForm.controls["customsSigner"].setValidators([Validators.required])
      this.customForm.controls["customsSigner"].updateValueAndValidity()
    }else{
      this.customForm.controls["customsSigner"].clearValidators()
      this.customForm.controls["customsSigner"].updateValueAndValidity()
    }
  }
  changerestrictionType(){
    if (this.customForm.value.restrictionType != 'NONE') {
      this.customForm.controls["restrictionDescription"].setValidators([Validators.required])
      this.customForm.controls["restrictionDescription"].updateValueAndValidity()
    }else{
      this.customForm.controls["restrictionDescription"].clearValidators()
      this.customForm.controls["restrictionDescription"].updateValueAndValidity()
    }
  }
brokerEmailAndNumber(event){
   
   this.brInfo = this.customDropdownValues.find(o => o.brokerId === event);
   this.brokerId=this.brInfo['brokerId']
   this.brokerName=this.brInfo['brokerName']
}

}
