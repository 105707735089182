import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import { PinCodeAddress } from './PinCodeAddress';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  addrForm: FormGroup;
  geometry: any;
  pinCodeAddress: any;
  isAddrAvailable: boolean;

  errorMessage: any;

  constructor( private router: Router, private _formBuilder: FormBuilder, private scHttpService: ScHttpService ) {
    this.pinCodeAddress = new PinCodeAddress();
   }

  ngOnInit() {
    this.loadFormData();
  }

  loadFormData(){

    this.addrForm = this._formBuilder.group({

     zipCode: [this.pinCodeAddress.zipCode],
     city: {value: this.pinCodeAddress.city, disabled: true},
     state: [{value: this.pinCodeAddress.state, disabled: true}],
    //  latitude: [{value: this.pinCodeAddress.latitude, disabled: true}],
    //  longitude: [{value: this.pinCodeAddress.longitude, disabled: true}],
     country: [{value: this.pinCodeAddress.country, disabled: true}]

   });

 }

 loadZipCodeData() {
   
    let formData = this.addrForm.getRawValue();

    this.errorMessage = '';
    let pinCode = formData["zipCode"];

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 
      
      this.pinCodeAddress.city    = value["data"]["city"];
      this.pinCodeAddress.state   = value["data"]["state"];
      this.pinCodeAddress.country = value["data"]["country"];

      this.addrForm.setValue ({
         'zipCode': pinCode,
         'city': this.pinCodeAddress.city,
         'state': this.pinCodeAddress.state,
         'country': this.pinCodeAddress.country,
        
       });

       this.isAddrAvailable = true;
     
    });

    promise_.catch(error => { // on error and other than http code:200
      this.isAddrAvailable = false;
      this.errorMessage =  error['message'];
     
    });

  }

  reset(){

    this.errorMessage = '';
    this.isAddrAvailable = false;
    this.loadFormData();
    this.pinCodeAddress = {};
  }

  cancel(){
    this.router.navigateByUrl("shipcarte/dashboard");
  }

}
