import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { CreditApplicantDocumentComponent } from './credit-applicant-document/credit-applicant-document.component';
import { CreditReferenceComponent } from './credit-reference/credit-reference.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const routes: Routes = [
  { path: 'shipcarte/creditv2/personal-info', component: PersonalInfoComponent},
  { path: 'shipcarte/creditv2/payment-info', component: PaymentInfoComponent},
  { path: 'shipcarte/creditv2/bank-info', component: BankInfoComponent},
  { path: 'shipcarte/creditv2/applicant/upload/document', component: CreditApplicantDocumentComponent},
  { path: 'shipcarte/creditv2/applicant/reference/info', component: CreditReferenceComponent}
];

@NgModule({
  declarations: [PersonalInfoComponent, PaymentInfoComponent, BankInfoComponent, CreditApplicantDocumentComponent, CreditReferenceComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class CreditappV2Module { }
