import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-consignee-book',
  templateUrl: './consignee-book.component.html',
  styleUrls: ['./consignee-book.component.scss']
})
export class ConsigneeBookComponent implements OnInit {

consignee:any;
activePage: number;
noOfPages: any;
startElementCount: any;
endElementCount: any;

constructor(private scHttpService: ScHttpService) { 
  
}

ngOnInit() {

  this.loadConsigneeBook();
}

loadPage(page: number) {

  if (page !== this.activePage) {
    this.loadConsigneeBook();
  }

}

page = 1;
pageSize = 5
collectionSize = 5; 

loadConsigneeBook(): void {

    let url = ServerURLS.getAllConsigneeBook.action + "/" + ( this.page - 1 ) + "/" + this.pageSize ;

    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.consignee = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;

    });

  }

  getAddress(id, con): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      con["moreInfo"]  = value["data"];
    });

  }
}
