import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateOfferComponent } from './create-offer/create-offer.component';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { OfferListComponent } from './offer-list/offer-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OfferViewComponent } from './offer-view/offer-view.component';
import { VoucherFormComponent } from './voucher-form/voucher-form.component';

const routes: Routes =[
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/offer/add', component: CreateOfferComponent},
  { path: 'shipcarte/coupon/add', component: CouponFormComponent},
  { path: 'shipcarte/offer/list', component: OfferListComponent},
  { path: 'shipcarte/offer/view', component: OfferViewComponent},
  { path: 'shipcarte/voucher/add', component: VoucherFormComponent}
];

@NgModule({
  declarations: [CreateOfferComponent, CouponFormComponent, OfferListComponent, OfferViewComponent, VoucherFormComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [CreateOfferComponent]
})
export class OfferModule { }
