import { Item } from './items';
import { Address } from '../../profile/address';
import { QuoteDelvryForm } from '../../quote-v2/quote-item/items/quotedelvry';

export class Quote {
    quoteId: string;
    shipmentMode : string;
    shpmtTypeId:string;
    shpmtPackagingTypeId:string;
    sourceAddress: Address;
    destinationAddress: Address;
    extraServices: string[];
    items: Item[];
    hasSourceServicesOpted: boolean;
    hasDestServicesOpted: boolean;
    delvryForm: QuoteDelvryForm;
    
    constructor () {
        this.sourceAddress = new Address();
        this.destinationAddress = new Address();
        this.delvryForm = new QuoteDelvryForm();

        this.items = new Array<Item>();
    }
}