import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierGraphComponent } from './carrier-graph/carrier-graph.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShipmentCarrierGraphComponent } from './shipment-carrier-graph/shipment-carrier-graph.component';
import { QuoteCarrierGraphComponent } from './quote-carrier-graph/quote-carrier-graph.component';
import { KPIComponent } from './kpi/kpi.component';

const routes: Routes = [
  { path: 'shipcarte/analytics/shipment/carrier-graph', component: ShipmentCarrierGraphComponent},
  { path: 'shipcarte/analytics/quote/carrier-graph', component: QuoteCarrierGraphComponent},
  { path: 'shipcarte/analytics/kpi-graph', component: KPIComponent}
];

@NgModule({
  declarations: [CarrierGraphComponent, ShipmentCarrierGraphComponent, QuoteCarrierGraphComponent, KPIComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})

export class AnalyticsModule { }
