import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { EnvironmentConstants, ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-package-manager',
  templateUrl: './package-manager.component.html',
  styleUrls: ['./package-manager.component.scss']
})
export class PackageManagerComponent implements OnInit {
  @ViewChild('loadContent', undefined) loadContent;
  servicesTypeAvailable: boolean = true;
  openAddNewPackageForm: boolean = false;
  carrierDetailList: any = []
  packageManagerRes: any;
  defaultPageNumber: number = 0;
  defaultListLength: number = 20;
  noDataErr: string = "NO Data Foudn";
  sortBy: any;
  isDesc: boolean = false;
  editPackageForm: boolean = false;
  shpmntModePkgTypeData: any = [];
  isShmtModeSelected: boolean = false;
  shmtModeSelectedArr = [];
  pkgTypes = [];
  shmtModeCourier = false;
  selectedShmtMode: string = '';
  selectedShmtModeId: string = '';
  selectedPkgType: string = '';
  selectedPkgTypeId: string = '';
  selectedPkgStyleCode: string = '';
  isPkgTypeSelected: boolean = false;
  pkgTypeSelectedArr = [];
  packageName: string = '';
  assignToCustomer: string = '';
  packageDescription: string = '';
  selectedUnit: string = 'imperial';
  pkgLength: any;
  pkgWidth: any;
  pkgHeight: any;
  pkgWeight: any;
  loadingCustomPackageOptions: boolean = false;
  resPackageId = '';
  selectedEditPkgDetails: any = {};
  editPkgIconName: string;
  editPkgName: string;
  customerId: string = '';
  page: any = 0;
  pageSize: any = 20;
  packageList: any = [];
  totolPackageEnteries: any = 0;
  searchkey: any = '';
  confirmDeletePkgBox: boolean = false;
  packageDetailToRemove: any = {}

  constructor(
    private httpService: ScHttpService,
    private modelService: NgbModal,
    private toastr: ToastrService,
    private listDataService: ListDataServiceService
  ) { }

  ngOnInit() {
    this.sortBy = {
      "package_name": "down",
      "style_code": "down",
      "dimension": "down",
      "weight": "down",
      "assignedCustomer": 'down'
    }
    let listDataFromService = this.listDataService.getDataOfSettingPackageMansger();
    // console.log(listDataFromService)
    if (listDataFromService.dataList.length > 0) {
      this.searchkey = listDataFromService.searckKey
      this.defaultPageNumber = listDataFromService.pageCount;
      this.defaultListLength = listDataFromService.pageSize;
      this.packageList.push(listDataFromService.dataList)
    } else {
      this.getListOfPackages(this.defaultListLength, this.defaultPageNumber, this.searchkey)
    }
  }
  isDataLoading: any = ''
  getListOfPackages(pageSize, pageNumber, searchkey) {
    this.allDataLoaded=false;
    this.isDataLoading = 'Loading Your Options, Please Wait...'
    this.packageList = []
    let url = ServerURLS.fetchPackageManagerData.action + '/' + pageNumber + '/' + pageSize;
    // let url = EnvironmentConstants.DOMAINPATH + '/api/sc/salesRep/customPackageList/0/10'
    if (searchkey !== '') {
      url = url + '?query=' + searchkey
    }
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      if (pageNumber == 0) {
        if (res['packages'].length == 0) {
          this.isDataLoading = 'No Data Found';
        } else {
          this.isDataLoading = ''
        }
      }
      this.packageList.push(res['packages']);
      let data = {
        pageCount: this.defaultPageNumber,
        pageSize: this.defaultListLength,
        dataList: res['packages']
      }
      this.listDataService.setDataForSettingsPackageManagerList(data);
      this.totolPackageEnteries = res.totalEntries;


    })
      .catch((err: any) => {
        this.isDataLoading = 'Something Went Wrong! Please try Again Later.'

      })
  }
  sort(property) {

    this.isDesc = !this.isDesc; //change the direction

    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "down" : "up";

    if (property == "weight") {
      this.packageList[0].sort(function (a, b) {
        if (parseFloat(a.package_dimension[property]) < parseFloat(b.package_dimension[property])) {
          return -1 * direction;
        }
        else if (parseFloat(a.package_dimension[property]) > parseFloat(b.package_dimension[property])) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
    else if (property == "dimension") {
      this.packageList[0].sort(function (a, b) {
        a = a.package_dimension['length'] + a.package_dimension['width'] + a.package_dimension['height'];
        b = b.package_dimension['length'] + b.package_dimension['width'] + b.package_dimension['height'];
        if (parseFloat(a) < parseFloat(b)) {
          return -1 * direction;
        }
        else if (parseFloat(a) > parseFloat(b)) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
    else {
      this.packageList[0].sort(function (a, b) {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * direction;
        }
        else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
  };
  packageNameDisable: boolean = false
  packageDescriptionDisable: boolean = false;
  packageLengthDisable: boolean = false;
  packageHeightDisable: boolean = false;
  packageWidthDisable: boolean = false;
  packageWeightDisable: boolean = false

  getCustomerListByName(value?) {
    let url = ServerURLS.getCustomerListAdmin.action + '/0/';
    if (value) {
      url = url + '10?query=' + value + '&status=Active'
    } else {
      url = url + '10?status=Active'
    }
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.carrierDetailList = res.response
    })
      .catch((err) => {

      })
  }
  getCustomerDetailsbyId(value: string) {

    this.customerId = value;
  }
  openCloseAddNewPackage() {
    this.pkgNameErr = false;
    this.customerIdForEdit = ''
    this.openAddNewPackageForm = !this.openAddNewPackageForm;

    if (this.openAddNewPackageForm) {
      this.getAllServiceMapPackageType()
      window.scrollTo(0, 0);
      document.body.classList.add('overflow-hidden');
    }
    else {
      document.body.classList.remove('overflow-hidden');
      this.shpmntModePkgTypeData = [];
      this.isShmtModeSelected = false;
      this.shmtModeSelectedArr = [];
      this.pkgTypes = [];
      this.shmtModeCourier = false;
      this.selectedShmtMode = '';
      this.selectedShmtModeId = '';
      this.selectedPkgType = '';
      this.selectedPkgTypeId = '';
      this.selectedPkgStyleCode = '';
      this.isPkgTypeSelected = false;
      this.pkgTypeSelectedArr = [];
      this.packageName = '';
      this.assignToCustomer = ''
      this.packageDescription = '';
      this.selectedUnit = 'imperial';
      this.pkgLength = '';
      this.pkgWidth = '';
      this.pkgHeight = '';
      this.pkgWeight = '';
      this.editPackageForm = false;
      this.resPackageId = '';
    }
  }
  getAllServiceMapPackageType() {
    this.loadingCustomPackageOptions = true;
    let fetchShipmentMode = ServerURLS.getAllServiceMapPackageType.action;
    let promise = this.httpService.getHttpService(fetchShipmentMode, '')
    promise.then((res: any) => {
      this.shpmntModePkgTypeData = res;
      if (this.editPackageForm) {
        this.getShpmtModePkgDetails(this.selectedEditPkgDetails.shipment_mode_id, this.selectedEditPkgDetails.shipment_type_id);
      } else {
        this.loadingCustomPackageOptions = false
      }
    })
      .catch((err: any) => {

      })
  }
  getShpmtModePkgDetails(shpmtId, pkgId) {
    for (let shpmtDetail of this.shpmntModePkgTypeData) {
      if (shpmtDetail.shippingModeId == shpmtId) {
        this.selectedShmtMode = shpmtDetail.shippingMode;
        if (shpmtDetail.shippingModeId == '0a13d82b-f7ee-11e9-8462-163046d26cd4') { //when shipment mode is Courier
          for (let pkgDetail of shpmtDetail.packagingDetails) {
            if (pkgDetail.packagingId == pkgId) {
              this.loadingCustomPackageOptions = false;
              this.editPkgIconName = pkgDetail.styleCode;
              this.editPkgName = pkgDetail.packagingType;
            }
          }
        }
        else { //when shipment mode is not Courier
          for (let pkgDetail of shpmtDetail.packagingTypeDetails) {
            if (pkgDetail.packagingId == pkgId) {
              this.loadingCustomPackageOptions = false;
              this.editPkgIconName = pkgDetail.packagingCode;
              this.editPkgName = pkgDetail.packagingName;
            }
          }
        }
      }
    }
  }
  timer: any;
  setSearchKey(value) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (value.length >= 3) {
        this.listDataService.setSearchKeyForSettingPackageManager(value)
        this.defaultPageNumber = 0
        this.searchkey = value;
        this.searchButtonApplyFilter()
      } else if (value.length == 0) {
        this.listDataService.setSearchKeyForSettingPackageManager('')
        this.defaultPageNumber = 0
        this.searchkey = '';
        this.searchButtonApplyFilter()
      }
    }, 1000)


  }
  searchButtonApplyFilter() {
    this.getListOfPackages(this.defaultListLength, this.defaultPageNumber, this.searchkey)
  }
  selectShmt(index, shmtData) {
    this.isShmtModeSelected = true;
    this.shmtModeSelectedArr = []
    this.pkgTypes = []
    this.pkgTypeSelectedArr = []
    this.isPkgTypeSelected = false;
    this.packageName = '';
    this.assignToCustomer = ''

    this.packageDescription = '';
    this.pkgLength = '';
    this.pkgWidth = '';
    this.pkgHeight = '';
    this.pkgWeight = '';
    if (!this.shmtModeSelectedArr.includes(index)) {
      this.shmtModeSelectedArr.push(index);
    }

    this.selectedShmtMode = shmtData.shippingMode;
    this.selectedShmtModeId = shmtData.shippingModeId;
    if (shmtData.shippingModeId == '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
      this.shmtModeCourier = true;
      for (let pkgType of shmtData.packagingDetails) {
        if (pkgType.packagingType != 'Custom Package') {
          this.pkgTypes.push(pkgType);
        }
      }
    }
    else {
      this.shmtModeCourier = false;
      if (shmtData.packagingTypeDetails != null) {
        for (let pkgType of shmtData.packagingTypeDetails) {
          if (pkgType.packagingName != 'Custom Package') {
            this.pkgTypes.push(pkgType);
          }
        }
      }
      else if (shmtData.packagingTypeDetails == null) {
        this.isShmtModeSelected = false;
        this.pkgTypes = [];
      }
    }
  }
  selectPkgType(index, pkgTypeData) {
    this.isPkgTypeSelected = true;
    this.pkgTypeSelectedArr = []
    if (!this.pkgTypeSelectedArr.includes(index)) {
      this.pkgTypeSelectedArr.push(index);
    }

    if (this.shmtModeCourier) {
      this.selectedPkgType = pkgTypeData.packagingType;
      if (pkgTypeData.styleCode == 'package') {
        this.selectedPkgStyleCode = 'boxed';
      }
      else {
        this.selectedPkgStyleCode = pkgTypeData.styleCode;
      }
    }
    else if (!this.shmtModeCourier) {
      this.selectedPkgType = pkgTypeData.packagingName;
      this.selectedPkgStyleCode = pkgTypeData.packagingCode;
    }
    this.selectedPkgTypeId = pkgTypeData.packagingId;
  }
  selectUnit(unitSelected) {
    this.selectedUnit = unitSelected;

  }

  pkgNameErr: boolean = false;
  pkgDescErr: boolean = false;
  pkgLenErr: boolean = false;
  pkgWidErr: boolean = false;
  pkgHeiErr: boolean = false;
  pkgWeiErr: boolean = false;
  assignToCustomerErr: boolean = false;
  isUpdatingAndAddingData: boolean = false
  createUpdatePackage(updatePkg: boolean) {

    if (this.packageName == '') {
      this.pkgNameErr = true;

    }
    if (this.assignToCustomer === '') {
      this.assignToCustomerErr = true;


    }
    if (this.packageDescription == '') {
      this.pkgDescErr = true;


    }
    if (this.pkgLength == null || this.pkgLength == 0 || this.pkgLength < 0) {
      this.pkgLenErr = true;


    }
    if (this.pkgWidth == null || this.pkgWidth == 0 || this.pkgWidth < 0) {
      this.pkgWidErr = true;


    }
    if (this.pkgHeight == null || this.pkgHeight == 0 || this.pkgHeight < 0) {
      this.pkgHeiErr = true;


    }
    if (this.pkgWeight == null || this.pkgWeight == 0 || this.pkgWeight < 0) {
      this.pkgWeiErr = true;


    }
    if (
      this.packageName != '' &&
      this.packageDescription != '' &&
      this.pkgLength != null && this.pkgLength != 0 && this.pkgLength > 0 &&
      this.pkgWidth != null && this.pkgWidth != 0 && this.pkgWidth > 0 &&
      this.pkgHeight != null && this.pkgHeight != 0 && this.pkgHeight > 0 &&
      this.pkgWeight != null && this.pkgWeight != 0 && this.pkgWeight > 0
    ) {
      let inputData = {
        customer_id: this.customerId,
        shipment_mode_id: this.selectedShmtModeId,
        shipment_type_id: this.selectedPkgTypeId,
        style_code: this.selectedPkgStyleCode,

        package_name: this.packageName,
        package_description: this.packageDescription,
        unit_mode: this.selectedUnit,
        package_dimension: {
          length: this.pkgLength,
          width: this.pkgWidth,
          height: this.pkgHeight,
          weight: this.pkgWeight
        }
      }


      let promise;
      this.isUpdatingAndAddingData = true;
      this.packageNameDisable = true
      this.packageDescriptionDisable = true;
      this.packageLengthDisable = true;
      this.packageHeightDisable = true;
      this.packageWidthDisable = true;
      this.packageWeightDisable = true;
      if (updatePkg) {
        let updateCustomPackageUrl = `${ServerURLS.updateCustomPackage.action}?customPackageId=${this.resPackageId}`;
        promise = this.httpService.putHttpService(updateCustomPackageUrl, inputData);
      }
      else {
        let createCustomPackageUrl = `${ServerURLS.createCustomPackage.action}`;
        promise = this.httpService.postHttpService(createCustomPackageUrl, inputData);
      }


      promise.then((res: any) => {
        this.isUpdatingAndAddingData = false;
        this.packageNameDisable = false
        this.packageDescriptionDisable = false;
        this.packageLengthDisable = false;
        this.packageHeightDisable = false;
        this.packageWidthDisable = false;
        this.packageWeightDisable = false;

        // this.fetchPackageManagerList(this.defaultPageNumber,this.defaultListLength, this.searchPkgString);
        this.page = 0
        this.toastr.success('Success! Custom package was succesfully added to the list')
        if (!updatePkg) {
          this.getListOfPackages(this.pageSize, this.page, this.searchkey)
        } else {
          // console.log(inputData)
          let data = {
            packageName: inputData.package_name,
            packageDescription: inputData.package_description,
            styleCode: inputData.style_code,
            length: inputData.package_dimension.length,
            width: inputData.package_dimension.width,
            height: inputData.package_dimension.height,
            weight: inputData.package_dimension.weight,
            packageId: this.resPackageId
          }
          this.listDataService.updateDataInListOfSettingPackageManager(data)
        }
        this.openCloseAddNewPackage()

      }).catch((err) => {
        this.packageNameDisable = false
        this.packageDescriptionDisable = false;
        this.packageLengthDisable = false;
        this.packageHeightDisable = false;
        this.packageWidthDisable = false;
        this.packageWeightDisable = false;
        this.isUpdatingAndAddingData = false
        this.toastr.error(`${err.message ? err.message : err.Message ? err.Message : 'Something Went Wrong'}`);
        this.toastr.error('Something Went Wrong');


      })


    } else {


    }
  }
  redirectToAddingSaleReps() {
    this.editPackageForm = false;
    this.openAddNewPackageForm = !this.openAddNewPackageForm;

  }
  paginationClick(prevNext: string) {
    if (prevNext == 'previous') {
      if (this.defaultPageNumber > 0) {
        this.defaultPageNumber -= 1;
        this.getListOfPackages(this.defaultListLength, this.defaultPageNumber, this.searchkey);
      }
    }
    else if (prevNext == 'next') {
      if (this.defaultPageNumber < (this.totolPackageEnteries - 1)) {
        this.defaultPageNumber += 1;
        this.getListOfPackages(this.defaultListLength, this.defaultPageNumber, this.searchkey);
      }
    }
  }
  incrementByOne(measurementType: string) {
    if (measurementType == "length") {
      // ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) + 1).toFixed(2))));
      this.pkgLength += 1;
      this.pkgLength.toFixed(2);
    }
    else if (measurementType == "width") {
      // ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) + 1).toFixed(2))));
      this.pkgWidth += 1;
      this.pkgWidth.toFixed(2);
    }
    else if (measurementType == "height") {
      // ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) + 1).toFixed(2))));
      this.pkgHeight += 1;
      this.pkgHeight.toFixed(2);
    }
    else if (measurementType == "weight") {
      // ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) + 1).toFixed(2))));
      this.pkgWeight += 1;
      this.pkgWeight.toFixed(2);
    }
  }

  decrementByOne(measureMentUnit) {
    if (measureMentUnit == "length") {
      // if (this.customDetailsForm.value.length && this.customDetailsForm.value.length > 1) {
      //   ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) - 1).toFixed(2))));
      // }
      if (this.pkgLength && this.pkgLength > 1) {
        this.pkgLength -= 1;
        this.pkgLength.toFixed(2);
      }
    }
    else if (measureMentUnit == "weight") {
      // if (this.customDetailsForm.value.weight && this.customDetailsForm.value.weight > 1) {
      //   ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) - 1).toFixed(2))));
      // }
      if (this.pkgWeight && this.pkgWeight > 1) {
        this.pkgWeight -= 1;
        this.pkgWeight.toFixed(2);
      }
    }
    else if (measureMentUnit == "width") {
      // if (this.customDetailsForm.value.width && this.customDetailsForm.value.width > 1) {
      //   ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) - 1).toFixed(2))));
      // }
      if (this.pkgWidth && this.pkgWidth > 1) {
        this.pkgWidth -= 1;
        this.pkgWidth.toFixed(2);
      }
    }
    else if (measureMentUnit == "height") {
      // if (this.customDetailsForm.value.height && this.customDetailsForm.value.height > 1) {
      //   ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) - 1).toFixed(2))));
      // }
      if (this.pkgHeight && this.pkgHeight > 1) {
        this.pkgHeight -= 1;
        this.pkgHeight.toFixed(2);
      }
    }
  }
  onChangeListLength(length) {

    this.getListOfPackages(length, this.defaultPageNumber, this.searchkey)
  }
  getListOfAllTheCustomer(customerName) {

    let url = ServerURLS.getCustomerListAdmin.action + '/0/50?query=' + customerName;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.carrierDetailList = res['response']

      return 0;
    })
      .catch((err: any) => {

        return err;
      })
  }
  customerIdForEdit: any;
  editPackage(packageDetail) {
    // console.log(packageDetail)
    this.editPackageForm = true;
    let data = this.getListOfAllTheCustomer(packageDetail.customer_name
    )
    this.selectedEditPkgDetails = packageDetail;

    this.editPackageForm = true;
    this.openCloseAddNewPackage()

    this.customerIdForEdit = packageDetail.customer_id

    this.resPackageId = this.selectedEditPkgDetails.package_id;

    this.selectedShmtModeId = this.selectedEditPkgDetails.shipment_mode_id;
    this.selectedPkgTypeId = this.selectedEditPkgDetails.shipment_type_id;
    this.selectedPkgStyleCode = this.selectedEditPkgDetails.style_code;
    this.selectedUnit = this.selectedEditPkgDetails.unit_mode;
    this.packageName = this.selectedEditPkgDetails.package_name;
    this.packageDescription = this.selectedEditPkgDetails.package_description;
    this.pkgLength = this.selectedEditPkgDetails.package_dimension.length;
    this.pkgWidth = this.selectedEditPkgDetails.package_dimension.width;
    this.pkgHeight = this.selectedEditPkgDetails.package_dimension.height;
    this.pkgWeight = this.selectedEditPkgDetails.package_dimension.weight;
  }
  confirmDeletePkgBoxFn(packageDetails) {

    this.confirmDeletePkgBox = !this.confirmDeletePkgBox;
    this.packageDetailToRemove = packageDetails;
    if (this.confirmDeletePkgBox) {
      window.scrollTo(0, 0);
      document.body.classList.add('overflow-hidden');
    }
    else {
      document.body.classList.remove('overflow-hidden');
      // this.showActionsArr = [];
      this.packageDetailToRemove = {};
    }
  }
  removePackage(packageDetail) {



    this.confirmDeletePkgBox = false;
    // this.showActionsArr = [];
    this.packageDetailToRemove = {};
    let deleteCustomPkgUrl = `${ServerURLS.deleteCustomPackage.action}?customPackageId=${packageDetail.package_id}&customerId=${this.customerId}`;
    let promise = this.httpService.deleteHttpService(deleteCustomPkgUrl, '');
    promise.then((res: any) => {



      this.toastr.success('Success! Custom package was succesfully removed from the list')
      this.listDataService.removeDataInListOfSettingPackageManager(packageDetail.package_id)

    }).catch((err) => {

      this.toastr.error(`${err.message ? err.message : err.Message ? err.Message : 'Something Went Wrong'}`);
    })
  }

  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerStandardCharges(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerPackageManager(e) {

    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      // console.log(window.innerHeight + window.scrollY, document.body.offsetHeight)
      const scrollableDiv = document.querySelector('.scrollable-div');
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {
        // console.log('hello')
        // console.log(this.allDataLoaded)
        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.defaultPageNumber = this.defaultPageNumber + 1
    // this.getListOfPackages(this.defaultListLength, this.defaultPageNumber + 1, this.searchkey)
    let url = ServerURLS.fetchPackageManagerData.action + '/' + this.defaultPageNumber + '/' + this.defaultListLength;
    // let url = EnvironmentConstants.DOMAINPATH + '/api/sc/salesRep/customPackageList/0/10'
    if (this.searchkey !== '') {
      url = url + '?query=' + this.searchkey
    }
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isLoading = false
      if (res['packages'].length > 0) {
        this.packageList[0] = this.packageList[0].concat(res['packages'])
        let data = {
          pageCount: this.defaultPageNumber,
          pageSize: this.defaultListLength,
          dataList: this.packageList[0]
        }
        this.listDataService.setDataForSettingsPackageManagerList(data)
      } else {
        this.allDataLoaded = true;
      }
    })
      .catch((err: any) => {
        this.isLoading = false


      })
  }
}
