import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { Permission } from '../../commons/pojo/permission';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sc-user-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sc-user-list.component.html',
  styleUrls: ['./sc-user-list.component.scss']
})
export class ScUserListComponent implements OnInit {

  userList:any;
  permission :Permission;
  noOfPages: any;
  activePage: number;
  startElementCount: any;
  endElementCount: any;
  errorMessage: string;
  resetSuccessMsg: any;
  groups: any;
  groupName:any;
  isDataReady = false;
  @ViewChild('loadContent', undefined) loadContent;
  constructor(private scHttpService: ScHttpService, private modalService: NgbModal) { 
    
    this.permission = new Permission();

    this.permission.read = true;
    this.permission.write = true;
    this.permission.action = true;
  }

  ngOnInit() {

    //this.loadUserList();
    this.groupList();
  }

  ngAfterViewInit() {
    this.loadUserList();
  }

  loadPage(page: number) {

    if (page !== this.activePage) {
      this.loadUserList();
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  search(key){
    this.groupName = key.target.value;
 

    this.loadUserList();
  }

  loadUserList(): void {
    this.loadCotent(this.loadContent);
    let url = ServerURLS.getUserList.action + "/" + ( this.page - 1 ) + "/" + this.pageSize ;

    if(this.groupName){
      url += "?group-name=" + this.groupName;
    }

    this.isDataReady =false;
    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.userList = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;
      this.isDataReady = true;
      this.closeModal();
    });

  }

  groupList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getGroups.action,'');
    promise1.then( value => {
      this.groups = value["data"];

    });

  }
  
  resetPassword( entityId) {
    
    let promise = this.scHttpService.putHttpService(ServerURLS.resetPasswordByAdmin.action+"?entityId="+entityId,'');

    promise.then( (result) => {
      this.errorMessage = '';
      this.resetSuccessMsg =  result['data'];
      
    });
  
    promise.catch ( (result) => {
      this.errorMessage =  result['message'];
      this.resetSuccessMsg = '';
    });
    
  }

  loadCotent(loadContent) {
    this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static' })
  }

  closeModal(){
    this.modalService.dismissAll();
  } 
}

