import { Component, OnInit } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-quote-results',
  templateUrl: './quote-results.component.html',
  styleUrls: ['./quote-results.component.scss']
})
export class QuoteResultsComponent implements OnInit {
  carriers: any;
  value:string;
  quoteId: any;
  errMsg: string;
  successMsg: any;

  respMap: Map<any, any>;
  costMap: Map<any, any>;

  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.respMap = new Map();
    this.costMap = new Map();
    this.quoteId = this.route.snapshot.paramMap.get('quote-id');

    this.shpmtCarriers();
    this.operationalVariable ();
  }


  shpmtCarriers (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.carriers.action + "?quote-id=" + this.quoteId ,'');
    promise1.then( value => {

       this.carriers = value["data"];
      
       this.carriers.forEach(element => {

        let unit = "";
        if(element.curUnit.includes(",")){
          unit = element.curUnit.split(",")[0];

        } else {
          unit = element.curUnit;
        }

        this.costMap.set(element.carrierReqId, ( unit + " " + element.totalCost));

        if(element.carrierRespStts == 2){
          this.respMap.set(element.carrierName, element.carrierMsg);
        }
        
      });
    
    });

  }

  operationalVariable (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.operationalVarValue.action+"?key=ORDER_INFO_TRACKING_CARE_NUMBER",'');
    promise1.then( value => {
      this.value = value["data"];
    });

  }

  updateShipment( shpmtId ): void {

    let url = ServerURLS.updateShpmt.action + "?quoteId=" + this.quoteId +"&shpmtId="+shpmtId;

    let authPromise = this.scHttpService.putHttpService(url ,'');
    authPromise.then( (resolve) => {
      let response = resolve;
     
      this.errMsg = '';
      this.successMsg = response["message"];
      this.router.navigateByUrl("shipcarte/quote/location;quote-id=" + this.quoteId);

    }).catch((error) => {
      this.errMsg = error.message;
      this.successMsg = '';
    });

  }

}
