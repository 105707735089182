import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppendixComponent } from './appendix/appendix.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';


const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/appendix', pathMatch: 'full' },
  { path: 'shipcarte/appendix', component: AppendixComponent},

];


@NgModule({
  declarations: [AppendixComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
  ]
})

export class AppendixModule { }
