import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-view-conslidated-invoice',
  templateUrl: './view-conslidated-invoice.component.html',
  styleUrls: ['./view-conslidated-invoice.component.scss']
})
export class ViewConslidatedInvoiceComponent implements OnInit {

  @ViewChild('loadContent', undefined) loadContent;
  data: any;
  oddNumber:any=[3,5,7,9,11,13,15,17,19,21];
  isInvoicePrinting:boolean = false;
  consolidatedInvoiceList: any = [];
  commonData: any;
  amountDue:any=0.0;
  subTotal:any=0.0;
 
  @Input() listOrderId;
 
  constructor(
    private scHttpService: ScHttpService,
    private modal:NgbModal
  ) { }

  ngOnInit() {

    this.getInvoiceDetails();
  }

  cancel() {
    this.modal.dismissAll();
  }

   

  getInvoiceDetails() {
    // this.loadCotent(this.loadContent);
    // let url = ServerURLS.getConsolidateInvoice.action + '?orderIds=' + this.listOrderId + '&invoiceDate=' + this.date.toISOString();
    // let promise1 = this.scHttpService.postHttpService(url, '');
    // promise1.then((res: any) => {
    //   // console.log(res)
    //   this.consolidatedInvoiceLis // console.log(this.consolidatedInvoiceList, this.commonData[0])t = res;
    //   this.commonData = res[0];
    // }).catch((error) => {
    //   this.cancel();
    //   this.toastr.error('Something went wrong', '', {
    //     timeOut: 3000
    //   })
    // });
  
    this.listOrderId.map((element, index) => {
      let url = ServerURLS.getInvoiceDetailsById.action + '?orderId=' + element;
      let promise = this.scHttpService.getHttpService(url, '');
      promise.then((res: any) => {
        this.consolidatedInvoiceList.push(res);
      
        this.amountDue=this.amountDue+parseFloat(res.amountDue?res.amountDue:0);
        this.subTotal=this.subTotal+parseFloat(res.totalCharges?res.totalCharges:0);
        this.commonData = this.consolidatedInvoiceList[0]
        // console.log(this.amountDue,this.subTotal)
        

        // if (this.listOrderId.length === index + 1) {
        //   // console.log(this.consolidatedInvoiceList, this.commonData[0])
        // }
      })
        .catch((err: any) => {

        })
    })
  }
 

   

  
 
}
