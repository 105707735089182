import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditApplicant } from './creditapplicant';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { BillingInfo } from './biilinginfo';
import { CompanyForm } from './companyform';
import { DatePipe } from '@angular/common';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  applicantForm: FormGroup;
  isPincodeReleased:boolean=false;
  applicant:CreditApplicant;
  addressForm: FormGroup;
  successMsg: any;
  errMsg: string;
  PHONE_REGEXP = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
  applicantId: any;
  address: any;
  applicantDetail: any;
  isEdit: boolean;
  quoteCompletionRate: string;

  //Billing info
  billingForm:FormGroup;
  billAddressForm:FormGroup;
  isSPincodeReleased:boolean;
  billingInfo: BillingInfo;
  isAddrAvailable :boolean;
  billErrMsg:string;
  searchZipcode: any;
  applicantData:any;
  isSameAsApplicant:boolean = false;
  addrType: any;
  isApplicantAddr: boolean;
  billingAddr: any;

  //Company info
  companyInfoForm: FormGroup;
  compErrMsg: string;
  compSuccessMsg: any;
  companyType: any;
  locationType: any;

  choosenLocationType:any;
  isLocationChecked:any;
  
  companyTypes:any;
  companyInfo:CompanyForm;
  companyDetail: any;
  optedServices: any[];

  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
  billSuccessMsg: any;
  billId: any;
  compInfoId: any;

  constructor( private route: ActivatedRoute, private storageService: ScStorageService, private router: Router,private _formBuilder: FormBuilder , private scHttpService: ScHttpService, 
    public commonService: ScCommonService, private datePipe: DatePipe, public scAuthService: ScAuthService) {

    this.applicant = new  CreditApplicant();
    this.billingInfo  = new BillingInfo();
    this.companyInfo = new CompanyForm();
   }

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit') != null;

    this.loadFormData();
   
    if( this.applicantId ){
      this.getApplicantDetails(this.applicantId);
      this.loadCreditCompletionRate();
      this.getCompanyDetails(this.applicantId );
    }

    if(this.applicantId || this.isEdit){
      this.getBillingAddress();
    }
    this.loadAddrFormData();

    this.loadCompanyTypes();
    this.loadLocationTypes();
    this.loadCompFormData();

    this.choosenLocationType = new Set();
    this.isLocationChecked = new Array();
   
  }

  loadFormData(){

    this.applicantForm = this._formBuilder.group({
      tradeName: ['',Validators.required],
      legalName:['',Validators.required],
      tollFree: [],
      telePhone: ['',[Validators.required,  Validators.pattern(this.PHONE_REGEXP)]],
      uuid:[this.applicantId]
    });

    this.addressForm = this._formBuilder.group({
      zipCode: [this.applicant.contactAddress.zipCode, Validators.required],
      city: {value: this.applicant.contactAddress.city, disabled: true},
      state: [{value: this.applicant.contactAddress.state, disabled: true}],
      email:['',[Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      fax: [],
      country: [{value: this.applicant.contactAddress.country, disabled: true}],
      addrLine1:['', Validators.required],
      addrLine2:[],
      addressId:[]
    });

  }

  get f() { return this.applicantForm.controls; }
  get f1() { return this.addressForm.controls; }

  loadZipCodeData(event) {
      
    this.isPincodeReleased = true;
    let pinCode =  event.srcElement.value;//formData["zipCode"];

    if( pinCode == "" || pinCode.length < 5) {
      this.addressForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 

      this.applicant.contactAddress.city    = value["data"]["city"];
      this.applicant.contactAddress.state   = value["data"]["state"];
      this.applicant.contactAddress.country = value["data"]["country"];

      this.addressForm.patchValue  ({
         'zipCode': pinCode,
         'city': this.applicant.contactAddress.city,
         'state': this.applicant.contactAddress.state,
         'country': this.applicant.contactAddress.country,
       });

    });

    promise_.catch(error => { // on error and other than http code:200
      this.addressForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
    });

  }

  loadCreditCompletionRate() {

    if( this.route.snapshot.paramMap.get('completion-rate') != null) {

      this.quoteCompletionRate =  this.route.snapshot.paramMap.get('completion-rate');

      if( this.quoteCompletionRate != null && this.applicantId != null ) {

        if( this.quoteCompletionRate == '1'){
          this.router.navigateByUrl("shipcarte/creditv2/personal-info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '2'){
          this.router.navigateByUrl("shipcarte/creditv2/personal-info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '3'){
          this.router.navigateByUrl("shipcarte/creditv2/payment-info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '4'){
          this.router.navigateByUrl("shipcarte/creditv2/bank-info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '5'){
          this.router.navigateByUrl("shipcarte/creditv2/applicant/upload/document;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '6'){
          this.router.navigateByUrl("shipcarte/creditv2/applicant/reference/info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '7'){
          this.router.navigateByUrl("shipcarte/creditv2/applicant/reference/info;applicant-id=" + this.applicantId);

        }
      }
    }
  }

  saveApplicant () {

    let form_data = this.applicantForm.getRawValue();
    this.applicant = form_data;
 
      this.applicant.contactAddress = this.addressForm.getRawValue();

      if(  this.address != null ){
          this.applicant.contactAddress.addressId = this.applicantDetail.contactAddrId;
      }
      let promise = this.scHttpService.postHttpService(ServerURLS.saveCreditAppInfo.action, this.applicant);
        
      promise.then( (resolve) => {

          let response = resolve;
          this.errMsg = '';
          this.applicantId = response['data'];
          this.successMsg = response["message"];

          if(this.applicantId){

            let promise1 = this.getApplicantAddress();

            promise1.then( (resolve) => {
              this.saveBillInfo ();
            } );

          }
          
         }).catch((error) => {
          this.errMsg = error.error;
          this.successMsg = '';
        });
   
    }

    getApplicantDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantDetails.action +"?id=" +id,'');
      promise1.then( value => {

        this.applicantDetail = value["data"];
        this.applicantForm.patchValue (this.applicantDetail);

        if(this.applicantDetail != null){
          this.getApplicantAddressDetails(this.applicantDetail.contactAddrId);
        }

      });
    }

    getApplicantAddressDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action +"?id=" +id,'');
      promise1.then( value => {
        this.address = value["data"];
        
        this.addressForm.patchValue (this.address);
        this.applicant.contactAddress.zipCode = value["data"]["zipCode"];
        this.applicant.contactAddress.city    = value["data"]["city"];
        this.applicant.contactAddress.state   = value["data"]["state"];
        this.applicant.contactAddress.country = value["data"]["country"];

         this.addressForm.patchValue  ({
          'zipCode': this.applicant.contactAddress.zipCode,
           'city': this.applicant.contactAddress.city,
           'state': this.applicant.contactAddress.state,
          'country': this.applicant.contactAddress.country 
         });
      });
    }

 //Billing info
 loadAddrFormData(){

  this.billAddressForm = this._formBuilder.group({

    zipCode: [this.billingInfo.billingAddr.zipCode, Validators.required],
    city: {value: this.billingInfo.billingAddr.city, disabled: true},
    state: [{value: this.billingInfo.billingAddr.state, disabled: true}],
    country: [{value: this.billingInfo.billingAddr.country, disabled: true}],
    addrLine1: ['', Validators.required],
    addrLine2: [],
    name:[],
    addressId:[],
    billingId:[],
    email:['',[Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
    isApplicantAddr:[]
  });

}

get f2() { return this.billAddressForm.controls; }

loadBillZipCodeData(event) {

  this.isSPincodeReleased = true;
  let pinCode =  event.srcElement.value;//formData["zipCode"];
  if( pinCode == "" || pinCode.length < 5) {
    this.billAddressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
    return ;
  }
 
  let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;
 
  let promise_ = this.scHttpService.getHttpService(url, '');
   promise_.then(value => { 

    this.billingInfo.billingAddr.zipCode = pinCode;
    this.billingInfo.billingAddr.city    = value["data"]["city"];
    this.billingInfo.billingAddr.state   = value["data"]["state"];
    this.billingInfo.billingAddr.country = value["data"]["country"].trim();
 
    this.billAddressForm.patchValue  ({
       'zipCode': this.billingInfo.billingAddr.zipCode,
       'city': this.billingInfo.billingAddr.city,
       'state': this.billingInfo.billingAddr.state,
       'country': this.billingInfo.billingAddr.country.trim(),
     });
 
     this.isAddrAvailable = true;
   
  });
 
  promise_.catch(error => { // on error and other than http code:200
    this.isAddrAvailable = false;
    this.billErrMsg =  error['message'];
 
    this.billAddressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
  });
 
 }

getApplicantAddress (): any {

 let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicant.action + "?applicant-id=" + this.applicantId, '');
 promise1.then( value => {
 
   let response = value["data"];
   this.applicantData = response;

   if(this.applicantData && this.isSameAsApplicant){
     this.billAddressForm.patchValue (value["data"]);
     this.billingInfo.billingAddr.zipCode = value["data"]["zipCode"];
     this.billingInfo.billingAddr.city    = value["data"]["city"];
     this.billingInfo.billingAddr.state   = value["data"]["state"];
     this.billingInfo.billingAddr.country = value["data"]["country"].trim();
  
     this.billAddressForm.patchValue  ({
        'zipCode': this.billingInfo.billingAddr.zipCode,
        'city': this.billingInfo.billingAddr.city,
        'state': this.billingInfo.billingAddr.state,
        'country': this.billingInfo.billingAddr.country.trim(),
      });
  
   }
   
 });

 return promise1;

}

getApplicantAddrType(isCreditApplicant){  

   if(isCreditApplicant){

    if(this.applicantId){
      this.getApplicantAddress();

    } else {

      this.billAddressForm.patchValue  ({
          'zipCode': this.addressForm.value['zipCode'],
          'city': this.addressForm.value['city'],
          'state': this.addressForm.value['state'],
          'country': this.addressForm.value['country'],
          'addrLine1': this.addressForm.value['addrLine1'],
          'addrLine2': this.addressForm.value['addrLine2'],
      });

    }
     
    this.isSameAsApplicant = isCreditApplicant;
    this.addrType = "same";
    this.onChanges( this.addrType );

   } else {
      this.isSameAsApplicant = isCreditApplicant;
      
       if(this.isEdit && !this.isApplicantAddr){
         this.getBillingAddress();

       } else {
         this.billAddressForm.reset();

        this.billingInfo.billingAddr.zipCode = '';
        this.billingInfo.billingAddr.city    = '';
        this.billingInfo.billingAddr.state   = '';
        this.billingInfo.billingAddr.country = '';
     
        this.billAddressForm.patchValue  ({
           'zipCode': this.billingInfo.billingAddr.zipCode,
           'city': this.billingInfo.billingAddr.city,
           'state': this.billingInfo.billingAddr.state,
           'country': this.billingInfo.billingAddr.country.trim(),
         });
       }
      
      this.addrType = "diff";
      this.onChanges( this.addrType );
   }
}

saveBillInfo () {
      
 this.billingInfo.applicantId = this.applicantId;

 if( this.isSameAsApplicant){
    this.billingInfo.addrId = this.applicantData["addrId"];
    this.billingInfo.name = this.applicantData["name"];

 } else {
   let form_data = this.billAddressForm.getRawValue();
   this.billingInfo.name = this.billAddressForm.value['name'];
   this.billingInfo.billingAddr = form_data;
   
 }

 if( this.applicantId && this.isEdit ){
   this.billingInfo.billingId = this.billingAddr['billingId'];
 }

 let serviceCall :any;
 serviceCall = this.scHttpService.postHttpService(ServerURLS.saveBillingInfo.action, this.billingInfo);

  let promise = serviceCall;
   
   promise.then( (resolve) => {
     let response = resolve;
     this.billErrMsg = '';
     this.billSuccessMsg = response["message"];
     this.billId = response['data'];
     
     this.saveCompanyInfo ();

   }).catch((error) => {
     this.billErrMsg = error.message;
   });

 }

 getBillingAddress (): void {

   let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditBillingAddress.action + "?applicant-id=" + this.applicantId,'');
   promise1.then( value => {

     this.billingAddr = value["data"];
     
     if( this.billingAddr == null ){
       this.isEdit = false;

     }  else {
       this.isApplicantAddr = this.billingAddr["isApplicantAddr"];

       if( this.isApplicantAddr ){
         this.addrType = "same";
         this.isSameAsApplicant = true;
         this.getApplicantAddress ();
         this.onChanges( this.addrType );
 
       } else {
         this.addrType = "diff";
         this.onChanges( this.addrType );
       }
     
      if(this.billingAddr){
        this.billAddressForm.patchValue (value["data"]);
  
        this.billingInfo.billingAddr.zipCode = value["data"]["zipCode"];
        this.billingInfo.billingAddr.city    = value["data"]["city"];
        this.billingInfo.billingAddr.state   = value["data"]["state"];
        this.billingInfo.billingAddr.country = value["data"]["country"].trim();
     
        this.billAddressForm.patchValue  ({
           'zipCode': this.billingInfo.billingAddr.zipCode,
           'city': this.billingInfo.billingAddr.city,
           'state': this.billingInfo.billingAddr.state,
           'country': this.billingInfo.billingAddr.country.trim(),
         });
      }

     }
    
   });
 
 }

 onChanges( addrType ) {

  if (addrType == 'same') {
    this.billAddressForm.get('zipCode').disable();
    this.billAddressForm.get('addrLine1').disable();
    this.billAddressForm.get('addrLine2').disable();
   // this.billAddressForm.get('email').disable();

} else {
  this.billAddressForm.get('zipCode').enable();
  this.billAddressForm.get('addrLine1').enable();
  this.billAddressForm.get('addrLine2').enable();
 // this.billAddressForm.get('email').enable();
}
  }

  toggleAddress(event){
      
    let value = event.target.checked;
    if(value){
      this.getApplicantAddrType(true);

    }else{
      this.getApplicantAddrType(false);
    }

  }
  // End billing info

  //Company info
  loadCompFormData(){
     
    this.companyInfoForm = this._formBuilder.group({
      applicantId:[],
      dateOfIncorporation: [],
      locationType: ['',Validators.required],
      companyType: ['',Validators.required],
    });
  }

  saveCompanyInfo () {
   
    let form_data = this.companyInfoForm.getRawValue();

    this.companyInfo = form_data;

    this.companyInfo.companyType = this.choosenLocationType;
    this.companyInfo.applicantId = this.applicantId;

    for (let service of this.choosenLocationType) {  
      this.companyInfo.companyType.add( service ); 
    } 

    this.companyInfo.companyType = Array.from(this.companyInfo.companyType.values());

    if( form_data.dateOfIncorporation != null ){
      this.companyInfo.dateOfIncorporation = this.commonService.formatDate(form_data.dateOfIncorporation);
    }

      let Promise = this.scHttpService.putHttpService(ServerURLS.updateCompanyInfo.action, this.companyInfo);
        
      Promise.then( (resolve) => {
          let response = resolve;
          this.compErrMsg = '';
          this.compSuccessMsg = response["message"];
          this.compInfoId = response["data"];
          
          if(this.applicantId && this.billId && this.compInfoId){
            let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
            this.router.navigateByUrl("shipcarte/creditv2/payment-info" + params);
          } 

        }).catch((error) => {
          this.compErrMsg = error.error;
          this.compSuccessMsg = '';
        });
   
    }

    loadCompanyTypes (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCompanyTypes.action,'');
      promise1.then( value => {
        this.companyType = value["data"];
       
        if ( this.companyType && this.optedServices != undefined ) {
            
          this.companyType.forEach(element => {

            if ( this.optedServices.includes ( element.companyTypeId ) ) {
              let values= {};
              values['target'] = {};
              values['target']['value'] = element.companyTypeId ;
              element.checked = 'checked';
              this.setCompanyLocationType ( values, true);
            }
          });
       } 

      });
    
    }

    loadLocationTypes (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCompanyLocationTypes.action,'');
      promise1.then( value => {
        this.locationType = value["data"];
      });
    
    }

    setCompanyLocationType(values:any, checked:boolean){

      checked = !this.choosenLocationType.has(values.target.value);

      if( checked ) {
        this.choosenLocationType.add( values.target.value );
      } else {
        this.choosenLocationType.delete( values.target.value );
      }
      
    }

    getCompanyDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantDetails.action +"?id=" +id,'');
      promise1.then( value => {
        this.companyDetail = value["data"];
        this.optedServices = this.companyDetail["companyType"];
        
        if(this.companyDetail["dateOfIncorporation"]){
          this.companyDetail["dateOfIncorporation"] = this.commonService.formatNgBDate(this.companyDetail["dateOfIncorporation"]);
        }
        this.companyInfoForm.patchValue (this.companyDetail);
        this.loadCompanyTypes();
      });
    }

    continue(){

      this.quoteCompletionRate = this.scAuthService.getCreditApplicantRate();

      if(!this.applicantId || this.isEdit){
        this.saveApplicant ();
      }
      
      if(this.applicantId && this.quoteCompletionRate == '1'){
        this.saveBillInfo();
      }

      if(this.applicantId && this.quoteCompletionRate == '2'){
        this.saveCompanyInfo();
      }

      if(this.applicantId && this.quoteCompletionRate == '3'){
          let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
          this.router.navigateByUrl("shipcarte/creditv2/payment-info" + params);
      }
      
    }

}
