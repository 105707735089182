import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { FormBuilder, FormGroup, Validators } from '../../../../../node_modules/@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';

@Component({
  selector: 'app-group-user-assoication',
  templateUrl: './group-user-assoication.component.html',
  styleUrls: ['./group-user-assoication.component.scss']
})
export class GroupUserAssoicationComponent implements OnInit {
  @Input() entityId: any;
  associateUserGrp:FormGroup
  successMsg:String;
  errorMsg:String;
  groups:any;
  constructor(  private _formBuilder: FormBuilder,private scHttpService: ScHttpService,
                private route: ActivatedRoute, private router: Router) { 


                }

  ngOnInit() {

    this.loadFormData();
    this.groupList();
 
    
  }
  loadFormData(){

    this.associateUserGrp = this._formBuilder.group({
    groupId: ['', [Validators.required]],
    autzInfoId:[]
   });

 }

 get f() { return this.associateUserGrp.controls; }

 associateUserGroup () {

  let form_data = this.associateUserGrp.getRawValue();
  form_data.autzInfoId = [this.entityId];
  
  let authPromise = this.scHttpService.postHttpService(ServerURLS.assoicateUserGroup.action, form_data);
    
    authPromise.then( (resolve) => {
      let response = resolve;
      this.successMsg = response["message"];
      this.router.navigateByUrl("shipcarte/user/list" );

      
    }).catch((error) => {
      this.errorMsg = error.message;
    });
  }


  groupList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getGroups.action,'');
    promise1.then( value => {
      this.groups = value["data"];

    });

  }

}


