import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlagService {

  private selectedFlagItemSubject = new BehaviorSubject<any>(null);
  selectedFlagItem$ = this.selectedFlagItemSubject.asObservable();

  setSelectedFlagItem(item: any) {
    this.selectedFlagItemSubject.next(item);
  }
}
