import { formatDate } from '@angular/common';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbDateStruct, NgbDatepickerConfig, NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Server } from 'http';
import { ToastrService } from 'ngx-toastr';
import { userInfo } from 'os';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { DocumentService } from 'src/app/services/document.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { runInThisContext } from 'vm';
import { BillOfLadingComponent } from '../../commons/bill-of-lading/bill-of-lading.component';
import { InvoiceComponent } from '../../commons/invoice/invoice.component';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-allOrder',
  templateUrl: './allOrder.component.html',
  styleUrls: ['./allOrder.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})

export class AllOrderComponent implements OnInit {
  @ViewChild('loadContent2', undefined) loadContent;
  allOrderList: any = [];
  selectAllOrderlist: number = 1;
  mapHours: any;
  customerDetails: any;
  cardMetaDataDetails: any;
  originalDeliveryDate: any;
  quoteDetails: any = {};
  createCustomerFormData: any = [];
  customerDataFromApi: any = [];
  accessrialData: any = [];
  serviceTypeData: any = [];
  packageTypeDate: any = [];
  tz: any;
  data: any;
  rate: any;
  displayData = true;
  rateCurrency: any;
  formattedDate: any;
  modelFromDate: NgbDateStruct;
  rateData: any;
  isStatusFailed: boolean = false;
  currentDate: any;
  month: any;
  year: any;
  day: any;
  isCarrierContractPage: boolean = false;
  BOLd: any = {
    orderNumber: '',
    shipDate: '',
    customerNumber: '',
    shippercompany: '',
    shipperaddrLine1: '',
    shippercity: '',
    shipperstate: '',
    shipperzip: '',
    consigneecompany: '',
    consigneeaddrLine1: '',
    consigneecity: '',
    consigneestate: '',
    consigneezip: '',
    quoteNumber: '',
    shipmentItemsqty: '',
    shipmentItemslengthType: '',
    shipmentItemsdescription: '',
    shipmentItemstotalWeight: '',
    shipmentItemsfreightClass: '',
    shipmentItemsnmfc: '',
    shipmentItemslength: 0,
    shipmentItemsbreadth: 0,
    shipmentItemsheight: 0,
    shipperAddressfirstName: '',
    shipperAddresslastName: '',
    consigneeAddressfirstName: '',
    consigneeAddresslastName: '',
    brokerName: '',
    Phone: '',
    brokerEmail: '',

  };
  resultData: any;
  isViewBoL: boolean = false;
  onClose: any;
  industryType: any;
  showBolNo: boolean;
  sharedData: any;
  shareData2:any
  constructor(
    private route: Router,
    private httpService: ScHttpService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private commonService: ScCommonService,
    private docService: DocumentService,
    private http: HttpClient,
    private config: NgbDatepickerConfig,
    private changeDetector: ChangeDetectorRef,


  ) {
    this.commonService.currentData.subscribe((data)=>{
      this.shareData2=data
    })
    this.commonService.orderDetailCurrentData.subscribe((data: any) => {
      this.sharedData = data
      
      // console.log(this.sharedData)
    })
    this.changeDetector.detach();

  }
  ngDoCheck() {

    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Call your function here
        // console.log(event)
        this.setTabId()
        // this.onUrlChange(event.url);
      });
    this.allOrderList = [
      { id: 1, tabType: "Order Details" },
      { id: 2, tabType: "Shipment Items" },
      { id: 3, tabType: 'Rates' },
      { id: 4, tabType: 'Tracking' },
      { id: 5, tabType: "Documents" },
      { id: 6, tabType: 'Billing' },
      { id: 7, tabType: 'Carrier Contract' }
    ]
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const current = new Date();
    // this.config.minDate = { year: current.getFullYear(), month: 
    // current.getMonth() + 1, day: current.getDate() };
    // this.config.outsideDays = 'hidden';
    this.currentDate = new Date();
    this.month = new Date().getMonth().toString() + 1;
    this.year = new Date().getFullYear().toString();
    this.day = new Date().getDate();
    //this.month = new Date().getMonth().toString() + 1;

    this.customerDetails = JSON.parse(sessionStorage.getItem('userInformation'))
    if (this.customerDetails.source == 'Quoted') {
      this.showBolNo = false;
    } else {
      this.showBolNo = true;
    }
    this.getOrderMetaDataById(this.customerDetails.quoteid);
    // if (this.customerDetails.orderStatus === 'FAILED') {
    //   this.isStatusFailed = true;
    // }
    this.getData();
    this.getInvoiceDetails();
    this.setTabId();
    // console.log(this.customerDetails.quoteid)
  }

  routeToEdit(id) {
    sessionStorage.setItem('customerId', id)
    this.commonService.customerId = id;
    this.route.navigateByUrl('shipcarte/customer/list/generalDetail/edit/shippingReceivingHours')
    // this.route.navigate([]).then(result => { window.open('shipcarte/customer/list/generalDetail/edit', '_blank'); });
  }
  checkTabIsCarrierContract(id: any) {
    if (id === 7) {
      this.isCarrierContractPage = true;
    } else {
      this.isCarrierContractPage = false;
    }
  }
  redirectAllOrders() {
    this.route.navigateByUrl('/shipcarte/orders')
  }
  openEditModal(editModal, edit) {
    this.modalService.open(editModal, { size: 'lg', centered: true, backdrop: true, windowClass: "UpdateEstimatedDeliveryDateModal" }).result.then((result) => {
      //   const current = new Date();
      // this.config.minDate = { year: current.getFullYear(), month: 
      // current.getMonth() + 1, day: current.getDate() };
      // this.config.outsideDays = 'hidden';
      this.getQuoteAddressDetailsById(this.customerDetails.quoteid);
    }, (reason) => {

    })

  }

  getPaymentStatusBgColor(paymentStatus){
    if(paymentStatus == 'PAID'){
      return '#09C199'; //green
    } else if(paymentStatus == 'UNPAID'){
      return '#F85359'; //red
    } else {
      return 'orange'; //orange
    }
  }

  getQuoteAddressDetailsById(id: any) {

    this.openModel();
    if (this.modelFromDate) {
      let date = this.modelFromDate.month + '-' + this.modelFromDate.day + '-' + this.modelFromDate.year;
      let estimatedDate = new Date(date)
      if (estimatedDate > this.currentDate) {
        let url = ServerURLS.updateEstimatedDelDate.action + '?quoteId=' + id + "&estDlvDate=" + date;
        let promise = this.httpService.putHttpService(url, '')
        promise.then((res) => {
          this.displayData = false
          // // console.log(res)
          this.cardMetaDataDetails.deliveryDate = date;
          this.closeModel();
          this.toastr.success('Date updated');
        })
          .catch((err) => {
            // // console.log(err)
            this.closeModel();
            this.toastr.error('Update Failed')
          })
      } else {
        this.closeModel();
        this.toastr.error('Please Enter Valid Date')
      }
    } else {
      this.toastr.error('Please select date')
    }

    // let url = ServerURLS.getCustomerOrderPriceComponent.action+'?quoteId='+this.quoteid +'&customerId='+this.customerid

  }




  fetchPopupData(customerId) {
    this.openModel();
    let url = ServerURLS.getCreateCustomerForm.action;
    let promise1 = this.httpService.getHttpService(url, '');
    promise1.then((res: any) => {
      this.displayData = false
      this.createCustomerFormData = res;

      // // console.log(this.industryType, 'hello')
      let URL = ServerURLS.getCustomerById.action + '?customerId=' + customerId;
      let promise2 = this.httpService.getHttpService(URL, '');
      promise2.then((res) => {
        this.displayData = false
        this.customerDataFromApi = res;


        this.createCustomerFormData.business_type.map((element) => {
          if (this.customerDataFromApi.business_type_id == element.business_id) {
            this.industryType = element.business_type

          }
        })



        this.createCustomerFormData.package_type.map((data) => {
          if (this.customerDataFromApi.package_type && this.customerDataFromApi.package_type !== '' && this.customerDataFromApi.package_type !== null) {
            this.customerDataFromApi.package_type.map((data2) => {

              if (data2.package_id === data.package_id) {
                this.packageTypeDate.push({ "name": data.package_name, "map_id": data2.cust_package_map_id })
              }

            })
          }
        })
        // // console.log(this.packageTypeDate)
        this.createCustomerFormData.services_type.map((data) => {
          if (this.customerDataFromApi.services_type && this.customerDataFromApi.services_type !== '' && this.customerDataFromApi.services_type !== null) {
            this.customerDataFromApi.services_type.map((data2) => {
              if (data2.services_id === data.services_id) {
                this.serviceTypeData.push({
                  "name": data.service_name, "map_id": data2.cust_services_map_id
                })
              }
            })
          }
        })
        // // console.log(this.serviceTypeData)


        // accessories
        this.accessrialData = this.customerDataFromApi.accessorials_type;
        this.closeModel();
      })
        .catch((err) => {
          this.displayData = false
          //  // console.log(err)
          this.toastr.error('Something went wrong')
        })

    })


  }

  selectedPartnerType(id) {
    this.selectAllOrderlist = id;
    this.checkTabIsCarrierContract(id);
    if (id == 2) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/shipmentitem')
    } else if (id == 3) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/rates')
    } else if (id == 4) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/tracking')
    } else if (id == 5) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/documents')
    } else if (id == 6 && !this.isStatusFailed) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/billing')
    } else if (id == 7 && !this.isStatusFailed) {
      this.route.navigateByUrl('shipcarte/orders/orderDetail/carrierContract')
    } else if (id == 1) {
      this.selectAllOrderlist = 1;
      this.route.navigateByUrl('shipcarte/orders/orderDetail')
    }
    let urlArray = window.location.href.split('/');
    if (id === 6 && this.isStatusFailed) {
      this.setTabId();
    } else if (id === 7 && this.isStatusFailed) {
      this.setTabId()
    }
  }
  back(){
    // window.history.back()
    // setTimeout(()=>{

    //   this.setTabId()
    // },500)
    this.commonService.navigateBack();
  }
  setTabId() {
    let urlArray = window.location.href.split('/');
    // console.log(urlArray)
    if (urlArray[urlArray.length - 1] === 'rates') {
      this.selectAllOrderlist = 3
    } else if (urlArray[urlArray.length - 1] === 'orders') {
      this.selectAllOrderlist = 1;
    } else if (urlArray[urlArray.length - 1] === 'shipmentitem') {
      this.selectAllOrderlist = 2
    } else if (urlArray[urlArray.length - 1] === 'billing') {
      this.selectAllOrderlist = 6;
    } else if (urlArray[urlArray.length - 1] === 'tracking') {
      this.selectAllOrderlist = 4;
    } else if (urlArray[urlArray.length - 1] === 'documents') {
      this.selectAllOrderlist = 5;
    } else if (urlArray[urlArray.length - 1] === 'carrierContract') {

      this.selectAllOrderlist = 7
    } else {
      this.selectAllOrderlist = 1;
    }
    this.checkTabIsCarrierContract(this.selectAllOrderlist);
  }
  openModel() {
    // this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modalService.dismissAll()
  }


  orderStatus:any=''
  getOrderMetaDataById(id: any) {

    let url = ServerURLS.orderDetails.action + '?quoteId=' + id;
    let promise = this.httpService.getHttpService(url, '');

    promise.then((res: any) => {
      // console.log(res)
      if (res.allOrderIdConsolidate) {
        sessionStorage.setItem('allOrderIdConsolidate', JSON.stringify(res.allOrderIdConsolidate))

      }
      this.displayData = false
      this.openModel();
      this.cardMetaDataDetails = res;
      this.sharedData.orderStatus=res.orderStatus
      this.orderStatus=res.orderStatus
      // console.log(this.cardMetaDataDetails)
      this.originalDeliveryDate = res.originalDeliveryDate
      sessionStorage.setItem('orderDetailInvoiceSubmitted', this.cardMetaDataDetails.invoiceSubmitted);

      const data = {
        proId: this.cardMetaDataDetails.proId,
        customQuoteId: this.cardMetaDataDetails.customQuoteId,
        carrierId: this.cardMetaDataDetails.carrierId,
        currencyUnit: this.cardMetaDataDetails.currencyUnit,
        estimatedDelveryDate: this.cardMetaDataDetails.deliveryDate,
        trackingId: this.cardMetaDataDetails.orderId
      }

      this.fetchPopupData(this.cardMetaDataDetails.customerId);
      sessionStorage.setItem('orderDetailProbill', JSON.stringify(data))

      //************ Charge */
      let url = ServerURLS.getCustomerOrderPriceComponent.action + '?quoteId=' + id + '&customerId=' + this.cardMetaDataDetails.customerId;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((response) => {
        this.displayData = false
        this.commonService.carrierRates = response;
        sessionStorage.setItem('carrierRatesData', JSON.stringify(response))
        this.rate = response['totalAmount']
        this.rateCurrency = response['currency']
      }).catch((res) => {
        this.toastr.error('Some thing wrong', '', { timeOut: 2000 })
        this.displayData = false

      })
      this.closeModel();
    })
      .catch((err) => {
        this.displayData = false
        this.toastr.error('Some thing wrong', '', { timeOut: 2000 })
        this.modalService.dismissAll()
      })
  }

  delete(type, id) {
    //// console.log(type,id)
    this.openModel();
    let url = ServerURLS.deleteCustomerServicePackageAccesorial.action;
    if (type == "ser") {
      url = url + "?featureType=Services&featureMapId=" + id
    } else if (type == "pac") {
      url = url + "?featureType=PackagesType&featureMapId=" + id;
    } else if (type == "acc") {
      url = url + "?featureType=Accessorial&featureMapId=" + id
    }
    // // console.log(id)

    let promise = this.httpService.deleteHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      if (type == 'ser') {
        //    this.modalService.open(this.loadContent2,{centered:true,backdropClass:'light-blue-backdrop,',windowClass:'dark-model',backdrop:'static'})

        this.serviceTypeData = this.serviceTypeData.filter((data => data.map_id != id))
      } else if (type == 'pac') {
        this.packageTypeDate = this.packageTypeDate.filter((data => data.map_id != id));
      } else if (type === 'acc') {
        this.accessrialData = this.accessrialData.filter((data => data.cust_accessorial_map_id !== id))
      }
      this.closeModel();
    })
      .catch((err) => {
        this.toastr.error("Something went wrong!")
        this.closeModel();
      })
  }

  ngOnDestroy() {
    sessionStorage.removeItem('userInformation');
    sessionStorage.removeItem('orderDetailProbill');
    sessionStorage.removeItem('orderDetailInvoiceSubmitted');
    sessionStorage.removeItem('carrierRatesData');
    sessionStorage.removeItem('allOrderIdConsolidate')
  }

  orderId: any;
  documentId: any;
  shippingLabel: boolean;
  showComInv: boolean;
  dynamicDimension = {
    length: 0,
    width: 0,
    height: 0,
    weight: 0
  }
  totalItem: any;
  declaredValue: any;
  isCommercialInvoiceGenerated: boolean;
  displayLoader: boolean;
  getData() {
    this.openModel();
    // var quoteIdTemp= "c76a35aa-afd4-4c73-94fc-2f74a85ec4c5";
    this.docService.getData(this.customerDetails.quoteid).subscribe(result => {
      this.displayData = false;
      // // console.log('getDatahere',result)
      this.resultData = result;
      this.commonService.bolResultData = this.resultData;
      this.closeModel();
      //Revers shipment items
      //result['shipmentItems'].reverse();

      this.resultData['shipmentItems'].sort((a, b) => {
        return a.displayOrder - b.displayOrder
      });
      // // console.log(this.resultData['shipmentItems'])

      // this.isCommercialInvoiceGenerated = result['isCommercialInvoiceGenerated'];
      this.isViewBoL = result['isPdfExist'];
      this.orderId = result['orderId'];
      this.documentId = result['documentId'];
      localStorage.setItem('com_documentId_' + this.customerDetails.quoteid, result['documentId']);

      this.BOLd['carrier'] = result['carrier']
      this.BOLd['carrierQuoteId'] = result['carrierQuoteId']
      this.BOLd['reference'] = result['customerNumber']
      this.BOLd['orderNumber'] = result['orderNumber'];
      this.BOLd['accountNum'] = result['accountNum']
      this.BOLd['shipmentMode'] = result['shipmentMode']
      this.BOLd['shipperTaxId'] = result['shipperTaxId']
      this.BOLd['consigneeTaxId'] = result['consigneeTaxId']
      this.BOLd['purchaserAddress'] = result['purchaserAddress']
      let shipDate = result['shipDate'].split('-');
      this.BOLd['shipDate'] = shipDate[2] + '/' + shipDate[1] + '/' + shipDate[0];
      this.BOLd['customerNumber'] = result['customerNumber'];
      this.BOLd['shippercompany'] = result['shipperAddress']['company'];
      this.BOLd['shipperaddrLine1'] = result['shipperAddress']['addrLine1'];
      this.BOLd['shipperaddrLine2'] = result['shipperAddress']['addrLine2'];
      this.BOLd['shipperfullAddr'] = result['shipperAddress']['fullAddr'];

      this.BOLd['shippercity'] = result['shipperAddress']['city'];
      this.BOLd['shipperstate'] = result['shipperAddress']['state'];
      this.BOLd['shipperzip'] = result['shipperAddress']['zipCode'];
      this.BOLd['shipperCountry'] = result['shipperAddress']['country'];

      this.BOLd['consigneecompany'] = result['consigneeAddress']['company'];
      this.BOLd['consigneeaddrLine1'] = result['consigneeAddress']['addrLine1'];
      this.BOLd['consigneefullAddr'] = result['consigneeAddress']['fullAddr'];
      this.BOLd['consigneeaddrLine2'] = result['consigneeAddress']['addrLine2'];
      this.BOLd['consigneecity'] = result['consigneeAddress']['city'];
      this.BOLd['consigneeCountry'] = result['consigneeAddress']['country'];
      this.BOLd['consigneestate'] = result['consigneeAddress']['state'];
      this.BOLd['consigneezip'] = result['consigneeAddress']['zipCode'];
      this.BOLd['quoteNumber'] = result['quoteNumber'];
      // this.BOLd['shipmentItemsqty'] = result['shipmentItems'][0]['qty'];
      // this.BOLd['shipmentItemslengthType'] = result['shipmentItems'][0]['lengthType'];
      this.BOLd['shipmentItemslengthType'] = 'Pallete';
      // this.BOLd['shipmentItemsdescription'] = result['shipmentItems'][0]['description'];
      // this.BOLd['shipmentItemstotalWeight'] = result['shipmentItems'][0]['totalWeight'];
      // this.BOLd['shipmentItemsfreightClass'] = result['shipmentItems'][0]['freightClass'];
      // this.BOLd['shipmentItemsnmfc'] = result['shipmentItems'][0]['nmfc'];

      // // console.log(this.BOLd['shipmentItemslengthType'])

      if (this.BOLd['shipmentMode'] == "Courier") {
        this.shippingLabel = true;
      }
      else {
        this.shippingLabel = false;

      }

      if (this.BOLd['shipperCountry'] != this.BOLd['consigneeCountry']) {
        this.showComInv = true;
      } else {
        this.showComInv = false;
      }
      this.dynamicDimension = {
        length: 0,
        width: 0,
        height: 0,
        weight: 0
      }
      this.totalItem = 0
      for (let i in result['shipmentItems']) {
        this.totalItem += 1
        this.commonService.totalItemBol = this.totalItem;
        if (result['shipmentItems'][i] && result['shipmentItems'][i].unit == 'cm') {
          result['shipmentItems'][i].length = result['shipmentItems'][i].length * 0.39;
          result['shipmentItems'][i].totalWeight = result['shipmentItems'][i].totalWeight * 2.20;
          result['shipmentItems'][i].height = result['shipmentItems'][i].height * 0.39;
          result['shipmentItems'][i].breadth = result['shipmentItems'][i].breadth * 0.39;
          this.declaredValue += result['shipmentItems'][i]['shipmentItemValue'] * result['shipmentItems'][i]['qty'];
          this.commonService.declaredBol = this.declaredValue;
          this.dynamicDimension['length'] += result['shipmentItems'][i].length;
          this.dynamicDimension['width'] += result['shipmentItems'][i].breadth;
          this.dynamicDimension['height'] += result['shipmentItems'][i].height;
          this.dynamicDimension['weight'] += result['shipmentItems'][i].totalWeight * result['shipmentItems'][i]['qty'];
          this.commonService.dimensionBOL = this.dynamicDimension;
        } else {
          this.dynamicDimension['length'] += result['shipmentItems'][i].length;
          this.dynamicDimension['width'] += result['shipmentItems'][i].breadth;
          this.dynamicDimension['height'] += result['shipmentItems'][i].height;
          this.dynamicDimension['weight'] += result['shipmentItems'][i].totalWeight * result['shipmentItems'][i]['qty'];
          this.declaredValue += result['shipmentItems'][i]['shipmentItemValue'];
          this.commonService.declaredBol = this.declaredValue;

          this.commonService.dimensionBOL = this.dynamicDimension;

        }
      }
      this.BOLd['shipperAddressfirstName'] = result['shipperAddress']['firstName'];
      this.BOLd['shipperAddresslastName'] = result['shipperAddress']['lastName'];
      this.BOLd['consigneeAddressfirstName'] = result['consigneeAddress']['firstName'];
      this.BOLd['consigneeAddresslastName'] = result['consigneeAddress']['lastName'];
      this.BOLd['brokerName'] = result['brokerName'];
      this.BOLd['Phone'] = result['brokerPhone'];
      this.BOLd['brokerEmail'] = result['brokerEmail'];
      //this.BOLd['shipcarteLogoSmall']= result['shipcarteLogoSmall'];
      this.BOLd['carrierLogo'] = result['carrierLogo'];
      // if(this.BOLd['shipmentMode']=='Courier'){
      //   this.getShipmentLabelUrl();
      // }


      // if(!localStorage.getItem("fav_"+this.quoteId)){
      //   this.dialogOpen('addFav','');
      // }
      this.commonService.bolInvoiceDetails = this.BOLd;
      var self = this;
      setTimeout(function () {
        if (result) {
          //self.savePdf();
          if (self.isCommercialInvoiceGenerated) {
            self.saveCommercialPdf();
          }
          else {
            self.displayLoader = false;
          }
        } else {
          self.displayLoader = false;
        }
      }, 3000);

    }, error => {

      if (error.status == 401) {
        this.route.navigate(['/signin']);
      }

      this.displayLoader = false;

      this.toastr.error(error['error']["message"], 'Please try again.', {
        timeOut: 10000,
      });
      this.displayData = false
      this.displayLoader = false;

    });
  }
  saveCommercialPdf() {
    let currentcomp = this;
    this.displayLoader = true;
    html2canvas(document.querySelector('#DownloadCommercialPdfData'), { useCORS: false }).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }
        var blobData = pdf.output("blob");
        currentcomp.sendCommercialPDFToServer(blobData);
      };
    });
  }
  sendCommercialPDFToServer(pdfContent) {
    // // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    var commercialDocumentId = localStorage.getItem("com_documentId_" + this.customerDetails.quoteid);
    formdata.append("documentId", commercialDocumentId);

    var date = new Date().valueOf();

    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });



    var dummyFile = new File([pdfContent], "COM_" + commercialDocumentId + ".pdf");
    formdata.append("file", dummyFile);

    this.docService.saveCommercialPdf(formdata).subscribe(result => {
      this.displayLoader = false;
      this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
        timeOut: 5000,
      });
    }, error => {
      if (error.status == 401) {
        localStorage.clear();
        this.route.navigate(['/signin']);
      }
      this.displayLoader = false;
      this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
        timeOut: 5000,
      });
      this.displayLoader = false;
    });
  }

  getInvoiceDetails() {
    // var orderIdTemp ="9b1bbd5c-e21b-4182-b980-8296aa32179a";
    let promise = this.httpService.getHttpService(ServerURLS.getInvoiceDetailsById.action + '?orderId=' + this.customerDetails.orderId, { withCredentials: true });
    promise.then((result:any) => {
      this.displayData = false
      this.data = result;
      // console.log(this.data)
      if(result.status=='FAILED'){
        this.isStatusFailed=true
      }
      this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');
    })
  }

  open_modal_invoice(modal, showIcons) {
    if (modal == 'viewBol') {
      if (this.showBolNo) {
        const modalRef = this.modalService.open(BillOfLadingComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal12', keyboard: true });
        this.route.events.subscribe((val) => {
          modalRef.close();
        });
        // modalRef.componentInstance.data = this.invoiceDetails;
        modalRef.componentInstance.quoteIdForEmail = this.customerDetails.quoteid;
        modalRef.componentInstance.showIcons = showIcons;
        modalRef.result.then((result) => {
        });
      }
    }
    else if (modal == 'viewinvoice') {
      if (this.cardMetaDataDetails.invoiceSubmitted) {
        const modalRef = this.modalService.open(InvoiceComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal10', keyboard: true });
        this.route.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.data = this.data;
        modalRef.componentInstance.quoteIdForEmail = this.customerDetails.quoteid;
        modalRef.componentInstance.showIcons = showIcons;
        modalRef.result.then((result) => {
        });
      } else {
        this.toastr.error('Invoice is not submitted yet.')
      }
    }
  }

  goToEditOrder() {
    // // console.log(this.cardMetaDataDetails.carrierId, this.cardMetaDataDetails.customerId, this.cardMetaDataDetails.orderId, this.cardMetaDataDetails.quoteId)
    // this.route.navigateByUrl('shipcarte/bookedOrder/edit'+this.cardMetaDataDetails.customerId+'/'+this.cardMetaDataDetails.orderId);
    sessionStorage.setItem('editOrderQuoteId', this.customerDetails.quoteid)
    this.route.navigate(['shipcarte/bookedOrder/edit', this.customerDetails.quoteid, this.cardMetaDataDetails.orderId]);
  }
}