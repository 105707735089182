import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { Router } from '@angular/router';

// import {GoogleSignInSuccess} from 'angular-google-signin';

import { FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angular-6-social-login';

import { Socialusers } from 'src/app/services/social/socialusers';
import { ServerURLS } from 'src/environments/environment.prod';
import { EnvironmentConstants } from 'src/environments/environment';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

declare var $: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  showCustTab: boolean;
  showCustForm: boolean;

  showPartnerTab: boolean;
  showPartnerForm: boolean;
  postLoginURL: string;
  loginForm: FormGroup;

  errorMsg: any;
  successMsg: any;
  authStatus: boolean;

  actorType: string;
  socialusers = new Socialusers();

  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor(private router: Router, private _formBuilder: FormBuilder, private scHttpService: ScHttpService,
    private scAuthService: ScAuthService, public OAuth: AuthService /*private OAuth: ScSocialAuthServiceService*/,private listDataService:ListDataServiceService,private commanService:ScCommonService) { }
    dashboardShareData:any;
  ngOnInit() {
    
    this.router.navigateByUrl('shipcarte/user/login');
    // window.location.href = 'https://staging.shipcarte.com/#/shipcarte/user/login';
    this.showCustTab = true;
    this.showCustForm = true;
    this.actorType = 'customer';

    this.postLoginURL = "shipcarte/dashboard";
    if (
      this.scAuthService.isLoggedSession()) {
      this.router.navigateByUrl(this.postLoginURL);

    }
    this.loadFormData();
  }

  loadFormData() {

    this.loginForm = this._formBuilder.group({

      email: ['', [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      password: ['', [Validators.required]],

    });

  }

  get f() { return this.loginForm.controls; }

  changeType(actorType) {
    if (actorType == 'customer') {
      this.actorType = 'partner'
    } else {
      this.actorType = 'customer';
    }
  }

  traverse(tab) {

    if (tab == 'partner') {

      this.showPartnerTab = true;
      this.showPartnerForm = true;

      this.showCustTab = false;
      this.showCustForm = false;

      this.actorType = tab;

    } else if (tab == 'customer') {

      this.showCustTab = true;
      this.showCustForm = true;

      this.showPartnerTab = false;
      this.showPartnerForm = false;

      this.actorType = tab;

    }

  }

  processing = false;
  procesing_img = EnvironmentConstants.IMAGE_LOADER;
  authUser() {

    this.processing = true;
    this.errorMsg = '';

    let formData_ = this.loginForm.getRawValue();

    let email = formData_.email;
    let password = formData_.password;

    this.authorize(email, password, '', this.actorType, this.postLoginURL);

  }

  private authorize(email, password, socialProvider, actorType, poURL) {

    let authPromise = this.scAuthService.signinUser(email, password, socialProvider, actorType, poURL);

    authPromise.then((resolve) => {
      let response = resolve;
      let data = response["data"];

      this.authStatus = response["data"];

      this.processing = false;
      if (this.authStatus) {
        this.successMsg = response["message"];
        if (this.actorType == 'partner') {
          this.router.navigateByUrl("shipcarte/profilev2");
        } else {
          this.router.navigateByUrl(this.postLoginURL);
        }
      } else {
        throw new Error("No user object found");
      }

    }).catch((error) => {
      if (error.error) {
        this.errorMsg = error.error;
      } else {
        this.errorMsg = error;
      }

      this.successMsg = '';
      this.processing = false;
    });

  }
  private myClientId: string = EnvironmentConstants.GOOGLE_CLIENT_ID;

  /*onGoogleSignInSuccess(event: GoogleSignInSuccess) {
    
    let googleUser: gapi.auth2.GoogleUser = event.googleUser;
    let id: string = googleUser.getId();
    let profile: gapi.auth2.BasicProfile = googleUser.getBasicProfile();
    // console.log('ID: ' +
      profile
        .getId()); // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + profile.getName());

    googleUser.disconnect(); // invoke after the logout

  }*/

  public socialSignIn(socialProvider: string) {
    let formData = this.loginForm.getRawValue();
    formData.actorType = this.actorType;
    let socialPlatformProvider;

    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    if (this.OAuth) {

      this.OAuth.signIn(socialPlatformProvider).then(socialusers => {
        // // console.log(socialProvider, socialusers);  
        // // console.log(socialusers);  
        this.processing = true;
        this.socialusers = socialusers;

        if (this.socialusers) {

          let url = '';
          let actorType = formData.actorType;
          formData = {
            "email": this.socialusers.email,
            //"password": this.socialusers.name,
            "socialProvider": this.socialusers.provider
          }

          if (actorType == 'customer') {
            url += ServerURLS.registerCustomer.action;
          } else if (actorType == 'partner') {
            url += ServerURLS.registerPartner.action;
          }

          this.authorize(formData.email, formData.password, formData.socialProvider, this.actorType, this.postLoginURL);

        }
        //this.Savesresponse(socialusers);  
      });

    } else {
      console.error("service is not installed properly");
    }

  }

  navigateURL(url: string) {

    //this.router.navigate([{outlets: {primary: url, newUser: url }}]);
    this.router.navigateByUrl(url);
  }

}
