import { Injectable } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Injectable({
  providedIn: 'root'
})
export class ScFileExporterService {

  exportAsConfig: ExportAsConfig;

  constructor(private exportAsService: ExportAsService) { }
  
  export( docType, elementId, orderId ) {

    this.exportAsConfig = {
      type: docType, // the type you want to download
      elementIdOrContent: elementId // the id of html/table element,
    }
    
    // download the file using old school javascript method
    this.exportAsService.save( this.exportAsConfig, 'order-'+ orderId ).subscribe(() => {
      // save started
    });
    
  }
}
