import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from '../../../services/storage/sc-storage.service';
import { ServerURLS } from '../../../../environments/environment';
import { BillingInfo } from '../bill-info/biilinginfo';
@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {

  paymentForm:FormGroup;
  billingInfo:BillingInfo;
  errMsg:string;
  paymentMethods:any;
  searchZipcode: any;
  applicantId:any;
  isEdit:any;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService, 
               private storageService: ScStorageService, private router: Router, 
               private route: ActivatedRoute ) {

                this.billingInfo  = new BillingInfo();
    } 

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit');
    this.loadpaymntMethods();
    this.loadFormData();

    if( this.isEdit){
       this.loadpaymntData();
    }
    
  }

   loadFormData(){

     this.paymentForm = this._formBuilder.group({
        
        gstnNumber:[],
        bondNumber:[],
        paymentMethodId:['',Validators.required],
        creditCardNumber:[],
        expDate:[],
        natureOfBusiness:['',Validators.required],
        yearInBusiness:['',Validators.required],
        accountPayableContact:['',Validators.required],
        ext:[],
        email:['',[Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
        operationsContact:[],
        requestedCreditLimit:[],
        title:[],
        principalName:[],
        
    });
 }




 get f1() { return this.paymentForm.controls; }

 loadpaymntMethods (): void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPaymentMethods.action,'');
  promise1.then( value => {
    this.paymentMethods = value["data"];
  });

}

savePaymentInfo () {
  let form_data = this.paymentForm.getRawValue();
  this.billingInfo  = form_data
  this.billingInfo.applicantId = this.applicantId
  let serviceCall :any;
  if(form_data.paymentMethodId==''){
    
    this.errMsg = "Please slect payment method"

  }
  serviceCall = this.scHttpService.putHttpService(ServerURLS.updatePayment.action, this.billingInfo);
  let authPromise = serviceCall;
    
    authPromise.then( (resolve) => {
      let response = resolve;
      this.errMsg = '';

      let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
      this.router.navigateByUrl("shipcarte/credit/applicant/company/info" + params);
  
    }).catch((error) => {
      this.errMsg = error.message;
     
    });

  }

  loadpaymntData (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPayments.action+"?applicant-id="+this.applicantId,'');
    promise1.then( value => {
      let response = value["data"];
 
       this.paymentForm.patchValue (value["data"]);
      
    });
  
  }
}
