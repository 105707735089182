import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SCToastService {

  constructor() { }

  toasts: any[] = [];
  showToast = false;

  close() {
    this.showToast = false;
    //setTimeout(() => this.showToast = true, 5000);
  }

  show(header: string, body: string) {
    this.toasts.push({ header, body });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t != toast);
  }

 
}
