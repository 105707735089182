import { Component, OnInit, ɵConsole } from '@angular/core';
import { Permission } from '../../commons/pojo/permission';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {

  offerList:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;

  permission: Permission;
  successMsg: any;
  errorMsg: any;

  constructor(private scHttpService: ScHttpService) { 
    
    this.permission = new Permission();

    this.permission.write = false;
    this.permission.read = false;
    this.permission.action = false;
  }

  ngOnInit() {

    this.loadOfferList(false);
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadOfferList(false);
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  loadOfferList(status ): void {

      let url = ServerURLS.getOffers.action + "/" + ( this.page - 1 ) + "/" + this.pageSize +"?archive-flag=" + status;

      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {

        this.offerList = value["data"]["content"];
        this.pageSize = value["data"]["pageable"]["pageSize"];
        this.collectionSize = value["data"]["totalElements"];
        this.page = value["data"]["pageable"]["pageNumber"] + 1;
        this.noOfPages = value["data"]["totalPages"];

        let offset = value["data"]["pageable"]["offset"];
        this.startElementCount = offset + 1;
        this.endElementCount = offset + value["data"]["numberOfElements"];
        
        this.activePage = this.page;

      });

    }

    archiveUnarchiveOffer(isArchive, id) {
  
      let promise = null;
      promise  = this.scHttpService.putHttpService ( ServerURLS.updateArchiveOrUnarchive.action + isArchive, [id] )
      promise.then( (value) => {
        this.successMsg =  value['message'];
        this.loadOfferList( isArchive == 'false' ? true : false ) ;
      });
  
      promise.catch ( (value) => {
        this.errorMsg =  value['message'];
      });
    }

    getListBasedOnFilter(event){
      this.loadOfferList(event.target.value)
     }
  }
