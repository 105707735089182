import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { jsPDF } from 'jspdf';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js'
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailDocumentComponent } from '../email-document/email-document.component';
import { DownloadLabelComponent } from '../download-label/download-label.component';
import { ViewBolDocComponent } from '../view-bol-doc/view-bol-doc.component';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ViewInvoiceComponent } from '../view-invoice/view-invoice.component';
import { ViewCommInvQuickActionComponent } from '../view-comm-inv-quick-action/view-comm-inv-quick-action.component';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  extBolId: string = '';
  quoteNum: string = '';
  addFav: any; 
 // isLabelUrl:SafeResourceUrl;
  emailDoc: any;
  BOLNumber: any;
  quoteId: any;
  displayLoader: boolean;
  declaredValue: any;
  printBOL: boolean;
  ShipmentLabelUrl: any;
  isViewBoL: boolean = false;
  BOLd: any = {
    carrierLogo: '',
    bolNum: '',
    shipDate: '',
    customerNumber: '',
    shippercompany: '',
    shipperaddrLine1: '',
    shippercity: '',
    shipperstate: '',
    shipperzip: '',
    consigneecompany: '',
    consigneeaddrLine1: '',
    consigneecity: '',
    consigneestate: '',
    consigneezip: '',
    quoteNumber: '',
    shipmentItemsqty: '',
    shipmentItemslengthType: '',
    shipmentItemsdescription: '',
    shipmentItemstotalWeight: '',
    shipmentItemsfreightClass: '',
    shipmentItemsnmfc: '',
    shipmentItemslength: 0,
    shipmentItemsbreadth: 0,
    shipmentItemsheight: 0,
    shipperAddressfirstName: '',
    shipperAddresslastName: '',
    consigneeAddressfirstName: '',
    consigneeAddresslastName: '',
    shipcarteLogoSmall: "",
    brokerName: '',
    Phone: '',
    brokerEmail: '',
  };
  totalItem: any;
  documentId: any;
  orderId: any;
  bolId:any
  pdfFile = new jsPDF("p", "mm", "a4");
  resultData: any;
  isCommercialInvoiceGenerated: boolean;
  dynamicDimension = {
    length: 0,
    width: 0,
    height: 0,
    weight: 0
  }
  labaelList: any;
  data: any;
  formattedDate: any;
  showComInv: boolean;
  shippingLabel: boolean;
  sidebarToggle: boolean;
  apiCall: Boolean = false;
  shipperAddress: any;
  constructor(private commonService:ScCommonService, private scHttpService: ScHttpService,private changeDetector: ChangeDetectorRef,private modalService: NgbModal, private toastr: ToastrService, public router: Router,public activatedRoute: ActivatedRoute,) { }
  ngOnDestroy(): void {
    sessionStorage.removeItem('shipSmallLogo');
    sessionStorage.removeItem('carrierLogo')
  }

  ngOnInit(): void {

    this.extBolId = this.commonService.bolId = this.activatedRoute.snapshot.paramMap.get('extBolId');
    this.apiCall=false;
    this.printBOL = true;
    this.displayLoader = true;
    this.declaredValue = 0;
    this.quoteId = "a5cca260-3c2a-426d-8d40-31d708e550a0";
    // this.isCommercialInvoiceGenerated = localStorage.getItem('com_generated_' + this.quoteId) == 'true' ? true : false;

    // this.getBOLNumber();
    // this.isLabelUrl=" ";
    this.getData();
    this.getCommercialInvoiceData(this.orderId, 'ext');

    this.BOLd = {
      "documentId": "739608cb-5ec9-4315-9e1d-ca392a8e81ed",
      "bolNumber": "BOL7882264117282071297",
      "quoteNum": "4545454545",
      'accountNum': "22",
      "orderId": "fba900d7-5fb6-440d-b22c-1edc90d3895c",
      "bolNum": "EB-00-0002",
      "shipDate": "2021-08-12",
      "customerNumber": "486",
      "carrierOrderRef": "",
      "shipmentMode": "Less than Truckload (LTL)",
      "carrier": "XPO",
      "carrierLogo": "https://dev-shipcarte-docs.s3.amazonaws.com/1613057326640-XPO-Logistics_(2)_(1).jpg",
      "shipperAddress": {
        "addressId": null,
        "addressName": "name of address",
        "addrLine1": "null",
        "addrLine2": null,
        "city": "missisuga",
        "state": "USA",
        "country": "canada",
        "zipCode": "L4W 5N2",
        "addrTypeId": null,
        "company": "shipper company",
        "firstName": "John",
        "lastName": "Doe",
        "dept": null,
        "email": "JohnDoe@email.com",
        "phoneNumber": "888 8888 888",
        "alternatePhoneNumber": null,
        "fax": null,
        "hasAddrBook": false,
        "latitude": null,
        "longitude": null
      },
      "consigneeAddress": {
        "addressId": null,
        "addressName": "Name of address",
        "addrLine1": null,
        "addrLine2": null,
        "city": "missisuga",
        "state": "USA",
        "country": "Canada",
        "zipCode": "L4W 5N2",
        "addrTypeId": null,
        "company": "Consignee Company",
        "firstName": "null",
        "lastName": "null",
        "dept": null,
        "email": null,
        "phoneNumber": null,
        "alternatePhoneNumber": null,
        "fax": null,
        "hasAddrBook": false,
        "latitude": null,
        "longitude": null
      },
      "items": [
        {
          "quoteItemId": null,
          "packaging": null,
          "qty": 2,
          "totalWeight": 20.0,
          "length": 20.0,
          "breadth": 20.0,
          "height": 20.0,
          "unit": "cm",
          "wghtUnit": "lbs",
          "freightClass": "500",
          "lengthType": null,
          "shipmentItemValueCurrency": "CAD",
          "description": "test",
          "shipmentItemValue": 5.0,
          "nmfc": "test",
          "stackable": true,
          "hazMat": true
        }
      ],
      "brokerName": "brokerName",
      "brokerEmail": "brokerEmail",
      "brokerPhone": "brokerPhone",
      "shipcarteAddress": "8-120 West Beaver Creek Rd, Richmond Hill, ON L4B1L2, Canada",
      "shipcarteEmail": "partners@shipcarte.com",
      "shipcartePhone": "8884248878",
      "shipcarteLogoSmall": "assets/img/shipcarte-logo-350x130.png",
      "shipcarteLogoLarge": "assets/img/shipcarte-logo-350x130.png"
    }

  }
  commercialInvoiceData:any;
  getCommercialInvoiceData(orderId,extension) {
    let promise = this.scHttpService.getHttpService(ServerURLS.getCommercialInvoiceData.action + '?extCommercialInvoiceId=' + this.orderId+'&actionType=' + extension, { withCredentials: true });
    promise.then((res: any) => {
      if(res['commercialInvoiceId']===null || res['commercialInvoiceId']=='undefined'){
              this.isCommercialInvoiceGenerated=false;
            }else{
              this.commercialInvoiceData=res;
              this.isCommercialInvoiceGenerated=true;
            }
            this.commonService.commResultData = this.commercialInvoiceData;
            // // console.log( this.commonService.commResultData,'result')
          },err=>{
      
          })
  }

  saveCommercialPdf() {
    let currentcomp = this;
    this.displayLoader = true;
    html2canvas(document.querySelector('#DownloadCommercialPdfData'), { useCORS: false }).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }
        var blobData = pdf.output("blob");
        currentcomp.sendCommercialPDFToServer(blobData);
      };
    });
  }


  sendCommercialPDFToServer(pdfContent) {
    // // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    var commercialDocumentId = localStorage.getItem("com_documentId_" + this.quoteId);
    formdata.append("documentId", commercialDocumentId);

    var date = new Date().valueOf();

    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });



    var dummyFile = new File([pdfContent], "COM_" + commercialDocumentId + ".pdf");
    formdata.append("file", dummyFile);
    let promise = this.scHttpService.getHttpService(ServerURLS.commercialInvoiceSavePDF.action, formatDate, { withCredentials: true });
    promise.then(result => {
      this.displayLoader = false;
      this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
        timeOut: 5000,
      });
    }, error => {
      if (error.status == 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
      this.displayLoader = false;
      this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
        timeOut: 5000,
      });
      this.displayLoader = false;
    });
  }

  async savePdf() {
    this.displayLoader = true;
    let currentcomp = this;
    var element = document.querySelector('#content');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + currentcomp.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // // console.log(worker);
    currentcomp.sendPDFToServer(worker);
  }

  sendPDFToServer(pdfContent) {

    const formdata: FormData = new FormData();
    formdata.append("documentId", this.documentId);
    formdata.append("bolId", this.bolId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "BOL_" + this.documentId + ".pdf");
    formdata.append("file", dummyFile);

        let promise = this.scHttpService.putHttpService(ServerURLS.commercialInvoiceSavePDF.action +formatDate, { withCredentials: true });
        promise.then((result)=>{
          this.displayLoader = false;
          this.toastr.success('PDF Saved Successfully.', '', {
            timeOut: 10000,
          });
        }, error => {
          if (error.status == 401) {
            this.router.navigate(['/signin']);
          }
          this.displayLoader = false;
          this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
            timeOut: 10000,
          });
          this.displayLoader = false;
        })
  }

  createCommercialInvoice(){
    sessionStorage.setItem('createCommercialInvoiceOrderId',this.orderId);
      this.router.navigateByUrl('commercialInvoice')
  }
  returnDashboard() {
    this.router.navigate(['/dashboard'])
  }
  shipcarteSmallLogo: any = '';
  carrierLogo: any = '';
  packagesArray: any = [];
  totalPackageCountArray: any = []
  getData() {
    let fetchQuickBolFormData = ServerURLS.fetchQuickBolFormData.action + "?bolId=" + this.commonService.bolId;
    let promise = this.scHttpService.getHttpService(fetchQuickBolFormData, '');
    promise.then(result => {
      // // console.log('getDatahere', result)
      this.resultData = result;
      // Packages handling logic
      if (this.resultData.description === 'Courier' && this.resultData.packagingDetails) {
        this.resultData.packagingDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingType,
            'packageId': element1.packagingId
          });
        });
      } else if (this.resultData.description !== 'Full Truckload (TL) Shipment' && this.resultData.packagingTypeDetails) {
        this.resultData.packagingTypeDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingName,
            'packageId': element1.packagingId
          });
        });
      }


        this.commonService.bolResultData = this.resultData;
  
        this.resultData['items'].sort((a, b) => {
          return a.displayOrder - b.displayOrder
        });
        this.isViewBoL = result['isPdfExist'];
        this.bolId = result['bolId'];
        this.documentId = result['documentId'];
        localStorage.setItem('com_documentId_' + this.commonService.quoteId, result['documentId']);

        if (this.BOLd['shipmentMode'] == "Courier") {
          this.shippingLabel = true;
        }
        else {
          this.shippingLabel = false;

        }

        if (this.BOLd['shipperCountry'] != this.BOLd['consigneeCountry']) {
          this.showComInv = true;
        } else {
          this.showComInv = false;
        }
        this.dynamicDimension = {
          length: 0,
          width: 0,
          height: 0,
          weight: 0
        }
        this.totalItem = 0
        for (let i in result['items']) {
          this.totalItem += 1
          this.commonService.totalItemBol = this.totalItem;
          if (result['items'][i] && result['items'][i].unit == 'cm') {
            result['items'][i].length = result['items'][i].length * 0.39;
            result['items'][i].totalWeight = result['items'][i].totalWeight * 2.20;
            result['items'][i].height = result['items'][i].height * 0.39;
            result['items'][i].breadth = result['items'][i].breadth * 0.39;
            this.declaredValue += result['items'][i]['shipmentItemValue'] * result['items'][i]['qty'];
            this.commonService.declaredBol = this.declaredValue;
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.commonService.dimensionBOL = this.dynamicDimension;
          } else {
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.declaredValue += result['items'][i]['shipmentItemValue'];
            this.commonService.declaredBol = this.declaredValue;

            this.commonService.dimensionBOL = this.dynamicDimension;

          }
        }
        this.commonService.bolInvoiceDetails = this.BOLd;
        var self = this;

      }, error => {

        if (error.status == 401) {
          this.router.navigate(['/signin']);
        }

        this.displayLoader = false;

        this.toastr.error(error['error']["message"], 'Please try again.', {
          timeOut: 10000,
        });
        this.displayLoader = false;

      });
    }

  dialogOpen(modelToOpn, action) {

    //   if(modelToOpn === "addFav") {
    //   const modalRef = this.modalService.open(AddFavoriteComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
    //   this.router.events.subscribe((val) => {
    //     modalRef.close();
    //   });
    //   modalRef.componentInstance.quoteId = this.quoteId;
    //   modalRef.componentInstance.bolId = this.bolId;
    //   modalRef.result.then((result) => {
    //     if (result['success'] == true) {
    //       this.toastr.success('Order saved as a favorite.', 'Success', {
    //         timeOut: 30000,
    //       });
    //       localStorage.setItem("fav_" + this.quoteId, "true");
    //     }
    //   });
    // }
    if (modelToOpn === "emailDoc") {
      if (localStorage.getItem("com_documentId_" + this.commonService.quoteId) == null && action != "bol") {
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
        });
        return;
      }
      const modalRef = this.modalService.open(EmailDocumentComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.documentId = action == "commercial" ? localStorage.getItem("com_documentId_" + this.commonService.quoteId) : this.documentId;
      modalRef.componentInstance.action = action;

      modalRef.result.then((result) => {
        if (result.success) {
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        } else {
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }
  }

  download() {
    let compData = this;
    var element = document.querySelector('#contentbol');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + compData.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  }

  print(doc) {

    if (doc == 'bol') {
      this.printBOL = true;
    } else {
      if (localStorage.getItem("com_documentId_" + this.commonService.quoteId) == null) {
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
        });
        return;
      }
      this.printBOL = false;
    }

    setTimeout(() => {
      window.print();
    }, 1000);
  }


  downloadCommercialInvoice(){
    if(localStorage.getItem("com_documentId_"+this.commonService.quoteId) == null){
      this.toastr.error('First create commercial invoice.', 'Please', {
        timeOut: 30000,
     });
     return;
    }
    let compData = this;
    var doc = document.getElementById('DownloadCommercialPdfData')  
        var element = document.querySelector('#DownloadCommercialPdfData');
        var opt = {
        margin:       0.1,
        filename:     "Commercial_"+compData.quoteId+".pdf",
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { dpi: 192, scale: 2, letterRendering: true},
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true},
        pageBreak:    { mode: 'css', after:'.break-page'}
        };
  
          // New Promise-based usage:
        html2pdf().set(opt).from(element).save();
    }

  getShipmentLabelUrl() {
    this.displayLoader = true;
    this.commonService.getShipmentLabelUrls(this.orderId).subscribe(result => {
      this.labaelList = result
      if (this.labaelList['link'].length == 0) {
        this.toastr.error(result['error'], '', {
          timeOut: 5000,
        });
        this.displayLoader = false;
        return
      }
      if (this.labaelList['link'] == null) {
        this.toastr.error(result['error'], 'Please try again after sometime ', {
          timeOut: 5000,
        });
        this.displayLoader = false;
        return
      }
      if (this.labaelList['link'].length <= 1 && this.labaelList['link'] != null) {
        // this.isLabelUrl=this.sanitizer.bypassSecurityTrustResourceUrl(result['link'])
        window.open(result['link'], '_blank').focus();
      } else if (this.labaelList['link'].length > 1) {
        this.open_modal()
        // // console.log(this.labaelList)
      }
      else {
        this.toastr.error(result['error'], 'Please try again after sometime ', {
          timeOut: 5000,
        });
      }
      this.displayLoader = false;
    }, (error) => {
      this.displayLoader = false
      if (!error || error['status'] !== 200) {
        this.toastr.error('No Data Found', 'Please try again after sometime ', {
          timeOut: 5000,
        });
      } else {
        window.open(error['error']['text'], '_blank').focus(); //This code here due to invalid json in response
      }
    });

  }
  open_modal() {
    const modalRef = this.modalService.open(DownloadLabelComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal4' });
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.componentInstance.data = this.labaelList;
    modalRef.result.then((result) => {
    });

  }
  viewBoL() {
    this.isViewBoL = true;
    this.savePdf();
  }

  downloadInvoice() {
    let compData = this;
    html2canvas(document.querySelector('#contentInvoice'), { useCORS: true }).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }
        pdf.save("invoice_" + compData['data']['invoiceNumber'] + '.pdf');
      };
    });

  }

  open_modal_invoice(modal) {
    if (modal == 'viewBol') {
      const modalRef = this.modalService.open(ViewBolDocComponent, { size: 'lg', centered: false, backdrop: true,windowClass: 'my-custom-modal12', keyboard: true });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      // modalRef.componentInstance.data = this.invoiceDetails;
      modalRef.result.then((result) => {
      });
    }
    else if (modal == 'viewinvoice') {
      const modalRef = this.modalService.open(ViewInvoiceComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal10', keyboard: true });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.data = this.data;
      modalRef.result.then((result) => {
      });
    }
    else if (modal == 'viewCom') {
      const modalRef = this.modalService.open(ViewCommInvQuickActionComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal11', keyboard: true });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.data = this.data;
      modalRef.result.then((result) => {
      });
    }
  }
}
