import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from './address-form/address-form.component';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DistanceCalculatorComponent } from './distance-calculator/distance-calculator.component';
import { AddressBookComponent } from './address-book/address-book.component';
import { ConsigneeBookComponent } from './consignee-book/consignee-book.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressSearchComponent } from './address-search/address-search.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { AuthGuardService } from 'src/app/services/auth/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/address/form', pathMatch: 'full' },
  { path: 'shipcarte/address/form', component: AddressFormComponent, canActivate: [AuthGuardService]},
  { path: 'shipcarte/address/distance/calc/form', component: DistanceCalculatorComponent, canActivate: [AuthGuardService]},
  { path: 'shipcarte/address/book', component: AddressBookComponent, canActivate: [AuthGuardService]},
  { path: 'shipcarte/consignee/book', component: ConsigneeBookComponent, canActivate: [AuthGuardService]},
  { path: 'shipcarte/address/search', component: AddressSearchComponent, canActivate: [AuthGuardService]},
  
];

@NgModule({
  declarations: [AddressFormComponent, DistanceCalculatorComponent, AddressBookComponent, ConsigneeBookComponent, AddressSearchComponent, AddAddressComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [ 
    AddressSearchComponent,
    AddAddressComponent
  ]
})
export class AddressModule { }
