import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from './../../../services/payment/payment.service';


@Component({
  selector: 'app-quote-checkout',
  templateUrl: './quote-checkout.component.html',
  styleUrls: ['./quote-checkout.component.scss']
})
export class QuoteCheckoutComponent implements OnInit {
  model: any = {};
  sub: any;
  countryListAry = [];
  countryStateListObj = {};
  stateListAry = [];

  constructor(private route: ActivatedRoute,private paymentService:PaymentService,private router:Router) { }

  ngOnInit() {
 
    this.paymentService.getCountryList().subscribe(data =>{
      for(let key in data['success']){
        if('undefined' != typeof data['success'][key]['countryName']){
          this.countryStateListObj[data['success'][key]['countryName']] = data['success'][key]['provinceState'];
          this.countryListAry.push({name:data['success'][key]['countryName'],value:data['success'][key]['countryName']});
        }
      }
    })

    this.sub = this.route
    .data
    .subscribe(  );

 
  }

  getState(country){
    for(let key in this.countryStateListObj[country]){
      this.stateListAry.push({name:this.countryStateListObj[country][key],value:this.countryStateListObj[country][key]});
    }

  }

  updateCheckoutDetail(){
 
    let totalCost = localStorage.getItem('quotePrice');
    let detailObject = {
      "total": totalCost,
      "quoteId": localStorage.getItem('quotecheckoutId'),
      "currencyUnit": "CAD",
      "CCN": this.model.creditordebit,
      "expiryDate": this.model.expirationDate+'/'+this.model.expirationDate1,
      "CVV": this.model.cvv.toString() ,
      "nameOnCard": this.model.cardholdername,
      "serviceProvider": "AUTHORIZENET",
    }
 
    this.paymentService.paymentCheckout(detailObject).subscribe((result:any)=>{
 
      let orderInputData = {
        "paymentId": "",
        "paymtGatewayRefId": result['data'],
        "paymtMethod": "AUTHORIZENET",
        "sttsMsg": true,
        "isTerms": true,
        "quoteId": localStorage.getItem('quotecheckoutId'),
        "paymtStts": "SUCCESS",
        "freightChrgs": totalCost, 
        "total": totalCost,
        "paymts": totalCost,
        "amountDue": totalCost,
        "currencyUnit": "CAD",
        "addrId": {
            "zipCode": this.model.zip,
            "city": this.model.city,
            "state": this.model.state,
            "country": this.model.country,
            "addrTypeId": "5e83b7cc-df9a-11e9-b6b0-f07959651ad4" ,
            "addrLine1": this.model.address,
            "firstName": this.model.firstName,
            "phoneNumber": "0000000000"
        }
      }
      if(result['data']){
        this.paymentService.paymentOrder(orderInputData).subscribe(data =>{
 
          this.router.navigate(['shipcarte/quote/order-list']);
        },
        error =>{
 
          this.router.navigate(['shipcarte/quote/order-list']);
          return;
        })
      }
    },
    err =>{
     
          alert(err['message'])
          return;
    })
  }

}
