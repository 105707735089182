import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlagService } from '../flag.service';


@Component({
  selector: 'app-contact-shipper',
  templateUrl: './contact-shipper.component.html',
  styleUrls: ['./contact-shipper.component.scss']
})
export class ContactShipperComponent implements OnInit {
  addressForm: FormGroup;
  email: any;
  firstname: any;
  lastname: any;
  mobnmber: any;
  selectedFlagItem: any;
  extension: any;
  Position: any;
  pattern: string | "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  selectFlagValue = [
    {
      name: "(+1)",
      icon: "assets/img/shipment/USA.svg",
    },
    {
      name: "(+1)",
      icon: "assets/img/shipment/CANADA.svg",
    },
  ]
  shipperSelected: any;
  myFlagGroup: FormGroup;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private flagService: FlagService) { }

  ngOnInit() {
    this.myFlagGroup = new FormGroup({
      selectedFlagItem: new FormControl()
    });

    setTimeout(() => {
      this.myFlagGroup.get('selectedFlagItem').setValue(this.selectFlagValue[0]);
    }, 100);
    this.addressForm = this.formBuilder.group({
      'firstName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
      ]),
      'lastName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')
      ]),
      'phone': new FormControl('', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ]),
      'extension': new FormControl(''),
      'selectedFlagItem': new FormControl(''),
      'Position': new FormControl('')
    })
    this.shipperSelected = this.selectFlagValue[0];
  }

  submit() {
    this.email = this.addressForm.value;
    this.firstname = this.addressForm.value.firstName;
    this.lastname = this.addressForm.value.lastName;
    this.mobnmber = this.addressForm.value.phone
    this.extension = this.addressForm.value.extension
    this.selectedFlagItem = this.addressForm.value.selectedFlagItem
    // console.log(this.selectedFlagItem)
    this.Position = this.addressForm.value.Position
    let data = {
      'Position': this.addressForm.get('Position').value ? this.addressForm.get('Position').value : "",
      'email': this.addressForm.get('email').value ? this.addressForm.get('email').value : "",
      'extension': this.addressForm.get('extension').value ? this.addressForm.get('extension').value : "",
      'firstName': this.addressForm.get('firstName').value ? this.addressForm.get('firstName').value : "",
      'lastName': this.addressForm.get('lastName').value ? this.addressForm.get('lastName').value : "",
      "phone": this.addressForm.get('phone').value ? this.addressForm.get('phone').value : "",
      'selectedFlagItem': this.myFlagGroup.get('selectedFlagItem').value ? this.myFlagGroup.get('selectedFlagItem').value : ""
    }
    this.activeModal.close(data);
  }
  onSave() {
    const selectedFlagItem = this.myFlagGroup.get('selectedFlagItem').value;
    // console.log(selectedFlagItem)
    this.flagService.setSelectedFlagItem(selectedFlagItem);
  }

  closeModal() { this.activeModal.close(); }


}
