import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrier-dashboard',
  templateUrl: './carrier-dashboard.component.html',
  styleUrls: ['./carrier-dashboard.component.scss']
})
export class CarrierDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
