import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, UrlHandlingStrategy } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormControlName, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ToastrService } from 'ngx-toastr';
import { url } from 'inspector';
// import { InvoiceComponent } from '../commons/invoice/invoice.component';
import { formatDate } from '@angular/common';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
// import { CustomDateParserFormatter } from '../creditapp/view/view.component';

@Component({
  selector: 'app-favourite-order',
  templateUrl: './favourite-order.component.html',
  styleUrls: ['./favourite-order.component.scss']
})
export class FavouriteOrderComponent implements OnInit {
  @ViewChild('confirmWarn', undefined) confirmWarn;
  @ViewChild('loadContent', undefined) loadContent;
  sortBy: any;
  orders: any = [];
  orderId: any;
  temp: any = [];
  status: any = [];
  selectTab: any = 1;
  manualOrder: any = [];
  page: number = 0;
  pageSize: number = 20;
  isDesc: any = false;
  searchKey: any = '';
  tabStatus: any = '';
  modelToDate: NgbDateStruct;
  modelFromDate: NgbDateStruct;
  savingToFavValue: string = '';
 
  tabStyle: number = 1;
  popOverDataPrice: any = [];
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  trackingData: any;

  quoteId: any;
  quote: any;
  ordersId: any;
  orderStatus: any;
  customOrderId: any;
  displayCourier: true;
  orderid: any;
  shipperZip: any;
  consigneeZip: any;
  trackOrderId: any;
  addFevForm: FormGroup;
  quoteIdArray: any = [];
  todaysDate: any;
  minDate: any;
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private httpService: ScHttpService,
    private commanService: ScCommonService,
    private toastService: ToastrService,
    private fb: FormBuilder
    , private config: NgbDatepickerConfig,
    private listDataService:ListDataServiceService
  ) { }

  ngOnInit() {

    this.orders = [
      { id: 1, orderType: 'All Orders' },
      { id: 2, orderType: 'In-Transit Orders' },
      { id: 3, orderType: 'Completed Orders' }
    ]
    this.sortBy = {
      "customOrderId": "downOrder",
      "consigneeName": 'downOrder',
      "source": 'downOrder',
      "pickupDate": 'downOrder',
      "shipper": 'downOrder',
      "origin": 'downOrder',
      "consignee": "downOrder",
      "destination": "downOrder",
      "price": "downOrder",
      "carrierName": "downOrder"
    }
    this.checkDataIsPresentInServiceOrNot();
    //  this.getOrderMetaDataById(this.quote)

    this.addFevForm = this.fb.group({
      addFev: ['', [Validators.required]]
    })


    this.config.minDate = { month: 1, day: 1, year: 1980, };
    this.config.outsideDays = 'hidden';

  }

  onClickCreateManualOrder() {
    this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
    this.route.navigateByUrl('/shipcarte/orders/newOrderEntry');
  }
  checkDataIsPresentInServiceOrNot(){
    let dataFromservice=this.listDataService.getAllDataOfFavoriteOrderList();
    // console.log(dataFromservice)
    if(dataFromservice.dataList.length>0){
      this.page=dataFromservice.pageCount;
      this.pageSize=dataFromservice.pageSize;
      this.manualOrder=dataFromservice.dataList;
      if(dataFromservice.searchKey!=''){
        this.searchKey=dataFromservice.searchKey
      }
    }else{
      this.listDataService.removeAllDataOfFavoriteOrderList();
      this.page=0;
      this.fetchOrderList();
    }
  }
  isDataLoading:any=''
  // all order list Fetch **************************************************
  fetchOrderList() {

    this.isDataLoading='Loading Your Options, Please wait...'
    let url = ServerURLS.getManualOrder.action + this.page + '/' + this.pageSize + '?isFavourite=true';
    if (this.searchKey.length > 0) {
      url = url + `&searchKey=${this.searchKey}`
    }
    if (this.modelToDate && this.modelFromDate) {
      let fromDate = `${this.modelFromDate.year}-${this.modelFromDate.month}-${this.modelFromDate.day}`;
      let toDate = `${this.modelToDate.year}-${this.modelToDate.month}-${this.modelToDate.day}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }
    if (this.tabStatus !== '') {
      url = url + `&shipmentStatus=${this.tabStatus}`;
    }

    let promise = this.httpService.getHttpService(url, '')
    promise.then((res: any) => {
      // this.displayData = false;
      // this.isDataLoading = ''  //3
      this.manualOrder = res.response;
      if (res.response.length > 0) {
        let iter = 0;
        this.quoteIdArray = []
        for (let i = 0; i < res["response"].length; i++) {
          res["response"][i].pickupDate = res["response"][i].quoteDetails.pickupDate ? res["response"][i].quoteDetails.pickupDate : "N/A";
          res["response"][i].shipper = res["response"][i].quoteDetails.shipperAddress.company ? res["response"][i].quoteDetails.shipperAddress.company : 'N/A';
          res["response"][i].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
          res["response"][i].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
          res["response"][i].consignee = res["response"][i].quoteDetails.consigneeAddress.company ? res["response"][i].quoteDetails.consigneeAddress.company : 'N/A';
          res["response"][i].consigneeName = `${res["response"][i].quoteDetails.customerName}` ? `${res["response"][i].quoteDetails.customerName} ` : 'N/A'
          res["response"][i].carrierName = res["response"][i].quoteDetails.carrierName ? res["response"][i].quoteDetails.carrierName : 'N/A';
          res["response"][i].partnerLogo = res["response"][i].quoteDetails.carrierLogo ? res["response"][i].quoteDetails.carrierLogo : 'N/A';
          res["response"][i].customerId = res["response"][i].quoteDetails.customerId ? res["response"][i].quoteDetails.customerId : 'N/A';
          res["response"][i].profilePic = res["response"][i].quoteDetails.profileImageDownloadUrl ? res["response"][i].quoteDetails.profileImageDownloadUrl : 'N/A';
          res["response"][i].shipmentMdName = res["response"][i].quoteDetails.shipmentModeName ? res["response"][i].quoteDetails.shipmentModeName : 'N/A';
          res["response"][i].shipmentCarrierCode = res["response"][i].quoteDetails.shipmentModeStyleCode ? res["response"][i].quoteDetails.shipmentModeStyleCode : 'N/A';
          iter = iter + 1;
          if (iter == res["response"].length) {
            this.manualOrder = res["response"];
            let data={
              pageCount:this.page,
              pageSize:this.pageSize,
              dataList:this.manualOrder
            }
            this.listDataService.setAllDataOfFavoriteOrderlist(data);
            this.isDataLoading=''
          }
          this.quoteIdArray.push(res['response'][i].quoteDetails.quoteId)
        }
      }
      else {
        this.manualOrder = []
        this.listDataService.removeAllDataOfFavoriteOrderList();
        this.isDataLoading='No Data Found!'
      }
    })
      .catch((err) => {
        this.isDataLoading='something Went Wrong! Please Try Again Later';

      })


  }
  // end******************************************************************

  quantity: any;
  weight: any;
  class: any;
  amountCharged: any;
  partnerLogo: any;
  carrierName: any;
  packageLogo: any;
  packageName: any;
  shipperDate: any;
  popOverIndex(value, packageName, packageLogo, orderSource) {
    this.quantity = 0;
    this.weight = 0;
    this.class = 0;
    this.shipperDate = value.pickupDate ? value.pickupDate : '--'
    this.packageName = packageName ? packageName : '';
    this.amountCharged = value.totalAmount ? value.totalAmount : '--';
    this.carrierName = value.carrierName ? value.carrierName : '--';
    this.partnerLogo = value.carrierLogo ? value.carrierLogo : '--';
    this.packageLogo = packageLogo ? packageLogo : '';
    // // console.log(this.packageLogo,'gj')
    value.items.forEach((item) => {
      if (typeof item.qty === 'number') {
        this.quantity += item.qty;
      }

      if (typeof item.totalWeight === 'number') {
        this.weight += item.totalWeight;
      }

      let dataClass = Number(item.freightClass)
      if (typeof dataClass === 'number') {
        this.class += dataClass;
      }

      // this.weight += item.totalWeight;
      // this.class += item.freightClass;   
    });

  }



  postFevData(orderId, fevName) {
    // console.log(fevName, 'hg')
    this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
    let url = ServerURLS.addFevOrder.action;
    let payload = {
      "orderId": orderId,
      "isFavourite": false,
      "favouriteOrderName": fevName
    }
    let promise = this.httpService.putHttpService(url, payload);
    promise.then((res) => {
      this.toastService.success('Shipment Successfully Removed From Favorites.')
      this.modalService.dismissAll();
      // this.fetchOrderList(this.page - 1, this.pageSize, this.searchKey);
    }).catch((err) => {
      this.modalService.dismissAll();
      this.toastService.error(err.message ? err.message : 'Something Went Wrong', '', {
      })
    })
  }





  viewQuote(quoteid, customOrId, orderId, orderStatus, customerName, customerid, status, carrierName, carrierLogo, orderSource) {
    ///shipcarte/orders/orderDetail


    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource
      // carrierId:
      // currencyUnit:
      // contractId

    }
    this.commanService.quoteId = quoteid;
    sessionStorage.setItem("userInformation", JSON.stringify(userInforamation))
    this.route.navigate(['/shipcarte/orders/orderDetail']);

    this.orderid = orderId
    this.quote = quoteid


  }

  // Moduls *************************************************************
  open(content, type, orderId, fevName) {
    this.modalService.open(this.confirmWarn, { size: 'sm', centered: true, windowClass: 'confirmModal' }).result.then((result) => {
      this.postFevData(orderId, fevName);
      this.fetchOrderList();
    }, (reason) => {
    })
  }
  //end module ***********************************************************

  sorting(property) {
    this.isDesc = !this.isDesc  // change the direction
    let direction = this.isDesc ? 1 : -1;
    this.sortBy[property] = !this.isDesc ? 'downOrder' : 'upOrder';


    if (property === 'price') {
      this.manualOrder.sort(function (a, b) {
        if (parseFloat(a[property]) < parseFloat(b[property])) {
          return -1 * direction;
        }
        else if (parseFloat(a[property]) > parseFloat(b[property])) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    } else {
      this.manualOrder.sort(function (a, b) {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * direction;
        }
        else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
  }


  //filters*************************************************************
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length > 2 && searchParam != null && searchParam != '') {
        this.searchKey = searchParam;
        this.page=0
        this.listDataService.setSearchKeyForFavoriteOrderList(searchParam);
        this.fetchOrderList();
      } else if (searchParam.length === 0) {
        this.searchKey = '';
        this.page=0;
        this.listDataService.setSearchKeyForFavoriteOrderList('');
        this.fetchOrderList();
      }
    }, 500);
  }


  showToastDateError() {
    this.toastService.error('Please select valid date range', '', {
      timeOut: 3000
    })
  }
  // display popup data for quotes summery ********************************************************************************
  // popOverIndex(i) {
  //   this.popOverDataPrice = []
  //   this.popOverDataPrice = this.manualOrder[i]['priceObj'];

  //   this.popOverDataPrice['customId'] = this.manualOrder[i]['quoteDetails'].customQuoteId;
  //   this.popOverDataPrice['shpmtModeName'] = this.manualOrder[i]['quoteDetails'].shipmentModeName;
  //   this.popOverDataPrice['shipmentModeStyleCode'] = this.manualOrder[i]['quoteDetails'].shipmentModeStyleCode;
  //   //this.popOverDataPrice['carrierName'] = this.shipments[i]['quoteDetails'].carrierName;
  //   var charges = [];

  //   if (this.popOverDataPrice['charges']) {

  //     this.popOverDataPrice['charges'].forEach((item, index) => {
  //       for (var key of Object.keys(item)) {
  //         charges.push([key, item[key]])
  //       }
  //     });

  //   }
  //   this.popOverDataPrice['price_breakUp'] = charges
  //   var totalWeight = 0;
  //   this.manualOrder[i]['quoteDetails']['items'].forEach((item, index) => {
  //     totalWeight += (item.wghtUnit.toLowerCase() == 'lbs' ? item.totalWeight : item.totalWeight / 2.20).toFixed(2) * item.qty;
  //   });
  //   this.popOverDataPrice['weight'] = totalWeight;
  // }
  // popup data for delivered ************************ ************* *************************** *****************************
  showViewPodBtn: boolean = false;
  showPopupDeleivered(quoteid, orderId, orderStatus) {
    this.showViewPodBtn = false;
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
    let url = `${ServerURLS.traceOrder.action}?orderId=` + orderId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.trackingData = res;
      this.getInvoiceDetails(orderId);
    }).catch((err) => {
    })
  }

  showPopupInTransit(quoteId) {

    // this.getOrderMetaDataById(quoteId);
    let url = ServerURLS.orderDetails.action + '?quoteId=' + quoteId;
    let promise = this.httpService.getHttpService(url, '');

    promise.then((res: any) => {

      this.trackOrderId = res['orderId'];

      this.manualOrder.filter(element => {
        if (element.orderId == this.trackOrderId) {
          this.shipperZip = element.quoteDetails.shipperAddress.zipCode;
          this.consigneeZip = element.quoteDetails.consigneeAddress.zipCode
        }
      })

      let url1 = ServerURLS.getTrackingData.action + '?orderId=' + this.trackOrderId;
      let promise1 = this.httpService.getHttpService(url1, '');
      promise1.then((response) => {
        this.trackingData = response;

      })

    })


  }

  redirctToGeneralDetails(id) {
    // console.log(id)
    this.commanService.customerId = id
    this.route.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`)
  }


  data: any;
  formattedDate: any;
  getInvoiceDetails(orderId) {
    // var orderIdTemp ="9b1bbd5c-e21b-4182-b980-8296aa32179a";
    let promise = this.httpService.getHttpService(ServerURLS.getInvoiceDetailsById.action + '?orderId=' + orderId, { withCredentials: true });
    promise.then((result) => {
      this.data = result;
      this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');
      this.showViewPodBtn = true;
    })
  }
  // open_modal_invoice(modal, showIcons) {
  //   if (modal == 'viewinvoice') {
  //     const modalRef = this.modalService.open(InvoiceComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal10', keyboard: true });
  //     this.route.events.subscribe((val) => {
  //       modalRef.close();
  //     });
  //     modalRef.componentInstance.data = this.data;
  //     modalRef.componentInstance.quoteIdForEmail = this.selectedQuoteID;
  //     modalRef.componentInstance.showIcons = showIcons;
  //     modalRef.result.then((result) => {
  //     });
  //   }
  // }

  shipAgain(quoteId, backToQuote, orderId) {
    if (backToQuote == 'On Call Order') {
      this.route.navigate(['/shipcarte/orders/newOrderEntry/edit', quoteId, orderId]);
    } else if (backToQuote == 'Quoted') {
      this.route.navigate(['/shipcarte/step-one/' + quoteId]);
    }
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrollHandlerFavoriteList(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }
  allDataLoaded: any = false;
  scrollHandlerFavoriteList(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {


        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
 
  }
  fetchListOfDataToAppend(){
    this.page=this.page+1
    let url = ServerURLS.getManualOrder.action + this.page + '/' + this.pageSize + '?isFavourite=true';
    if (this.searchKey.length > 0) {
      url = url + `&searchKey=${this.searchKey}`
    }
    if (this.modelToDate && this.modelFromDate) {
      let fromDate = `${this.modelFromDate.year}-${this.modelFromDate.month}-${this.modelFromDate.day}`;
      let toDate = `${this.modelToDate.year}-${this.modelToDate.month}-${this.modelToDate.day}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }
    if (this.tabStatus !== '') {
      url = url + `&shipmentStatus=${this.tabStatus}`;
    }

    let promise = this.httpService.getHttpService(url, '')
    promise.then((res: any) => {
 
      if (res.response.length > 0) {
        let iter = 0;
        this.quoteIdArray = []
        for (let i = 0; i < res["response"].length; i++) {
          res["response"][i].pickupDate = res["response"][i].quoteDetails.pickupDate ? res["response"][i].quoteDetails.pickupDate : "N/A";
          res["response"][i].shipper = res["response"][i].quoteDetails.shipperAddress.company ? res["response"][i].quoteDetails.shipperAddress.company : 'N/A';
          res["response"][i].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
          res["response"][i].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
          res["response"][i].consignee = res["response"][i].quoteDetails.consigneeAddress.company ? res["response"][i].quoteDetails.consigneeAddress.company : 'N/A';
          res["response"][i].consigneeName = `${res["response"][i].quoteDetails.customerName}` ? `${res["response"][i].quoteDetails.customerName} ` : 'N/A'
          res["response"][i].carrierName = res["response"][i].quoteDetails.carrierName ? res["response"][i].quoteDetails.carrierName : 'N/A';
          res["response"][i].partnerLogo = res["response"][i].quoteDetails.carrierLogo ? res["response"][i].quoteDetails.carrierLogo : 'N/A';
          res["response"][i].customerId = res["response"][i].quoteDetails.customerId ? res["response"][i].quoteDetails.customerId : 'N/A';
          res["response"][i].profilePic = res["response"][i].quoteDetails.profileImageDownloadUrl ? res["response"][i].quoteDetails.profileImageDownloadUrl : 'N/A';
          res["response"][i].shipmentMdName = res["response"][i].quoteDetails.shipmentModeName ? res["response"][i].quoteDetails.shipmentModeName : 'N/A';
          res["response"][i].shipmentCarrierCode = res["response"][i].quoteDetails.shipmentModeStyleCode ? res["response"][i].quoteDetails.shipmentModeStyleCode : 'N/A';
          iter = iter + 1;
          if (iter == res["response"].length) {
            this.manualOrder =this.manualOrder.concat(res["response"]);
            let data={
              pageCount:this.page,
              pageSize:this.pageSize,
              dataList:this.manualOrder
            }
            this.listDataService.setAllDataOfFavoriteOrderlist(data);
            this.isDataLoading=''
            this.isLoading=false;
          }
          this.quoteIdArray.push(res['response'][i].quoteDetails.quoteId)
        }
      }
      else {
        this.isLoading=false;
        this.allDataLoaded=true
      }
    })
      .catch((err) => {
        this.isLoading=false
      })
  }
}
