import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { HttpClient } from '@angular/common/http';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-pod-email',
  templateUrl: './pod-email.component.html',
  styleUrls: ['./pod-email.component.scss']
})
export class PodEmailComponent implements OnInit {
  displayData:boolean
  submitted = false;
  sendEmail: FormGroup;
  actionString='';
  @Input() public documentId;
  @Input() public invoiceNumber;
  @Input() public action;
  @Input() public carrierEmail = '';
  @Input() public orderNumber;
  @Input() public savePodFormData;
  @ViewChild('fileInput',undefined) fileInput: ElementRef;
  sharedData:any;
  constructor(public activeModal: NgbActiveModal,public fb:FormBuilder,private http: HttpClient,
    private toastr: ToastrService, private DocumentService: DocumentService,private httpService: ScHttpService, private commonService:ScCommonService) { 
    this.sendEmail = this.sendEmailForm();
    this.commonService.currentData.subscribe((data: any) => {
      this.sharedData = data;
    })
  }

  ngOnInit(): void {
    this.sharedData.appLoader=false;
    if(this.carrierEmail){
      this.sendEmail.controls['email'].setValue(this.carrierEmail);
    }
   
    // this.sendEmail.controls['email'].setValue(this.carrierEmail);
  }

  sendEmailForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
        [Validators.required,
         Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]
        ],
        bccEmail:[null]
      }
    );
  }

  get f() { return this.sendEmail.controls; }

  closeModal() { this.activeModal.close(); }
  
  onSubmit() {
    this.sharedData.appLoader=true
    let tempObj = {
      "documentId": "",
      "emailId": this.sendEmail.get('email').value,
      "bccEmailId": this.sendEmail.get('bccEmail').value
    };
    this.submitted = true;
    if (this.sendEmail.invalid) {
      return;
    }
  
    let url = ServerURLS.sendEmail.action + '?orderNumber=' + this.orderNumber;
    let formData = new FormData();
    formData.append('requestString', JSON.stringify(tempObj));
  
    // Access savePodFormData from ViewPodComponent
    formData.append('podDocumentFile', this.savePodFormData.get('podDocumentFile'));
  
    let promise = this.httpService.postFileHttpService(url, formData);
    promise.then((res:any) => {
      this.sharedData.appLoader =false
      this.toastr.success('POD Request file sent successfully.', res.message)
      this.activeModal.close();
    },
    (error) => {
      this.sharedData.appLoader =false
      console.error('Error uploading file:', error);
    });
  }  

}
