import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-case-document',
  templateUrl: './case-document.component.html',
  styleUrls: ['./case-document.component.scss']
})
export class CaseDocumentComponent implements OnInit {

    caseDocForm: FormGroup;
    selectedFile: any;
    incidentTypes: any;
    errorMessage: string;
    successMessage: string;
    caseId: string;
    caseDocuments: any;
    caseIncidentData: any;

    constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {

      this.caseId = this.route.snapshot.paramMap.get('case-id');

      if( this.caseId){
        this.initCaseIncident();
        this.loadCaseDocuments();
      }

      this.loadCaseIncidents();
      this.loadFormData();
    }

    loadFormData(){

      this.caseDocForm = this._formBuilder.group({
        description: ['', Validators.required],
        caseIncidentTypeId: ['', Validators.required],
        uuid: [this.caseId ? this.caseId : null],
    });

  }
  get f() { return this.caseDocForm.controls; }

  loadCaseIncidents(): void {
  
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCaseIncidentTypes.action, '');
    promise1.then( value => {
      this.incidentTypes = value["data"];
    });
    
  }

  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
    
  }

  addCaseIncident(){

    let formdata = this.caseDocForm.getRawValue();

    if(this.caseId && _.isEqual(this.caseIncidentData, formdata)){
        this.addCaseDocument(this.caseId);
        this.loadCaseDocuments();

    } else {

      let promise = this.scHttpService.postFileHttpService(ServerURLS.saveCaseIncident.action, formdata);
      promise.then( (resolve) => {

        this.errorMessage = "";
        let response = resolve["data"];

        if(response && this.selectedFile){
          this.addCaseDocument(response);
        }

        //if(this.caseId){
          this.successMessage = resolve ["message"];
       // }
        this.router.navigate(['shipcarte/my/case/list']);

      }).catch((error) => {
        this.errorMessage = error.message;
      });

    }

  }

  addCaseDocument( caseId ){

    let data = this.caseDocForm.getRawValue();

    const formdata: FormData = new FormData();
    formdata.append('file', this.selectedFile);
    formdata.append('caseId', caseId );

    let promise = this.scHttpService.postFileHttpService(ServerURLS.saveCaseDocument.action, formdata);
    promise.then( (resolve) => {

      this.errorMessage = "";
      let response = resolve;
      this.successMessage = response["message"];
      this.selectedFile = null;

    }).catch((error) => {
      this.errorMessage = error.message;
    });

  }

  cancel(){
    this.caseDocForm.reset();
  }

  initCaseIncident(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCaseIncident.action + "?caseId=" + this.caseId,'');
    promise1.then( value => {
      this.caseIncidentData = value["data"];

      if(this.caseIncidentData){
        this.caseDocForm.patchValue(this.caseIncidentData);
      }
     
    });

  }

  loadCaseDocuments(): void {
  
    let promise1 = this.scHttpService.getHttpService( ServerURLS.caseDocumentList.action + "?caseId=" + this.caseId, '');
    promise1.then( value => {
      this.caseDocuments = value["data"];
    });
    
  }

  deleteDocument( docId ): void {

    let url = ServerURLS.deleteCaseDocument.action + "?docId=" + docId;

    let promise1 = this.scHttpService.deleteHttpService(url ,'');
    promise1.then( value => {
      let response = value["message"];
      this.loadCaseDocuments();
    });

  }

}
