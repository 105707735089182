import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditReference } from './creditreference';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-credit-reference',
  templateUrl: './credit-reference.component.html',
  styleUrls: ['./credit-reference.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class CreditReferenceComponent implements OnInit {

  creditRefForm: FormGroup;
  addressForm: FormGroup;
  isSPincodeReleased:boolean;
  creditRef: CreditReference;
  isAddrAvailable :boolean;
  errMsg:string;
  paymentMethods:any;
  searchZipcode: any;
  applicantId:any;
  successMsg:any;
  references: any;
  address: any;
  refDetail: any;
  isReferenceSaved: boolean;
  errorMessage: any;
  isEdit: false;

  closeResult = '';



  PHONE_REGEXP = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor( config: NgbModalConfig, private modalService: NgbModal,private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private storageService: ScStorageService, private router: Router, 
               private route: ActivatedRoute ) {
      this.creditRef  = new CreditReference();
      config.backdrop = 'static';
      config.keyboard = false;
    } 

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');

    this.loadFormData();
    this.loadAddrFormData();
    this.getApplicantReferences(this.applicantId);
    
  }

   loadFormData(){

     this.creditRefForm = this._formBuilder.group({
       refCompany:['', Validators.required],
       contactName:['', Validators.required],
       refId:[]
    });
 }

 loadAddrFormData(){

  this.addressForm = this._formBuilder.group({

   zipCode: [this.creditRef.refAddr.zipCode, Validators.required],
   city: {value: this.creditRef.refAddr.city, disabled: true},
   state: [{value: this.creditRef.refAddr.state, disabled: true}],
   country: [{value: this.creditRef.refAddr.country, disabled: true}],
   phoneNumber: ['', [Validators.required,  Validators.pattern(this.PHONE_REGEXP)]],
   email: ['', [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
   addressId:[]
 });

}

loadZipCodeData(event) {

  this.isSPincodeReleased = true;
  let pinCode =  event.srcElement.value;//formData["zipCode"];
  if( pinCode == "" || pinCode.length < 5) {
    this.addressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
    return ;
  }

  let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

  let promise_ = this.scHttpService.getHttpService(url, '');
  promise_.then(value => { 
    this.creditRef.refAddr.zipCode = pinCode;
    this.creditRef.refAddr.city    = value["data"]["city"];
    this.creditRef.refAddr.state   = value["data"]["state"];
    this.creditRef.refAddr.country = value["data"]["country"].trim();

    this.addressForm.patchValue  ({
       'zipCode': this.creditRef.refAddr.zipCode,
       'city': this.creditRef.refAddr.city,
       'state': this.creditRef.refAddr.state,
       'country': this.creditRef.refAddr.country.trim(),
     });

     this.isAddrAvailable = true;
   
  });

  promise_.catch(error => { // on error and other than http code:200
    this.isAddrAvailable = false;
    this.errMsg =  error['message'];

    this.addressForm.patchValue ({
      'zipCode': '',
      'city':'',
      'state': '',
      'country': '',
    });
  });

 }
 get f() { return this.creditRefForm.controls; }
 get f1() { return this.addressForm.controls; }

saveCreditReference () {

  let form_data = this.creditRefForm.getRawValue();
  this.creditRef = form_data;
  this.creditRef.refAddr =   this.addressForm.getRawValue();
  this.creditRef.applicantId = this.applicantId;

  if(  this.refDetail != null && this.isEdit){
    this.creditRef.refAddr.addressId = this.refDetail.refAddr;
    this.creditRef.refId = this.refDetail.refId;
  }

  let serviceCall :any;
  serviceCall = this.scHttpService.postHttpService(ServerURLS.saveCreditReference.action, this.creditRef);

  let authPromise = serviceCall;
    
    authPromise.then( (resolve) => {
      let response = resolve;
      // this.successMsg = response["message"];
      this.getApplicantReferences(this.applicantId);
      this.reset();
      this.errMsg = "";
      
    }).catch((error) => {
      this.errMsg = error.error;
    });

  }

  getApplicantReferences (applicantId): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditReferences.action +"?id=" +applicantId,'');
    promise1.then( value => {
      this.references = value["data"];
    });
  }

  continue(){
    if( this.references.length >= 2 ){
      this.isReferenceSaved = true;
    }
  }
  getApplicantRefDetails (id, refId, isEdit): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditRefDetails.action +"?id=" + id + "&ref-id=" + refId, '');
    promise1.then( value => {

      this.refDetail = value["data"];
      this.isEdit = isEdit;
      this.creditRefForm.patchValue ( this.refDetail);

      if(this.refDetail != null){
        this.getApplicantAddressDetails(this.refDetail.refAddr)
      }

    });
  }

  getApplicantAddressDetails (id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.address = value["data"];
      this.addressForm.patchValue (this.address);
    });
  }

  reset(){
    this.isEdit = false;
    this.addressForm.reset();
    this.creditRefForm.reset();
    this.isSPincodeReleased = false;
  }

  submit(){
    let status = "Submitted";
    this.addStatusTracker(status);
    this.successMsg = "Credit Applied successfully";
  }

  editForm(){
    this.router.navigateByUrl("shipcarte/creditv2/personal-info;applicant-id=" + this.applicantId + ";is-edit=true");
  }

  addStatusTracker(status) {

      let params = "?applicantId=" + this.applicantId + "&status=" + status;
      let promise = this.scHttpService.postHttpService(ServerURLS.saveCreditApplicantSttsTracker.action + params, '');
    
      promise.then( (resolve) => {
        let response = resolve;

      }).catch((error) => {
        this.errorMessage = error.message;
      });
      
  }

  deleteReferences( refId): void {

    let url = ServerURLS.deleteReferences.action + "?ref-id=" + refId;

    let promise1 = this.scHttpService.deleteHttpService(url ,'');
    promise1.then( value => {
      let response = value["message"];
      this.getApplicantReferences(this.applicantId);
      this.reset();
    });

  }

  open(content) {
    this.modalService.open(content);
  }

}
