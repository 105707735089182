import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-view-pod-request',
  templateUrl: './view-pod-request.component.html',
  styleUrls: ['./view-pod-request.component.scss']
})
export class ViewPodRequestComponent implements OnInit {
  @Input() pdfurl: string;
  sanitizedPdfContent: SafeResourceUrl;
  constructor(public activeModal: NgbActiveModal,   private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.sanitizedPdfContent = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfurl+ '#toolbar=0');
  }
  closeModal(): void {
    this.activeModal.close();
  }
}
