export class paymentGateWayCADForm{

    responseOrderId:string;
    custId:string;
    chargeTotal:string;
    txnNum:string;
    billAddressOne:string;
    billCompanyName:string;
    billFirstName:string;
    billLastName:string;
    billStateOrProvince:string;
    billCity:string;
    billCountry:string;
    billPostalCode:string;
    billPhone:string
    email:string;

}