import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from '../../../../environments/environment';

@Component({
  selector: 'app-credit-applicant-document',
  templateUrl: './credit-applicant-document.component.html',
  styleUrls: ['./credit-applicant-document.component.scss']
})
export class CreditApplicantDocumentComponent implements OnInit {
  
  selectedFile: any;
  showMsg:any;
  errMsg:any;
  applicantId:any;
  description:any
  isEdit: boolean;
  documents:any;
  isDocupload:boolean;
  docDetails:any;
  docId:string;
  fileName:string;

  constructor(private _formBuilder: FormBuilder, private scHttpService: ScHttpService,private route: ActivatedRoute, private router: Router) {
  
   }

  ngOnInit() {

    this.applicantId =  this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit') != null;
    this.getApplicantDocuments();
    
  }

  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
        this.selectedFile = event.target.files[0];
    }
  }

  uploadFile(  ){
   
    // debugger;
      
     const formdata: FormData = new FormData();

      formdata.append('file', this.selectedFile);
      
      let fileURL = ServerURLS.uploadCreditApplicantDoc.action;

      if(this.selectedFile){
    
        this.errMsg = "";

        formdata.append( 'applicantId', this.applicantId );
        let decr = "";
        if( this.description != undefined && this.description != null){

           decr = this.description
        }
       
        let promise = this.scHttpService.postFileHttpService(fileURL+"?description="+decr, formdata );
        promise.then( (resolve) => {
          let response = resolve;
          if( response ){

             this.selectedFile = "";
             this.isDocupload = true
             this.getApplicantDocuments();
           
          }
         
        }).catch((error) => {
          this.errMsg = error.message;
        });

      } else if ( this.isEdit && this.description && ! this.selectedFile){

        let  promise = this.scHttpService.putHttpService(ServerURLS.updateCreditApplicantDoc.action
                      +"?description="+this.description+"&id="+this.docId,'');
        promise.then( (resolve) => {
          let response = resolve;
          if( response ){
             this.getApplicantDocuments();
          }
         
        }).catch((error) => {
          this.errMsg = error.message;
        });
      } else {

          this.errMsg = "Please Select file"

      }

  }

  getApplicantDocuments (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicantDocs.action + "?applicant-id=" + this.applicantId, '');
    promise1.then( value => {
    
      let response = value["data"];
      if(response != null) {
        this.documents = response;
      }
     
    });
  
  }
 
  skip(){
    let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
    this.router.navigateByUrl("shipcarte/credit/applicant/reference/info" + params);

  }

  deleteDocument( docId ): void {

    let url = ServerURLS.deleteDocument.action + "?doc-id=" + docId;

    let promise1 = this.scHttpService.deleteHttpService(url ,'');
    promise1.then( value => {
      let response = value["message"];
      this.getApplicantDocuments();
      this.getApplicantDocDetails(docId);
    });

  }

  getApplicantDocDetails (id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicantDoc.action +"?id=" +id,'');
    promise1.then( value => {

      this.docDetails = value["data"];

      if( this.docDetails != null){

         this.description =  this.docDetails["description"];
         this.fileName = this.docDetails["fileName"];
         this.docId = id;
         this.isEdit  = true;
      } else {

        this.isEdit = false;
      }

    });
  }

  uploadDoc(){

    this.isDocupload  = true;
  }
}
