import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Server } from 'http';
import { ToastrService } from 'ngx-toastr';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})
export class QuoteListComponent implements OnInit {
  searchingMode: any = 'all'
  isDataLoadig: any = '';
  isPriceSummaryDataLoading: any = true;
  collectionSize: any = 0;
  page: number = 0;
  noOfPages: any = 0;
  pageSize: number = 20;
  list: any;
  SelectedTabId: any = 1;
  searchQuery: any = '';
  modelToDate: NgbDateStruct;
  modelFromDate: NgbDateStruct;
  sortBy: any;
  sharedData: any;
  isDesc: any = false;
  items: any = []
  isDashboardView: boolean = false;
  constructor(
    private router: Router,
    private toastr:ToastrService,
    private httpService: ScHttpService,
    private commanService: ScCommonService,
    private activatedRoute: ActivatedRoute,
    private listDataService: ListDataServiceService
  ) {
    this.commanService.currentData.subscribe((data: any) => {
      this.sharedData = data;
    })
  }
  isDashBoardView: boolean = false;
  isOnlyViewing: boolean = false;
  ngOnInit() {
    let url = window.location.href.split('/');
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfPartnerList()
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList();
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();

    if (url[url.length - 3] === 'draft') {
      this.SelectedTabId = 2;
      this.searchingMode = 'draft'
      this.isDashBoardView = true;
    }
    if (url[url.length - 3] === 'view') {
      this.SelectedTabId = 1;
      this.searchingMode = 'all'
      this.isOnlyViewing = true
    }
    if (url[url.length - 3] === 'draft' || url[url.length - 3] === 'view') {
      let startDate = this.activatedRoute.snapshot.paramMap.get("fromDate");
      let endDate = this.activatedRoute.snapshot.paramMap.get("toDate");
      this.isDashboardView = true;
      this.modelFromDate = { year: parseInt(startDate.split('-')[0]), month: parseInt(startDate.split('-')[1]), day: parseInt(startDate.split('-')[2]) }
      this.modelToDate = { year: parseInt(endDate.split('-')[0]), month: parseInt(endDate.split('-')[1]), day: parseInt(endDate.split('-')[2]) }
    }
    this.list = [
      { 'heading': 'All Quotes', 'tabId': 1 },
      { 'heading': 'Drafted Quotes', 'tabId': 2 },
      { 'heading': 'Executed Quotes', 'tabId': 3 }
    ]
    this.sortBy = {
      "quoteNo": "downOrder",
      "poNumber": 'downOrder',
      'service': 'downOrder',
      "pickupDate": 'downOrder',
      "shipperCompany": 'downOrder',
      'customer': 'downOrder',
      "consigneeCompany": 'downOrder',
      "totalCost": "downOrder",
      'step':'downOrder'
    }
    this.checkDataIsPresentInServiceFileOrNot()

  }
  checkDataIsPresentInServiceFileOrNot() {
    let listDataServiceData = this.listDataService.getAllDataOfQuoteHistory();
    // console.log(listDataServiceData)
    if (listDataServiceData.tabId > 0) {
      if (listDataServiceData.tabId == 1) {
        if (listDataServiceData.allQuotes.dataList.length > 0) {
          this.isDataLoadig = '';
          this.items = listDataServiceData.allQuotes.dataList;
          this.page = listDataServiceData.allQuotes.pageCount;
          this.pageSize = listDataServiceData.allQuotes.pageSize;
          if (listDataServiceData.allQuotes.searckey != '') {
            this.searchQuery = listDataServiceData.allQuotes.searchkey
          }
          if (listDataServiceData.allQuotes.fromDate && listDataServiceData.allQuotes.toDate) {
            this.modelFromDate = { year: parseInt(listDataServiceData.allQuotes.fromDate.split('-')[0]), month: parseInt(listDataServiceData.allQuotes.fromDate.split('-')[1]), day: parseInt(listDataServiceData.allQuotes.fromDate.split('-')[2]) }
            this.modelToDate = { year: parseInt(listDataServiceData.allQuotes.toDate.split('-')[0]), month: parseInt(listDataServiceData.allQuotes.toDate.split('-')[1]), day: parseInt(listDataServiceData.allQuotes.toDate.split('-')[2]) }
          }
          this.scrollToViewMethod(listDataServiceData.tabId, listDataServiceData.allQuotes.selectedId)
        } else {
          this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
        }
        this.getAllKeyOfObj().forEach((element,index)=>{
          this.sortBy[element]='downOrder'
        })
        this.isAscending=listDataServiceData.allQuotes.isAscending
        if(listDataServiceData.allQuotes.sortParam!=''){
          this.sortParam=listDataServiceData.allQuotes.sortParam;
          this.sortBy[listDataServiceData.allQuotes.sortParam]=this.isAscending?'upOrder':'downOrder'
        }
      } else if (listDataServiceData.tabId == 2) {
        if (listDataServiceData.draftQuotes.dataList.length > 0) {
          this.isDataLoadig = '';
          this.items = listDataServiceData.draftQuotes.dataList;
          this.page = listDataServiceData.draftQuotes.pageCount;
          this.pageSize = listDataServiceData.draftQuotes.pageSize;
          if (listDataServiceData.draftQuotes.searchkey != '') {
            this.searchQuery = listDataServiceData.draftQuotes.searchkey
          }
          if (listDataServiceData.draftQuotes.fromDate && listDataServiceData.draftQuotes.toDate) {
            this.modelFromDate = { year: parseInt(listDataServiceData.draftQuotes.fromDate.split('-')[0]), month: parseInt(listDataServiceData.draftQuotes.fromDate.split('-')[1]), day: parseInt(listDataServiceData.draftQuotes.fromDate.split('-')[2]) }
            this.modelToDate = { year: parseInt(listDataServiceData.draftQuotes.toDate.split('-')[0]), month: parseInt(listDataServiceData.draftQuotes.toDate.split('-')[1]), day: parseInt(listDataServiceData.draftQuotes.toDate.split('-')[2]) }
          }
          this.scrollToViewMethod(listDataServiceData.tabId, listDataServiceData.draftQuotes.selectedId)
        } else {
          this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
        }
        this.getAllKeyOfObj().forEach((element,index)=>{
          this.sortBy[element]='downOrder'
        })
        this.isAscending=listDataServiceData.draftQuotes.isAscending
        if(listDataServiceData.draftQuotes.sortParam!=''){
          this.sortParam=listDataServiceData.draftQuotes.sortParam;
          this.sortBy[listDataServiceData.draftQuotes.sortParam]=this.isAscending?'upOrder':'downOrder'
        }
      } else if (listDataServiceData.tabId == 3) {
        if (listDataServiceData.executedQuotes.dataList.length > 0) {
          this.isDataLoadig = '';
          this.items = listDataServiceData.executedQuotes.dataList;
          this.page = listDataServiceData.executedQuotes.pageCount;
          this.pageSize = listDataServiceData.executedQuotes.pageSize;
          if (listDataServiceData.executedQuotes.searchkey != '') {
            this.searchQuery = listDataServiceData.executedQuotes.searchkey
          }
          if (listDataServiceData.executedQuotes.fromDate && listDataServiceData.executedQuotes.toDate) {
            this.modelFromDate = { year: parseInt(listDataServiceData.executedQuotes.fromDate.split('-')[0]), month: parseInt(listDataServiceData.executedQuotes.fromDate.split('-')[1]), day: parseInt(listDataServiceData.executedQuotes.fromDate.split('-')[2]) }
            this.modelToDate = { year: parseInt(listDataServiceData.executedQuotes.toDate.split('-')[0]), month: parseInt(listDataServiceData.executedQuotes.fromDate.split('-')[1]), day: parseInt(listDataServiceData.executedQuotes.toDate.split('-')[2]) }
          }
          this.scrollToViewMethod(listDataServiceData.tabId, listDataServiceData.executedQuotes.selectedId)

        } else {
          this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
        }
        this.getAllKeyOfObj().forEach((element,index)=>{
          this.sortBy[element]='downOrder'
        })
        this.isAscending=listDataServiceData.executedQuotes.isAscending
        if(listDataServiceData.executedQuotes.sortParam!=''){
          this.sortParam=listDataServiceData.executedQuotes.sortParam;
          this.sortBy[listDataServiceData.executedQuotes.sortParam]=this.isAscending?'upOrder':'downOrder'
        }
      }
      this.SelectedTabId = listDataServiceData.tabId;
    } else {
      this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode);

    }
  }
  scrollToViewMethod(tabId: any, selectedItemId: any) {
    setTimeout(() => {
      let element;
      if (tabId == 1) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 2) {
        element = document.getElementById(selectedItemId);

      } else if (tabId == 3) {
        element = document.getElementById(selectedItemId);

      } else if (tabId == 4) {
        element = document.getElementById(selectedItemId);

      }

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 500)
  }
  getListOfQuotes(page, pageSize, searchQuery, quoteType) {
    this.allDataLoaded=false;
    this.isDataLoadig = 'Loading Your Options, Please wait...'
    let url = ServerURLS.getListOfQuoteHistory.action + '/' + page + '/' + pageSize + '?quoteStatus=' + quoteType+'&isAscending='+this.isAscending;
    if(this.sortParam!=''){
      url=url+'&sortParam='+this.sortParam
    }
    if (searchQuery != '') {
      url = url + '&searchKey=' + searchQuery;
    }
    if (this.isDashboardView) {
      url = url + '&isDashBoardView=true'
    }
    if (this.modelFromDate && this.modelToDate) {
      let dates = this.commanService.dateFormatter(this.modelFromDate, this.modelToDate)
      // let dates = this.dateFormatter(this.modelFromDate, this.modelToDate);
      let fromDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
      let toDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
      // let fromDate = this.modelFromDate.month + '-' + this.modelFromDate.day + '-' + this.modelFromDate.year;
      // let toDate = this.modelToDate.month + '-' + this.modelToDate.day + '-' + this.modelToDate.year;
      url = url + '&startDate=' + fromDate + '&endDate=' + toDate
    }
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {

      this.items = res['response'];
      if (res['response'].length > 0) {
        this.isDataLoadig = ''
        res['response'].forEach((element, index) => {
          // this.getPriceSummaryDetails(element.quoteId, element.customerId)
          if (element.shipperAddress) {
            let str = (element.shipperAddress.company ? element.shipperAddress.company + ',' : '') + (element.shipperAddress.country ? element.shipperAddress.country + ',' : '') + (element.shipperAddress.zipCode ? element.shipperAddress.zipCode : '')
            this.items[index]['completeShipperAddress'] = str
          }
          if (element.consigneeAddress) {
            let str = (element.consigneeAddress.company ? element.consigneeAddress.company + ',' : '') + (element.consigneeAddress.country ? element.consigneeAddress.country + ',' : '') + (element.consigneeAddress.zipCode ? element.consigneeAddress.zipCode : '')
            this.items[index]['completeConsigneeAddress'] = str;
          }
        })
        let data = {
          pageCount: this.page,
          pageSize: this.pageSize,
          dataList: this.items
        }
        this.listDataService.setAllDataOfQuoteHistory(data, this.SelectedTabId)
      } else if (res['response'].length == 0 && this.page == 0) {
        this.isDataLoadig = 'No Data Found'
      }

    })
      .catch((err: any) => {

      })
  }
  changeTheTab(tabId) {
    this.modelFromDate = undefined
    this.modelToDate = undefined
    this.searchQuery = ''
    this.page = 0
    // console.log(tabId)
    this.allDataLoaded=false;
    this.listDataService.setTabIdForQuoteHistory(tabId)
    this.SelectedTabId = tabId
    this.sortParam=''
    this.isAscending=false
    if (tabId === 1) {
      this.searchingMode = 'all'
    } else if (tabId === 2) {
      this.searchingMode = 'draft'
    } else if (tabId === 3) {
      this.searchingMode = 'executed'
    }
    this.checkDataIsPresentInServiceFileOrNot()

    // this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
  }
  getAllKeyOfObj(){
    return Object.keys(this.sortBy)
  }
  isAscending:boolean=false;
  sortParam:any=''
  sorting(property) {
    this.getAllKeyOfObj().forEach((element,index)=>{
      if(property!=element){
        this.sortBy[element]='downOrder'
      }
    })
    if(this.sortBy[property]=='downOrder'){
      this.sortBy[property]='upOrder';
      this.isAscending=true;
      this.sortParam=property
    }else{
      this.sortBy[property]='downOrder'
      this.isAscending=false;
      this.sortParam=property
    }
    this.listDataService.setDataOfSortParamInQuoteHistory(this.isAscending,this.sortParam,this.SelectedTabId)
    this.getListOfQuotes(0,(this.page+1)*20,this.searchQuery,this.searchingMode)
    // this.isDesc = !this.isDesc  // change the direction
    // let direction = this.isDesc ? 1 : -1;
    // this.sortBy[property] = !this.isDesc ? 'downOrder' : 'upOrder';


    // if (property === 'totalAmount' || property == 'stepNo') {
    //   this.items.sort(function (a, b) {
    //     if (parseFloat(a[property]) < parseFloat(b[property])) {
    //       return -1 * direction;
    //     }
    //     else if (parseFloat(a[property]) > parseFloat(b[property])) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // } else {
    //   this.items.sort(function (a, b) {
    //     // console.log(a[property], b[property])
    //     if (a[property] && b[property]) {

    //       if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //         return -1 * direction;
    //       }
    //       else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //         return 1 * direction;
    //       }
    //       else {
    //         return 0;
    //       }
    //     }
    //   });
    // }
  }
  timer: any;
  seachQuote(value) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (value.length >= 3) {
        this.page=0
        this.listDataService.setSearchKeyForQuoteHistory(value, this.SelectedTabId)
        this.searchQuery = value;
        this.doSearch()
      }
      if (value.length === 0) {
        this.page=0
        this.listDataService.setSearchKeyForQuoteHistory('', this.SelectedTabId)
        this.searchQuery = value;
        this.doSearch()
      }
    }, 500)

  }
  doSearch() {
    this.page = 0
    if (this.modelToDate && this.modelFromDate) {
      let dates = this.commanService.dateFormatter(this.modelFromDate, this.modelToDate);
      let fromDate = Date.parse(`${dates[0].year}-${dates[0].month}-${dates[0].date}`);
      let toDate = Date.parse(`${dates[1].year}-${dates[1].month}-${dates[1].date}`);
      if(fromDate>toDate){
        this.toastr.error("'From Date' should be smaller than 'To Date'.")
        return;
      }else{
        this.listDataService.setFromDateToDateOfQuoteHistory(fromDate, toDate, this.SelectedTabId)
        this.getListOfQuotes(this.page,this.pageSize,this.searchQuery,this.searchingMode)
      }
    }else{
      this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
    }
  }
  quoteDetails: any = {
    'customerName': "",
    'createdBy': "",
    'dateCreated': "",
    'carrierName': "",
    'shipmentMode': "",
    'origin': "",
    'destination': ""
  }
  showQuoteDetails(index) {
    // console.log(index)
    this.quoteDetails = [];
    this.items.map((x, i) => {
      if (index === i) {
        // this.quoteDetails = x
        this.quoteDetails.customerName = x.customerName ? x.customerName : "--"
        this.quoteDetails.createdBy = x.createdBy ? x.createdBy : null
        this.quoteDetails.dateCreated = x.createdDate ? x.createdDate : null
        this.quoteDetails.carrierName = x.carrierName ? x.carrierName : '--'
        this.quoteDetails.shipmentMode = x.shipmentModeName ? x.shipmentModeName : "--"
        if (x.shipperAddress) {
          this.quoteDetails.origin = (x.shipperAddress.city ? x.shipperAddress.city + ',' : "") + (x.shipperAddress.state ? x.shipperAddress.state : '')
        } else {
          this.quoteDetails.origin = '--'
        }
        if (x.consigneeAddress) {
          this.quoteDetails.destination = (x.consigneeAddress.city ? x.consigneeAddress.city + ',' : '') + (x.consigneeAddress.state ? x.consigneeAddress.state : '')
        } else {
          this.quoteDetails.destination = '--'
        }
      }
    })
    // console.log(this.quoteDetails)
  }
  priceSummaryObj: any = {
    'carrierLogo': '',
    'shipmentCodeStyle': '',
    'weight': '',
    'shipmentMode': '',
    'quoteNo': "",
    'priceArray': []
  }
  getPriceSummaryDetails(quoteId: any, customerId: any, i: any) {
    this.isPriceSummaryDataLoading = true;
    this.priceSummaryObj = {
      'carrierLogo': '',
      'shipmentCodeStyle': '',
      'weight': '',
      'shipmentMode': '',
      'quoteNo': "",
      'priceArray': []
    }
    let url = ServerURLS.getCustomerOrderPriceComponent.action + '?quoteId=' + quoteId + '&customerId=' + customerId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isPriceSummaryDataLoading = false;
      this.priceSummaryObj.carrierLogo = res.carrierLogo;
      this.items.forEach((element, index) => {
        if (index === i) {
          this.priceSummaryObj.shipmentMode = element.shipmentModeName;
          this.priceSummaryObj.shipmentCodeStyle = element.shipmentModeStyleCode;
          this.priceSummaryObj.quoteNo = element.customQuoteId;
          let obj = {
            'priceName': "",
            'price': ""
          }

          let totalWeight = 0;
          res.charges.forEach((ele1, inde1) => {
            let key = Object.keys(ele1);
            let value = Object.values(ele1);
            obj.priceName = key[0];
            obj.price = (value[0]).toString();
            this.priceSummaryObj.priceArray.push(obj)
            obj = {
              'priceName': "",
              'price': ""
            }
          })
          element.items.forEach((ele, ind) => {
            totalWeight += (ele.wghtUnit === 'lbs' ? ele.totalWeight : (ele.totalWeight / 2.20)).toFixed(2) * ele.qty;
          })
          this.priceSummaryObj.weight = totalWeight;
        }
      })
      // console.log(this.priceSummaryObj)
    })
      .catch((err: any) => {

      })
  }
  loadPerPageData(value) {
    this.pageSize = value;
    this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
  }
  loadPage(value) {
    this.page = value;
    this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode)
  }
  goToGetQuote() {
    this.router.navigateByUrl('shipcarte/step-one')
  }
  ngOnDestroy(): void {
    if (this.pleaseremoveDataFromSerivceFile) {
      this.listDataService.removeAllDataOfQuoteHistory()
    }
  }
  pleaseremoveDataFromSerivceFile: boolean = true;
  goToQuoteDetailPage(data) {
    // console.log(data)
    this.listDataService.setSelectedItemIdForQuoteHistory(data.quoteId, this.SelectedTabId)
    const userInforamation = {
      quoteid: data.quoteId,
      customOrderId: data.customQuoteId,
      orderId: data.orderId,
      orderStatus: data.quoteStatus,
      customerName: data.customerName,
      customerId: data.customerId,
      carrierName: data.carrierName,
      carrierLogo: data.carrierLogo,
      source: data.orderSource
      // carrierId:
      // contractId

    }
    // console.log(userInforamation)
    this.commanService.quoteId = data.quoteid;
    sessionStorage.setItem("userInformation", JSON.stringify(userInforamation))
    let quoteInformation = {
      quoteid: data.quoteId,
      customQuoteId: data.customQuoteId,
      quoteStatus: data.quoteStatus,
      customerName: data.customerName,
      carrierName: data.carrierName,
      carrierLogo: data.carrierLogo,
      customerId: data.customerId
    };
    this.pleaseremoveDataFromSerivceFile = false;
    sessionStorage.setItem('quoteInformation', JSON.stringify(quoteInformation));
    this.router.navigateByUrl('shipcarte/quoteHistory/quoteDetails')
  }
  executedQuote(data) {
    // // console.log(data)
    if(data.orderSource == 'Quoted'){
      this.router.navigateByUrl('shipcarte/step-one/' + data.quoteId)
    }else if(data.orderSource == 'On Call Order'){
      this.router.navigate(['shipcarte/orders/newOrderEntry/execute/' + data.quoteId],{queryParams:{
        execute: true
      }})
    }
  }
  redirctToGeneralDetails(id) {
    this.commanService.customerId = id
    sessionStorage.setItem('customerId', id)
    this.router.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`)
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerOfQuoteHistory(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerOfQuoteHistory(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {


        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        }else{
          this.isLoading=false;
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.page = this.page + 1
    let url = ServerURLS.getListOfQuoteHistory.action + '/' + this.page + '/' + this.pageSize + '?quoteStatus=' + this.searchingMode+'&isAscending='+this.isAscending;
    if(this.sortParam!=''){
      url=url+'&sortParam='+this.sortParam
    }
    if (this.searchQuery != '') {
      url = url + '&searchKey=' + this.searchQuery;
    }
    if (this.isDashboardView) {
      url = url + '&isDashBoardView=true'
    }
    if (this.modelFromDate && this.modelToDate) {
      let dates = this.commanService.dateFormatter(this.modelFromDate, this.modelToDate)
      // let dates = this.dateFormatter(this.modelFromDate, this.modelToDate);
      let fromDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
      let toDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
      // let fromDate = this.modelFromDate.month + '-' + this.modelFromDate.day + '-' + this.modelFromDate.year;
      // let toDate = this.modelToDate.month + '-' + this.modelToDate.day + '-' + this.modelToDate.year;
      url = url + '&startDate=' + fromDate + '&endDate=' + toDate
    }
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isLoading = false
      let itesmArray = res['response']
      if (res['response'].length > 0) {


        res['response'].forEach((element, index) => {
          // this.getPriceSummaryDetails(element.quoteId, element.customerId)
          if (element.shipperAddress) {
            let str = (element.shipperAddress.company ? element.shipperAddress.company + ',' : '') + (element.shipperAddress.country ? element.shipperAddress.country + ',' : '') + (element.shipperAddress.zipCode ? element.shipperAddress.zipCode : '')
            itesmArray[index]['completeShipperAddress'] = str
          }
          if (element.consigneeAddress) {
            let str = (element.consigneeAddress.company ? element.consigneeAddress.company + ',' : '') + (element.consigneeAddress.country ? element.consigneeAddress.country + ',' : '') + (element.consigneeAddress.zipCode ? element.consigneeAddress.zipCode : '')
            itesmArray[index]['completeConsigneeAddress'] = str;
          }
        })
        this.items = this.items.concat(itesmArray)
        let data = {
          pageCount: this.page,
          pageSize: this.pageSize,
          dataList: this.items
        }
        this.listDataService.setAllDataOfQuoteHistory(data, this.SelectedTabId)
      }else{
        this.allDataLoaded=true;
        this.isLoading=false;
      }
    }).catch((err:any)=>{
      this.isLoading=false;
    })
  }
  clearFilter() {
    if (!this.isDashBoardView) {
      if (!this.isOnlyViewing) {
          this.page = 0;
          this.pageSize = 20;
          this.modelFromDate = undefined
          this.modelToDate = undefined
          this.searchQuery = ''
          this.listDataService.removeDataOfTabWhenClickOnResetButtonQuoteHistory(this.SelectedTabId);
          this.getListOfQuotes(this.page, this.pageSize, this.searchQuery, this.searchingMode);
      } else {
        this.listDataService.removeAllDataOfQuoteHistory();
        this.router.navigateByUrl('shipcarte/quoteHistory/allQuotes')
      }
    }
  }
  removeQuote(index, quoteId){
    this.sharedData.appLoader = true;
    let url = ServerURLS.removeQuote.action + '?quoteId=' + quoteId;
    let promise = this.httpService.putHttpService(url, '');
    promise.then((res: any) => {
      this.items.splice(index, 1);
      this.toastr.error(res.data);
      this.sharedData.appLoader = false;
    }).catch((err:any)=>{
      this.sharedData.appLoader = false;
      this.toastr.error("Unable to remove this quote.");
    })
  }
}
