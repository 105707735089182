import { Component, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent implements OnInit {
  warehouses: any;

  constructor( private scHttpService: ScHttpService ) { }

  ngOnInit() {
    this.loadWarehouses();
  }

  loadWarehouses (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllWarehouse.action, '');
    promise1.then( value => {
      this.warehouses = value["data"];
    });

  }

}
