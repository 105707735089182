import { Component, OnInit } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { BillingInfo } from '../../creditapp/bill-info/biilinginfo';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {

  paymentForm: FormGroup;
  billingInfo: BillingInfo;
  errMsg: string;
  paymentMethods:any;
  searchZipcode: any;
  applicantId: any;
  isEdit: any;

  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
  iconMap: Map<any, any>;
  selected: any;

  YEAR_REGEXP = /^([0-9]{4})$/;
  MONTH_REGEXP = /^([0-9]{2})$/;

  methodMap: Map<any, any>;
  cardTypes: { type: string; value: string; }[];

  constructor( private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private storageService: ScStorageService, private router: Router, 
               private route: ActivatedRoute, private datePipe: DatePipe ) {
      this.billingInfo  = new BillingInfo();
      this.methodMap = new Map();
      this.paymentMethods = [];
    } 

  ngOnInit() {

    this.cardTypes = [
      {type: 'visa', value: "VISA"},
      {type: 'master-card', value: "MASTER CARD"}
    ];

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit');
    this.loadpaymntMethods();
    this.loadFormData();

    if( this.applicantId && this.isEdit){
       this.loadpaymntData();
    }
    
  }

   loadFormData(){

     this.paymentForm = this._formBuilder.group({
        gstnNumber:[],
        bondNumber:[],
        paymentMethodId:['', Validators.required],
        creditCardNumber:[],
        expDate:[],
        cardHolderName: [],
        year: [, Validators.pattern(this.YEAR_REGEXP)],
        month: [, Validators.pattern(this.MONTH_REGEXP)],
        cardType: []
    });
 }

 get f1() { return this.paymentForm.controls; }

 loadpaymntMethods (): void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPaymentMethods.action,'');
  promise1.then( value => {

    let list = value["data"];
    list.forEach(element => {
      if(element["methodName"] != 'Visa' && element["methodName"] != 'Master Card'){
        this.paymentMethods.push(element);
        this.methodMap.set(element["paymtId"], element["methodName"]);
      }
    });

    this.loadMethodIcons();
    
  });

}

loadMethodIcons(){
  this.iconMap = new Map();

  this.iconMap.set("Credit Card", "fa fa-credit-card") ; 
  this.iconMap.set("Cheque", "fas fa-money-check-alt") ; 
  this.iconMap.set("Direct Deposit", "fa fa-money") ; 
}

setPaymentMethodId( methodId ){
  this.selected = methodId;
  this.paymentForm.get('paymentMethodId').setValue(methodId);
}

setCardType( type ){
  this.paymentForm.get('cardType').setValue(type);
}

savePaymentInfo () {

  let form_data = this.paymentForm.getRawValue();
  this.billingInfo  = form_data;
  this.billingInfo.applicantId = this.applicantId;
  let serviceCall :any;

  if(form_data.year != null && form_data.month != null ){
    this.billingInfo.expDate = form_data.year + '-' + form_data.month;
  }

 
  if(form_data.paymentMethodId == ''){
    this.errMsg = "Please select payment method";

  } else {

      serviceCall = this.scHttpService.putHttpService(ServerURLS.updatePayment.action, this.billingInfo);
      let promise = serviceCall;
      
      promise.then( (resolve) => {
        let response = resolve;
        this.errMsg = '';

        let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
        this.router.navigateByUrl("shipcarte/creditv2/bank-info" + params);
    
      }).catch((error) => {
        this.errMsg = error.message;
      });

    }

  }

  loadpaymntData (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPayments.action + "?applicant-id=" + this.applicantId,'');
    promise1.then( value => {
      let response = value["data"];

      if(response){
        this.selected = response['paymentMethodId'];

        let expdate = response["expDate"];

        if(expdate){
          this.paymentForm.get("year").setValue(expdate.split("-")[0]);
          this.paymentForm.get("month").setValue(expdate.split("-")[1]);
        }
        this.paymentForm.patchValue (value["data"]);
      }

    });
  
  }

}
