import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { Routes, Router, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 
import { FormsModule } from '@angular/forms';
import { SalesRepsComponent } from './sales-reps-list/sales-reps-list.component';
import { AuthGuardService } from 'src/app/services/auth/auth-guard.service';
const routes: Routes = [
  { path: 'shipcarte/salesrep', component: SalesRepsComponent,canActivate: [AuthGuardService] },
  {path:'shipcarte/salesrep/generalDetails',loadChildren:()=>import('./salesrepsdetails/salesrepsdetails.module').then(m=>m.SalesRepsDetailModule),canActivate: [AuthGuardService]}
 

]

@NgModule({
  declarations: [SalesRepsComponent ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    NgbModule,
    FormsModule
  ],
  exports: []
})
export class SalesRepsModule { }
