export class Dimension {

    length: number;
    breadth: number;
    height: number;

    unit: string;

    constructor ( length, breadth, height, unit ) {
        this.length  = length;
        this.breadth = breadth;
        this.height  = height;

        this.unit    = unit;
    }
}