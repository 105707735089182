import { Address } from '../../profile/address';

export class CreditApplicant{

    uuid:String;
    legalName:string;
    tradeName:string;
    telePhone:string;
    tollFree:string;
   
    contactAddress:Address;

    constructor () {
        this.contactAddress = new Address();
    }
}