import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { ServerURLS, EnvironmentConstants } from 'src/environments/environment';
import { ProfileEdit } from './profileEdit';
import { Address } from './address';

import pdfMake from '../../../../node_modules/pdfmake/build/pdfmake';
import pdfFonts from '../../../../node_modules/pdfmake/build/vfs_fonts';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  editProfile:FormGroup
  successMsg:string;
  errMsg:String;
  profileEditForm:ProfileEdit;
  loggedUserId:any;
  loggedEntityType:any;
  isEditMode:boolean;

  addressForm:FormGroup
  addrSuccessMsg: any;
  addrErrMsg: any;
  loggedUserAddrId:any;
  address: Address;
  isEditAddrMode: boolean;
  userData: any;
  image: any;
  selectedFile: any;

  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService,  public scAuthService: ScAuthService, private storageService: ScStorageService, private scCommonService: ScCommonService ) {
      

      this.profileEditForm = new ProfileEdit();
      this.address = new Address();
  }

  ngOnInit() {
    
    this.userData = this.scAuthService.getLoggedUserData();
    this.loggedUserId = this.scAuthService.getLoggedUserId() ;
    this.loggedEntityType = this.scAuthService.getLoggedEntityType() ;
   
    this.loadFormData();

    if( this.loggedUserId ){
      this.initProfileData(this.loggedUserId);
    }
  }

  loadFormData(){

    this.editProfile = this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [{value: '', disabled: true}],
      phoneNumber: [{value: '', disabled: true}],
      loggedUserId :[this.scAuthService.getLoggedUserId()],
    });

    this.addressForm = this._formBuilder.group({
      addrLine1: ['', Validators.required],
      addrLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.maxLength(6)]],
      addressId : [ this.loggedUserAddrId ? this.loggedUserAddrId : null ]
  });

 }

 editDetails(){
  this.successMsg = "";
  this.isEditMode = true ;
 }

 get f() { return this.editProfile.controls; }

  initProfileData (id): void {

    let url =  "" ;

    if( this.loggedEntityType =='customer'){

      url += ServerURLS.getCustomerDetails.action;

    } else if (  this.loggedEntityType =='partner'){

      url += ServerURLS.getPartnerDetails.action;

    } else if (  this.loggedEntityType == 'user' || this.loggedEntityType =='admin' ){

      url += ServerURLS.getUserDetails.action;

    } 
    
    let promise1 = this.scHttpService.getHttpService( url +"?id="+id,'');
    promise1.then( value => {
      this.profileEditForm = value["data"];

      this.image = this.scCommonService.getLocalProfileImage();

      this.loggedUserAddrId = this.profileEditForm.defaultAddrId;
      if(this.loggedUserAddrId != null && this.loggedUserAddrId !=  undefined){
        this.initAddresData(this.loggedUserAddrId);
      }
    });

  }

  updateProfile () {

    this.profileEditForm = this.editProfile.getRawValue();

    let url =  "" ;

      if(this.loggedUserId != null && this.loggedUserId != undefined){
        this.profileEditForm.uuid = this.loggedUserId;
      }

      if( this.loggedEntityType == 'customer'){

        url+= ServerURLS.updateCustomer.action;

      } else if (  this.loggedEntityType == 'partner'){

        url+= ServerURLS.updatePartner.action;

      } else if (this.loggedEntityType == 'user' || this.loggedEntityType =='admin'){

        url+= ServerURLS.updateUser.action;

      } 

    let authPromise = this.scHttpService.putHttpService(url, this.profileEditForm);

    authPromise.then( (resolve) => {
      let response = resolve;
      this.successMsg = response["message"];
      this.errMsg = "";
      this.initProfileData(this.loggedUserId);
      this.isEditMode = false ;
      
    }).catch((error) => {
      this.errMsg = error.message;
      this.successMsg ="";
    });

  }

  //Address component
  get a() { return this.addressForm.controls; }

    editAddrDtls(){
      this.addrSuccessMsg = "";
      this.isEditAddrMode = true ;
    }

    initAddresData (id): void {

      let url =  ServerURLS.getAddressDetails.action;
  
      let promise1 = this.scHttpService.getHttpService( url +"?id="+id,'');
      promise1.then( value => {
        this.address = value["data"];
      });
  
    }

    saveAddress () {

      this.address = this.addressForm.getRawValue();

      if(this.loggedUserAddrId != null && this.loggedUserAddrId != undefined){
        this.address.addressId = this.loggedUserAddrId;
      }
    
     let authPromise = this.scHttpService.postHttpService(ServerURLS.saveAddress.action, this.address);
      
      authPromise.then( (resolve) => {
        let response = resolve;
        this.addrSuccessMsg = response["message"];
        this.addrErrMsg = "";
        this.initProfileData ( this.loggedUserId);
        this.isEditAddrMode = false ;
        
      }).catch((error) => {
        this.addrErrMsg = error.message;
        this.addrSuccessMsg ="";
      });
    }

    generatePdf(): void{
      
      const documentDefinition = { content: 'This is an sample PDF printed with pdfMake' };
      pdfMake.createPdf(documentDefinition).open();
      
    }

    saveProfileImage () {

      const formdata: FormData = new FormData();
      formdata.append('file', this.selectedFile);
      
      let url =  "" ;
  
        if( this.loggedEntityType == 'customer'){
  
          url+= ServerURLS.saveCustProfileImage.action;
  
        } else if (  this.loggedEntityType == 'partner'){
  
          url+= ServerURLS.savePartnerProfileImage.action;
  
        } else if (this.loggedEntityType == 'user' || this.loggedEntityType =='admin'){
  
          url+= ServerURLS.saveShipUserProfileImage.action;
  
        } 
  
      this.image = EnvironmentConstants.IMAGE_LOADER;
      let authPromise = this.scHttpService.postFileHttpService(url, formdata);
  
      authPromise.then( (resolve) => {

        let response = resolve;
        this.selectedFile = null;
        this.errMsg = "";

        if( response ){

          let fileId = response["data"]["fileId"];

          this.userData = this.scAuthService.getLoggedUserData();
          if( this.userData && fileId ){
            this.userData['fileId'] = fileId;
            this.storageService.saveBucket("entity", this.userData);
          }

          if( fileId ){
            let promise2 = this.scCommonService.getProfileImage( fileId);
            promise2.then( value => {
              this.image = this.scCommonService.getLocalProfileImage();
            });
            
          }

        }

      }).catch((error) => {
        this.errMsg = error.message;
        this.successMsg ="";
      });
  
    }

    onFileChanged(event) {

      if (event.target.files && event.target.files[0]) {
        this.selectedFile = event.target.files[0];
      }

      if( this.selectedFile ){
        this.saveProfileImage ();
      }
      
    }
}

