import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment'
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScCommonService } from '../../../services/common/sc-common.service';

@Component({
  selector: 'app-quote-payment-list',
  templateUrl: './quote-payment-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./quote-payment-list.component.scss']
})
export class QuotePaymentListComponent implements OnInit {

  closeResult = '';
  payments:any;
  t:Array<NgbTooltipConfig>;

  page = 1;
  pageSize = 10;
  collectionSize = 10; 
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  searchParam: any;

  approvalForm: FormGroup;
  errorMessage: any;
  successMessage: any;

  minDate: any; 

  @ViewChild('loadContent', undefined) loadContent;

  constructor(private scHttpService: ScHttpService, toolTipconfig: NgbTooltipConfig, private modalService: NgbModal, public scAuthService: ScAuthService,
    private _formBuilder: FormBuilder, public commonService: ScCommonService) { 

    toolTipconfig.placement = 'right';
    toolTipconfig.triggers = 'click';
    toolTipconfig.tooltipClass = "pay-tooltip"

    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

  }

  ngOnInit() {
    this.quotePayments();
    this.loadFormData();
  }

  ngAfterViewInit() {
    this.quotePayments();
  }

  loadFormData(){

    this.approvalForm = this._formBuilder.group({

       quoteId: [],
       paymentId: [],
       approverId: [],
       approverComment: []
      // approvedDate: [, Validators.required]
    });

  }

  get f() { return this.approvalForm.controls; }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.quotePayments();
    }

  }

  search(key){

    this.searchParam = key;
    this.quotePayments();
  }

  isDataReady = false;
  quotePayments (): void {

    this.loadCotent(this.loadContent);

    let url = ServerURLS.getQuotePaymentList.action + "/" + ( this.page - 1 ) + "/" + this.pageSize + "?";

    if(this.searchParam){
      url += "&searchKey=" + this.searchParam;
    }
    this.isDataReady =false;
    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {

      this.payments = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;
      this.isDataReady = true;
      this.closeModal();
      // this.t = new Array[this.payments.length];
    });

  }

  toggleWithGreeting(tooltip, custCode: string) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({custCode});
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  loadCotent(loadContent) {
    this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal',backdrop:'static' })
  }
 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  markAsPaid(index){

    let formData = this.approvalForm.getRawValue();

    formData.quoteId = this.payments[index].quoteId;
    formData.paymentId = this.payments[index].uuid;
    //formData.approvedDate = this.commonService.formatDate(formData.approvedDate);
    formData.approverId = this.scAuthService.getLoggedUserId();
    
    let promise = this.scHttpService.putHttpService(ServerURLS.setPaymentAsPaid.action, formData);

    promise.then( (response) => {

      this.errorMessage = "";
      if( response ){
        this.successMessage = response['message'];
        this.closeModal();
        this.loadFormData();
        this.quotePayments();
      }
     
    }).catch((error) => {
      this.errorMessage = error.message;
    });

  }

  closeModal(){
    this.modalService.dismissAll();
  }  

}
