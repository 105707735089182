import { Address } from '../../profile/address';

export class QuotePaymentForm{

    paymentId:String;
    quoteId:string;
    paymtStts:string;
    paymtGatewayRefId:string;
    freightChrgs:string;
    total:string;
    paymts:string;
    amountDue:string;
    paymtMethod:string;
    sttsMsg:boolean
    addrId:Address;
    isTerms:boolean;
    constructor () {
        this.addrId = new Address();
    }
}