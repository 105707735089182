import { Address } from '../../profile/address';

export class  CreditReference{
   
    refAddr: Address;
    refId:string;
    applicantId:string;
    refCompany:string;
    contactName:string;
    constructor () {
        this.refAddr = new Address();

    }
}