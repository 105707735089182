import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScUserFormComponent } from './sc-user-form/sc-user-form.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ScUserListComponent } from './sc-user-list/sc-user-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupUserAssoicationComponent } from './group-user-assoication/group-user-assoication.component';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/user/add', component: ScUserFormComponent},
  { path: 'shipcarte/user/list', component: ScUserListComponent},

];

@NgModule({
  declarations: [ScUserFormComponent, ScUserListComponent, GroupUserAssoicationComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule
  
  ]
})
export class UserModule { }
