import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonsModule } from './commons/commons.module';
import { AutnModule } from './autn/autn.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserModule } from './user/user.module';
import { AddressModule } from './address/address.module';
import { ProfileModule } from './profile/profile.module';
import { QuoteModule } from './quote/quote.module';
import { CustomerModule } from './customer/customer.module';
import { PartnerModule } from './partner/partner.module';
import { WarehouseModule } from './warehouse/warehouse.module';
import { CarrierModule } from './carrier/carrier.module';
import { OrderModule } from './order/order.module';
import { CreditappModule } from './creditapp/creditapp.module';
import { OfferModule } from './offer/offer.module';
import { CaseModule } from './case/case.module';
import { QuoteV2Module } from './quote-v2/quote-v2.module';
import { TutorialsModule } from './tutorials/tutorials.module';
import { DashboardV2Module } from './dashboard-v2/dashboard-v2.module';
import { ModalModule } from './modal/modal.module';
import { AppendixModule } from './appendix/appendix.module';
import { CreditappV2Module } from './creditapp-v2/creditapp-v2.module';
import { AnalyticsModule} from './analytics/analytics.module';
import { PartModule } from './part/part.module';
 import { InvoiceModule } from './invoice/invoice.module';
import { OrdersModule } from './orders/orders.module';
import { SalesRepsModule } from './salesreps/salesreps.module';
import {SettingsModule} from './settings/settings.module'
import { GetQuoteModule } from './get-quote/get-quote.module';
import { QuoteHistoryModule } from './quote-history/quote-history.module';
import { QuickActionsModule } from './quick-actions/quick-actions.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonsModule,
    UserModule,
    AutnModule,
    DashboardModule,
    AddressModule,
    ProfileModule,
    QuoteModule,
    CustomerModule,
    PartnerModule,
    WarehouseModule,
    CarrierModule,
    OrderModule,
    CreditappModule,
    OfferModule,
    CaseModule,
    QuoteV2Module,
    TutorialsModule,
    DashboardV2Module,
    ModalModule,
    InvoiceModule,
    AppendixModule,
    CreditappV2Module,
    AnalyticsModule,
    PartModule,
    OrdersModule,
    SalesRepsModule,
    SettingsModule,
    GetQuoteModule,
    QuickActionsModule,
    QuoteHistoryModule
  ]
})
export class ModulesModule { }
