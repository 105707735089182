import { Component, OnInit } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute } from '@angular/router';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';

@Component({
  selector: 'app-order-response',
  templateUrl: './order-response.component.html',
  styleUrls: ['./order-response.component.scss']
})
export class OrderResponseComponent implements OnInit {
  
  orderResponse: any;
  orderId: string;
  order: any;

  constructor( private scHttpService: ScHttpService, private route: ActivatedRoute, public scAuthService: ScAuthService  ) { }

  ngOnInit() {

    this.orderId =  this.route.snapshot.paramMap.get('order-id');

    if(this.orderId){
      this.getOrderResonseDetails();
      this.getCustIdByOrderId();
    }
  }

  getOrderResonseDetails(): void {

    let url = ServerURLS.getOrderResonseDetails.action + "?order-id=" + this.orderId;

    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.orderResponse = value["data"];
    });

  }

  getOrderDetails( custId ): void {

    let url = ServerURLS.getOrderDetails.action + "?id=" + this.orderId;

    if( custId ){
      url += "&cust-id=" + custId;
    }

    let promise1 = this.scHttpService.getHttpService( url,'');
    promise1.then( value => {
      this.order = value["data"];
    });

  }

  getCustIdByOrderId(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCustIdByOrderId.action + "?order-id=" + this.orderId,'');
    promise1.then( value => {
      let custId = value["data"];

      if(custId){
        this.getOrderDetails( custId );
      }
      
    });

  }

}
