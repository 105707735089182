import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileV2Component } from './profile-v2/profile-v2.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/profile', component: ProfileComponent},
  { path: 'shipcarte/change/password', component: ChangePasswordComponent},
  { path: 'shipcarte/profilev2', component: AdminProfileComponent},
  { path: 'shipcarte/profilev3', component: ProfileV2Component},

];

@NgModule({
  declarations: [ProfileComponent, ChangePasswordComponent, ProfileV2Component,AdminProfileComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule
  ]
})
export class ProfileModule { }
