import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { CreditApplicant } from './creditapplicant';

@Component({
  selector: 'app-credit-applicant-form',
  templateUrl: './credit-applicant-form.component.html',
  styleUrls: ['../../quote/quote-location/quote-location.component.scss','./credit-applicant-form.component.scss']
})
export class CreditApplicantFormComponent implements OnInit {

  applicantForm: FormGroup;
  isPincodeReleased:boolean=false;
  applicant:CreditApplicant;
  addressForm: FormGroup;
  successMsg: any;
  errMsg: string;
  PHONE_REGEXP = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
  applicantId: any;
  address: any;
  applicantDetail: any;
  isEdit: string;
  quoteCompletionRate: string;

  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor( private route: ActivatedRoute, private storageService: ScStorageService, private router: Router,private _formBuilder: FormBuilder , private scHttpService: ScHttpService) {
    this.applicant = new  CreditApplicant();
   }

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit');

    this.loadFormData();
   
    if( this.applicantId != null){
      this.getApplicantDetails(this.applicantId);
      this.loadCreditCompletionRate();
    }
  }

  loadFormData(){

    this.applicantForm = this._formBuilder.group({
      tradeName: ['',Validators.required],
      legalName:['',Validators.required],
      tollFree: [],
      telePhone: ['',[Validators.required,  Validators.pattern(this.PHONE_REGEXP)]],
      uuid:[this.applicantId]
    });

    this.addressForm = this._formBuilder.group({
      zipCode: [this.applicant.contactAddress.zipCode, Validators.required],
      city: {value: this.applicant.contactAddress.city, disabled: true},
      state: [{value: this.applicant.contactAddress.state, disabled: true}],
      email:['',[Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      fax: [],
      country: [{value: this.applicant.contactAddress.country, disabled: true}],
      addrLine1:['', Validators.required],
      addrLine2:[],
      addressId:[]
    });

  }

  get f() { return this.applicantForm.controls; }
  get f1() { return this.addressForm.controls; }

  loadZipCodeData(event) {
      
    this.isPincodeReleased = true;
    let pinCode =  event.srcElement.value;//formData["zipCode"];

    if( pinCode == "" || pinCode.length < 5) {
      this.addressForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 

      this.applicant.contactAddress.city    = value["data"]["city"];
      this.applicant.contactAddress.state   = value["data"]["state"];
      this.applicant.contactAddress.country = value["data"]["country"];

      this.addressForm.patchValue  ({
         'zipCode': pinCode,
         'city': this.applicant.contactAddress.city,
         'state': this.applicant.contactAddress.state,
         'country': this.applicant.contactAddress.country,
       });

       //// console.log(this.applicant.contactAddress);

    });

    promise_.catch(error => { // on error and other than http code:200
      this.addressForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
    });

  }

  loadCreditCompletionRate() {

    if( this.route.snapshot.paramMap.get('completion-rate') != null) {

      this.quoteCompletionRate =  this.route.snapshot.paramMap.get('completion-rate');

      if( this.quoteCompletionRate != null && this.applicantId != null ) {

        if( this.quoteCompletionRate == '1'){
          this.router.navigateByUrl("shipcarte/credit/applicant/billing/info;applicant-id="  + this.applicantId);

        } else if( this.quoteCompletionRate == '2'){
          this.router.navigateByUrl("shipcarte/credit/applicant/payment/info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '3'){
          this.router.navigateByUrl("shipcarte/credit/applicant/company/info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '4'){
          this.router.navigateByUrl("shipcarte/credit/applicant/bank/info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '5'){
          this.router.navigateByUrl("shipcarte/credit/applicant/upload/document;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '6'){
          this.router.navigateByUrl("shipcarte/credit/applicant/reference/info;applicant-id=" + this.applicantId);

        } else if( this.quoteCompletionRate == '7'){
          this.router.navigateByUrl("shipcarte/credit/applicant/reference/info;applicant-id=" + this.applicantId);

        }
      }
    }
  }
  saveApplicant () {

    let form_data = this.applicantForm.getRawValue();
    this.applicant = form_data;
   
      this.applicant.contactAddress =  this.addressForm.getRawValue();

      if(  this.address != null ){
          this.applicant.contactAddress.addressId = this.applicantDetail.contactAddrId;
      }

      let authPromise = this.scHttpService.postHttpService(ServerURLS.saveCreditAppInfo.action, this.applicant);
        
        authPromise.then( (resolve) => {
          let response = resolve;
          this.errMsg = '';
          this.successMsg = response["message"];
          this.applicantId = response['data'];

          let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
          this.router.navigateByUrl("shipcarte/credit/applicant/billing/info" + params);
  
        }).catch((error) => {
          this.errMsg = error.error;
          this.successMsg = '';
        });
   
    }

    getApplicantDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantDetails.action +"?id=" +id,'');
      promise1.then( value => {
        this.applicantDetail = value["data"];
        this.applicantForm.patchValue (this.applicantDetail);
        if(this.applicantDetail != null){
          this.getApplicantAddressDetails(this.applicantDetail.contactAddrId);
        }
      });
    }

    getApplicantAddressDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action +"?id=" +id,'');
      promise1.then( value => {
        this.address = value["data"];
        this.addressForm.patchValue (this.address);
      });
    }
}
