import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  @ViewChild('loadContent', { static: true }) loadConten;
  dropdownFilder: boolean = false;
  selectPartnerType: number = 1;
  list: any[];
  displayData = true;
  page = 1;
  partnerList: any;
  fetchPartnerListRes: any;
  searchKey: string = '';
  statusKey: string = '';
  sortBy: any;
  isDesc: boolean;
  dropDownFilterListOriginal: any = [
    { id: 1, value: 'A/P Code (CDN)' },
    { id: 2, value: 'A/P Code (U.S)' },
    { id: 3, value: 'Address' },
    { id: 4, value: 'Cargo Insurance' },
    { id: 5, value: 'Carrier Name' },
    { id: 5, value: 'City' },
    { id: 6, value: "Contact" },
    { id: 7, value: "DOT Number" },
    { id: 8, value: 'Email' },
    { id: 9, value: "Extension" },
    { id: 10, value: "Fuel Surcharges" },
    { id: 11, value: "Insurance Broker" },
    { id: 12, value: "Insurance Broker" },
    { id: 13, value: "Insurance Broker" },
    { id: 14, value: "Insurance Broker" },
    { id: 15, value: "Insurance Broker" },
    { id: 16, value: "Insurance Broker" },
    { id: 17, value: "Insurance Broker" },
    { id: 18, value: "Insurance Broker" },
  ];
  dropDownFilterList: any = [];
  isDataReady = false;
  isViewingPartnerGeneralDetail: boolean = false;
  constructor(
    private commonService: ScCommonService,
    private router: Router,
    private httpService: ScHttpService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private listDataService: ListDataServiceService) {

  }

  ngOnInit() {
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfQuoteHistory()
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList()
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();

    this.sortBy = {
      "carrierName": "up",
      "phoneNumber": 'down',
    }
    this.dropDownFilterList = this.dropDownFilterListOriginal;
    this.checkDataIsPresentInServiceFile();
  }
  checkDataIsPresentInServiceFile() {
    this.partnerList = []
    let dataFromServiceFile = this.listDataService.getAllDataOfPartnerList();
    // console.log(dataFromServiceFile)
    if (dataFromServiceFile.tabId > 0) {
      this.selectPartnerType = dataFromServiceFile.tabId;
      if (dataFromServiceFile.tabId == 3) {
        this.statusKey = 'Active'
      } else if (dataFromServiceFile.tabId == 4) {
        this.statusKey = 'Inactive'
      } else {
        this.statusKey = ''
      }
    }
    if (dataFromServiceFile.tabId == 1) {
      if (dataFromServiceFile.allOrder.dataList.length > 0) {
        this.defaultPageNumber = dataFromServiceFile.allOrder.pageCount;
        this.defaultListLength = dataFromServiceFile.allOrder.pageSize
        this.partnerList = dataFromServiceFile.allOrder.dataList
        if (dataFromServiceFile.allOrder.searchkey != '') {
          this.searchKey = dataFromServiceFile.allOrder.searchkey
        }
        if (dataFromServiceFile.allOrder.selectedId != '') {
          this.scrollToViewMethod(dataFromServiceFile.allOrder.selectedId)
        }
      } else {
        this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
      }
      if(dataFromServiceFile.allOrder.sortParam==''){
        this.sortParam='carrierName';
        this.sortBy['carrierName']='up'
        this.sortBy['phoneNumber']='down'
        this.isAscending=true
      }else{
        this.sortParam=dataFromServiceFile.allOrder.sortParam;
        this.getAllKeysArray().forEach((element,index)=>{
          if(element==dataFromServiceFile.allOrder.sortParam){
            this.sortBy[dataFromServiceFile.allOrder.sortParam]=dataFromServiceFile.allOrder.isAscending?'up':'down'
          }else{
            this.sortBy[element]='down'
          }
        })
        this.sortBy[dataFromServiceFile.allOrder.sortParam]=dataFromServiceFile.allOrder.isAscending?'up':'down'
        this.isAscending=dataFromServiceFile.allOrder.isAscending
      }
    } else if (dataFromServiceFile.tabId == 3) {
      if (dataFromServiceFile.activeOrders.dataList.length > 0) {
        this.defaultPageNumber = dataFromServiceFile.activeOrders.pageCount;
        this.defaultListLength = dataFromServiceFile.activeOrders.pageSize
        this.partnerList = dataFromServiceFile.activeOrders.dataList
        if (dataFromServiceFile.activeOrders.searchkey != '') {
          this.searchKey = dataFromServiceFile.activeOrders.searchkey
        }
        if (dataFromServiceFile.activeOrders.selectedId != '') {
          this.scrollToViewMethod(dataFromServiceFile.activeOrders.selectedId)
        }
      } else {
        this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
      }
      if(dataFromServiceFile.activeOrders.sortParam==''){
        this.sortParam='carrierName';
        this.sortBy['carrierName']='up'
        this.sortBy['phoneNumber']='down'
        this.isAscending=true
      }else{
        this.sortParam=dataFromServiceFile.activeOrders.sortParam;
        this.sortBy[dataFromServiceFile.activeOrders.sortParam]=dataFromServiceFile.activeOrders.isAscending?'up':'down'
        this.isAscending=dataFromServiceFile.activeOrders.isAscending
      }
    } else if (dataFromServiceFile.tabId == 4) {
      if (dataFromServiceFile.inActiveOrder.dataList.length > 0) {
        this.defaultPageNumber = dataFromServiceFile.inActiveOrder.pageCount;
        this.defaultListLength = dataFromServiceFile.inActiveOrder.pageSize
        this.partnerList = dataFromServiceFile.inActiveOrder.dataList
        if (dataFromServiceFile.inActiveOrder.searchkey != '') {
          this.searchKey = dataFromServiceFile.inActiveOrder.searchkey
        }
        if (dataFromServiceFile.inActiveOrder.selectedId != '') {
          this.scrollToViewMethod(dataFromServiceFile.inActiveOrder.selectedId)
        }
      } else {
        this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
      }
      if(dataFromServiceFile.inActiveOrder.sortParam==''){
        this.sortParam='carrierName';
        this.sortBy['carrierName']='up'
        this.sortBy['phoneNumber']='down'
        this.isAscending=true
      }else{
        this.sortParam=dataFromServiceFile.inActiveOrder.sortParam;
        this.sortBy[dataFromServiceFile.inActiveOrder.sortParam]=dataFromServiceFile.inActiveOrder.isAscending?'up':'down'
        this.isAscending=dataFromServiceFile.inActiveOrder.isAscending
      }
    }

  }
  scrollToViewMethod(selectedItemId: any) {
    setTimeout(() => {
      let element = document.getElementById(selectedItemId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 500)
  }

  loadModal(content: any) {
    // this.modalService.open(content,{centered:true,backdropClass:'light-blue-backdrop',windowClass:'dark-modal',backdrop:'static'});
  }
  isDataLoading: any = ''
  fetchPartnerList(pageNumber, listLength, searchQuery, statusKey) {
    this.allDataLoaded=false;
    this.isDataLoading = 'Loading Your Options, Please wait...'
    // this.loadModal(this.loadConten)
    let url = ServerURLS.getPartList.action + '/' + (pageNumber) + '/' + listLength+'?isAscending='+this.isAscending;
    if(this.sortParam!=''){
      url=url+'&sortParam='+this.sortParam
    }
    if (searchQuery != '' && statusKey == '') {
      url = url + "&query=" + searchQuery;
    } else if (searchQuery == '' && statusKey != '') {
      url = url + '&status=' + statusKey;
    } else if (searchQuery != '' && statusKey != '') {
      url = url + "&query=" + searchQuery + "&status=" + statusKey;
    }



    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isDataLoading = ''
      if (pageNumber == 0 && res.response.length == 0) {
        this.isDataLoading = 'No Data Found';
      }
      this.partnerList = res.response;
      this.partnerList.forEach((partnerData, index) => {
        if (partnerData.hasOwnProperty('carrier_name')) {
          if (partnerData.carrier_name == '') {
            this.partnerList[index].carrier_name = '--';
          }
        } else {
          this.partnerList[index].carrier_name = '--';
        }

        if (partnerData.hasOwnProperty('full_address')) {
          if (partnerData.full_address == '') {
            this.partnerList[index].full_address = '--';
          }
        } else {
          this.partnerList[index].full_address = '--';
        }

        if (partnerData.hasOwnProperty('contact_name')) {
          if (partnerData.contact_name == '') {
            this.partnerList[index].contact_name = '--';
          }
        } else {
          this.partnerList[index].contact_name = '--';
        }

        if (partnerData.hasOwnProperty('email_address')) {
          if (partnerData.email_address == '') {
            this.partnerList[index].email_address = '--';
          }
        } else {
          this.partnerList[index].email_address = '--';
        }

        if (partnerData.hasOwnProperty('phone_number')) {
          if (partnerData.phone_number == '') {
            this.partnerList[index].phone_number = '--';
          }
        } else {
          this.partnerList[index].phone_number = '--';
        }

        if (partnerData.hasOwnProperty('status')) {
          if (partnerData.status == '') {
            this.partnerList[index].status = '--';
          }
        } else {
          this.partnerList[index].status = '--';
        }
      });
      let data = {
        dataList: this.partnerList,
        pageCount: pageNumber,
        pageSize: listLength
      }

      this.listDataService.setAllDataOfPartnerMainList(data, this.selectPartnerType);


    }).catch((err) => {
      this.isDataLoading = 'Something Went Wrong! Please Try Again Later'
    })

  }

  replaceDoubleCommasWithSpace(inputString: string): string {   return inputString.replace(/,+/g, ', '); }

  searchButtonApplyFilter(value: any) {
    this.searchKey = value;
    this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
  }

  closeDropDown() {
    this.dropdownFilder = false;
  }

  openDropDown() {
    if (this.dropdownFilder) {
      this.dropdownFilder = false;
    } else {
      this.dropdownFilder = true;
    }
  }

  addCustomerRoute() {
    // sessionStorage.removeItem('customerId')
    this.commonService.customerId = null;
    this.router.navigateByUrl(`shipcarte/partner/generalDetials`)
  }

  partners = [
    { id: 1, partnerType: 'Partner List' },

    { id: 3, partnerType: 'Active Partner' },
    { id: 4, partnerType: 'Inactive Partner' }
  ]
  selectedPartnerType(partnerId) {
    this.defaultPageNumber = 0
    this.listDataService.setTabIdOfPartnerList(partnerId)
    this.allDataLoaded=false;
    this.selectPartnerType = partnerId;
    this.sortBy['carrierName']='up'
    this.sortBy['phoneNumber']='down'
    this.sortParam='carrierName'
    if (partnerId == 1) {
      this.searchKey = '';
      this.statusKey = '';
      this.checkDataIsPresentInServiceFile()
    }
    else if (partnerId == 3) {
      this.searchKey = '';
      this.statusKey = 'Active'
      this.checkDataIsPresentInServiceFile()
    } else if (partnerId == 4) {
      this.statusKey = 'Inactive'
      this.searchKey = '';
      this.checkDataIsPresentInServiceFile()
    }
  }

  setFilterValue(filterValue) {
    this.searchKey = filterValue;
  }
  getAllKeysArray(){
    return Object.keys(this.sortBy)
  }
  isAscending:boolean=true;
  sortParam:any='carrierName'
  sort(property: any) {

    this.getAllKeysArray().forEach((element,index)=>{
      if(element!=property){
        this.sortBy[element]='dowm'
      }
    })
    if(this.sortBy[property]=='up'){
      this.sortBy[property]='down';
      this.isAscending=false;
      this.sortParam=property
    }else{
      this.sortBy[property]='up';
      this.isAscending=true;
      this.sortParam=property
    }
    this.listDataService.setDataOfSortValueInPartnerList(this.isAscending,this.sortParam,this.selectPartnerType)
    this.fetchPartnerList(0,(this.defaultPageNumber+1)*20,this.searchKey,this.statusKey)
    // this.isDesc = !this.isDesc; //change the direction
    // let direction = this.isDesc ? 1 : -1;
    // this.sortBy[property] = !this.isDesc ? "down" : "up";

    // this.partnerList.sort(function (a, b) {
    //   if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //     return -1 * direction;
    //   }
    //   else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //     return 1 * direction;
    //   }
    //   else {
    //     return 0;
    //   }
    // });
  }

  searchCustomerValue(value: any) {
    this.searchKey = value
  }
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (searchParam.length >= 3) {
        this.listDataService.setSearchKeyOfPartnerList(searchParam, this.selectPartnerType);
        this.defaultPageNumber = 0
        this.searchButtonApplyFilter(searchParam);
        this.listDataService.setSearchKeyOfPartnerList('', this.selectPartnerType);
      } else if (searchParam.length === 0) {
        this.defaultPageNumber = 0
        this.searchButtonApplyFilter(searchParam);
      }
    }, 500)

  }

  inputFilterValue(dataFind: any) {


    if (dataFind.length > 0) {

      let arr = [];
      this.dropDownFilterListOriginal.map((data) => {

        if (data.value.toLowerCase().includes(dataFind.toLowerCase())) {
          arr.push(data)
        }
      })
      this.dropDownFilterList = arr;
    }
    else {
      this.dropDownFilterList = this.dropDownFilterListOriginal;
    }
  }
  ngOnDestroy() {
    if (!this.isViewingPartnerGeneralDetail) {
      this.listDataService.removeAllDataOfPartnerList()
    }
  }
  routeToGeneralDetails(id: any) {
    this.listDataService.setSelectedItemIdOfPartnerList(id, this.selectPartnerType);
    this.isViewingPartnerGeneralDetail = true;
    sessionStorage.setItem('partnerId', id)
    this.commonService.partnerId = id;
    this.router.navigateByUrl("shipcarte/partner/generalDetails/edit");
  }

  redirectToPartnerList() {
    this.isViewingPartnerGeneralDetail = true;

    sessionStorage.removeItem('partnerId');
    this.commonService.partnerId = null;
    this.router.navigateByUrl('shipcarte/partner/generalDetails')
  }

  defaultPageNumber: number = 0;
  defaultListLength: number = 20;
  totalPagePartnersList: number;
  paginationClick(prevNext: string) {
    if (prevNext == 'previous') {
      if (this.defaultPageNumber > 0) {
        this.defaultPageNumber -= 1;
        this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
      }
    }
    else if (prevNext == 'next') {
      if (this.defaultPageNumber < (this.totalPagePartnersList - 1)) {
        this.defaultPageNumber += 1;
        this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
      }
    }
  }

  onChangeListLength(newDefaultListLength) {
    this.defaultPageNumber = 0;
    this.defaultListLength = parseInt(newDefaultListLength);
    this.fetchPartnerList(this.defaultPageNumber, this.defaultListLength, this.searchKey, this.statusKey);
  }



  activeInactive(status, partnerId, index) {
    let url = `${ServerURLS.changePartnerStatus.action}?partnerId=${partnerId}&isActive=${status}`
    let promise = this.httpService.deleteHttpService(url, '');
    promise.then((res) => {
      this.toastr.success("Status Updated Successfully")
      if (status) {
        this.partnerList[index]['status'] = 'Active'
      } else {
        this.partnerList[index]['status'] = 'Inactive'
      }
      if (this.selectPartnerType == 1) {
        if (status) {
          //refresh active tab
          this.listDataService.refreshPartnerListDataAccordingToTabId(3)
        } else {
          //refresh inactive tab
          this.listDataService.refreshPartnerListDataAccordingToTabId(4)
        }
      } else if (this.selectPartnerType == 3) {
        //update data in allPartnerlist
        this.listDataService.updateStatusOfPartnerActiveInActive(status ? 'Active' : 'Inactive', partnerId)
        //refresth inactive tab
        this.listDataService.refreshPartnerListDataAccordingToTabId(4)
        //refreshCurrentListOfPartnerList
        this.partnerList.splice(index, 1)
      } else if (this.selectPartnerType == 4) {
        //update data in allpartnerlist
        this.listDataService.updateStatusOfPartnerActiveInActive(status ? 'Active' : 'Inactive', partnerId)
        //refresh active tab
        this.listDataService.refreshPartnerListDataAccordingToTabId(3)
        //refreshCurrentListOfPartnerList
        this.partnerList.splice(index, 1)
      }

    }).catch((err) => {
      // console.log(err)
      this.toastr.error("Something Wrong!")
    })
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerOfQuoteHistory(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerPartnerList(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      // console.log(window.innerHeight + window.scrollY, document.body.offsetHeight)
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {


        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.defaultPageNumber = this.defaultPageNumber + 1
    let url = ServerURLS.getPartList.action + '/' + this.defaultPageNumber + '/' + this.defaultListLength+'?isAscending='+this.isAscending;
    if(this.sortParam!=''){
      url=url+'&sortParam='+this.sortParam
    }
    if (this.searchKey != '' && this.statusKey == '') {
      url = url + "&query=" + this.searchKey;
    } else if (this.searchKey == '' && this.statusKey != '') {
      url = url + '&status=' + this.statusKey;
    } else if (this.searchKey != '' && this.statusKey != '') {
      url = url + "&query=" + this.searchKey + "&status=" + this.statusKey;
    }



    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.isLoading = false
      if (res.response.length > 0) {
        let dataList = res.response;

        dataList.forEach((partnerData, index) => {
          if (partnerData.hasOwnProperty('carrier_name')) {
            if (partnerData.carrier_name == '') {
              dataList[index].carrier_name = '--';
            }
          } else {
            dataList[index].carrier_name = '--';
          }

          if (partnerData.hasOwnProperty('full_address')) {
            if (partnerData.full_address == '') {
              dataList[index].full_address = '--';
            }
          } else {
            dataList[index].full_address = '--';
          }

          if (partnerData.hasOwnProperty('contact_name')) {
            if (partnerData.contact_name == '') {
              dataList[index].contact_name = '--';
            }
          } else {
            dataList[index].contact_name = '--';
          }

          if (partnerData.hasOwnProperty('email_address')) {
            if (partnerData.email_address == '') {
              dataList[index].email_address = '--';
            }
          } else {
            dataList[index].email_address = '--';
          }

          if (partnerData.hasOwnProperty('phone_number')) {
            if (partnerData.phone_number == '') {
              dataList[index].phone_number = '--';
            }
          } else {
            dataList[index].phone_number = '--';
          }

          if (partnerData.hasOwnProperty('status')) {
            if (partnerData.status == '') {
              dataList[index].status = '--';
            }
          } else {
            dataList[index].status = '--';
          }
        });
        this.partnerList = this.partnerList.concat(dataList);
        let data = {
          pageCount: this.defaultPageNumber,
          pageSize: this.defaultListLength,
          dataList: this.partnerList
        }
        this.listDataService.setAllDataOfPartnerMainList(data, this.selectPartnerType)
      } else {
        this.allDataLoaded = true;
      }



    }).catch((err) => {
      this.isLoading=false;

    })
  }

}
