import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { QuickActionsModule } from './modules/quick-actions/quick-actions.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModulesModule } from './modules/modules.module';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ScHttpService } from './services/http/sc-http.service';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScAuthService } from './services/auth/sc-auth.service';
import { StorageServiceModule } from 'angular-webstorage-service';
import { ScStorageService } from './services/storage/sc-storage.service';
import { AuthService, SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { HttpRequestInterceptor } from './services/http/HttpRequestInterceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScFileExporterService } from './services/export/sc-file-exporter.service';
import { ScCommonService } from './services/common/sc-common.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { CommonsModule } from './modules/commons/commons.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CalculateDistanceComponent } from './modules/quick-actions/calculate-distance/calculate-distance.component';
import { ZipPostalCodeComponent } from './modules/quick-actions/zip-postal-code/zip-postal-code.component';
import { DimensionalWeightCalculatorComponent } from './modules/quick-actions/dimensional-weight-calculator/dimensional-weight-calculator.component';
import { FreightClassCalculatorComponent } from './modules/quick-actions/freight-class-calculator/freight-class-calculator.component';
import { FavouriteOrderComponent } from './modules/quick-actions/favourite-order/favourite-order.component'; 
// import { CreateEditViewComponent } from './modules/quick-actions/quickAction-Bol/create-edit-view/create-edit-view.component';
import { ListComponent } from './modules/quick-actions/quickAction-Bol/list/list.component';
import { CreateNewCommInvoiceComponent } from './modules/quick-actions/create-new-comm-invoice/create-new-comm-invoice.component';

// import { AngularSvgIconModule } from 'angular-svg-icon';

export function socialConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('app -id')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('100154224989-s04fma668s316kfl3aolelev2v1l8t5e.apps.googleusercontent.com')
      }
    ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    FavouriteOrderComponent,
    CreateNewCommInvoiceComponent,
 
   // CreateEditViewComponent,
    ListComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    // QuickActionsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    StorageServiceModule,
    ReactiveFormsModule,
    // App specific Modules
    ModulesModule,
    CommonsModule,
    GooglePlaceModule,
    NgSelectModule,
    // Importing Social Login Module
    SocialLoginModule, /*AngularSvgIconModule.forRoot()*/
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  entryComponents: [
    CalculateDistanceComponent,
    ZipPostalCodeComponent,
    DimensionalWeightCalculatorComponent,
    FreightClassCalculatorComponent
  ],
  providers: [

     // Start - Required for Social Plugin SignIn
     AuthService,
     AuthGuardService,
     {
       provide: AuthServiceConfig,
       useFactory: socialConfigs
     },
     // End - Required for Social Plugin SignIn

    {provide: LocationStrategy, useClass: HashLocationStrategy},

    // App specific Services
    ScHttpService,
    ScAuthService,
    ScStorageService,
    ScFileExporterService,
    ScCommonService,

      { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
      DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }