import { Component, OnInit, Input } from '@angular/core';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { EnvironmentConstants } from 'src/environments/environment';


@Component({
  selector: 'app-carrier-graph',
  templateUrl: './carrier-graph.component.html',
  styleUrls: ['./carrier-graph.component.scss']
})
export class CarrierGraphComponent implements OnInit {
 
  carriergraph: { name: string; value: string; }[];
  interval: any;
  //graphType: any;
  customDate: any;

  isDatepicker: boolean = false;
  selected: any;
  graph: string;

  @Input("action") action: any;
  @Input("graphType") graphType: any;

  constructor( private _commonServices: ScCommonService ) { }

  ngOnInit() {

    this.carriergraph = [
      {name: 'Last 1 month', value: "1"},
      {name: 'Last quarter', value: "3"},
      {name: 'Last 6 months', value: "6"},
      {name: 'Last 1 year', value: "12"},
      {name: 'Custom date', value: "Custom date"}
    ];

    let months = 12;
    this.setCarrierGraph(months);
  }

  onDateSelect ( event ) {
    this.isDatepicker = true;
    let date = this._commonServices.formatDate(event);
    this.interval = date;
    this.generateGraph();
  }

  setCarrierGraph(value){

    let data = value;
    this.selected = data;
    
    if(data == "Custom date"){
      this.isDatepicker = true;
    } else{
      this.isDatepicker = false;
    }
    this.interval = data;

    this.generateGraph();
  }

  generateGraph(){
    this.graph = EnvironmentConstants.DOMAINPATH + this.action + "?interval=" + this.interval + "&graph-type=" + this.graphType;
  }
}
