import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-quote-carrier-graph',
  templateUrl: './quote-carrier-graph.component.html',
  styleUrls: ['./quote-carrier-graph.component.scss']
})
export class QuoteCarrierGraphComponent implements OnInit {
  
  constructor( private scHttpService: ScHttpService ) { }

  ngOnInit() {

  }

}
