import { Component, Input, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-pod',
  templateUrl: './upload-pod.component.html',
  styleUrls: ['./upload-pod.component.scss']
})
export class UploadPodComponent implements OnInit {
  @Input() uploadOrderId;
  @ViewChild('fileInput',undefined) fileInput: ElementRef;
  constructor( private httpService: ScHttpService,private http: HttpClient,public activeModal: NgbActiveModal,private toastService: ToastrService) { }

  ngOnInit() {
  }

  upload() {
    const fileInput: HTMLInputElement = this.fileInput.nativeElement;

    if (fileInput.files && fileInput.files.length > 0) {
        const file: File = fileInput.files[0];

        // Create a FormData object to append the file
        const formData: FormData = new FormData();
        formData.append('orderId', this.uploadOrderId);
        formData.append('podDocumentFile', file);

        let url = ServerURLS.uploadPodDoc.action;

        // Make the HTTP request with FormData and headers
        const headers = new HttpHeaders(); 
        this.http.post(url, formData, { headers }).subscribe(
            (response: any) => {
                // Handle the response if needed
                this.toastService.success('File uploaded successfully ')
                this.activeModal.close();
            },
            (error: any) => {
                // Handle the error if needed
                this.toastService.error('Document is already present')
                console.error(error);
            }
        );
    } else {
        this.toastService.error('No file selected ')
    }
    
}
}
