import { Component, OnInit } from '@angular/core';
import { ServerURLS } from '../../../../environments/environment';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Permission } from '../../commons/pojo/permission';

@Component({
  selector: 'app-quote-order-list',
  templateUrl: './quote-order-list.component.html',
  styleUrls: ['./quote-order-list.component.scss']
})
export class QuoteOrderListComponent implements OnInit {

  quotes:any;

  sourceAddress:any;
  destAddress:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;

  searchParam: string;
  carrierId: any;
  errorMsg:string;
  successMsg:string;
  orderNum: string;
  getTraceOrder: any;
  permission:Permission;

  constructor(private scHttpService: ScHttpService, private route: ActivatedRoute,private router: Router ) { 

     this.permission = new Permission();
     this.permission.read = true;
     this.permission.write = true;
  }

  ngOnInit() {

    this.carrierId = this.route.snapshot.paramMap.get('carrier');
    this.orderNum = this.route.snapshot.paramMap.get('order-num');

    this.loadQuoteOrderList();
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadQuoteOrderList();
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  search(key){
    this.searchParam = key;
    this.loadQuoteOrderList();
  }

  loadQuoteOrderList(): void {

    let url = ServerURLS.getQuoteOrders.action + "/" + ( this.page - 1 ) + "/" + this.pageSize + "?";

    if(this.searchParam){
      url += "&searchKey=" + this.searchParam;
    }
    
    if(this.carrierId){
      url += "&carrierId=" + this.carrierId;
    }

    if(this.orderNum){
      url += "&orderNum=" + this.orderNum;
    }

    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.quotes = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;

      this.quotes.forEach(element => {
        this.getSourceAddress( element );
        this.getDestAddress( element );
      });

    });

  }

  getSourceAddress ( element ): void {

    let url =  ServerURLS.getAddressById.action + "?id=" + element["sourceAddr"];

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      element ['source_addr'] = value["data"];
      
    });

  }

  getDestAddress ( element ): void {

    let url =  ServerURLS.getAddressById.action + "?id=" + element["destAddr"];

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.destAddress = value["data"];
      element ['destination_addr'] = value["data"];
     
    });

  }

  repeatOrder (quoteId) {
    let authPromise = this.scHttpService.postHttpService(ServerURLS.repeatOrder.action+"?quoteId="+quoteId,'')
     
      authPromise.then( (resolve) => {
        let response = resolve;
        this.successMsg = response["message"];
        if(  this.successMsg  ){
          let id = response['data'];
          this.router.navigateByUrl("shipcarte/quote/results;quote-id=" + id);
        }
        
      }).catch((error) => {
        this.errorMsg = error.message;
      });
  }

  returnOrder (quoteId, orderId) {
    let authPromise = this.scHttpService.postHttpService(ServerURLS.repeatOrder.action+"?quoteId="+quoteId+"&order-id="+orderId,'')
     
      authPromise.then( (resolve) => {
        let response = resolve;
        this.successMsg = response["message"];
        if(  this.successMsg  ){
          let id = response['data'];
          this.router.navigateByUrl("shipcarte/quote/results;quote-id=" + id);
        }
        
      }).catch((error) => {
        this.errorMsg = error.message;
      });
  }

  orderView(id,  carrierServiceName){
    
    this.traceOrder(id, carrierServiceName);
  }

  traceOrder(id, subCarrierName): void {

   let promise1 = null;
   promise1 = this.scHttpService.getHttpService( ServerURLS.getTraceOrder.action + "?order-id=" + id +"&carrier-service=" +subCarrierName,'');
    promise1.then( value => {
      this.getTraceOrder = value["data"];
      this.router.navigateByUrl("shipcarte/order/view;order-id=" + id);
    }).catch((error) => {
    
    });
  }
}
