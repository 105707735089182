import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, StorageKeys } from '../../../environments/environment';
import { ServerURLS } from 'src/environments/environment.prod';
import 'rxjs/add/operator/catch'; 
import 'rxjs/add/operator/map'
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';






@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl:any;
  myheader = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private httpClient: HttpClient) { }

  getCountryList(){
    this.baseUrl = "http://52.5.190.96:9090/shipcarte/api/country";
    return this.httpClient.get(this.baseUrl);
  }

  paymentCheckout(paymentData){
 
    // return this.httpClient.post(ServerURLS.paymentCheckoutCharge,'')
    // return this.httpClient.post(ServerURLS.paymentCheckoutCharge,paymentData ,{ withCredentials:true,
    //   headers: this.myheader
    //   }).pipe(map(response => {
    //   return response;
    // })); 

    return this.httpClient.post(ServerURLS.paymentCheckoutCharge,paymentData ,{ withCredentials:true,
      headers: this.myheader
      }).pipe(map(response => {
      return response;
      }),
      catchError( error =>{
        return throwError(error);
      })
      );
  }

  paymentOrder(paymentOrder){
    // return this.httpClient.post(ServerURLS.paymentOrder,'')
    return this.httpClient.post(ServerURLS.paymentOrder,paymentOrder ,{
      headers: this.myheader
      }).pipe(map(response => {
      return response;
    }),
    catchError( error =>{
      return throwError(error);
    })
    );  
  }

  


}
