import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

  addAddrForm: FormGroup;
  errorMessage: string;
  successMsg: string;
  zipCode:any;

  @Output() getAddrList = new EventEmitter();
  showAddrList: Boolean;
  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor(private scHttpService: ScHttpService, private modalService: NgbModal,private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loadFormData();
  }

  loadFormData(){
    this.addAddrForm = this._formBuilder.group({
      firstName:['',Validators.required],
      lastName:[],
      company:['',Validators.required],
      department:[],
      phoneNumber:['',Validators.required],
      altphone:[,Validators.pattern(this.PHONE_REGEXP)],
      email:[,[Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      addrLine1:['',Validators.required],
      addrLine2:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required], 
      country:['',Validators.required],    
      zipCode:['',Validators.required]
   });
  }

  get f() { return this.addAddrForm.controls; }

  saveaddress() {
    
    let form_data = this.addAddrForm.getRawValue();
    
    let promise = this.scHttpService.postHttpService(ServerURLS.saveAddress.action+ "?address-book=true", form_data);
    promise.then((response) => {

      this.errorMessage = "";
      if (response) {
        this.successMsg = response['message'];
        this.showAddrList = true;
        this.getAddrList.emit(this.showAddrList);
        this.closeModal();
      }

    }).catch((error) => {
      this.errorMessage = error.message;
    });
  }

  loadZipCodeData(event) {

    // this.isPincodeReleased = true;
    this.zipCode = event.srcElement.value;//formData["zipCode"];
  
    if (this.zipCode == "" || this.zipCode.length < 5) {
      this.addAddrForm.patchValue({
        'zipCode': '',
        'city': '',
        'state': '',
        'country': '',
      });
      return;
    }
  
    let url = ServerURLS.geoLocationDetails.action + "/" + this.zipCode;
  
    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => {
  
      this.addAddrForm.patchValue({
        'zipCode': this.zipCode,
        'city': value["data"]["city"],
        'state': value["data"]["state"],
        'country': value["data"]["country"],
      });
  
    });
  
    promise_.catch(error => { // on error and other than http code:200
      //this.errMsg =  error['message'];
      this.addAddrForm.patchValue({
        'zipCode': '',
        'city': '',
        'state': '',
        'country': '',
      });
    });
  } 
  closeModal() {
    this.modalService.dismissAll();
  }
}
