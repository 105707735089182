import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './modules/commons/welcome/welcome.component';
import { SignInComponent } from './modules/autn/sign-in/sign-in.component';
import { FavouriteOrderComponent } from './modules/quick-actions/favourite-order/favourite-order.component';
import { CreateEditViewComponent } from './modules/quick-actions/quickAction-Bol/create-edit-view/create-edit-view.component';
import { ListComponent } from './modules/quick-actions/quickAction-Bol/list/list.component';
import { CreateNewCommInvoiceComponent } from './modules/quick-actions/create-new-comm-invoice/create-new-comm-invoice.component';
const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/signin', component: SignInComponent},
  { path: 'favorite', component: FavouriteOrderComponent },
  { path: 'quickBol/bol-list', component: ListComponent },
  { path: 'quickBol/create-bol', component: CreateEditViewComponent},
  { path: 'quickBol/edit-bol/:extBolId', component: CreateEditViewComponent},
  { path: 'quickBol/view-bol/summary/:extBolId', component: CreateEditViewComponent},
  { path: 'quickBol/view-bol/documents/:extBolId', component: CreateEditViewComponent},
  { path: 'commercialInvoice', component: CreateNewCommInvoiceComponent},
  { path: 'commercialInvoice/edit', component:CreateNewCommInvoiceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
