import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddItemComponent } from '../add-item/add-item.component';
import { debug } from 'util';
import { SCToastService } from 'src/app/services/ngb/sctoast.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  @Output() dataEvent = new EventEmitter();
  @Input("pageType") pageType: string;
  isResultPage: boolean;
  pageNo: number;
  pageSize: number;
  quoteId: string;
  quoteItems: any;
  itemFormArray: any;
  showForm: boolean;successMsg: string;
  errMsg: any;
  freightMap :Map<any, any>
  packagingTypeMap: Map<any, any>
  selectedItemIds: any ;
  processing: boolean;
  autohide :any;
  showMessage: any;
  shipmentName :string;
  limitAddItem :boolean;
  quote:any;
  shipmentModeName: Map<any, any>;

  quoteDeliveryForm: FormGroup;
  startIndex: number;
  endIndex: any;
  pickupTimes: any;
  rankMap: Map<any, any>;
  timeMap: Map<any, any>;
  minDate: any; 

  @Output() response = new EventEmitter();

  //itemDescr:string;
  //costValue:string;

  constructor(private router: Router, private modalService: NgbModal, private storageService: ScStorageService, private _formBuilder: FormBuilder,
    private scHttpService: ScHttpService, private route: ActivatedRoute, public toastService: SCToastService, private _commonServices: ScCommonService ) {
    
     this.itemFormArray = [];
     this.freightMap = new Map();
     this.packagingTypeMap = new  Map();
     this.quoteItems = [];
     this.selectedItemIds= [];
     this.shipmentModeName = new Map();

     this.rankMap = new Map();
     this.timeMap = new Map();

   }

  ngOnInit() {

    this.quoteId =  this.route.snapshot.paramMap.get('quote-id');
    this.isResultPage = false;
    
    this.packageTypeList();
    this.freightTypeList();
    this.getTimes();
     
    let storage = this.storageService.getBucket("items");

    if( this.quoteId && !storage){
      this.loadQuoteItems();

    } else {
      this.itemFormArray = storage;
      this.response.emit( {size: this.itemFormArray.length, value: this.itemFormArray});
    }
    this.quote = this.storageService.getBucket("quote");

    if(this.quote){
      this.shpmtModeList();
    }
   
    this.loadQuoteDeliveryFormData();

    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

    if(this.quoteId){
      this.getQuoteDeliveryDetails();
    }

  }

  loadQuoteDeliveryFormData(){

    this.quoteDeliveryForm = this._formBuilder.group({

      pickupDate1:['',Validators.required],
      pickupDate:[''],
      pickupFromTime:[],
      pickupToTime:[],
      quoteId: [this.quoteId ? this.quoteId : null],
      deliveryId: []
     
   });
  }

  get f() { return this.quoteDeliveryForm.controls; }

  updateForm( e ) {
    this.itemFormArray = e;
  }

  loadQuoteItems(): void {

    this.pageNo = 0;
    this.pageSize = 20;

    let url = ServerURLS.getQuoteItems.action + "/" + this.pageNo + "/" + this.pageSize;
    url += "?quoteId=" + this.quoteId;

    let promise1 = this.scHttpService.getHttpService( url, '');
    promise1.then( value => {
      this.itemFormArray = value["data"]["content"];
      this.storageService.saveBucket("items", this.itemFormArray );
      this.response.emit({size: this.itemFormArray.length, value: this.itemFormArray});
    });
    
  }

  modalItem:any;

  closeResult: any;
  open(content, item, index) {
   
    if(item == undefined){
      this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'add-item-component'});
      this.modalItem = undefined;
    } else {
      
      let selectedItem = new Object;
      Object.assign(selectedItem, item); // Copy by value or Deep Copy

      const modalRef = this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'add-item-component'});
      
      selectedItem["freightClass"] = selectedItem["freightClass"]; //this.freightMap.get(selectedItem["freightClass"]);
      selectedItem["index"] = index;
      this.modalItem = selectedItem;

      /*const modalRef = this.modalService.open(AddItemComponent, {size: 'xl', ariaLabelledBy: 'add-item-component'});
      modalRef.componentInstance.item = selectedItem;

      modalRef.result.then((result) => {
        debugger;
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });*/

    }
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  freightTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getFreightClassTypes.action,'');
    promise1.then( value => {

      let freightTypes = value["data"];
      for (let i = 0; i < freightTypes.length; i++) {
        let index = 0;
        if(i > 0){
          index = i - 1;
        }

        this.freightMap.set(freightTypes[i].freightId, freightTypes[i].freightType + "," + index);
      }
    });

  }

  packageTypeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getPackageTypes.action,'');
    promise1.then( value => {

      let packageType = value["data"];
      packageType.forEach(element => {
        this.packagingTypeMap.set(element.packageId, ( element.styleCode + " " + element.name) );
      });

    });
  }

  shpmtModeList (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtModeList.action, '');

    promise1.then( value => {

      let shpmtModes = value["data"];
      shpmtModes.forEach(element => {
        this.shipmentModeName.set(element.shpngModeId, element.shpngMode);
      });

      this.shipmentName = this.shipmentModeName.get(this.quote.shipmentMode);
      if( this.shipmentName == 'Courier' && this.itemFormArray.length == 1){
          this.limitAddItem = true;

      } else {
       this.limitAddItem = false;
     }

    });
  
  }

  saveQuote () {

    this.processing = true;
    
    let serviceCall: any;

    let data = this.storageService.getBucket("quote");
    data['items'] = this.itemFormArray;

    let delvryForm = this.quoteDeliveryForm.getRawValue();

    delvryForm['pickupFromTime'] = this.timeMap.get('00:00 AM');
    delvryForm['pickupToTime'] = this.timeMap.get('23:00 PM');
    data['delvryForm'] = delvryForm;
   
    if( this.quoteId ){
      data['quoteId'] = this.quoteId;
      serviceCall = this.scHttpService.putHttpService(ServerURLS.updateQuote.action, data);

    } else {
      serviceCall = this.scHttpService.postHttpService(ServerURLS.saveQuote.action, data);
    }
    
    let promise = serviceCall;
      
      promise.then( (resolve) => {
        let response = resolve;
        this.errMsg = '';
        this.successMsg = response["message"];
        this.quoteId = response['data'];
        this.processing = false;

        if(this.quoteId){
          this.removeLocStorage();
          this.router.navigateByUrl("shipcarte/quote/rates;quote-id=" + this.quoteId);
        }
        
      }).catch((error) => {
        
        this.processing = false;
        this.errMsg = 'Something went wrong, We appreciate you to contact administrator for this incident !!' + "<br/>" + error.message;
        this.toastService.showToast = true;
        //this.toastService.toasts.push("Something went wrong, We appreciate to contact administrator !! ");
        this.successMsg = '';
      });

    }

    showToast:any;

    cancel(){
      this.router.navigateByUrl("shipcarte/dashboard");
    }

    removeLocStorage(){
      this.storageService.removeBucket("quote");
      this.storageService.removeBucket("items");
      this.storageService.removeBucket("shipment");
      this.storageService.removeBucket("quoteService");
    }
  
    removeItem(index){
      
      if(this.itemFormArray && index > -1){
        this.itemFormArray.splice(index, 1);
      }

      this.storageService.saveBucket("items",  this.itemFormArray );
      this.dataEvent.emit( this.itemFormArray);
    }

    selectedItem(index,event){

      if ( event.target.checked ){
          this.selectedItemIds.push(index);
          
        } else {
          const i = this.selectedItemIds.indexOf(index);
          this.selectedItemIds.splice(i, 1)
        }

    }

    removeMultipleItems() {
      for ( let index = 0; index < this.selectedItemIds.length; index++ ) {
        let removeIndex = this.selectedItemIds[index] - index; // Each delete reduces the array sizes by 1, and selected index must be reduced to iteration#
        this.removeItem(removeIndex);
      }
      this.selectedItemIds = [];
    }

    updateItemDescrOrCost (id, descr, cost) {

      let params = "?item-id=" + id + "&description=" + descr + "&value-cost=" + cost;

      if( descr == null && cost == null ){
        this.errMsg = "Please fill the mandatory details"
        this.showMessage = id;

        this.response.emit({size: this.itemFormArray.length, value: this.itemFormArray});
        
      } else{

          let authPromise = this.scHttpService.putHttpService(ServerURLS.updateItemDesrCost.action + params, '');
            authPromise.then( (resolve) => {
              let response = resolve['data'];
              this.errMsg = '';
              this.successMsg = 'Changes saved successfully.'; //resolve['message'];
              this.showMessage = id;
              this.response.emit({size: this.itemFormArray.length, value: this.itemFormArray});

            }).catch((error) => {
              this.successMsg = '';
              this.errMsg = error.error;
              this.showMessage = id;
            });
      
        }

      }

      getTimes(): void {

        this.startIndex = 0;
        
        let promise1 = this.scHttpService.getHttpService( ServerURLS.getPickupTimes.action,'');
        promise1.then( value => {
          this.pickupTimes = value["data"];
          this.endIndex = this.pickupTimes.length;
    
          this.pickupTimes.forEach(element => {
            this.rankMap.set(element.pickupId, element.rankAssigned);
            this.timeMap.set(element.pickupTime, element.pickupId);
          });
         
        });
    
      }

      onDateSelect ( event ) {
        this.quoteDeliveryForm.get('pickupDate').setValue(this._commonServices.formatDate(event));
      }
    
      getFromTime(event){
    
        let startRank = this.rankMap.get(event.target.value);
        this.startIndex = startRank;
        this.endIndex = this.pickupTimes.length;
      
        let endTime = this.quoteDeliveryForm.get('pickupToTime').value;
        let endRank = this.rankMap.get(endTime);

        if( startRank >= endRank ) {
            this.quoteDeliveryForm.patchValue({'pickupToTime': ''});
          }
      }

      getQuoteDeliveryDetails(): void {

        let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteDeliveryDetails.action + "?quoteId=" + this.quoteId, '');
        promise1.then( value => {

          let deliveryData = value["data"];

          if(deliveryData){
            
            this.quoteDeliveryForm.get("pickupDate1").setValue(this._commonServices.formatNgBDate(deliveryData["pickupDate"]));
            this.quoteDeliveryForm.patchValue (value["data"]);
    
            //Rank based pickup to time set up
            let rank = this.rankMap.get(deliveryData["pickupFromTime"]);
            this.startIndex = rank;
            this.endIndex = this.pickupTimes.length;
          }
          
        });
    
      }

}
