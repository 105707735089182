export class QuoteDelvryForm{

    quoteId:string;
    pickupDate:string;
    pickupFromTime:string;
    pickupToTime: string;
    deliveryId:string;

    
    constructor ( quoteId, pickupDate, pickupFromTime, pickupToTime, deliveryId ) {
        this.quoteId  = quoteId;
        this.pickupDate = pickupDate;
        this.pickupFromTime  = pickupFromTime;
        this.pickupToTime    = pickupToTime;
        this.deliveryId = deliveryId;
    }
}