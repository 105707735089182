import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import SignaturePad from 'signature_pad';


@Component({
    selector: 'app-signature-popup',
    templateUrl: './signature-popup.component.html',
    styleUrls: ['./signature-popup.component.css']
})

export class SignaturePopUpComponent implements OnInit {
    @ViewChild('canvas', { static: true }) canvas: ElementRef;
    @Input() public signatureUrl: any;
    @Input() public mode: any;
    signatureError:any='';
    nameError:any='';
    formData:FormGroup;
    sig: any = '';
    name:any='';
    ownerOrAgentError:any='';
    ngDoCheck():void{
        if(this.sig.isEmpty()){
            this.signatureError='Signature is required*'
        }else{
            this.signatureError=''
        }
    }
    constructor(private ngbActiveMOdal: NgbActiveModal,private fb:FormBuilder) { }
    ngOnInit(): void {
         this.sig = new SignaturePad(this.canvas.nativeElement)
        
        this.formData=this.fb.group({
            name:[''],
            agent:[false],
            owner:[false]
        })
        if(this.mode==='edit'){
            this.formData.patchValue({
                name:this.signatureUrl.name,
            })
            if(this.signatureUrl.type==='Agent'){
                this.formData.patchValue({
                    agent:true
                })
            }
            if(this.signatureUrl.type==='Owner'){
                this.formData.patchValue({
                    owner:true
                })
            }
        }
    }
    checkOwnerandAgent(mode){
 
        if(mode==='agent'){
            this.formData.patchValue({
                owner:false
            })
        }else if(mode==='owner'){
            this.formData.patchValue({
                agent:false
            })
        }
        this.removeError()
    }

    removeError(){
         
        if(this.sig['_data'].length>0){
            this.signatureError=''
        }else{
            this.signatureError='Signature is required*'
        }
        if(this.formData.get('name').value){
            this.nameError='';
        }else{
            this.nameError='Name is required*'
        }
        if(this.formData.get('owner').value || this.formData.get('agent').value){
            this.ownerOrAgentError=''
        }else{
            this.ownerOrAgentError='Please Select at least one'
        }
    }
    cancelSignature() {
        if(this.mode==='edit'){
            let data={
                'type':this.formData.get('owner').value?'Owner':'Agent',
                'name':this.formData.get('name').value,
                'signatureValue':this.sig.toDataURL()
            }
            this.ngbActiveMOdal.close(data)
        }else{

            this.ngbActiveMOdal.close();
        }

    }
    closeModal(){
    }
    completedSignature() {
        // console.log(this.sig)
        if(this.sig['_data'].length===0){
            this.signatureError='Signature is Required*';
        }
        if(!this.formData.get('name').value){
            this.nameError='Name is required*'
        }
        if(!this.formData.get('owner').value && !this.formData.get('agent').value){
            this.ownerOrAgentError='Please Select at least one'
        }
        if(!this.signatureError  && !this.nameError && !this.ownerOrAgentError){
            let data={
                'type':this.formData.get('owner').value?'Owner':'Agent',
                'name':this.formData.get('name').value,
                'signatureValue':this.sig.toDataURL()
            }
            this.ngbActiveMOdal.close(data)
        }
    }
}