import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit {

  @Output() getAddress: EventEmitter<any> = new EventEmitter();
  @Input("zipcode") zipcode: string;

  closeResult = '';
  addrnames: any;

  address:any;

  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  isDataReady = false;

  pincode:any;
  addressname:any;
  addrsrchForm: FormGroup;
  addrBookId: any;
  isEnabled:boolean =false;

  constructor(private _formBuilder: FormBuilder,private modalService: NgbModal,private scHttpService: ScHttpService) { }

  ngOnInit() {

    this.loadFormData();
    this.loadAddressNames();

    if(this.zipcode){
      this.setZipcode();
      this.isEnabled = true;
    }
  }
  
  loadFormData(){
    this.addrsrchForm = this._formBuilder.group({
      addressName:['',Validators.required],
      pincode:[]
   });
  }

  get f() { return this.addrsrchForm.controls; }

  setZipcode(){
    this.addrsrchForm.get("pincode").setValue(this.zipcode);
    this.pincode =this.zipcode; 
    this.loadAddressBook();
  }

  searchPincode(key){
    this.pincode = "";
    this.pincode = key;
    this.loadAddressBook();
  }

  searchAddrNames(key){
    this.addressname = "";
    this.addressname = key;
    this.loadAddressBook();
  }

  getSelectedAddr( addrBookId ){
    this.addrBookId = addrBookId;
    this.addressname = this.addrBookId ;
  }

  page = 1;
  pageSize = 5;
  collectionSize = 5; 

  loadAddressBook(): void {

      let url = ServerURLS.getAllAddressBook.action + "/" + ( this.page - 1 ) + "/" + this.pageSize + "?" ;

      if( this.addressname ){
        url += "&addr-name=" + this.addressname;
      } 
      if( this.pincode){
          url += "&zip-code=" + this.pincode;
      }

      this.isDataReady = false;
      let promise1 = this.scHttpService.getHttpService( url , '');

      promise1.then( value => {
        this.address = value["data"]["content"];
        this.isDataReady = true;

        if(this.addrBookId){
          this.getAddress.emit(this.address[0]);
        }
        
      });

    }

  loadAddressNames (): void {

   this.addrsrchForm.reset();
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressNames.action,'');

    promise1.then( value => {
      this.addrnames = value["data"];
    });

  }

  open(content) {
    this.modalService.open(content, {size: 'lg',centered: true,backdrop: 'static',ariaLabelledBy: 'modal-basic-title'});
  }

  close(){
    this.modalService.dismissAll();

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}


