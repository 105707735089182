import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScStorageService {
   
  data: any;
  statusMessage: any;
  loading: boolean;

  constructor( @Inject(LOCAL_STORAGE) private storage: WebStorageService ) { }
 
   updateBucket(key: string, value: any) : void {

    this.removeBucket(key);
    this.storage.set(key, {original: value});
    return;
  }

  getUnSubmitData( key: string ): any {
    let data = this.getBucket(key);
    return data == null || data == undefined? {}: data.change;
  }
  
  saveBucket(key: string, value: any) : void {
     
    let data = this.getBucket(key);
    
    /*if( data == null || data.original == undefined ) {
      data = {change: value};
    } else {
      data.change = value;
    }*/

    if( data == null || data.original == undefined ) {
      data = value;
    } 

    this.storage.set(key, data);
    return;
  }
  
  parseJSONData ( value: any ): void {
    
    for (let prop in value) {
        this[prop] = value[prop];
    }
  }

  getBucket ( key: string ): any {
    return this.storage.get(key);
  }
  
  removeBucket ( key: string ): void {
    this.storage.remove(key);
  }
  
  clearBucket ( key: string ): void {
    this.removeBucket(key);
  }
  
  getBase64Image(img) {

    var canvas = document.createElement("canvas");
    //canvas.width = img.width;
   // canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  
}
