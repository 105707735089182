import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordForm:FormGroup
  pwdPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  successMsg:string;
  errMsg:String;

  userData: any;

  constructor( private router: Router, private _formBuilder: FormBuilder,private scHttpService: ScHttpService,  private scAuthService: ScAuthService ) { }

  ngOnInit() {
    
    this.userData = this.scAuthService.getLoggedUserData();
    this.loadFormData();
  }

  loadFormData(){

    this.passwordForm = this._formBuilder.group({
    currentPassword: ['', this.userData.isPwdCrtd ? [Validators.required, Validators.pattern(this.pwdPattern)] : ''],
    password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
    confirmPassword: ['', Validators.required],
    loggedUserId :[this.scAuthService.getLoggedUserId()],

   }, {
     validator: MustMatch('password', 'confirmPassword')
   });

 }

 get f() { return this.passwordForm.controls; }

 updatePassword () {

  let form_data = this.passwordForm.getRawValue();

   let authPromise = this.scHttpService.putHttpService(ServerURLS.changePassword.action, form_data);
 
   authPromise.then( (resolve) => {
     let response = resolve;
     this.successMsg = response["message"];
     this.router.navigateByUrl("/shipcarte/profilev2");
     
   }).catch((error) => {
     this.errMsg = error.message;
   });

 }

 cancel(){
  this.router.navigateByUrl("/shipcarte/profilev2");
 }

}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

