import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-create-commercial-pdf-view',
  templateUrl: './create-commercial-pdf-view.component.html',
  styleUrls: ['./create-commercial-pdf-view.component.scss']
})
export class CreateCommercialPdfViewComponent implements OnInit {

  commercialPdfData:any;
  shipmentItems:any;
  consigneeData:any;
  invoiceId:any;
  purchaser: any;
  shipperData:any; 
  shipmantValue:any;
  totalUnit:number = 0;
  totalWeight:number = 0;
  totalWeightUnit: string;
  totalOfShpmtItemValue:number = 0;
  itemCurrency: string;
  totalTaxPercentage: any;
  totalDue: any;
  constructor(private DocumentService : DocumentService, private activeModal:NgbActiveModal ) { }

  ngOnInit() {
    this.commercialPdfData = this.DocumentService.commercialPdfData;
    this.shipmentItems = this.DocumentService.shipmentItems;
    this.consigneeData = this.DocumentService.consigneeData;
    this.shipperData = this.DocumentService.shipperData;
    this.purchaser = this.DocumentService.purchaser;
    this.shipmantValue = this.shipmantValue;

    for(let perItem of this.shipmentItems){
      this.totalUnit += perItem.qty;
      this.totalWeight += perItem.totalWeight;
      this.totalOfShpmtItemValue += (perItem.qty * perItem.shipmentItemValue);
      // this.totalOfShpmtItemValue += (perItem.shipmentItemValue);
    }
    this.totalWeightUnit = this.shipmentItems[0].wghtUnit;
    this.itemCurrency = this.shipmentItems[0].shipmentItemValueCurrency;
    
    let salesTaxAmout = this.commercialPdfData.salesTaxAmout;
    if(this.commercialPdfData.salesTaxIsPercent){
      salesTaxAmout = (this.totalOfShpmtItemValue * salesTaxAmout)/100
    }

    this.totalDue = this.totalOfShpmtItemValue + salesTaxAmout + this.commercialPdfData.freightCost;
  }
 
  closeModal(){ this.activeModal.close(); }

}
