import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponForm } from './couponform';
import { ServerURLS } from '../../../../environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.scss']
})
export class CouponFormComponent implements OnInit {

  offerId: string;
  couponform: FormGroup;

  errMsg: any;
  successMessage: any;

  isEdit: boolean;
  couponForm : CouponForm
  couponId:string;

  constructor( private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, 
               private router: Router) { 

    this.couponForm = new CouponForm();
  }

  ngOnInit() {

    this.offerId = this.route.snapshot.paramMap.get('offer-id');
    this.couponId = this.route.snapshot.paramMap.get('coupon-id');

    if( this.couponId ) {
      this.getCouponDetails();
    }
    this.loadFormData();
  }

  loadFormData(){

     this.couponform = this._formBuilder.group({

      description: ['', Validators.required],
      offerCode: ['', Validators.required],
      maxUsagePerUser: ['', Validators.required],
      offerId:[this.offerId]
   });

 }

  get f() { return this.couponform.controls; }

  addCouponDetails() {

    let formData = this.couponform.getRawValue();
    this.couponForm = formData;

    if( this.couponId ){

       this.couponForm.couponId = this.couponId
    }
    let promise : any;
    if( this.couponId ){
       promise = this.scHttpService.putHttpService(ServerURLS.updateCouponDetails.action, this.couponForm);

    } else {
      promise = this.scHttpService.postHttpService(ServerURLS.addCouponDetails.action, this.couponForm);
    }
      promise.then( (resolve) => {
        this.errMsg = "";
        let response = resolve;
        this.successMessage = response["message"];
        this.router.navigateByUrl("shipcarte/offer/view;offer-id=" + this.offerId );
      }).catch((error) => {
        this.errMsg = error.message;
      });
      
  }
  
  getCouponDetails (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCouponDetails.action +"?coupon-id=" +this.couponId,'');
    promise1.then( value => {
      let response = value["data"];
      if( response ){
        this.offerId = response["offerId"];
        this.couponform.patchValue (value["data"]);
      }
    });
  }
}
