export class paymentGateWayUSDForm{

    orderNo:string;
    custId:string;
    amount:string;
    txnNum:string;
    odBillAddress:string;
    odBillState:string;
    odBillFirstname:string;
    odBillLastname:string;
    odBillCity:string;
    odBillCountry:string;
    odBillZipcode:string;
    odBillPhone:string;
    odBillCompany:string;


}