import { Component, OnInit, Injectable } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ScCommonService } from '../../../services/common/sc-common.service';
import { FormControl, ValidationErrors } from "@angular/forms";
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {formatNumber} from '@angular/common';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class ViewComponent implements OnInit {
  applicantDetail: any;
  address: any;
  references: any;
  applicantId: string;
  bankingInfo: any;
  billingInfo: any;
  paymentInfo: any;
  errorMessage: any;
  applicantStatus:any;
  documents:string

  isDisable: boolean = false;
  isApprove: boolean;
  description: string;
  validityForm: FormGroup;
  isValidDate: boolean;
  error: { isError: boolean; errorMessage: string; };
  paymentDetails: any;
  statusHistory: any;
  minDate: any; 
  
  model: string;
  model2: string;
  userData: any;

  constructor( private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, public scAuthService: ScAuthService,
                public commonService: ScCommonService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string> ) {

                  this.minDate = {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate()
                };

                 }

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');

    this.validityForm = this._formBuilder.group({
      applicantId: [],
      startDate: ['', Validators.required],
      expiryDate: [, Validators.required],
      creditLimit:['',Validators.required]
    });

    this.error = { isError:false, errorMessage:''};

    this.getApplicantDetails();
    this.getApplicantReferences();
    this.getBankingInfo();
    this.getBillingInfo();
    //this.loadpaymentInfo();
    this.getCreditApplicantStatus();
    this.getApplicantDocuments();
    this.loadpaymntData();
    this.getStatusHistory();

    this.userData = this.scAuthService.getLoggedUserData();
  }

  get f() { return this.validityForm.controls; }

  getApplicantDetails (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantDetails.action +"?id=" +this.applicantId,'');
    promise1.then( value => {
      this.applicantDetail = value["data"];
     
      if(this.applicantDetail != null){
        this.getApplicantAddressDetails(this.applicantDetail.contactAddrId);
      }
    });
  }
  
  getApplicantAddressDetails (id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action +"?id=" +id,'');
    promise1.then( value => {
      this.address = value["data"];
    });
  }

  getApplicantReferences (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditReferences.action +"?id=" +this.applicantId,'');
    promise1.then( value => {
      this.references = value["data"];
    });
  }

  getStatusHistory (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantStatusHistory.action +"?applicant-id=" +this.applicantId,'');
    promise1.then( value => {
      this.statusHistory = value["data"];
    });
  }

  getBankingInfo (): void {

    let url =  ServerURLS.getCreditApplicantBankInfo.action;

    let promise1 = this.scHttpService.getHttpService( url + "?applicant-id=" + this.applicantId, '');
    promise1.then( value => {
      this.bankingInfo = value["data"];
    });
  }

  getBillingInfo (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditBillingAddress.action+"?applicant-id="+this.applicantId,'');
    promise1.then( value => {
      this.billingInfo = value["data"];
    });
  }

  loadpaymentInfo (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPayments.action+"?applicant-id="+this.applicantId,'');
    promise1.then( value => {
     this.paymentInfo= value["data"];
    });
  }

  changeStatus(status): any {

    let params = "?applicantId=" + this.applicantId + "&status=" + status;
    if(this.description){
      params += "&comment=" + this.description;
    }

    let promise = this.scHttpService.postHttpService(ServerURLS.saveCreditApplicantSttsTracker.action + params, '');
  
    promise.then( (resolve) => {
      let response = resolve;
      this.getCreditApplicantStatus();

    }).catch((error) => {
      this.errorMessage = error.message;
    });

    return promise;
    
}

getCreditApplicantStatus (): void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantStatus.action+"?applicant-id="+this.applicantId,'');
  promise1.then( value => {
    this.applicantStatus = value["data"];
  });
}

getApplicantDocuments (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditApplicantDocs.action + "?applicant-id=" + this.applicantId, '');
    promise1.then( value => {
    
      let response = value["data"];
      if(response) {
        this.documents = response;
      }
    });

  }

  disable() {
    this.isDisable = true;
  }

  approve(){
    this.isApprove = true;
  }

  decimalPlaces(float,length) {
    let ret = "";
    let str = float.toString();
    let array = str.split(".");
    if(array.length==2) {
       ret += array[0] + ".";
       for(let i=0;i<length;i++) {
          if(i>=array[1].length) ret += '0';
          else ret+= array[1][i];
       }
    }
    else if(array.length == 1) {
      ret += array[0] + ".";
      for(let i=0;i<length;i++) {
         ret += '0'
      }
    }
  
    return ret;
  }
  

  approveApplicant(){

    let formData = this.validityForm.getRawValue();
    formData.startDate = this.commonService.changedateformat(formData.startDate);
    formData.expiryDate = this.commonService.changedateformat(formData.expiryDate);
    formData.applicantId = this.applicantId;
    formData.creditLimit = this.decimalPlaces((Math.round(formData.creditLimit * 100) / 100).toFixed(2),2);
    let promise = this.scHttpService.putHttpService(ServerURLS.setCreditUserValidity.action, formData);

      promise.then( (resolve) => {
       //Change applicant status to approved
        let promise1 = this.changeStatus('Approved');

        promise1.then( (resolve) => {

          this.error.errorMessage = '';
          this.isApprove = false;
          this.validityForm.reset();

        });

      }).catch((error) => {
        this.errorMessage = error.message;
      });
  }

  validateDates(sDate: string, eDate: string){
    this.isValidDate = true;

    if((sDate != null && eDate != null) && (eDate) < (sDate)){
      this.error = { isError:true, errorMessage:'Expiry date should be greater than start date.'};
      this.isValidDate = false;
    }

    return this.isValidDate;
  }

  cancel(){
    this.isApprove = false;
    this.validityForm.reset();
  }

  loadpaymntData (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCreditPayments.action+"?applicant-id="+this.applicantId,'');
    promise1.then( value => {
      this.paymentDetails = value["data"];
    });
  }

}
