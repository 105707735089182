import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from '../../custom-validators';
import { ScCommonService } from '../../../services/common/sc-common.service';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ServerURLS } from '../../../../environments/environment';
import { ThrowStmt } from '@angular/compiler';
import { Server } from 'http';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  @ViewChild('loadContent', undefined) loadContent;
  @ViewChild('contentWarn', undefined) confirmModal;
  @ViewChild('resetPassword', undefined) resetPassModal;
  isEdit: boolean = false;
  isEditDone: boolean = false;
  uploadImageShow: any = '';
  imageData: any = null;
  userData: FormGroup;
  resetPass: FormGroup;
  passwordVisible: boolean = true;
  confirmPasswordVisible: boolean = true;
  newPasswordVisible: boolean = true;
  confirmNewPasswordVisible: boolean = true;
  userFetchedData: any;
  emailNotificationError: boolean = false;
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  validationMessages = {
    'firstName': {
      'required': 'First name is required*',
      'maxlength': 'First name limit exceded*',
      'pattern':"Don't use spaces , numbers or special characters"
    },
    'lastName': {
      'required': 'Last name is required*',
      'maxlength': 'Last name limit exceded*',
      'pattern':"Don't use spaces , numbers or special characters"
    },
    'userName': {
      'required': 'User name is required*',
      'maxlength': 'User name limit exceded*',
      'pattern': "Only Alphabets and number are allowed",
      'minlength': 'Min. length should be 3',
    },
    'password': {
      'required': 'Password is required*',
      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character*'
    },
    'confirmPassword': {
      'required': 'Password is required*',
      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character*',
      'NoPassswordMatch': 'Password not matched*'
    },
    'newPassword': {
      'required': 'Password is required*',

      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character'
    },
    'confirmNewPassword': {
      'required': 'Password is required*',

      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character'
    },
    'email': {
      'required': 'Email is required*',
      'email': 'Invalid email*',
      'maxlength': 'Email limit exceded*'
    },

    'emailNotification': {
      'required': 'Notification is required'
    },
    'phoneNo': {
      'required': 'Phone number is required*',
      'pattern': 'Invalid phone no*',
      'minlength': 'Invalid mobile no*',
      'maxlength': 'Invalid mobile no*'
    },
    'phoneExt': {
      'maxlength': 'Phone  Ext limit exceded*',
      'pattern': 'Invalid ext*'
    },
    'fax': {
      'maxlength': 'Mobile  Ext limit exceded*',
      'pattern': 'Invalid fax*'
    },
    'userRole': {
      'required': 'User role is required*'
    }
  }

  formErrors = {
    'firstName': '',
    'lastName': '',
    'userName': '',
    'password': '',
    'confirmPassword': '',
    'newPassword': '',
    'confirmNewPassword': '',
    'email': '',
    'status': '',
    'emailNotification': '',
    'phoneNo': '',
    'phoneExt': '',
    'fax': '',
    'userRole': '',
    'NoPassswordMatch': ''
  }
  submitClicked: boolean = false;
  userId: string;
  customerId: any;
  customerName: any;
  userCode: any;
  countryPlaceHolder:any='Select Country';
  statePlacePlaceHolder:any='Select State'
  sharedData: any;
  isManualLookUp:boolean=false
  i: any = 0;
  constructor(
    private domSanitizer: DomSanitizer,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commenService: ScCommonService,
    private httpService: ScHttpService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) {
    this.commenService.currentData.subscribe((data: any) => {
      this.sharedData = data
    })
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {

    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    this.userCode = sessionStorage.getItem('userFromCustomerId');
    this.customerId = this.commenService.customerId ? this.commenService.customerId : sessionStorage.getItem('customerId');
    this.getAddressBookList('')
    this.customerName = sessionStorage.getItem('customerNameForAddEditUser');
    if (this.userId) {
      this.isEdit = true;
      this.userData = this.fb.group({
        emailNotification: [, [Validators.required]],
        status: [''],
        firstName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z]*$/)]],
        lastName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z]*$/)]],
        userName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z0-9]*$/), Validators.minLength(3), CustomValidators.onlySpaceCheckValidator]],
        password: [{ value: '', disabled: true }],
        confirmPassword: [{ value: '', disabled: true }],
        phoneCode: ['CA'],
        phoneNo: ['', [Validators.pattern(/^(0|[1-9]\d*)?$/), Validators.maxLength(10), Validators.minLength(10), Validators.required]],
        phoneExt: ['', [Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)]],
        email: ['', [Validators.email, Validators.maxLength(48), Validators.required]],
        fax: ['', [Validators.maxLength(20), Validators.pattern(/^[0-9]*$/)]],
        userRole: ['', Validators.required],
        enabled: ['no'],
        userManagement: [{ value: 'no', disabled: true }],
        viewInvoice: [{ value: 'no', disabled: true }],
        viewRates: [{ value: 'no', disabled: true }],
        viewAddress: [{ value: 'no', disabled: true }],
        addressLine1:[''],
        addressLine2:[''],
        city:[{value:'',disabled:true}],
        state:[{value:'',disabled:true}],
        zipcode:[{value:'',disabled:true}],
        country:[{value:'',disabled:true}]
      }, {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      })
      this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' });
      this.fetchUserData();

      this.resetPass = this.fb.group({
        userId: [this.userCode, [Validators.required]],
        newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
        confirmNewPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
      })
      this.resetPass.valueChanges.subscribe((value) => {
        this.logValidationErrors(this.resetPass);
      })
    }
    else {
      this.userData = this.fb.group({
        emailNotification: [true, [Validators.required]],
        status: ['active'],
        firstName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z]*$/)]],
        lastName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z]*$/)]],
        userName: ['', [Validators.required, Validators.maxLength(48), Validators.pattern(/^[a-zA-Z0-9]*$/), Validators.minLength(3), CustomValidators.onlySpaceCheckValidator, Validators.minLength(3)]],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
        confirmPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
        phoneCode: ['CA'],
        phoneNo: ['', [Validators.pattern(/^(0|[1-9]\d*)?$/), Validators.maxLength(10), Validators.minLength(10), Validators.required]],
        phoneExt: ['', [Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)]],
        email: ['', [Validators.email, Validators.maxLength(48), Validators.required]],
        fax: ['', [Validators.maxLength(20), Validators.pattern(/^[0-9]*$/)]],
        userRole: ['', Validators.required],
        enabled: ['no'],
        userManagement: [{ value: 'no', disabled: true }],
        viewInvoice: [{ value: 'no', disabled: true }],
        viewRates: [{ value: 'no', disabled: true }],
        viewAddress: [{ value: 'no', disabled: true }],
        addressLine1:[''],
        addressLine2:[''],
        city:[{value:'',disabled:true}],
        state:[{value:'',disabled:true}],
        zipcode:[{value:'',disabled:true}],
        country:[{value:'',disabled:true}]
      })
      this.isEdit = false;
    }

    this.userData.valueChanges.subscribe((value) => {

      this.logValidationErrors(this.userData);
    })

    this.userData.get('userRole').valueChanges.subscribe((data) => {
      if (data == 'admin') {
        this.userData.patchValue(({
          vendor: 'yes',
          userManagement: 'yes',
          viewInvoice: 'yes',
          viewRates: 'yes',
          viewAddress: 'yes'
        }))
      }
      else if (data == 'accounting') {
        this.userData.patchValue(({
          vendor: 'yes',
          userManagement: 'no',
          viewInvoice: 'yes',
          viewRates: 'no',
          viewAddress: 'no'
        }))
      }
      else if (data == 'shipper') {
        this.userData.patchValue(({
          vendor: 'yes',
          userManagement: 'no',
          viewInvoice: 'no',
          viewRates: 'yes',
          viewAddress: 'yes'
        }))
      }
    })

  }

  ngOnDestroy() {
    sessionStorage.removeItem('customerNameForAddEditUser');
  }
  toggleManualLookUp(){
    this.isManualLookUp=!this.isManualLookUp
    this.dataFromGoogleApiLocation=undefined;
    this.userData.patchValue({
      addressLine1:'',
      city:'',
      state:'',
      zipcode:'',
      country:''
    })
  }

  fetchUserData() {
    let url = `${ServerURLS.getCustomerUserById.action}?user_id=${this.userId}`;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.userFetchedData = res;
      // console.log(res)
      if (res.user_image_url) {
        this.uploadImageShow = res.user_image_url;
      }
      else {
        this.uploadImageShow = '';
      }
      if (res.user_permission.mail_notification_enabled) {
        this.emailNotificationError = false;
      }
      if(res.source_address){
        this.setStateArrayValue(res.source_address?res.source_address.country?res.source_address.country:'':'', 'google');
        if(res.source_address.googleMapId!=''){
          this.isManualLookUp=false
        }else{
          this.isManualLookUp=true
        }
      }
      
      this.userData.patchValue({
        emailNotification: res.user_permission.mail_notification_enabled,
        status: res.status ? 'active' : 'inactive',
        firstName: res.first_name ? res.first_name : '',
        lastName: res.last_name ? res.last_name : '',
        userName: res.user_name ? res.user_name : '',
        // password:res.password ? res.password :'',
        // confirmPassword:res.confirm_password ? res.confirm_password :'',
        phoneCode: res.country_code ? res.country_code : 'CA',
        phoneNo: res.phone_number ? res.phone_number.includes('+1') ? res.phone_number.replace('+1', '') : res.phone_number : '',
        phoneExt: res.phone_number_ext ? res.phone_number_ext : '',
        email: res.email_address ? res.email_address : '',
        fax: res.fax ? res.fax : '',
        userRole: res.user_permission.user_role ? res.user_permission.user_role.toLowerCase() : '',
        enabled: res.user_permission.enabled ? 'yes' : 'no',
        customerAdmin: res.user_permission.is_customer_admin,
        vendor: res.user_permission.is_vendor ? 'yes' : 'no',
        userManagement: res.user_permission.is_user_management ? 'yes' : 'no',
        viewInvoice: res.user_permission.view_invoice ? 'yes' : 'no',
        viewRates: res.user_permission.view_rate ? 'yes' : 'no',
        viewAddress: res.user_permission.view_address_book ? 'yes' : 'no',
        addressLine1:res.source_address?res.source_address.fullAddr?res.source_address.fullAddr:'':'',
        addressLine2:res.source_address?res.source_address.fullAddr?res.source_address.addrLine2:'':'',
        city:res.source_address?res.source_address.city?res.source_address.city:'':'',
        state:res.source_address?res.source_address.state?res.source_address.state:'':'',
        zipcode:res.source_address?res.source_address.zipCode?res.source_address.zipCode:'':'',
        country:res.source_address?res.source_address.country?res.source_address.country:'':''
      })
      this.dataFromGoogleApiLocation={
        sourceAddress: {
          companyName: '',
          latitude: res.source_address.latitude,
          longitude: res.source_address.longitude,
          fullAddr: res.source_address.fullAddr,
          addrLine1: res.source_address.addrLine1,
          addrLine2: res.source_address.addrLine2,
          country: res.source_address.country,
          city: res.source_address.city,
          state: res.source_address.state,
          zipCode: res.source_address.zipCode,
          website: '',
          placeId: res.source_address.googleMapId
        }
      }
      this.i = 1;
      this.modalService.dismissAll();

    }).catch((err) => {
      this.toastr.error(err.message ? err.message : 'Something went wrong !', '', {
        timeOut: 3000,
      });
      this.modalService.dismissAll();
    })

  }


  uploadImage(event): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2 && event.target.files[0]) {
        this.uploadImageShow = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string)
        this.imageData = event.target.files[0];
      }
    }
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeImage(): void {
    this.uploadImageShow = ''
    this.imageData = null;
  }

  togglePassword(type) {
    if (type == 'pass') {
      this.passwordVisible = !this.passwordVisible;
    }
    else if (type == 'confirmPass') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
    else if (type == 'newpass') {
      this.newPasswordVisible = !this.newPasswordVisible;
    }
    else if (type == 'confirmNewPass') {
      this.confirmNewPasswordVisible = !this.confirmNewPasswordVisible;
    }
    else if (type == 'newpass') {
      this.newPasswordVisible = !this.newPasswordVisible;
    }
  }

  logValidationErrors(group: FormGroup = this.userData): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key)

 
      this.formErrors[key] = ''
      if (abstractControl && !abstractControl.valid && ((abstractControl.touched || abstractControl.dirty)) || this.submitClicked) {
        const messages = this.validationMessages[key];
        // console.log(messages)
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            this.formErrors[key] += messages[errorKey] + " ";
          }
        }
      }
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      }
    })
  }
  setNotificationError(value) {

    if (value) {
      this.emailNotificationError = false;
    } else {
      this.emailNotificationError = true;
    }
  }
  passwordAreNotSame: boolean = false;
  checkPasswordAndConfirmPasswordAreSameOrNot() {
    let password = this.userData.get("password").value;
    let confirmPassword = this.userData.get("confirmPassword").value;
    if (password != '' && confirmPassword != '') {

      if (password != confirmPassword) {
        this.passwordAreNotSame = true;
      } else {
        this.passwordAreNotSame = false;
      }
    }

  }
  addUser() {
    let notificationValue = this.userData.get('emailNotification').value;


    if (!notificationValue) {
      this.emailNotificationError = true;
    }
    let password = this.userData.get("password").value;
    let confirmPassword = this.userData.get("confirmPassword").value;


    if (!this.userData.valid || this.emailNotificationError || this.passwordAreNotSame) {
      this.submitClicked = true;

      this.toastr.error('Please fill all the required feilds!', '', {
        timeOut: 3000,
      });
      this.logValidationErrors();
    }
    else {
      let obj = {
        user_id: null,
        customer_id: this.customerId,
        user_name: this.userData.get('userName').value,
        user_code: null,
        first_name: this.userData.get('firstName').value,
        last_name: this.userData.get('lastName').value,
        password: this.userData.get('password').value,
        confirm_password: this.userData.get('confirmPassword').value,
        fax: this.userData.get('fax').value ? this.userData.get('fax').value : null,
        email_address: this.userData.get('email').value,
        phone_number: this.userData.get('phoneNo').value,
        phone_number_ext: this.userData.get('phoneExt').value,
        source_address:{
          addrLine1:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.addrLine1:'':'',
          addrLine2:this.userData.get('addressLine2').value,
          city:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.city:'':'',
          state:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.state:'':'',
          country:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.country:'':'',
          zipCode:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.zipCode:'':'',
          googleMapId:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.placeId:'':'',
          latitude:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.latitude:'':'',
          longitude:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.longitude:'':''
        },
        country_code: this.userData.get('phoneCode').value,
        status: this.userData.get('status').value == 'active' ? true : false,
        user_permission: {
          user_permission_id: null,
          user_id: null,
          user_role: this.userData.get('userRole').value,
          enabled: this.userData.get('enabled').value == 'no' ? false : true,
          is_customer_admin: this.userData.get('userRole').value == "admin" ? true : false,
          is_user_management: this.userData.get('userManagement').value == 'no' ? false : true,
          view_invoice: this.userData.get('viewInvoice').value == 'no' ? false : true,
          view_rate: this.userData.get('viewRates').value == 'no' ? false : true,
          view_address_book: this.userData.get('viewAddress').value == 'no' ? false : true,
          is_vendor: this.userData.get('enabled').value == 'no' ? false : true,
          mail_notification_enabled: this.userData.get('emailNotification').value
        }
      }
      const formdata: FormData = new FormData();
      formdata.append('userDetails', JSON.stringify(obj));
      if (this.imageData) {
        formdata.append('userImage', this.imageData);
      }


      let url = ServerURLS.saveCustomerUser.action;

      let promise = this.httpService.postFileHttpService(url, formdata);
      this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
      promise.then((res: any) => {
        this.modalService.dismissAll();
        this.toastr.success(res.message ? res.message : 'Success! User has been successfully added', '', {
          timeOut: 5000,
        });
        this.isEditDone = false;
        this.routeToAllUsers();
      }).catch((err) => {
        this.toastr.error(err.message ? err.message : 'Something went wrong !', '', {
          timeOut: 3000,
        });
        this.modalService.dismissAll();
      })
    }
  }

  updateUser() {
    let notificationValue = this.userData.get('emailNotification').value;


    if (!notificationValue) {
      this.emailNotificationError = true;
    }
    if (!this.userData.valid || this.emailNotificationError || this.passwordAreNotSame) {
      this.submitClicked = true;
      this.toastr.error("Please Fill all the required Fields")
      this.logValidationErrors();
    }
    else {
      let obj = {
        user_id: this.userFetchedData.user_id,
        customer_id: this.customerId,
        user_name: this.userData.get('userName').value,
        first_name: this.userData.get('firstName').value,
        last_name: this.userData.get('lastName').value,
        // password: this.userData.get('password').value,
        // confirm_password: this.userData.get('confirmPassword').value,
        fax: this.userData.get('fax').value ? this.userData.get('fax').value : null,
        email_address: this.userData.get('email').value,
        phone_number: this.userData.get('phoneNo').value,
        source_address:{
          addrLine1:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.addrLine1:'':"",
          addrLine2:this.userData.get('addressLine2').value,
          city:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.city:'':"",
          state:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.state:'':"",
          country:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.country:'':"",
          zipCode:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.zipCode:'':"",
          googleMapId:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.placeId:'':"",
          latitude:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.latitude:'':"",
          longitude:this.dataFromGoogleApiLocation?this.dataFromGoogleApiLocation.sourceAddress?this.dataFromGoogleApiLocation.sourceAddress.longitude:'':""
        },
        phone_number_ext: this.userData.get('phoneExt').value,
        country_code: this.userData.get('phoneCode').value,
        status: this.userData.get('status').value == 'active' ? true : false,
        is_image_removed: false,
        user_permission: {
          user_permission_id: this.userFetchedData.user_permission.user_permission_id,
          user_id: this.userFetchedData.user_permission.user_id,
          user_role: this.userData.get('userRole').value,
          enabled: this.userData.get('enabled').value == 'no' ? false : true,
          // is_customer_admin: this.userData.get('customerAdmin').value,
          is_user_management: this.userData.get('userManagement').value == 'no' ? false : true,
          view_invoice: this.userData.get('viewInvoice').value == 'no' ? false : true,
          view_rate: this.userData.get('viewRates').value == 'no' ? false : true,
          view_address_book: this.userData.get('viewAddress').value == 'no' ? false : true,
          // is_vendor: this.userData.get('enabled').value == 'no' ? false : true,
          mail_notification_enabled: this.userData.get('emailNotification').value
        }
      }

      if (this.userFetchedData.user_image_url && this.userFetchedData.user_image_url.length > 0 && this.uploadImageShow.length == 0 && this.imageData == null) {
        obj.is_image_removed = true;
      }

      const formdata: FormData = new FormData();
      formdata.append('userDetails', JSON.stringify(obj));
      if (this.imageData) {
        formdata.append('userImage', this.imageData);
      }

      let url = ServerURLS.updateCustomerUser.action;

      let promise = this.httpService.putFileHttpService(url, formdata);
      this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
      promise.then((res: any) => {
        this.toastr.success(res.message ? res.message : 'Success! User has been successfully updated', '', {
          timeOut: 3000,
        });
        this.imageData = null;
        this.modalService.dismissAll()
        this.isEditDone = false;
        this.fetchUserData();
        // this.routeToAllUsers();
      }).catch((err) => {
        this.toastr.error(err.message ? err.message : 'Something went wrong !', '', {
          timeOut: 3000,
        });
        this.modalService.dismissAll();
      })
    }
  }

  confirmModalOpen() {
    this.isEditDone = false;
    if (!this.isEdit) {
      // keysOfArrayForm.map((element) => {
      //   // debugger
      //   let value = this.userData.get(element).value;

      //   if (element === 'emailNotification' && value === false) {
      //     this.isEditDone = true;
      //   }
      //   if (element === 'status' && value === 'inactive') {
      //     this.isEditDone = true;
      //   }
      //   if (element === 'phoneCode' && value === 'US') {
      //     this.isEditDone = true;
      //   }
      //   if (element === 'enabled' && value === 'yes') {
      //     this.isEditDone = true
      //   }
      //   if (element != 'userManagement' && element != 'viewInvoice' && element != 'viewRates' && element != 'viewAddress') {
      //     if (value != '' && element != 'emailNotification' && element != 'status' && element != 'phoneCode' && element != 'enabled') {
      //       this.isEditDone = true
      //     }
      //   }
      // })
      let data = {
        'emailNotification': true,
        'status': 'active',
        'firstName': '',
        'lastName': '',
        "userName": '',
        'phoneCode': 'CA',
        "phoneNo": '',
        'phoneExt': '',
        "email": '',
        'fax': '',
        'userRole': '',
        'enabled': 'no'
      }
      let dataKeys = Object.keys(data);
      dataKeys.map((element, index) => {
        if (data[element] !== this.userData.get(element).value) {
          this.isEditDone = true
        }
      })


    } else if (this.isEdit) {
      let data = {
        'emailNotification': this.userFetchedData['user_permission']['mail_notification_enabled'],
        'status': this.userFetchedData['status'] ? 'active' : 'inactive',
        'firstName': this.userFetchedData['first_name'],
        'lastName': this.userFetchedData['last_name'],
        "userName": this.userFetchedData['user_name'],
        'phoneCode': this.userFetchedData['country_code'],
        "phoneNo": this.userFetchedData['phone_number'],
        'phoneExt': this.userFetchedData['phone_number_ext'],
        "email": this.userFetchedData['email_address'],
        'fax': this.userFetchedData['fax'] ? this.userFetchedData['fax'] : '',
        'userRole': this.userFetchedData['user_permission']['user_role'],
        'enabled': this.userFetchedData['user_permission']['enabled'] ? 'yes' : 'no'
      }
      let dataKeys = Object.keys(data);
      dataKeys.map((element, index) => {
        if (data[element] !== this.userData.get(element).value) {
          this.isEditDone = true;
        }
      })
    }







    this.modalService.open(this.confirmModal, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', backdrop: 'static', windowClass: "my-custom-modal" }).result.then((result) => {
      if (result == 'Save click') {
        this.router.navigateByUrl('shipcarte/customer/list/generalDetail/edit/users');
      }
    }, (reason) => {

    });
  }

  openResetPassModal() {
    this.modalService.open(this.resetPassModal, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', backdrop: 'static', windowClass: "my-custom-modal" }).result.then((result) => {
      if (result == 'Save click') {
        let resetPassObj = {
          "userId": this.userId,
          "newPassword": this.resetPass.value.newPassword
        }
        let resetPassUrl = ServerURLS.resetUserPassword.action;
        let promise = this.httpService.postHttpService(resetPassUrl, resetPassObj);
        promise.then((res: any) => {
          this.toastr.success(res.message ? res.message : 'User Password has been successfully updated', '', {
            timeOut: 3000,
          });
          this.imageData = null;
          this.fetchUserData();
        }).catch((err) => {
          this.toastr.error(err.message ? err.message : 'Something went wrong !', '', {
            timeOut: 3000,
          });
          this.modalService.dismissAll();
        })
        this.resetPass.patchValue({
          newPassword: '',
          confirmNewPassword: ''
        });
        // this.resetPass.reset();
      }
    }, (reason) => {
      this.resetPass.patchValue({
        newPassword: '',
        confirmNewPassword: ''
      });
      // this.resetPass.reset();
    });
  }

  submitClickedContactShowError() {
    this.logValidationErrors(this.resetPass)
    if (this.resetPass.value.newPassword != this.resetPass.value.confirmNewPassword) {
      this.toastr.error("New password and Confirm password doesn't match", '', {
        timeOut: 3000,
      });
    }
  }

  routeToAllUsers() {
    this.router.navigateByUrl('shipcarte/customer/list/generalDetail/edit/users');
  }
  handleAddressChange(address){
    this.getzipcodestart(
      address.formatted_address,
      address.address_components,
      address.geometry.location,
      address.name,
      address.place_id
    );
    // checking for all the required feilds in the form coming from google maps ......

    this.setDataFromGoogle();
  }
  setDataFromGoogle(){
    if (
      this.dataFromGoogleApiLocation.sourceAddress.addrLine1 == '' ||
      this.dataFromGoogleApiLocation.sourceAddress.country == '' ||
      this.dataFromGoogleApiLocation.sourceAddress.city == '' ||
      this.dataFromGoogleApiLocation.sourceAddress.state == '' ||
      this.dataFromGoogleApiLocation.sourceAddress.zipCode == ''
    ) {
      this.showErrorGoogleMap = true
    } else {
      this.showErrorGoogleMap = false
      this.setStateArrayValue(this.dataFromGoogleApiLocation.sourceAddress.country, 'google');
      // console.log(this.dataFromGoogleApiLocation)
      this.userData.patchValue({
        city: this.dataFromGoogleApiLocation.sourceAddress.city,
        state: this.dataFromGoogleApiLocation.sourceAddress.state,
        zipcode: this.dataFromGoogleApiLocation.sourceAddress.zipCode,
        country: this.dataFromGoogleApiLocation.sourceAddress.country
      })
      this.statePlacePlaceHolder = '';
      this.countryPlaceHolder = ''
    }
  }
  setStateArrayValue(countryCode,mode){
    this.countryPlaceHolder = '';
    if (mode == 'manual') {
      this.statePlacePlaceHolder = 'Select State';
      this.userData.patchValue({
        state: ''
      })
    }
    let stateArray = this.countryData.success.filter((data) => data.countryCode == countryCode);
    this.stateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
  }
  stateArray:any=[]
  showErrorGoogleMap: boolean = false;
  getzipcodestart(formatted_address, address, geometry, companyName, placeID) {
    // this.postalValid = false;
    //set latlong
    // // console.log(address);
    this.showErrorGoogleMap = false;
    let tempObjSet = {
      sourceAddress: {
        companyName: '',
        latitude: '',
        longitude: '',
        fullAddr: '',
        addrLine1: '',
        addrLine2: '',
        country: '',
        city: '',
        state: '',
        zipCode: '',
        website: '',
        placeId: placeID
      }
    }

    if (geometry.lat()) {
      tempObjSet['sourceAddress']['latitude'] = geometry.lat();
      tempObjSet['sourceAddress']['longitude'] = geometry.lng();
      tempObjSet['sourceAddress']['fullAddr'] = formatted_address;
    }

    //get city and country and zipCode
    for (let key in address) {
      if (address[key]['types'][0] == 'country') {
        tempObjSet['sourceAddress']['country'] =
          address[key]['short_name'];
      }

      //select city
      let cityArrayObj = []
      if (address[key]['types'].includes('locality') || address[key]['types'].includes('sublocality') || address[key]['types'].includes('neighborhood')) {
        cityArrayObj.push(address[key]['long_name'])
      }
      cityArrayObj.forEach((cityName, indexOfCity) => {
        let fullAddr = formatted_address.toLowerCase();
        if (fullAddr.includes(cityName.toLowerCase())) {
          tempObjSet['sourceAddress']['city'] = cityName

        }
      })

      //select state
      if (address[key]['types'][0] == 'administrative_area_level_1') {
        tempObjSet['sourceAddress']['state'] = address[key]['short_name'];
      }

      //select postal_code
      if (address[key]['types'][0] == 'postal_code') {
        tempObjSet['sourceAddress']['zipCode'] = address[key]['long_name'];
      }

      //select addressLine1 and 2
      tempObjSet['sourceAddress']['addrLine2'] = '';
      let stepStart = 0;
      if (!tempObjSet['sourceAddress']['addrLine1'] && (address[key]['types'][0] === 'subpremise' || address[key]['types'][0] === 'street_number')) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] = address[key]['short_name'];
      }

      if (address[key]['types'][0] === 'route') {
        if (!tempObjSet['sourceAddress']['addrLine1']) {
          stepStart++;
          tempObjSet['sourceAddress']['addrLine1'] = address[key]['short_name'];
        } else {
          stepStart++;
          tempObjSet['sourceAddress']['addrLine1'] = tempObjSet['sourceAddress']['addrLine1'] + ' ' + address[key]['short_name'];
        }
      }

      this.dataFromGoogleApiLocation = tempObjSet;
      // console.log(tempObjSet)
    }
  }
  found:any=''
  addressData:any=[]
  dataFromGoogleApiLocation:any;
  getAddressBookList(searchKey) {
    let url = ServerURLS.getAddressBookget.action + '0/10' + '?searchKey='+ searchKey +'&uniqueCompany=false&customerId='+this.customerId;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((a)=>{
        if (a['totalPages'] === 0) {
          this.addressData = [];
 
          return;
        }
        this.addressData = a['response'];
 
      }).catch((error)=>{
        if (error['status'] === 404) {
         
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.toastr.error('Session TimeOut!')
          this.router.navigate(['/signin']);
        }
      });
  }
  getCompanyDetails(i, companyId, flag){
    let url=ServerURLS.getContactDetailsById.action+'?companyId='+companyId;
    let promise=this.httpService.getHttpService(url,'');
    promise.then((res:any)=>{
      if (res.companyDetails) {
        let fullAddr=''
        let companyName=res?res.companyDetails?res.companyDetails.companyName?res.companyDetails.companyName:'':'':''
        let latitude=res?res.companyDetails?res.companyDetails.latitude?res.companyDetails.latitude:'':'':''
        let longitude=res?res.companyDetails?res.companyDetails.longitude?res.companyDetails.longitude:'':'':''
        let fullAddress=res?res.companyDetails?res.companyDetails.fullAddress?res.companyDetails.fullAddress:'':'':''
        let streetLine1=res?res.companyDetails?res.companyDetails.streetLine1?res.companyDetails.streetLine1:'':'':''
        let streetLine2=res?res.companyDetails?res.companyDetails.streetLine2?res.companyDetails.streetLine2:'':'':''
        let city=res?res.companyDetails?res.companyDetails.city?res.companyDetails.city:'':'':''
        let state=res?res.companyDetails?res.companyDetails.province?res.companyDetails.province:'':'':''
        let country=res?res.companyDetails?res.companyDetails.country?res.companyDetails.country:'':'':''
        let zipcode=res?res.companyDetails?res.companyDetails.zipcode?res.companyDetails.zipcode:'':'':''
        if(fullAddress.includes(state)){
          fullAddr=fullAddress
        }else{
 
          fullAddr=streetLine1+', '+city+', '+state+' '+ zipcode+', '+(country=='US'?'USA':"Canada")
        }
        this.userData.patchValue({
          addressLine1:fullAddr
        })
        let tempobj = {
          sourceAddress: {
            companyName: companyName,
            latitude: latitude,
            longitude: longitude,
            fullAddr: fullAddr,
            addrLine1: streetLine1,
            addrLine2: streetLine2,
            country: country,
            city: city,
            state: state,
            zipCode: zipcode,
            website: '',
            placeId: ''
          }
        }
        // console.log(tempobj)
        this.dataFromGoogleApiLocation=tempobj
        this.setDataFromGoogle()
      }
    })
  }
}
