  import { Component, OnInit } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { NgbModal, NgbModalConfig, ModalDismissReasons, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
  import { ActivatedRoute, Router } from '@angular/router';
  import { Quote } from '../../quote/entity/quote';
  import { QuoteReqstForm } from '../../quote/quote-form/quoteForm';
  import { ScStorageService } from '../../../services/storage/sc-storage.service';
  import { ScHttpService } from '../../../services/http/sc-http.service';
  import { ServerURLS } from '../../../../environments/environment';
  import { QuoteLocalStorage } from '../quotestorage';

  @Component({
    selector: 'app-quote-shipment',
    templateUrl: './quote-shipment.component.html',
    styleUrls: ['./quote-shipment.component.scss'],
    providers: [NgbTooltipConfig]
  })
  export class QuoteShipmentComponent implements OnInit {

    shpmtModes:any;
    shipmentModeName: Map<any, any>;
    locationType:any;
    locationTypeServices:any;
    quoteForm:FormGroup;
    startLocationForm:FormGroup;
    endLocationForm:FormGroup;
    isAddrAvailable: boolean;
    isEndAddrAvailable: boolean;
    errMsg:String;
    locationId:any;
    startlocType:any;
    endlocType:any;
    endLocServices:any;
    choosenStartServiceTypes:any; // Holds the start extra services
    isStartServiceChecked:any;
    choosenEndServiceTypes:any;// Holds the destination extra services
    isEndServiceChecked:any;
    successMsg :string;
    isQuoteAdded: boolean;
    quoteId: string;
    isStartPinEntered: boolean;
    isEndPinEntered: boolean;
    editQId: string;
    quoteCompletionRate: string;
    //isEditPage: string;
    shipmentType:any;
    shipmentPackaging:any;
    shpmtTypeEnable: boolean;
    isSPincodeReleased: boolean;
    isCPincodeReleased: boolean;
    dimensionMap: Map<any, any>;
    shpmtModeTemp: any;
    packagingType:string;
    shipmentPackagingType: Map<any, any>;
    shpmtMode:any
    min:any;
    max:any;
    packagingMap: Map<any, any>;
    shpmtPackTemp: any;
    quote: Quote;
    selected: any;
    quoteReqstForm: QuoteReqstForm;

  // Used for quote form values
    shpmtTypeId:any;
    shpmtModeId:any;
    packagingTypeId:any;

    // used to set start location service to storage
    startLocationExtraServiceMap:any;
    startExtrsService:any;

    // used to set end location service to storage
    endLocationExtraServiceMap:any;
    endExtrsService:any;
    quoteLocStorage:QuoteLocalStorage;;
    isShpmtEdit :any;
    quoteShmptData:any;
    isQuoteCompletionRate : boolean;

    constructor(private _formBuilder: FormBuilder,private scHttpService: ScHttpService, private storageService: ScStorageService, private router: Router, 
                config: NgbModalConfig, private modalService: NgbModal, private route: ActivatedRoute, toolTipconfig: NgbTooltipConfig ) { 

      this.quote = new Quote ();
      this.quoteReqstForm = new QuoteReqstForm();
      this.choosenStartServiceTypes = new Set();
      this.isStartServiceChecked = new Array();

      this.choosenEndServiceTypes = new Set();
      this.isEndServiceChecked = new Array();
      this.shipmentPackagingType = new Map();
      this.shipmentModeName = new Map();

    // intializing array and map for start service
      this.startLocationExtraServiceMap = new  Map();
      this.startExtrsService = new Array(); 
      // intializing array and map for start service
      this.endLocationExtraServiceMap = new  Map();
      this.endExtrsService = new Array(); 
      this.quoteLocStorage = new QuoteLocalStorage;
      
      toolTipconfig.placement = 'right';
      toolTipconfig.triggers = 'click';
    }

    ngOnInit() {

      this.editQId = this.route.snapshot.paramMap.get('quote-id'); // Incomplete Quote or Repeat order 
      this.isShpmtEdit = this.route.snapshot.paramMap.get('is-shpmt-edit'); // Yet to get Quote

      this.shpmtModeList();
    // this.loadFormData(); // Quote
      this.loadAddrFormData(); // Start Address
      this.loadEndAddrFormData(); // End Address
      this.locationTypeList();

      this.shipmentPackagingList();

      if( this.editQId ){
        this.loadQuoteCompletionRate();
        if( !this.isShpmtEdit && !this.isQuoteCompletionRate){
          this.getQuote();
        }
      } else {
        this.loadDataFromStorage();
      }
    }

    loadQuoteCompletionRate() {
      if( this.route.snapshot.paramMap.get('completion-rate') != null) {

        this.quoteCompletionRate =  this.route.snapshot.paramMap.get('completion-rate');

        if( this.quoteCompletionRate != null && this.editQId != null ) {

          if( this.quoteCompletionRate == '10'){
            this.router.navigateByUrl("shipcarte/quote/rates;quote-id="  + this.editQId);

          } else if( this.quoteCompletionRate == '25'){
            this.router.navigateByUrl("shipcarte/quote/v2-location;quote-id=" + this.editQId);

          } else if( this.quoteCompletionRate == '35'){
            this.router.navigateByUrl("shipcarte/quote/v2-document;quote-id=" + this.editQId);

          } else if( this.quoteCompletionRate == '50' || this.quoteCompletionRate == '95'){
            this.router.navigateByUrl("shipcarte/quote/make/payment;quote-id="  + this.editQId);
          } 
        }
        this.isQuoteCompletionRate = true;
      }
     
    }

    /**
     * Shipment Mode list
     */
  shpmtModeList (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShpmtModeList.action,'');
    promise1.then( value => {
      this.shpmtModes = value["data"];
      this.shpmtModes.forEach(element => {
      this.shipmentModeName.set(element.shpngModeId, element.shpmntCode);
      });

      if( this.shpmtModeId ){
        this.shpmtMode = this.shipmentModeName.get(this.shpmtModeId);
      }
    });

  }

  shipmentTypeList (id): void {
  // setting the shipment mode value
    this.shpmtModeId =  id;
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShipmentTypes.action+"?shipmentModeId="+id,'');
    promise1.then( value => {
      this.shipmentType = value["data"];

      if(this.shipmentType != "" && this.shipmentType != null){
        this.shpmtTypeEnable = true ;
        //this.quoteForm.get('shpmtTypeId').setValidators(Validators.required);

        if( this.shpmtModeTemp != null){

            this.shpmtTypeId = this.shpmtModeTemp;
          // this.quoteForm.patchValue ({
          //   'shpmtTypeId': this.shpmtModeTemp,
          // });
        }

      } else {
        this.shpmtTypeEnable = false ;
        this.shpmtTypeId = null;
        // this.quoteForm.get('shpmtTypeId').clearValidators();
        // this.quoteForm.patchValue ({
        //   'shpmtTypeId': null ,
        // });
      }

    });
    this.getShipmentMode(id);
  }

  getShipmentMode(value){
    this.shpmtMode = this.shipmentModeName.get(value);
    this.shipmentPackagingList ();
  }

  shipmentPackagingList (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getShipmentPackaging.action,'');
    promise1.then( value => {
      this.shipmentPackaging = value["data"];

      if( ( this.shpmtPackTemp != undefined ) &&  ( this.shpmtPackTemp != null )){
        this.quoteForm.patchValue ({
          'shpmtPackagingTypeId': this.shpmtPackTemp,
        });
      }

      let list: any = [];
      if(  (this.shpmtMode == "LTL") || (this.shpmtMode == "FTL") ){

        this.shipmentPackaging.forEach(element => {
          if(element["packagingType"] == "Pallet"){
            list.push(element);
          }
          
        });
        this.shipmentPackaging = list;

      } else if(  (this.shpmtMode == "CRS") ){

        this.shipmentPackaging.forEach(element => {
          if(element["packagingType"] != "Pallet"){
            list.push(element);
          }
          
        });
        this.shipmentPackaging = list;

      }else {
        this.shipmentPackaging.forEach(element => {
        this.shipmentPackagingType.set(element.packagingId, element.packagingType);
      });

      }
    
    });

  }

  selectPackageType(value){
    this.packagingTypeId = value;
    this.selected = value;
    this.packagingType = "";
    this.packagingType = this.shipmentPackagingType.get(value);
    this.storageService.saveBucket("shipment",  this.packagingType);
  }

  locationTypeList (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationTypes.action,'');
    promise1.then( value => {
      this.locationType = value["data"];
    });

  }
  setStLocation(event,type){
    this.locationId = event.target.value;
    this.startlocType = type;
    this.locationStrtTypeServcsList(this.locationId,type);
  }

  setEndLocation(event,type){
    this.locationId = event.target.value;
    this.endlocType = type;
    this.locationEndTypeServcsList(this.locationId,type);
  }

  optedServices : any[];

  setStartShipmentServices(values:any, checked:boolean){
    checked = !this.choosenStartServiceTypes.has(values.target.value);
    if( checked ) {
      this.choosenStartServiceTypes.add( values.target.value );
    } else {
      this.choosenStartServiceTypes.delete( values.target.value );
    }
  }

  setEndShipmentServices(values:any, checked:boolean){
    checked = !this.choosenEndServiceTypes.has(values.target.value);
    if( checked ) {
      this.choosenEndServiceTypes.add( values.target.value );
    } else {
      this.choosenEndServiceTypes.delete( values.target.value );
    }
    
  }

  hasES ( type, item ) {

    if( this.optedServices && this.optedServices.length > 0) {

      let values= {};
      values['target'] = {};
      values['target']['value'] = item;
      this.setStartShipmentServices(values, item)
      return this.optedServices.includes (item);

    } else {
      return false;
    }
    
  }
  locationStrtTypeServcsList (locationId,type): void {

    this.isStartServiceChecked = new Array();
    this.choosenStartServiceTypes.clear();

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationShpmtServcs.action +"?locTypeId="+locationId+"&locType="+type,'');
    promise1.then( value => {
    
      this.locationTypeServices = value["data"];
      
      if (this.locationTypeServices ) {

        this.locationTypeServices.forEach(element => {
        this.startLocationExtraServiceMap.set(element.assocId, element.serviceName);

        });

        if ( this.optedServices) {

          this.locationTypeServices.forEach(element => {
            if ( this.optedServices.includes ( element.assocId ) ) {
              let values= {};
              values['target'] = {};
              values['target']['value'] = element.assocId;
              element.checked = 'checked';
              this.setStartShipmentServices ( values, true);
          
            }
          });
      }  

    } 

    });
    
    //[checked]="hasES('StartShipment',shpmtServcs.assocId)"

  }

  locationEndTypeServcsList (id, type1): void {
    this.isEndServiceChecked = new Array();
    this.choosenEndServiceTypes.clear();

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getLocationShpmtServcs.action +"?locTypeId="+id+"&locType="+type1,'');
    promise1.then( value => {
      this.endLocServices = value["data"];
      let list: any = [];

      if( this.packagingType && (this.packagingType == "Pak" || this.packagingType == "Parcel" || this.packagingType == "Letter" ) ){

        this.endLocServices.forEach(element => {
          if(element["serviceName"].includes("Signature")){
            list.push(element);
          }
        });

        this.endLocServices = list;
      }

      if (this.endLocServices ){
        this.endLocServices.forEach(element => {
        this.endLocationExtraServiceMap.set(element.assocId, element.serviceName);

      });

      if ( this.optedServices ){
        
        this.endLocServices.forEach(element => {
          if ( this.optedServices.includes ( element.assocId ) ) {
            let values= {};
            values['target'] = {};
            values['target']['value'] = element.assocId;
            element.checked = 'checked';
            this.setEndShipmentServices ( values, true);
          }
        });
  
      }
      }

    });

  }

  /*loadFormData(){

    this.quoteForm = this._formBuilder.group({
      
      shpmtModeId:['',Validators.required],
      shpmtTypeId:[],
      shpmtPackagingTypeId:['',Validators.required],
      stextraServcType:['',],
      edextraServcType:['',],
  });
  }*/

  loadAddrFormData(){

    this.startLocationForm = this._formBuilder.group({

    zipCode: [this.quote.sourceAddress.zipCode, Validators.required],
    city: {value: this.quote.sourceAddress.city, disabled: true},
    state: [{value: this.quote.sourceAddress.state, disabled: true}],
    country: [{value: this.quote.sourceAddress.country, disabled: true}],
    addrLine1:[''],
    addrLine2:[''],
    addrTypeId:['', Validators.required],

  });

  }

  loadEndAddrFormData(){

      this.endLocationForm = this._formBuilder.group({

      zipCode: [this.quote.destinationAddress.zipCode, Validators.required],
      city: {value: this.quote.destinationAddress.city, disabled: true},
      state: [{value: this.quote.destinationAddress.state, disabled: true}],
      country: [{value: this.quote.destinationAddress.country, disabled: true}],
      addrLine1:[''],
      addrLine2:[''],
      addrTypeId: ['',Validators.required],
    });

  }

  currentPincode;

  loadZipCodeData(event) {

    this.isSPincodeReleased = true;

    let pinCode =  event.srcElement.value;//formData["zipCode"];
    this.currentPincode = pinCode;
    if( pinCode == "" || pinCode.length < 5) {
      this.startLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }

    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 
      if( this.currentPincode == pinCode) {
        this.quote.sourceAddress.zipCode = pinCode;
        this.quote.sourceAddress.city    = value["data"]["city"];
        this.quote.sourceAddress.state   = value["data"]["state"];
        this.quote.sourceAddress.country = value["data"]["country"].trim();
        
        this.startLocationForm.patchValue  ({
          'zipCode': this.quote.sourceAddress.zipCode,
          'city': this.quote.sourceAddress.city,
          'state': this.quote.sourceAddress.state,
          'country': this.quote.sourceAddress.country.trim(),
        });
      
        this.isAddrAvailable = true;
      }
      
    });

    promise_.catch(error => { // on error and other than http code:200

      if( this.currentPincode == pinCode) {
        this.isAddrAvailable = false;
        this.errMsg =  error['message'];
        // this.quote.sourceAddress.zipCode = pinCode;
        // this.quote.sourceAddress.city    = '';
        // this.quote.sourceAddress.state   = '';
        // this.quote.sourceAddress.country = '';

        this.startLocationForm.patchValue ({
          'zipCode': '',
          'city':'',
          'state': '',
          'country': '',
        });
      }

      
    });

  }
  // Functionalty to load end address  
  loadEndLocZipCodeData(event) {
    this.isCPincodeReleased = true;
    let pinCode =  event.srcElement.value; // formData["zipCode"];
    if( pinCode == "" || pinCode.length < 5) {
      this.endLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
      return ;
    }
    let url = ServerURLS.geoLocationDetails.action + "/" + pinCode;

    let promise_ = this.scHttpService.getHttpService(url, '');
    promise_.then(value => { 

      this.quote.destinationAddress.zipCode = pinCode;
      this.quote.destinationAddress.city    = value["data"]["city"];
      this.quote.destinationAddress.state   = value["data"]["state"];
      this.quote.destinationAddress.country = value["data"]["country"].trim();

      this.endLocationForm.patchValue ({
        'zipCode': this.quote.destinationAddress.zipCode,
        'city': this.quote.destinationAddress.city,
        'state': this.quote.destinationAddress.state,
        'country': this.quote.destinationAddress.country.trim(),
        
      });
      
      this.isEndAddrAvailable = true;
    
    });

    promise_.catch(error => { // on error and other than http code:200
      this.isEndAddrAvailable = false;
      this.errMsg =  error['message'];
      this.endLocationForm.patchValue ({
        'zipCode': '',
        'city':'',
        'state': '',
        'country': '',
      });
    });
  }

  get f() { return this.quoteForm.controls; }
  get f1() { return this.startLocationForm.controls; }
  get f2() { return this.endLocationForm.controls; }

  isFormValid = true;

  saveQuote () {
    
    if(this.packagingTypeId && this.shpmtModeId && (this.endLocationForm.valid) && (this.startLocationForm.valid)) {

        if(this.shipmentModeName.get(this.shpmtModeId) == 'CRS'){
          this.storageService.removeBucket("items");
        }
      
        this.isFormValid = this.shpmtMode != 'FTL' ? true: this.shpmtTypeId ;

        if( !this.isFormValid ) {
          return;
        }
        this.quote.shipmentMode = this.shpmtModeId;
        this.quote.shpmtTypeId = this.shpmtTypeId;
        this.quote.shpmtPackagingTypeId = this.packagingTypeId
        
        if(this.editQId){
          this.quote.quoteId = this.editQId;
        }
        this.quote.sourceAddress.addrTypeId = this.startLocationForm.value['addrTypeId'];
        this.quote.destinationAddress.addrTypeId  = this.endLocationForm.value['addrTypeId'];
        this.quote.sourceAddress.zipCode = this.startLocationForm.value['zipCode'];
        this.quote.destinationAddress.zipCode = this.endLocationForm.value['zipCode'];
        
        this.quote.hasSourceServicesOpted = (this.choosenStartServiceTypes.size > 0);
        this.quote.hasDestServicesOpted   = (this.choosenEndServiceTypes.size > 0);
        
        // 02-05-2020 Used to set start source extra service
        if( this.quote.hasSourceServicesOpted ){
          
          for (let startServiceId of this.choosenStartServiceTypes) {

              let startService = this.startLocationExtraServiceMap.get(startServiceId);
              this.startExtrsService.push( startService); 
        
          }
        }
        this.quoteLocStorage.startExtServices = this.startExtrsService;

        // Used to set end source extra service

        if( this.quote.hasDestServicesOpted ){
          for (let endServiceId of this.choosenEndServiceTypes) {

            let endService = this.endLocationExtraServiceMap.get(endServiceId);
            this.endExtrsService.push( endService); 

          }
        }

        this.quoteLocStorage.endExtServices = this.endExtrsService;
        this.storageService.saveBucket("quoteService", this.quoteLocStorage);

      // concat totalExtraservices
        this.quoteReqstForm.extraServcType = this.choosenStartServiceTypes;

        // add destination services to the quote
        for (let service of this.choosenEndServiceTypes) {  
          this.quoteReqstForm.extraServcType.add( service ); 
        } 

        // since the localstorage doesn't store the set object conversion to array is must
        this.quote.extraServices = Array.from(this.quoteReqstForm.extraServcType.values()); 
        this.storageService.saveBucket("quote",  this.quote);
        
        let url = "";
        if(this.editQId){
          url = "shipcarte/quote/quote-item-view;quote-id=" + this.editQId;

        } else {
          url = "shipcarte/quote/quote-item-view";
        }
        this.router.navigateByUrl(url);
  
      } else {
          this.isFormValid = false;
      }

    }
    
    cancel(){
      this.router.navigateByUrl("shipcarte/dashboard");
    }

    // Edit data :

    getQuote(): void {
    
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuote.action + "?quoteId=" + this.editQId,'');
      promise1.then( value => {
        let response = value["data"];

        if(this.editQId){
          this.storageService.saveBucket("quote", {});

          this.getSourceAddress(response["sourceAddr"]);
          this.getDestAddress(response["destAddr"]);
          this.getShipmentService();

          this.startLocationForm.patchValue(response["sourceAddr"]);
          this.endLocationForm.patchValue(response["destAddr"]);
      
        }
      
      });

    }

    getSourceAddress(id): void {
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
      promise1.then( value => {
        let response = value["data"];

        if(response){
          this.startLocationForm.patchValue ({
            'zipCode': response["zipCode"],
            'city': response["city"],
            'state': response["state"],
            'country': response["country"].trim(),
            'addrTypeId': response["addrTypeId"]
          });
          
          this.locationStrtTypeServcsList(response["addrTypeId"],'start');

          this.quote.sourceAddress.zipCode = response["zipCode"];
          this.quote.sourceAddress.city    = response["city"];
          this.quote.sourceAddress.state   = response["state"];
          this.quote.sourceAddress.country = response["country"].trim();
          this.quote.sourceAddress.addressId = response["addressId"];
 
          let quote = this.storageService.getBucket("quote");
          quote["sourceAddress"] = this.quote.sourceAddress;
          this.storageService.saveBucket("quote", quote);
          
        }

      });

    }

    getDestAddress(id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
      promise1.then( value => {
        let response = value["data"];

        if(response){
          this.endLocationForm.patchValue ({
            'zipCode': response["zipCode"],
            'city': response["city"],
            'state': response["state"],
            'country': response["country"].trim(),
            'addrTypeId': response["addrTypeId"],
            'addressId': response["addressId"]
          });

          this.locationEndTypeServcsList(response["addrTypeId"],'end');
          
          this.quote.destinationAddress.zipCode = response["zipCode"];
          this.quote.destinationAddress.city    = response["city"];
          this.quote.destinationAddress.state   = response["state"];
          this.quote.destinationAddress.country = response["country"].trim();
          this.quote.destinationAddress.addressId = response["addressId"];
        
          let quote = this.storageService.getBucket("quote");
          quote["destinationAddress"] = this.quote.destinationAddress;
          this.storageService.saveBucket("quote", quote);
          
        }
      
      });

    }

    getShipmentService(): void {
    
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteService.action + "?quoteId=" + this.editQId,'');
      promise1.then( value => {
        let response = value["data"];
        
        if(response){

          this.optedServices = response["extraServices"];
          this.shpmtModeId = response["shipmentMode"];
          this.shpmtTypeId = response["shpmtTypeId"];
          this.packagingTypeId = response["shpmtPackagingTypeId"];

          this.packagingType = this.shipmentPackagingType.get(this.packagingTypeId);
          this.storageService.saveBucket("shipment",  this.packagingType);

        }
        if(response["shpmtTypeId"] != null){

          this.shpmtModeTemp = response["shpmtTypeId"];
          this.shipmentTypeList(response["shipmentMode"]);

        }
    
        this.storageService.saveBucket("quote", response);
        this.loadDataFromStorage();
      });

    }
    
    // This method is used load data from storge when quote is not saved to db
    loadDataFromStorage(){

      this.quoteShmptData = this.storageService.getBucket("quote");

      if( this.quoteShmptData ){

        this.optedServices = this.quoteShmptData.extraServices;

        this.shpmtModeId = this.quoteShmptData.shipmentMode;
        this.shpmtModeTemp = this.quoteShmptData.shpmtTypeId;
        this.shipmentTypeList(this.shpmtModeId);
        this.packagingTypeId = this.quoteShmptData.shpmtPackagingTypeId;

        this.startLocationForm.patchValue({
          'zipCode': this.quoteShmptData.sourceAddress.zipCode,
          'addrTypeId': this.quoteShmptData.sourceAddress.addrTypeId,
        });
        this.quote.sourceAddress.city = this.quoteShmptData.sourceAddress.city;
        this.quote.sourceAddress.country  = this.quoteShmptData.sourceAddress.country;
        this.quote.sourceAddress.state  = this.quoteShmptData.sourceAddress.state

        this.endLocationForm.patchValue({
          'zipCode': this.quoteShmptData.destinationAddress.zipCode,
          'addrTypeId': this.quoteShmptData.destinationAddress.addrTypeId
        });

        this.quote.destinationAddress.city = this.quoteShmptData.destinationAddress.city;
        this.quote.destinationAddress.country  = this.quoteShmptData.destinationAddress.country;
        this.quote.destinationAddress.state   = this.quoteShmptData.destinationAddress.state;

        this.locationStrtTypeServcsList(this.quoteShmptData.sourceAddress.addrTypeId,'start');
        this.locationEndTypeServcsList(this.quoteShmptData.destinationAddress.addrTypeId,'end');

      }

    }
    
  }