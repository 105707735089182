import { Injectable } from '@angular/core';
import { ScStorageService } from '../storage/sc-storage.service';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from '../http/sc-http.service';
import { DatePipe } from '@angular/common';
import { ServerURLS, EnvironmentConstants } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as html2pdf from 'html2pdf.js';
import { EmailDocumentComponent } from 'src/app/modules/commons/email-document/email-document.component';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScCommonService {
  customerId: any = sessionStorage.getItem('customerId') ? sessionStorage.getItem('customerId') : null;
  // customerName: any = sessionStorage.getItem('customerName') ? sessionStorage.getItem('customerName') : null;
  partnerId: any = sessionStorage.getItem('partnerId') ? sessionStorage.getItem("partnerId") : null;
  orderDetailProbill: any;
  bolResultData: any;
  quoteId: any;
  totalItemBol: number;
  declaredBol: number;
  dimensionBOL: any;
  bolInvoiceDetails: any;
  printInvoiceFromInvoices: boolean = false;
  carrierRates: any;
  currencyInSaleRep: any = '';
  shipperZIPCode:any;
  consigneeZIPCode:any;
  bolId:any
  commResultData:any;

  public data = new BehaviorSubject<any>({
    customerStatus: '',
    customerName: '',
    carrierContrat: '',
    toggle: false,
    appLoader: false
  })
  public orderDetailPageData=new BehaviorSubject<any>({
    orderStatus:''
  })
  public dashboardArray=new BehaviorSubject<any>({
    sideValue:[]
  })
  public currentData: any;
  public orderDetailCurrentData:any;
  public dashboardData:any
  // public PARTNERID:any;

  sharedData:any;
  saveBolFormData: any;
  saveCommInvFormData: any;
  saveCarrierContractFormData: any;
  currentRouteString: any;
  private previouslyVisitedUrls: string[] = [];

  constructor(private storageService: ScStorageService, private scHttpService: ScHttpService, private datePipe: DatePipe, private modalService: NgbModal, private toastr: ToastrService, private router: Router) {
    this.currentData = this.data.asObservable();
    this.currentData.subscribe((data:any)=>{
      this.sharedData=data
    })
    this.orderDetailCurrentData=this.orderDetailPageData.asObservable();
    this.dashboardData=this.dashboardArray.asObservable();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentRouteString = event.urlAfterRedirects || event.url;
      this.trackUrl(this.currentRouteString);
    });
  }

  private trackUrl(url: string): void {
    // Check if the URL should be saved
    if (this.shouldSaveUrl(url)) {
      this.previouslyVisitedUrls.push(url);
    }
    // if(this.lastUrl){
    //   this.previouslyVisitedUrls.pop();
    // }
    console.log(this.previouslyVisitedUrls);
  }

  mandatoryUrls = [
    //Orders
    '/shipcarte/orders', //no url for in-transit, completed, scheduled
    // '/shipcarte/orders/orderDetail',

    //Quote History
    '/shipcarte/quoteHistory/allQuotes', //no url for drafted and executed
    // '/shipcarte/quoteHistory/quoteDetails',

    //Customers
    '/shipcarte/customer/list', //no url for pending, active, inactive
    // '/shipcarte/customer/list/generalDetail/edit',
    '/shipcarte/customer/list/generalDetail/edit/users',
    '/shipcarte/customer/list/generalDetail/edit/orders',


    //Partners
    '/shipcarte/partner', //no url for active and inactive partner
    // '/shipcarte/partner/generalDetails/edit',
    '/shipcarte/partner/generalDetails/edit/orderHistory',
    '/shipcarte/partner/generalDetails/edit/invoice',

    //Sales Rep
    '/shipcarte/salesrep',
    '/shipcarte/salesrep/generalDetails/edit/reports',
    '/shipcarte/salesrep/generalDetails/edit/associatedCustomer',
    '/shipcarte/salesrep/generalDetails/edit/associatedOrder',

    //Invoices
    '/shipcarte/allinvoices',
    '/shipcarte/allinvoices/invoice-processing/toAudit',
    '/shipcarte/allinvoices/invoice-processing/toInvoice',
    '/shipcarte/allinvoices/contractprocessing',

    //Settings
    '/shipcarte/settings/userManagement',

  ];
  private shouldSaveUrl(url: string): boolean {
    // Define your logic here to determine if the URL should be saved
    // For example, save URLs that match a specific pattern or condition
    if (this.mandatoryUrls.includes(url)) {
      if (this.previouslyVisitedUrls.length > 0) {
        const lastString = this.previouslyVisitedUrls[this.previouslyVisitedUrls.length - 1];
        if(lastString === url) {
          return false;
        }else{
          return true;
        }
      } else {
        return true;
      }
    } else {
      // this.lastUrl = '';
      return false;
    }
  }

  lastUrl:string='';
  navigateBack(): void {
    // Pop the last URL from the array
    let lastUrl = this.previouslyVisitedUrls.pop();
    
    if (lastUrl == this.currentRouteString) {
      let secondLastUrl = this.previouslyVisitedUrls.pop();
      if(secondLastUrl) {
        // Navigate to the second last saved URL
        this.router.navigateByUrl(secondLastUrl);
      }else{
        this.router.navigateByUrl('/shipcarte/dashboard');
      }
    } else if (lastUrl != this.currentRouteString) {
      // Navigate to the last saved URL
      this.router.navigateByUrl(lastUrl);
    } else {
      // Handle the case when there are no saved URLs
      // For example, navigate to a default route
      this.router.navigateByUrl('/shipcarte/dashboard');
    }
    console.log(this.previouslyVisitedUrls);
  }

  clearCustomHistory(): void {
    this.previouslyVisitedUrls = [];
  }

  removeQuoteStorage() {
    this.storageService.removeBucket("quote");
    this.storageService.removeBucket("items");
    this.storageService.removeBucket("shipment");
    this.storageService.removeBucket("quoteService");
  }

  formatDate(d: NgbDate): string {

    if (d)
      return [
        d.year,
        (d.month < 10 ? ('0' + d.month) : d.month),
        (d.day < 10 ? ('0' + d.day) : d.day)
      ].join('-');

    else return null;
  }

  formatNgBDate(date: string) {
    let dt = new Date(date);
    let ngbDateStruct = { day: dt.getUTCDate(), month: dt.getUTCMonth() + 1, year: dt.getUTCFullYear() };
    return ngbDateStruct;
  }


  changedateformat(date) {
    let split = date.split('-');
    let dt = new Date(split[2], split[1] - 1, split[0]);
    let date1 = this.datePipe.transform(dt, "yyyy-MM-dd");
    return date1;
  }

  getLocalProfileImage() {
    return this.storageService.getBucket("profileImage");
  }
  dateFormatter(fromDate: any, toDate: any) {
     
    let fromDateObj = {
      date: '',
      month: '',
      year: ''
    }
    let toDateObj = {
      date: '',
      month: '',
      year: ''
    }
    fromDateObj.year = fromDate.year;
    toDateObj.year = toDate.year;
    let FROMDATE = fromDate.day;
    let FROMMONTH = fromDate.month;
    if (FROMDATE < 10) {
      FROMDATE = "0" + FROMDATE
      fromDateObj.date = FROMDATE
    } else {
      fromDateObj.date = FROMDATE
    }
    if (FROMMONTH < 10) {
      FROMMONTH = "0" + FROMMONTH
      fromDateObj.month = FROMMONTH
    } else {
      fromDateObj.month = FROMMONTH
    }

    let TODATE = toDate.day;
    let TOMONTH = toDate.month;
    if (TODATE < 10) {
      TODATE = "0" + TODATE;
      toDateObj.date = TODATE;
    } else {
      toDateObj.date = TODATE;
    }
    if (TOMONTH < 10) {
      TOMONTH = '0' + TOMONTH;
      toDateObj.month = TOMONTH
    } else {
      toDateObj.month = TOMONTH;
    }
    // console.log(fromDateObj, toDateObj)
    return [fromDateObj, toDateObj]
  }
  getProfileImage(id): any {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getImage.action + "?id=" + id, '');
    promise1.then(value => {
      let image = EnvironmentConstants.NO_PROFILE_IMAGE;
      if (value["data"]) {
        image = value["data"]["downloadLink"];

      }
      this.storageService.saveBucket("profileImage", image);

    });

    return promise1;

  }
  getShipmentLabelUrls(orderId): any {
    let promise = this.scHttpService.getHttpService(ServerURLS.getOrderLabel.action + '?orderId=' + orderId, { withCredentials: true });
    return promise;
  }

  async dialogOpenForEmail(requiredDataObj) {

    const {modelToOpn, action, defaultEmail, quoteId, orderId, bolDocId, customOrderId, commInvDocId} = requiredDataObj;

    if (modelToOpn === "addFav") {
      // const modalRef = this.modalService.open(AddFavoriteComponent, {size: 'lg',centered: false,backdrop: true, windowClass: 'my-custom-modal3'});
      // this.router.events.subscribe((val) => {
      //   modalRef.close();
      // });
      // modalRef.componentInstance.quoteId = quoteId;
      // modalRef.componentInstance.orderId = this.orderId;
      // modalRef.result.then((result) => {
      //   if(result['success'] == true){
      //     this.toastr.success('Order saved as a favorite.', 'Success', {
      //       timeOut: 30000,
      //     });
      //     localStorage.setItem("fav_"+quoteId, "true");
      //   }
      // });
    }
    if (modelToOpn === "emailDoc") {
      // if (localStorage.getItem("com_documentId_" + quoteId) == null && action == "commercial") {
      //   this.toastr.error('First create commercial invoice.', 'Please', {
      //     timeOut: 30000,
      //   });
      //   return;
      // }
      
      const requiredDataObjToSavePdf = {
        quoteId: quoteId,
        orderId: orderId,
        bolDocId: bolDocId,
        customOrderId: customOrderId
      }
      if(action == 'bol'){
        this.saveBolFormData = await this.saveBolPdf(requiredDataObjToSavePdf);
      }else if(action == 'commercial'){
        this.saveCommInvFormData = await this.saveCommercialPdf(requiredDataObjToSavePdf);
      }else if(action == 'carrier-contract'){
        this.saveCarrierContractFormData = await this.saveCarrierContractPdf(requiredDataObjToSavePdf);
      }
      const modalRef = this.modalService.open(EmailDocumentComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId = bolDocId;
      modalRef.componentInstance.commInvDocId = commInvDocId;
      modalRef.componentInstance.action = action;
      modalRef.componentInstance.defaultEmail = defaultEmail;
      modalRef.componentInstance.saveBolFormData = this.saveBolFormData;
      modalRef.componentInstance.saveCommInvFormData = this.saveCommInvFormData;
      modalRef.componentInstance.saveCarrierContractFormData = this.saveCarrierContractFormData;
      modalRef.result.then((result) => {
        if (result.success) {
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        } else {
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }
  }

  async saveCommercialPdf(requiredDataObj):Promise<FormData> {
    // let currentcomp = this;
    this.sharedData.appLoader = true;
    // await html2canvas(document.querySelector('#DownloadCommercialPdfData'), { useCORS: false }).then(function (canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = () => {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendCommercialPDFToServer(blobData);
    //   };
    // });

    var element = document.querySelector('#DownloadCommercialPdfData');
    var opt = {
      margin: 0.1,
      filename: "Commercial_Invoice_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })

    return this.sendCommercialPDFToServer(worker, requiredDataObj);
  }
  sendCommercialPDFToServer(pdfContent, requiredDataObj) {
    // // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    formdata.append("orderId", requiredDataObj.orderId);
    formdata.append("quoteId", requiredDataObj.quoteId);
    // formdata.append("commInvId", this.commercialInvoiceId)

    var date = new Date().valueOf();

    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });



    var dummyFile = new File([pdfContent], "COM_" + requiredDataObj.customOrderId + ".pdf");
    formdata.append("file", dummyFile);
    this.sharedData.appLoader=false;
    return formdata;

    // this.docService.saveCommercialPdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
    //     timeOut: 5000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     localStorage.clear();
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
    //     timeOut: 5000,
    //   });
    //   this.displayLoader = false;
    // });
  }

  async saveBolPdf(requiredDataObj):Promise<FormData> {
    this.sharedData.appLoader = true;
    // let currentcomp = this;
    // html2canvas(document.querySelector('#content'),{useCORS:false}).then(function(canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = function () {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendPDFToServer(blobData);
    //   };
    // });
    var element = document.querySelector('#contentbol');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // // console.log(worker);
    return this.sendBolPDFToServer(worker, requiredDataObj);
  }

  sendBolPDFToServer(pdfContent, requiredDataObj) {

    const formdata: FormData = new FormData();
    formdata.append("documentId", requiredDataObj.bolDocId);
    formdata.append("orderId", requiredDataObj.orderId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "BOL_" + requiredDataObj.bolDocId + ".pdf");
    formdata.append("file", dummyFile);

    this.sharedData.appLoader=false;
    return formdata;
    // this.docService.savePdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('PDF Saved Successfully.', '', {
    //     timeOut: 10000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
    //     timeOut: 10000,
    //   });
    //   this.displayLoader = false;
    // });

  }

  async saveCarrierContractPdf(requiredDataObj):Promise<FormData> {
    this.sharedData.appLoader = true;
    var element = document.querySelector('#contentContract');
    var opt = {
      margin: 0.1,
      filename: "Carrier-Contract_" + requiredDataObj.customOrderId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // // console.log(worker);
    return this.sendCarrierContractPDFToServer(worker, requiredDataObj);
  }

  sendCarrierContractPDFToServer(pdfContent, requiredDataObj) {

    const formdata: FormData = new FormData();
    formdata.append("orderId", requiredDataObj.orderId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "Carrier-Contract_" + requiredDataObj.customOrderId + ".pdf");
    formdata.append("file", dummyFile);

    this.sharedData.appLoader=false;
    return formdata;

  }

}
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('-');
    return { month: +parts[1], day: +parts[2], year: +parts[0] } as NgbDateStruct

  }
  format(date: NgbDateStruct): string {
    return date ? ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2) + '-' + date.year : null
  }
}
export class UnitedStatesDateParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value != null) {
      const parts = value.split('/');
      if (parts.length === 3 && this.isNumber(parts[0]) && this.isNumber(parts[1]) && this.isNumber(parts[2])) {
        return { month: parseInt(parts[0]), day: parseInt(parts[1]), year: parseInt(parts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date && this.isNumber(date.day) && this.isNumber(date.month) && this.isNumber(date.year)
      ? `${this.padNumber(date.month)}-${this.padNumber(date.day)}-${date.year}`
      : '';
  }

  private isNumber(value: any): value is number {
    return !isNaN(parseInt(value));
  }

  private padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

}

