import { Component, OnInit,Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuoteLocation } from '../../quote/entity/quotelocation';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';
import { QuoteDelvryForm } from '../../quote/entity/quotedelvry';
import * as _ from 'lodash';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quote-location',
  templateUrl: './quote-location.component.html',
  styleUrls: ['./quote-location.component.scss']
})
export class QuoteLocationComponent implements OnInit {

  quoteId: string;
  quote: any;
  sourceAddress:any;
  destAddress:any;
  pickupTimes:any;
  customsData:any;
  quoteLocationForm: FormGroup;
  quoteLocation: QuoteLocation;
  startLocationForm: FormGroup;
  endLocationForm: FormGroup;
  customTypes:any;
  successMsg:string;
  errMsg:string;
  deliveryData:any;
  isEdit:string;
  clonedOrderId:string;
  isClonedOrder: boolean = false;
  rankMap: Map<any, any>;
  timeMap: Map<any, any>;

  startIndex: number;
  endIndex; number;

  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  isDeliveryComp: boolean;
  isStartLocComp: boolean;
  isEndLocComp: boolean;

  isSrcAddrChecked: boolean;
  isDestAddrChecked: boolean;

  displayTel: string;
  minDate: any; 

  constructor( private modalService: NgbModal, private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private route: ActivatedRoute, private router: Router, private _commonServices: ScCommonService ) { 

    this.quoteLocation = new QuoteLocation();
    this.rankMap = new Map();
    this.timeMap = new Map();
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
  };
  }

  ngOnInit() {

    this.quoteId = this.route.snapshot.paramMap.get('quote-id');
    this.isEdit = "true";

    if(this.quoteId){

      this.getTimes();

      this.getQuote();
      this.loadSourceAddrFormData();
      this.loadDestinationAddrFormData();
      this.getQuoteCustomsDetails();
      this.getCustomFromTypes();

      this.getQuoteDeliveryDetails();
    }

    // if(this.isEdit){
    //   this.getQuoteDeliveryDetails();
    // }

    this.loadQuoteLocationFormData();

  }

  onDateSelect ( event ) {
    this.quoteLocationForm.get('pickupDate').setValue(this._commonServices.formatDate(event));
  }

  skip(){

    if(this.quoteId){
      this.router.navigateByUrl("shipcarte/quote/v2-document;quote-id=" + this.quoteId);
    }
    
  }
  
  loadQuoteLocationFormData(){

    this.quoteLocationForm = this._formBuilder.group({

      pickupDate1:['',Validators.required],
      pickupDate:[''],
      pickupFromTime:[],
      pickupToTime:[],
      customFormTypeId: [],
      hasCustoms: [''],
      customsShpmtValue: []
   });
  }

  srcZipcode: any;
  destZipcode:any;
 loadSourceAddrFormData(){


  this.startLocationForm = this._formBuilder.group({

    zipCode: [this.quoteLocation.sourceAddress.zipCode],
    city: {value: this.quoteLocation.sourceAddress.city, disabled: true},
    state: [{value: this.quoteLocation.sourceAddress.state, disabled: true}],
    country: [{value: this.quoteLocation.sourceAddress.country, disabled: true}],
    addrTypeId:[{value: this.quoteLocation.sourceAddress.addrTypeId, disabled: true}],
    locationType: [{value: this.quoteLocation.sourceAddress.locType, disabled: true}],
    addressId: [{value: this.quoteLocation.sourceAddress.addressId, disabled: true}],
    addrLine1:[, Validators.required],
    addrLine2:[, Validators.required],
    firstName: [, Validators.required],
    lastName: [],
    company: [, Validators.required],
    email: [, [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
    phoneNumber: [, Validators.required],
    alternatePhoneNumber: [, Validators.pattern(this.PHONE_REGEXP)],
    dept:[],
    fax: [],
    //hasAddrBook: ['']
  }); 

}

loadDestinationAddrFormData(){

  this.endLocationForm = this._formBuilder.group({

      zipCode: [this.quoteLocation.destinationAddress.zipCode],
      city: {value: this.quoteLocation.destinationAddress.city, disabled: true},
      state: [{value: this.quoteLocation.destinationAddress.state, disabled: true}],
      country: [{value: this.quoteLocation.destinationAddress.country, disabled: true}],
      addrTypeId:[{value: this.quoteLocation.destinationAddress.addrTypeId, disabled: true}],
      locationType: [{value: this.quoteLocation.destinationAddress.locType, disabled: true}],
      addressId: [{value: this.quoteLocation.destinationAddress.addressId, disabled: true}],
      addrLine1:[, Validators.required],
      addrLine2:[, Validators.required],
      firstName: [, Validators.required],
      lastName: [],
      company: [, Validators.required],
      email: [, [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP) ]],
      phoneNumber: [, Validators.required],
      alternatePhoneNumber: [, Validators.pattern(this.PHONE_REGEXP)],
      dept:[],
      fax: [],
      //hasAddrBook: ['']
  });

}

getQuote():void {

  let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuote.action + "?quoteId=" + this.quoteId,'');
  promise1.then( value => {
    this.quote = value["data"];

    if(this.quote["clonedOrderId"]) {
       this.isClonedOrder = true;
       this.startLocationForm.disable();
       this.endLocationForm.disable();
    }

    if(this.quote){
      this.getSourceAddress(this.quote["sourceAddr"]);
      this.getDestAddress(this.quote["destAddr"]);
    }
  });
 
}
 
  getSourceAddress(id): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {

      this.quoteLocation.sourceAddress.zipCode = value["data"]["zipCode"];
      this.quoteLocation.sourceAddress.city    = value["data"]["city"];
      this.quoteLocation.sourceAddress.state   = value["data"]["state"];
      this.quoteLocation.sourceAddress.country = value["data"]["country"];
      this.quoteLocation.sourceAddress.addrTypeId = value["data"]["addrTypeId"];
      this.quoteLocation.sourceAddress.addressId = value["data"]["addressId"];
      this.quoteLocation.sourceAddress.locType = value["data"]["locationType"];

      this.startLocationForm.patchValue ({
        'zipCode':  this.quoteLocation.sourceAddress.zipCode,
        'city':  this.quoteLocation.sourceAddress.city,
        'state': this.quoteLocation.sourceAddress.state ,
        'country':  this.quoteLocation.sourceAddress.country,
        'addrTypeId':  this.quoteLocation.sourceAddress.addrTypeId,
        'addressId':  this.quoteLocation.sourceAddress.addressId,
        'locType':this.quoteLocation.sourceAddress.locType
        
      });
      this.sourceAddress =value["data"];
      this.startLocationForm.patchValue ({dept: value["data"]["dept"]});
      this.startLocationForm.patchValue (value["data"]);
      this.srcZipcode = this.quoteLocation.sourceAddress.zipCode;
      // // console.log("In Quote Location Page");
      // // console.log(this.srcZipcode);
    });

  }

  getDestAddress(id): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getAddressById.action + "?id=" + id,'');
    promise1.then( value => {
      this.quoteLocation.destinationAddress.zipCode = value["data"]["zipCode"];
      this.quoteLocation.destinationAddress.city    = value["data"]["city"];
      this.quoteLocation.destinationAddress.state   = value["data"]["state"];
      this.quoteLocation.destinationAddress.country = value["data"]["country"];
      this.quoteLocation.destinationAddress.addrTypeId = value["data"]["addrTypeId"];
      this.quoteLocation.destinationAddress.addressId = value["data"]["addressId"];
      this.quoteLocation.destinationAddress.locType = value["data"]["locationType"];

      this.endLocationForm.patchValue ({
        'zipCode':  this.quoteLocation.destinationAddress.zipCode,
        'city':  this.quoteLocation.destinationAddress.city,
        'state': this.quoteLocation.destinationAddress.state ,
        'country':  this.quoteLocation.destinationAddress.country,
        'addrTypeId':  this.quoteLocation.destinationAddress.addrTypeId,
        'addressId':  this.quoteLocation.destinationAddress.addressId,
        'locType':this.quoteLocation.destinationAddress.locType
        
      });

      this.destAddress =value["data"];
       this.endLocationForm.patchValue ({
       dept: value["data"]["dept"]});
       this.endLocationForm.patchValue (value["data"]);
       this.destZipcode = this.quoteLocation.destinationAddress.zipCode;
      
    });

  }

  get f() { return this.quoteLocationForm.controls; }
  get f1() { return this.startLocationForm.controls; }
  get f2() { return this.endLocationForm.controls; }

  getTimes(): void {

    this.startIndex = 0;
    
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getPickupTimes.action,'');
    promise1.then( value => {
      this.pickupTimes = value["data"];
      this.endIndex = this.pickupTimes.length;

      this.pickupTimes.forEach(element => {
        this.rankMap.set(element.pickupId, element.rankAssigned);
        this.timeMap.set(element.pickupTime, element.pickupId);
      });
     
    });

  }

  getFromTime(event){

    let startRank = this.rankMap.get(event.target.value);
    this.startIndex = startRank;
    this.endIndex = this.pickupTimes.length;
  
    let endTime = this.quoteLocationForm.get('pickupToTime').value;
    let endRank = this.rankMap.get(endTime);
    if( startRank >= endRank ) {
        this.quoteLocationForm.patchValue({'pickupToTime': ''});
      }
  }

  getCustomFromTypes(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getCustomTypes.action,'');
    promise1.then( value => {
      this.customTypes = value["data"];
      
    });

  }

  saveQuoteLocation () {

    let form_data = this.quoteLocationForm.getRawValue();
    this.quoteLocation.sourceAddress =   this.startLocationForm.getRawValue();
    this.quoteLocation.destinationAddress = this.endLocationForm.getRawValue();
   
    this.quoteLocation.customFormTypeId = form_data.customFormTypeId;
    this.quoteLocation.hasCustoms = form_data.hasCustoms;
    this.quoteLocation.customsShpmtValue = form_data.customsShpmtValue;
    this.quoteLocation.isClonedOrder = this.isClonedOrder;

    form_data.pickupFromTime = this.timeMap.get('00:00 AM');
    form_data.pickupToTime = this.timeMap.get('23:00 PM');

    let quoteDelviery = new QuoteDelvryForm ( this.quoteId,form_data.pickupDate, form_data.pickupFromTime,form_data.pickupToTime, '' );
    this.quoteLocation.scQuoteDelvryForm = quoteDelviery;

    if(this.isEdit){
      this.quoteLocation.scQuoteDelvryForm.deliveryId = this.deliveryData.deliveryId;
    }

    let oldSourceAddr = this.sourceAddress;
    let oldDestAddr = this.destAddress;

    let newSourceAddr = this.quoteLocation.sourceAddress;
    let newDestAddr = this.quoteLocation.destinationAddress;

    if(this.isClonedOrder) {
      
      if(!(_.isEqual(oldSourceAddr, newSourceAddr))){
        this.quoteLocation.hasChangeInSourceAddr = true;
      }

      if(!(_.isEqual(oldDestAddr, newDestAddr))){
        this.quoteLocation.hasChangeInDestAddr = true;
      }
    }

    if(this.isSrcAddrChecked){
      this.quoteLocation.sourceAddress["hasAddrBook"] = true;
    }

    if(this.isDestAddrChecked){
      this.quoteLocation.destinationAddress["hasAddrBook"] = true;
    }
    
    let url = "";
    if(this.isEdit){
       url = ServerURLS.updateQuoteLocation.action+"?isEdit=true"
    } else {

      url = ServerURLS.updateQuoteLocation.action
    }
    let authPromise = this.scHttpService.putHttpService(url, this.quoteLocation);
      
      authPromise.then( (resolve) => {
        let response = resolve;
        this.successMsg = response["message"];
        this.router.navigateByUrl("shipcarte/quote/v2-document;quote-id=" + this.quoteId);
        
      }).catch((error) => {
        this.errMsg = error.error;
        this.successMsg = '';
     
      });
    }

  getQuoteCustomsDetails(): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteCustomDetails.action + "?quoteId=" + this.quoteId,'');
    promise1.then( value => {
      this.customsData = value["data"];
      this.quoteLocationForm.patchValue (value["data"]);
      
    });

  }

  getQuoteDeliveryDetails(): void {

  // this.isDeliveryComp = true;

    let promise1 = this.scHttpService.getHttpService( ServerURLS.getQuoteDeliveryDetails.action + "?quoteId=" + this.quoteId,'');
    promise1.then( value => {
      this.deliveryData = value["data"];
      this.quoteLocationForm.get("pickupDate1").setValue(this._commonServices.formatNgBDate(this.deliveryData["pickupDate"]));
      this.quoteLocationForm.patchValue (value["data"]);

      //Rank based pickup to time set up
      let rank = this.rankMap.get(this.deliveryData["pickupFromTime"]);
      this.startIndex = rank;
      this.endIndex = this.pickupTimes.length;
    });

  }
 
  resetAddress(id, addrType){

    if(addrType == 'start'){
      this.getSourceAddress(id);
      this.quoteLocation.hasChangeInSourceAddr = false;

    } else {
      this.getDestAddress(id);
      this.quoteLocation.hasChangeInDestAddr = false;
    }
    
  }

  changeAddress( addrType ){
    
    if(addrType == 'start'){

     // this.quoteLocation.hasChangeInSourceAddr = true;
      this.startLocationForm.enable();
     // this.startLocationForm.get('locType').disable();
      this.startLocationForm.get('zipCode').disable();
      this.startLocationForm.get('city').disable();
      this.startLocationForm.get('state').disable();
      this.startLocationForm.get('country').disable();

    } else {

      //this.quoteLocation.hasChangeInDestAddr = true;
      this.endLocationForm.enable();
      //this.endLocationForm.get('locType').disable();
      this.endLocationForm.get('zipCode').disable();
      this.endLocationForm.get('city').disable();
      this.endLocationForm.get('state').disable();
      this.endLocationForm.get('country').disable();
    }

  }

  setSrcAddressBook(event){

    let isChecked = event.target.checked;

    if( isChecked) {
      this.isSrcAddrChecked = true;

    } else {
      this.isSrcAddrChecked = false;
    }

  }

  setDestAddressBook(event){

    let isChecked = event.target.checked;

    if( isChecked) {
      this.isDestAddrChecked = true;

    } else {
      this.isDestAddrChecked = false;
    }

  }
  
  saveDeliveryData(){
    this.isDeliveryComp = true;
  }

  saveStartLoc(){
    this.isStartLocComp = true;
  }

  saveEndLoc(){
    this.isEndLocComp = true;
  }

  transform(tel){
    
        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);
        return (country + " (" + city + ") " + number).trim();
    }

    addressSearch(addrSrch) {
      this.modalService.open(addrSrch, {size: 'lg',centered: true,backdrop: 'static',ariaLabelledBy: 'modal-basic-title'});
    }
  
    getAddress(e) {
      this.startLocationForm.patchValue(e);
     }

}
