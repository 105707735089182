import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScCommonService } from 'src/app/services/common/sc-common.service';

@Component({
  selector: 'app-view-comm-inv-quick-action',
  templateUrl: './view-comm-inv-quick-action.component.html',
  styleUrls: ['./view-comm-inv-quick-action.component.scss']
})
export class ViewCommInvQuickActionComponent implements OnInit {
  data:any
  BOLd:any;
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  constructor( private commonService:ScCommonService,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.resultData=this.commonService.commResultData;
    // console.log(this.resultData,'rrsult')
    this.dynamicDimension=this.commonService.dimensionBOL;
    this.declaredValue=this.commonService.declaredBol;
    this.totalItem=this.commonService.totalItemBol;
  }

  closeModal() { this.activeModal.close(); }

}
