
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';





@Component({
  selector: 'app-all-invoice',
  templateUrl: './allinvoice.component.html',
  styleUrls: ['./allinvoice.component.scss']
})

export class AllInvoiceComponent implements OnInit {
  tabs = [
    { id: 1, tabName: "All Invoices" },
    { id: 2, tabName: "Invoice Processing" },
    { id: 3, tabName: "Contract Processing" }
  ];
  selectedTab: number = 1;

  ngOnInit(): void {
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList()
    this.listDataService.removeAllDataOfQuoteHistory()
    this.listDataService.removeAllSalesRepData();
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.setTabId()
  }
  constructor(
    private router: Router,
    private listDataService: ListDataServiceService
  ) {

  }
  selectTab(id) {
    this.selectedTab = id;
    if (id == 1) {
      this.router.navigateByUrl('shipcarte/allinvoices')

    } else if (id == 2) {
      this.router.navigateByUrl('shipcarte/allinvoices/invoice-processing/toAudit')

    }
    else if (id == 3) {
      this.router.navigateByUrl('shipcarte/allinvoices/contractprocessing')

    }
    // if (id == 1) {
    //   this.allInvoices = true;
    //   this.invoiceProcessing = false;
    //   this.contractProcessing = false;
    // }
    // else if (id == 2) {
    //   this.allInvoices = false;
    //   this.invoiceProcessing = true;
    //   this.contractProcessing = false;
    // }
    // else {
    //   this.allInvoices = false;
    //   this.invoiceProcessing = false;
    //   this.contractProcessing = true;
    // }
  }
  setTabId() {
    let urlArray = window.location.href.split('/');
    if (urlArray[urlArray.length - 1] === 'contractprocessing') {
      this.selectedTab = 3
    } else if (urlArray[urlArray.length - 1] === 'invoices') {
      this.selectedTab = 1;
    } else if (urlArray[urlArray.length - 1] === 'toAudit' || urlArray[urlArray.length - 1] === 'toInvoice') {
      this.selectedTab = 2
    }
  }
}


