import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { Offer } from './offer';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss']
})
export class CreateOfferComponent implements OnInit {
  
  offerType: any;
  offerMethod: any;
  offerForm:FormGroup;
  errMsg: any;
  successMsg: string;
  createOffer:Offer;
  selectedOfferType: any;
  today:any;
  offerDetails: any;
  offerId:String;
  allowedDiscount: boolean;
  currentDate: any;
  currUnit: any;
  types: Map<any, any>;
  offerMethodName:string;

  @Output() dataEvent = new EventEmitter();
  @Input() customerId: any;

  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService, 
               private storageService: ScStorageService, private router: Router, 
               private route: ActivatedRoute, private datePipe: DatePipe) { 
             
                this.createOffer = new Offer();
                this.types = new Map();
              }

  ngOnInit() {

    this.offerId = this.route.snapshot.paramMap.get('offer-id');
    this.currentDate = this.datePipe.transform(new Date(),'yyyy-MM-ddThh:mm');

    if( this.offerId ){
      this.getOfferDetails();
    }
    this.loadFormData();
    this.loadOfferTypes();
    this.loadOfferMethods();
    this.loadCurrencyUnits();

    if(this.customerId){
      this.onChangeMethod('Markup', true);
    }
  }

  loadFormData(){
    this.offerForm = this._formBuilder.group({
      offerName:['',Validators.required],
      type:['',Validators.required],
      method:['',Validators.required],
      startDateTime:['',Validators.required],
      endDateTime:['',Validators.required],
      discountAmt:['',Validators.required],
      allowedMaxDiscount:[],
      minAmt:['',Validators.required],
      maxTotalUsage:['',Validators.required],
      uuid:[this.offerId],
      currencyUnit:['',Validators.required],
      custId: [this.customerId]
   });
  }

  get f() { return this.offerForm.controls; }

  loadOfferTypes (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getOfferTypes.action,'');
    promise1.then( value => {
      this.offerType = value["data"];
    });

  }

  loadOfferMethods (): void {
    let promise1 = this.scHttpService.getHttpService( ServerURLS.getOfferMethods.action,'');
    promise1.then( value => {
      this.offerMethod = value["data"];
      let methods: any = [];

      this.offerMethod.forEach(element => {
        this.types.set(element.offerMethodId, element.offerMethodName);

        if(element.offerMethodName == 'Markup'){
          methods.push(element);
        }
      });

      if(this.customerId){
        this.offerMethod = methods;
      }
      
    });
  }

  saveOffer () {
    let form_data = this.offerForm.getRawValue();
    this.createOffer = form_data;
    this.createOffer.type = this.offerForm.value.type.split(',')[0]
    
    this.createOffer.startDateTime = this.datePipe.transform(form_data.startDateTime,'yyyy-MM-dd h:mm a');
    this.createOffer.endDateTime = this.datePipe.transform(form_data.endDateTime,'yyyy-MM-dd h:mm a');

    let authPromise = this.scHttpService.postHttpService(ServerURLS.saveOffer.action, this.createOffer);
        authPromise.then( (resolve) => {
          let response = resolve;
          this.errMsg = '';
          this.successMsg = response["message"];
          this.offerId = response['data'];

          if(this.customerId){
            this.dataEvent.emit(true);

          } else {
            this.router.navigateByUrl("shipcarte/offer/view;offer-id=" + this.offerId );
          }

        }).catch((error) => {
          this.errMsg = error.message;
          this.successMsg = '';
        });
    }

    onSelect(){

      this.selectedOfferType = this.offerForm.value.type.split(',')[1];

      if( this.selectedOfferType == 'Percentage'){
        this.allowedDiscount = true;
        this.offerForm.get('allowedMaxDiscount').setValidators(Validators.required);
      
        if(this.offerDetails.allowedMaxDiscount != null){
          this.offerForm.patchValue ({
            'allowedMaxDiscount': this.offerDetails.allowedMaxDiscount,
          });
        }
      } else {
        this.allowedDiscount = false;
        this.offerForm.patchValue ({
          'allowedMaxDiscount': null,
        });
        this.offerForm.get('allowedMaxDiscount').clearValidators();
      }
      this.offerForm.get('allowedMaxDiscount').updateValueAndValidity();
    }

    getOfferDetails (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getOfferDetails.action +"?offer-id=" +this.offerId ,'');
      promise1.then( value => {
        this.offerDetails = value["data"];

        if( this.offerDetails ) {
          this.selectedOfferType = this.offerDetails.type.split(',')[1];
          this.offerForm.patchValue (this.offerDetails);
          this.offerForm.patchValue ({
            'startDateTime':  this.datePipe.transform(this.offerDetails.startDateTime,'yyyy-MM-ddThh:mm'),
            'endDateTime':  this.datePipe.transform(this.offerDetails.endDateTime,'yyyy-MM-ddThh:mm'),
          });

          if(this.offerDetails.allowedMaxDiscount != null) {
            this.allowedDiscount = true;
          }
        }
      });
    }

    loadCurrencyUnits (): void {
      let promise1 = this.scHttpService.getHttpService( ServerURLS.getAllCurrencyUnits.action,'');
      promise1.then( value => {
        this.currUnit = value["data"];
      });
    }
  
    onChangeMethod(e, isMarkup){
      this.offerMethodName = isMarkup ? e : this.types.get(e);

      if( this.offerMethodName == 'Markup'){
        this.offerForm.get('maxTotalUsage').clearValidators();
        this.offerForm.patchValue ({
          'maxTotalUsage': null,
        });
      }
    }

    cancel(){

      if(this.customerId){
        this.dataEvent.emit(true);
        
      } else {
        this.router.navigateByUrl("/shipcarte/offer/list");
      }
    }
    
}

