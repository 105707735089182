import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerURLS } from '../../../../../environments/environment';
import { ScHttpService } from '../../../../services/http/sc-http.service';
import { CustomValidators } from '../../../custom-validators';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { ScCommonService } from '../../../../services/common/sc-common.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-create-edit-view',
  templateUrl: './create-edit-view.component.html',
  styleUrls: ['./create-edit-view.component.scss']
})

export class CreateEditViewComponent implements OnInit {

  sidebarToggle:boolean;
  currentRoute: string;
  quickBolFormData: any;
  extBolId: string;
  currentPage: string;
  apiLoader: boolean = false;
  pageTitle: string;
  isShipmentModeSelected: boolean = false;
  shipmentName: string = '';
  totalOfQuantity: any = 0;
  totalOfWeight: any = 0.0;
  totalOfShipmentValue: any = 0.0;
  totalofWidth: any = 0.0;
  totalofLength: any = 0.0;
  totalofHeight: any = 0.0;
  totalClass: any;
  setcurrency = ['CAD'];
  measurementType: string = 'imperial';
  activeTab: string = 'summary';
  quickBolCarrierDetails: FormGroup;
  quickBolLogisticsDetails: FormGroup;
  quickBolShipmentItems: FormGroup;
  defaultHazmat: boolean = false;
  accessorialStart: any[] = [];
  accessorialEnd: any[] = [];
  accessorialData: any[] = [];
  accessorialLabelStart: string = 'Select Accessorial';
  accessorialLabelEnd: string = 'Select Accessorial';
  editOrderEnabled: boolean = false;
  shipmentModeCourier: boolean = false;
  filterShipmentMode: any;
  shipmentData: any;
  shipmentModeOptions = [];
  phoneCountryCode = [
    {
      country: 'CA',
      code: '(+1)',
      imgUrl: 'assets/img/shipment/CANADA.svg'
    },
    {
      country: 'US',
      code: '(+1)',
      imgUrl: 'assets/img/shipment/USA.svg'
    }
  ];
  freightClassObj: any;
  freightDisplayDataObj: any;
  locationType: any;
  pickupTimeArr = [];
  shipperPickupDate: NgbDateStruct;
  consigneeDropDate: NgbDateStruct;
  isAddressBookShipper: boolean = false;
  isAddressBookConsignee: boolean = false;
  countryShipperString = '';
  countryConsigneeString = '';
  todaysDate: any;
  minDate:any;
  dateVal = true;
  defaultaddressbookdata: any[];
  addressData: any[];
  ConsigneeAddressData: any[];
  found: any = '';
  found2: any = '';
  selectedShipperAddressCountry:any='';
  selectedCosigneeAddressCountry:any='';
  orderIsWithInCanada:boolean=true;
  classToSet: any[] = [];
  detailsOfCompany: {};
  selectedShpmtModeDetails: any;
  densityCounter = 0;
  isNewShpmtItemArr = [];
  showHideHazmatOptionsIndexArr = [];
  height: any;
  weight: any;
  width: any;
  length: any;
  freightClassApplicable = true;
  displayOrder: number = 1;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private location: Location, 
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private scHttpService: ScHttpService,
  ) {
    // this.changeDetector.detach();
    this.activatedRoute.url.subscribe(segments => {
      this.currentRoute = segments.join('/');
    });

    // Alternatively, you can listen for route navigation events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }


  ngOnInit(): void {
    this.getshipmentData();
    this.getLocationType();
    this.getTimeData();
    this.getDefaultManualAddrList();

    const current = new Date();
    this.config.minDate = { month: current.getMonth() + 1, day: current.getDate(), year: current.getFullYear(), };
    this.config.outsideDays = 'hidden';

    if(this.currentRoute.split('/')[this.currentRoute.split('/').length - 1] == 'create-bol'){
      this.currentPage = 'create-bol';
      this.pageTitle = 'Create Quick BOL';    
      this.createFormGroup();
      
      //call GET api for the Freight Terms data
      this.fetchQuickBolFormData();
     this.changeDetector.detectChanges();
    } else{
      this.extBolId = this.activatedRoute.snapshot.paramMap.get('extBolId');
      if(this.currentRoute.split('/')[this.currentRoute.split('/').length - 2] == 'edit-bol'){
        this.currentPage = this.currentRoute.split('/')[this.currentRoute.split('/').length - 2];
        this.pageTitle = 'Edit Quick BOL'; 
        this.editOrderEnabled = true;
        this.createFormGroup();
        this.fetchQuickBolFormData(this.extBolId);
       this.changeDetector.detectChanges();
      } else {
        this.pageTitle = 'Viewing BOL';
        this.currentPage = this.currentRoute.split('/')[this.currentRoute.split('/').length - 3];
        this.activeTab = this.currentRoute.split('/')[this.currentRoute.split('/').length - 2];
        this.createFormGroup();
        this.fetchQuickBolFormData(this.extBolId);
       this.changeDetector.detectChanges();
      }
    }
  }
  getshipmentData() {
    this.apiLoader = true;
    let getShipmentModeData = ServerURLS.getShipmentModeData.action;
    let promise = this.scHttpService.getHttpService(getShipmentModeData, '');
  
    promise.then((result) => {
      if (Array.isArray(result)) {
        this.shipmentData = result;
        this.shipmentModeOptions = [];
  
        this.shipmentData.forEach((element, index) => {
          if (!element.isDisabled) {
            let pkgTypesArr = [];
            if (element.shippingModeId == '0a13d82b-f7ee-11e9-8462-163046d26cd4') { // if courier
              element.packagingDetails.forEach(pkgInfo => {
                let freshPkgTypeObj = {
                  pkgId: pkgInfo.packagingId,
                  pkgName: pkgInfo.packagingType,
                  imgCode: pkgInfo.styleCode,
                };
                pkgTypesArr.push(freshPkgTypeObj);
              });
            } else {
              element.packagingTypeDetails.forEach(pkgInfo => {
                let freshPkgTypeObj = {
                  pkgId: pkgInfo.packagingId,
                  pkgName: pkgInfo.packagingName,
                  imgCode: pkgInfo.packagingCode,
                };
                pkgTypesArr.push(freshPkgTypeObj);
              });
            }
            let shpmtModeOption = {
              id: element.shippingModeId,
              name: element.shippingMode,
              styleCode: element.styleCode,
              imageUrl: 'assets/img/quotes/shmt/' + element.styleCode + '.svg',
              isFreightNeeded: element.isFreightNeeded,
              isDisabled: element.isDisabledDay,
              pkgTypes: pkgTypesArr
            }
            this.shipmentModeOptions.push(shpmtModeOption);
          }
        });
        this.apiLoader = false;
      } else {
        console.error('Unexpected data format:', result);
        this.apiLoader = false;
        // Handle the unexpected data format, show an error, or perform other actions.
      }
    }, (error) => {
      this.apiLoader = false;
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    });
  }

  getfrieghtClass() {
    this.apiLoader = true;
    let getFreightClass = ServerURLS.getFreightClass.action;
    let promise = this.scHttpService.getHttpService(getFreightClass, '');
  
    promise.then(
      (result) => {
        // Check if the necessary properties are defined
        if (result && result['freight_info'] && result['freight_info']['data']) {
          this.freightClassObj = result['freight_info']['data'];
          this.freightDisplayDataObj = [];
          this.defaultHazmat = result['is_hazmat_default'];
  
          if (this.defaultHazmat == true) {
            (
              (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                0
              ) as FormGroup
            )
              .get('hazmet')
              .patchValue(this.defaultHazmat);
            this.showHideHazmatOptions(0, true);
          }
  
          this.freightClassObj.forEach((data, index) => {
            this.freightDisplayDataObj[data.freightId] = data;
          });
        } else {
          console.error('Unexpected response format:', result);
          // Handle the case where the response format is not as expected
        }
  
        this.apiLoader = false;
      },
      (error) => {
        this.apiLoader = false;
        this.router.navigate(['/signin']);
        throw error;
      }
    );
  }
  
  checkshipperAddressBook() {
    this.toggleShipperAddrType();
    this.quickBolLogisticsDetails.controls.shipper.reset();
    this.quickBolLogisticsDetails.controls.shipper.setValue({
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      fullAddr: '',
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneCountryCode: '',
      phoneNumber: '',
      phoneNumberExt: '',
      pickupDate: '',
      pickupFromTime: 'c7b3e35a-f7ed-11e9-8462-163046d26cd4',
      pickupToTime: 'c7b3e5e2-f7ed-11e9-8462-163046d26cd4',
      locationType: '',
      companyId: '',
      googleMapId: '',
      addressId: '',
    })
    this.accessorialStart = [];
    this.accessorialLabelStart = 'Select Accessorial';
  }
  toggleShipperAddrType() {
    this.isAddressBookShipper = !this.isAddressBookShipper;
   this.changeDetector.detectChanges();
  }

  checkconsigneeAddressBook() {
    this.toggleConsigneeAddrType();
    this.quickBolLogisticsDetails.controls.consignee.reset();
    this.quickBolLogisticsDetails.controls.consignee.setValue({
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      fullAddr: '',
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneCountryCode: '',
      phoneNumber: '',
      phoneNumberExt: '',
      dropDate: '',
      dropFromTime: 'c7b3e35a-f7ed-11e9-8462-163046d26cd4',
      dropToTime: 'c7b3e5e2-f7ed-11e9-8462-163046d26cd4',
      locationType: '',
      companyId: '',
      googleMapId: '',
      addressId: '',
    })
    this.accessorialEnd = [];
    this.accessorialLabelEnd = 'Select Accessorial';
    // // console.log('consignee blank fields - ', this.quickBolLogisticsDetails.controls.consignee.value)
  }
  toggleConsigneeAddrType() {
    this.isAddressBookConsignee = !this.isAddressBookConsignee;
   this.changeDetector.detectChanges();
  }

  handleAddressChange(e) {
    this.getzipcodestart(
      e.formatted_address,
      e.address_components,
      e.geometry.location,
      'start',
      e.name, // company name
      e.place_id
    );
    e.address_components['place_id'] = e.place_id;
    this.callcurrency(e.address_components, 'shipper');
    let latitude = e.geometry.location.lat();
    let longitude = e.geometry.location.lng();

    //Get Business Name
    // this.getResultFromMapService(
    //   { placeId: e.place_id, fields: ['name', 'business_status'] },
    //   'start'
    // );
  }

  handleConsignee(e) {
    this.getzipcodestart(
      e.formatted_address,
      e.address_components,
      e.geometry.location,
      'end',
      e.name, // company name
      e.place_id
    );
    e.address_components['place_id'] = e.place_id;
    this.callcurrency(e.address_components, 'consignee');
    let latitude = e.geometry.location.lat();
    let longitude = e.geometry.location.lng();

    //Get Business Name
    // this.getResultFromMapService(
    //   { placeId: e.place_id, fields: ['name', 'business_status'] },
    //   'end'
    // );
  }

  getzipcodestart(formatted_address, address, geometry, action, companyName, googleMapId?) {
    
    if (action === 'start') {
      this.quickBolLogisticsDetails.controls.shipper['controls'].latitude.patchValue(geometry.lat());
      this.quickBolLogisticsDetails.controls.shipper['controls'].longitude.patchValue(geometry.lng());
      this.quickBolLogisticsDetails.controls.shipper['controls'].fullAddr.patchValue(formatted_address);
      this.quickBolLogisticsDetails.controls.shipper['controls'].companyName.patchValue(companyName);
      this.quickBolLogisticsDetails.controls.shipper['controls'].companyId.patchValue(null);
      this.quickBolLogisticsDetails.controls.shipper['controls'].addressId.patchValue(null);
      this.quickBolLogisticsDetails.controls.shipper['controls'].googleMapId.patchValue(googleMapId);
      // this.shipperFullAddr = formatted_address;
    } else {
      this.quickBolLogisticsDetails.controls.consignee['controls'].latitude.patchValue(geometry.lat());
      this.quickBolLogisticsDetails.controls.consignee['controls'].longitude.patchValue(geometry.lng());
      this.quickBolLogisticsDetails.controls.consignee['controls'].fullAddr.patchValue(formatted_address);
      this.quickBolLogisticsDetails.controls.consignee['controls'].companyName.patchValue(companyName);
      this.quickBolLogisticsDetails.controls.consignee['controls'].companyId.patchValue(null);
      this.quickBolLogisticsDetails.controls.consignee['controls'].addressId.patchValue(null);
      this.quickBolLogisticsDetails.controls.consignee['controls'].googleMapId.patchValue(googleMapId);
      // this.consigneeFullAddr = formatted_address;
    }

    if (action === 'start') {
      this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.patchValue(false);
      this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine2.patchValue(false);
    } else {
      this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.patchValue(false);
      this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine2.patchValue(false);
    }

    //get  country
    for (let key in address) {
      if (address[key]['types'][0] == 'country') {
        if (action == 'start') {
          this.quickBolLogisticsDetails.controls.shipper['controls'].country.patchValue(address[key]['short_name']);
          this.quickBolLogisticsDetails.controls.shipper['controls'].phoneCountryCode.patchValue(address[key]['short_name']);
          this.selectedShipperAddressCountry = address[key]['short_name'];
          this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
        } else {
          this.quickBolLogisticsDetails.controls.consignee['controls'].country.patchValue(address[key]['short_name']);
          this.quickBolLogisticsDetails.controls.consignee['controls'].phoneCountryCode.patchValue(address[key]['short_name']);
          this.selectedCosigneeAddressCountry = address[key]['short_name'];
          this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
        }
      }

      //select city
      if (
        address[key]['types'][0] == 'locality' ||
        address[key]['types'][0] == 'sublocality_level_1' ||
        address[key]['types'][0] == 'neighborhood'
      ) {
        if (action == 'start') {
          this.quickBolLogisticsDetails.controls.shipper['controls'].city.patchValue(address[key]['short_name']);
        } else {
          this.quickBolLogisticsDetails.controls.consignee['controls'].city.patchValue(address[key]['short_name']);
        }
      }

      //select state
      if (address[key]['types'][0] == 'administrative_area_level_1') {
        if (action == 'start') {
          this.quickBolLogisticsDetails.controls.shipper['controls'].state.patchValue(address[key]['short_name']);
        } else {
          this.quickBolLogisticsDetails.controls.consignee['controls'].state.patchValue(address[key]['short_name']);
        }
      }

      //select postal_code
      if (address[key]['types'][0] == 'postal_code') {
        if (action == 'start') {
          this.quickBolLogisticsDetails.controls.shipper['controls'].zipCode.patchValue(address[key]['long_name']);
        } else {
          this.quickBolLogisticsDetails.controls.consignee['controls'].zipCode.patchValue(address[key]['long_name']);
        }
        // this.postalValid = true;
      }

      //select addressLine1 and 2
      if (action === 'start') {
        this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine2.patchValue('');
        let stepStart = 0;
        if (!this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.value && (address[key]['types'][0] === 'subpremise' || address[key]['types'][0] === 'street_number')) {
          stepStart++;
          this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.patchValue(address[key]['short_name']);
        }

        if (address[key]['types'][0] === 'route') {
          if(!this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.value){
            stepStart++;
            this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.patchValue(address[key]['short_name']);
          }else{
            stepStart++;
            this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.patchValue(this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.value +' '+ address[key]['short_name']);
          }
        }

        if (!this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.value && !stepStart) {
          // this.showDetailedAddressError = true;
          return;
        }
      } else {
        this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine2.patchValue('');
        let stepStartConsignee = 0;
        if (!this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.value && (address[key]['types'][0] === 'subpremise' || address[key]['types'][0] === 'street_number')) {
          stepStartConsignee++;
          this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.patchValue(address[key]['short_name']);
        }

        if (address[key]['types'][0] === 'route') {
          if(!this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.value){
            stepStartConsignee++;
            this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.patchValue(address[key]['short_name']);
          }else{
            stepStartConsignee++;
            this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.patchValue(this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.value +' '+ address[key]['short_name']);
          }
        }

        if (!this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.value && !stepStartConsignee) {
          // this.showDetailedEndAddressError = true;
          return;
        }
      }
    }
  }

  callcurrency(address, action) {
    for (let key in address) {
      for (let endkey in address[key]) {
        for (let mainkey in address[key][endkey]) {
          if (address[key][endkey].includes('country') == true) {
            if (action == 'shipper') {
              this.countryShipperString = address[key]['short_name'];
            } else {
              this.countryConsigneeString = address[key]['short_name'];
            }
          }
        }
      }
    }
    this.displayCurrency();
  }

  displayCurrency() {
    if (
      this.countryShipperString === 'CA' &&
      this.countryConsigneeString === 'CA'
    ) {
      this.setcurrency[0] = 'CAD';
    } else if (
      this.countryShipperString === 'US' &&
      this.countryConsigneeString === 'CA'
    ) {
      this.setcurrency[0] = 'USD';
    } else if (
      this.countryShipperString === 'CA' &&
      this.countryConsigneeString === 'US'
    ) {
      this.setcurrency[0] = 'USD';
    } else if (
      this.countryShipperString === 'US' &&
      this.countryConsigneeString === 'US'
    ) {
      this.setcurrency[0] = 'USD';
    } else {
      this.setcurrency[0] = 'CAD';
    }
  }

  getTimeData(){
    this.apiLoader = true;
    let getPickupTimes = ServerURLS.getPickupTimes.action;
    let promise = this.scHttpService.getHttpService(getPickupTimes, '');
    promise.then((res: any)=>{
      this.apiLoader = false;
      this.pickupTimeArr = res.data;
    }, (err)=>{
      this.apiLoader = false;
      this.modalService.dismissAll();
      this.toastr.error(`${err.message ? err.message : err.Message ? err.Message : 'Something Went Wrong'}`)
    })
  }

  getDefaultManualAddrList(){
    let url = ServerURLS.getAddressBookgetquote.action + '/' + 0 + "/" + 20 + "?searchKey=" + "&uniqueCompany=" + true;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((a)=>{
      if (a['totalPages'] === 0) {
        this.defaultaddressbookdata = [];
        this.apiLoader = false;
        return;
      }
      this.apiLoader = false;
      this.defaultaddressbookdata = a['response'];
      this.addressData = this.defaultaddressbookdata;
      this.ConsigneeAddressData = this.defaultaddressbookdata;
    },
    (error) => {
      this.apiLoader = false;
      if (error['status'] === 404) {
        this.apiLoader = false;
        return;
      }
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    })
  }

  selectShipperAddress(e) {
    this.found = '';
    if (e.length > 2) {
      this.found = 'searching...';
      this.addressData = [];
      let url = ServerURLS.getAddressBookgetquote.action + '/' + 0 + "/" + 10 + "?searchKey=" + "&uniqueCompany=" + true;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((a)=>{
      if (a['totalPages'] === 0) {
        this.addressData = [];
        this.found = 'No data found.';
        this.apiLoader = false;
        return;
      }
      this.apiLoader = false;
      this.addressData = a['response'];
      this.found = '';
    },
    (error) => {
      this.apiLoader = false;
      if (error['status'] === 404) {
        this.found = 'No data found.';
        this.apiLoader = false;
        return;
      }
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    })
    }
    if (e.length <= 2) {
      this.found = '';
      this.addressData = this.defaultaddressbookdata;
    }
  }

  selectConsigneeAddress(e) {
    this.found2 = '';
    if (e.length > 2) {
      this.found2 = 'searching...';
      this.ConsigneeAddressData = [];
      let url = ServerURLS.getAddressBookgetquote.action + '/' + 0 + "/" + 10 + "?searchKey=" + "&uniqueCompany=" + true;
      let promise = this.scHttpService.getHttpService(url, '');
      promise.then((a)=>{
        if (a['totalPages'] === 0) {
          this.ConsigneeAddressData = [];
          this.apiLoader = false;
          this.found2 = 'No data found.';
          return;
        }
        this.ConsigneeAddressData = a['response'];
        this.found2 = '';
      },
      (error) => {
        this.apiLoader = false;
        if (error['status'] === 404) {
          this.found2 = 'No data found.';
          this.apiLoader = false;
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      })
    }
    if (e.length <= 2) {
      this.found2 = '';
      this.ConsigneeAddressData = this.defaultaddressbookdata;
    }
  }

  getCompanyDetails(i, companyId, flag) {
    this.apiLoader = true;
    let getContactDetailsById = ServerURLS.getContactDetailsById.action + "?companyId=" + companyId;
    let promise = this.scHttpService.postHttpService(getContactDetailsById, '');
    promise.then((res)=>{
      this.detailsOfCompany = res;
      if (flag == 'start') {
        this.selectedShipperAddressCountry=res && res['companyDetails'] ? res['companyDetails']['country'] : undefined;
        this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();

        this.quickBolLogisticsDetails.controls.shipper['controls'].latitude.patchValue(
          this.detailsOfCompany['companyDetails']['latitude'] ? parseFloat(this.detailsOfCompany['companyDetails']['latitude']) : null
        );
        this.quickBolLogisticsDetails.controls.shipper['controls'].longitude.patchValue(
          this.detailsOfCompany['companyDetails']['longitude'] ? parseFloat(this.detailsOfCompany['companyDetails']['longitude']) : null
        );
        this.quickBolLogisticsDetails.controls.shipper['controls'].fullAddr.patchValue(
          this.detailsOfCompany['companyDetails']['streetLine1'] + ', ' + this.detailsOfCompany['companyDetails']['streetLine2'] + ', ' +
          this.detailsOfCompany['companyDetails']['zipcode'] + ', ' + this.detailsOfCompany['companyDetails']['city'] + ', ' +
          this.detailsOfCompany['companyDetails']['province'] + ', ' + this.detailsOfCompany['companyDetails']['country']
        );
        this.quickBolLogisticsDetails.controls.shipper['controls'].companyName.patchValue(this.detailsOfCompany['companyDetails']['companyName']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].companyId.patchValue(this.detailsOfCompany['companyId']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].addressId.patchValue(null); //not added prev
        this.quickBolLogisticsDetails.controls.shipper['controls'].googleMapId.patchValue(null); //not added prev
        this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine1.patchValue(this.detailsOfCompany['companyDetails']['streetLine1']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].addressLine2.patchValue(this.detailsOfCompany['companyDetails']['streetLine2']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].country.patchValue(this.detailsOfCompany['companyDetails']['country']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].phoneCountryCode.patchValue(this.detailsOfCompany['companyDetails']['country']);
        this.countryShipperString = this.detailsOfCompany['companyDetails']['country'];
        this.quickBolLogisticsDetails.controls.shipper['controls'].city.patchValue(this.detailsOfCompany['companyDetails']['city']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].state.patchValue(this.detailsOfCompany['companyDetails']['province']);
        this.quickBolLogisticsDetails.controls.shipper['controls'].zipCode.patchValue(this.detailsOfCompany['companyDetails']['zipcode']);
        if (this.detailsOfCompany['accessorialDetails']['locationTypeId']) {
          this.quickBolLogisticsDetails.controls.shipper['controls'].locationType.patchValue(this.detailsOfCompany['accessorialDetails']['locationTypeId']);
          this.onOptionsSelected(this.detailsOfCompany['accessorialDetails']['locationTypeId']);
        }
        this.apiLoader = false;
      } else {
        this.selectedCosigneeAddressCountry=res && res['companyDetails'] ? res['companyDetails']['country'] : undefined;
        this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();

        this.quickBolLogisticsDetails.controls.consignee['controls'].latitude.patchValue(parseFloat(this.detailsOfCompany['companyDetails']['latitude']));
        this.quickBolLogisticsDetails.controls.consignee['controls'].longitude.patchValue(parseFloat(this.detailsOfCompany['companyDetails']['longitude']));
        this.quickBolLogisticsDetails.controls.consignee['controls'].fullAddr.patchValue(
          this.detailsOfCompany['companyDetails']['streetLine1'] + ', ' + this.detailsOfCompany['companyDetails']['streetLine2'] + ', ' +
          this.detailsOfCompany['companyDetails']['zipcode'] + ', ' + this.detailsOfCompany['companyDetails']['city'] + ', ' +
          this.detailsOfCompany['companyDetails']['province'] + ', ' + this.detailsOfCompany['companyDetails']['country']
        );
        this.quickBolLogisticsDetails.controls.consignee['controls'].companyName.patchValue(this.detailsOfCompany['companyDetails']['companyName']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].companyId.patchValue(this.detailsOfCompany['companyId']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].addressId.patchValue(null); //not added prev
        this.quickBolLogisticsDetails.controls.consignee['controls'].googleMapId.patchValue(null); //not added prev
        this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine1.patchValue(this.detailsOfCompany['companyDetails']['streetLine1']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].addressLine2.patchValue(this.detailsOfCompany['companyDetails']['streetLine2']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].country.patchValue(this.detailsOfCompany['companyDetails']['country']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].phoneCountryCode.patchValue(this.detailsOfCompany['companyDetails']['country']);
        this.countryConsigneeString = this.detailsOfCompany['companyDetails']['country'];
        this.quickBolLogisticsDetails.controls.consignee['controls'].city.patchValue(this.detailsOfCompany['companyDetails']['city']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].state.patchValue(this.detailsOfCompany['companyDetails']['province']);
        this.quickBolLogisticsDetails.controls.consignee['controls'].zipCode.patchValue(this.detailsOfCompany['companyDetails']['zipcode']);
        if (this.detailsOfCompany['accessorialDetails']['locationTypeId']) {
          this.quickBolLogisticsDetails.controls.consignee['controls'].locationType.patchValue(this.detailsOfCompany['accessorialDetails']['locationTypeId']);
          this.onOptionsSelectedConsignee(this.detailsOfCompany['accessorialDetails']['locationTypeId']);
        }
        this.apiLoader = false;
        this.displayCurrency();
      }
      this.apiLoader = false;
     this.changeDetector.detectChanges();
    },
    (error) => {
      this.apiLoader = false;
      this.toastr.error('Something went wrong.', 'Oops', { timeOut: 10000 });
    }
  );
  }

  removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada(){
    if(this.selectedShipperAddressCountry && this.selectedCosigneeAddressCountry){
 
      if(this.selectedShipperAddressCountry==='CA' && this.selectedCosigneeAddressCountry==='CA'){
        this.orderIsWithInCanada=true;
        this.classToSet=[]
      }else{
        this.orderIsWithInCanada=false;
      }
    }
  }

  getLocationType() {
    let getLocationTypes = ServerURLS.getLocationTypes.action;
    let promise = this.scHttpService.getHttpService(getLocationTypes, '');
    promise.then(
      (result) => {
        if (result && result['data'] && result['data'].length != null) {
          this.locationType = result['data'];
        }
      },
      (error) => {
        // Handle the error, for example, log it or show a message to the user
        console.error('Error fetching location types:', error);
      }
    );
  }

  onOptionsSelected(locId, isPopulatingData?:boolean) {
    this.accessorialLabelStart = 'Loading Accessorial..';
    this.accessorialStart = [];
    this.apiLoader = true;
    var url1 = ServerURLS.getLocationExtraServices.action + '?locTypeId=' + locId + '&locType=start'
    let promise1 = this.scHttpService.getHttpService(url1, '');
    promise1.then((data)=>{
      this.accessorialData['start'] = data['data'];
      this.accessorialLabelStart = 'Select Accessorial';
      this.apiLoader = false;
      if (isPopulatingData) {
        this.accessorialData['start'].forEach((item, index) => {
          if (this.quickBolFormData && this.quickBolFormData.extraServices) {
            this.quickBolFormData.extraServices.forEach((itm, i) => {
              if (item.assocId == itm) {
                this.selectAccessorial(index, 'start');
              }
            });
          }     
        });
       this.changeDetector.detectChanges();
      }
    })
  }

  onOptionsSelectedConsignee(location, isPopulatingData?: boolean) {
    this.accessorialLabelEnd = 'Loading Accessorial..';
    this.accessorialEnd = [];
    this.apiLoader = true;
    let url1 = ServerURLS.getLocationExtraServices.action + '?locTypeId=' + location + '&locType=end';
    let promise1 = this.scHttpService.getHttpService(url1, '');
  
    promise1.then((data) => {
      this.accessorialData['end'] = data['data'];
      this.accessorialLabelEnd = 'Select Accessorial';
      this.apiLoader = false;
  
      if (isPopulatingData) {
        this.accessorialData['end'].forEach((item, index) => {
          if (this.quickBolFormData && this.quickBolFormData.extraServices) {
            this.quickBolFormData.extraServices.forEach((itm, i) => {
              if (item.assocId == itm) {
                this.selectAccessorial(index, 'end');
              }
            });
          }
        });
       this.changeDetector.detectChanges();
      }
    });
  }

  refreshList(action) {
    if (action == 'shipper') {
      if ('' == this.quickBolLogisticsDetails.controls.shipper['controls'].locationType.value) {
        this.toastr.error('Please select shipper address type.', '', {
          timeOut: 50000,
        });
        return;
      }

      if (!this.accessorialData['start']) {
        this.onOptionsSelected(this.quickBolLogisticsDetails.controls.shipper['controls'].locationType.value);
      }
     this.changeDetector.detectChanges();
    } else {
      if ('' == this.quickBolLogisticsDetails.controls.consignee['controls'].locationType.value) {
        this.toastr.error('Please select consignee address type.', '', {
          timeOut: 50000,
        });
        return;
      }

      if (!this.accessorialData['end']) {
        this.onOptionsSelectedConsignee(this.quickBolLogisticsDetails.controls.consignee['controls'].locationType.value);
      }
     this.changeDetector.detectChanges();
    }
  }

  selectAccessorial(index, accessorialType) {
    if (accessorialType == 'start') {
      if (
        this.checkAccessorialAlreadyExists('start', this.accessorialData['start'][index].assocId)
      ) {
        this.accessorialStart.push(this.accessorialData['start'][index]);
        this.accessorialLabelStart = '';
      }
     this.changeDetector.detectChanges();
    } else {
      if (
        this.checkAccessorialAlreadyExists('end', this.accessorialData['end'][index].assocId)
      ) {
        this.accessorialEnd.push(this.accessorialData['end'][index]);
        this.accessorialLabelEnd = '';
      }
     this.changeDetector.detectChanges();
    }
  }

  checkAccessorialAlreadyExists(accessorialType, accessorialId) {
    var flag = true;
    if (accessorialType == 'start' && this.accessorialStart) {
      this.accessorialStart.forEach((data, index) => {
        if (data.assocId === accessorialId) {
          flag = false;
        }
      });
      return flag;
    } else if (accessorialType == 'start' && !this.accessorialStart) {
      return true;
    }

    if (accessorialType == 'end' && this.accessorialEnd) {
      this.accessorialEnd.forEach((data, index) => {
        if (data.assocId === accessorialId) {
          flag = false;
        }
      });
      return flag;
    } else if (accessorialType == 'end' && !this.accessorialEnd) {
      return true;
    }
  }

  removeTag(index) {
    if (index !== -1) {
      this.accessorialStart.splice(index, 1);
      if (this.accessorialStart.length == 0) {
        this.accessorialLabelStart = 'Select Accessorial';
      }
     this.changeDetector.detectChanges();
    }
  }

  removeConsigneeAcc(index) {
    if (index !== -1) {
      this.accessorialEnd.splice(index, 1);
      if (this.accessorialEnd.length == 0) {
        this.accessorialLabelEnd = ' Select Accessorial';
      }
     this.changeDetector.detectChanges();
    }
  }

  createFormGroup(){
    this.quickBolCarrierDetails = this.formBuilder.group({
      freightCharges : this.formBuilder.array([this.addFreightChargeFormGroup()]),
      shipmentModeId: ['', [Validators.required]],
      carrierName: ['', [Validators.required]],
      carrierPro: ['', [Validators.required]],
      poNumber: ['', [Validators.required]],
      billOfLadingNumber: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      quote: ['', [Validators.required]],
      billToAccount: ['', [Validators.required]],
      specialInstructions: [''],
    });

    this.quickBolLogisticsDetails = this.formBuilder.group({
      shipper: this.formBuilder.group({
        companyName: ['', [Validators.required]],
        addressLine1: ['', [Validators.required]],
        addressLine2: [''],
        fullAddr: ['', [Validators.required]],
        latitude: [''],
        longitude: [''],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        country: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
        phoneCountryCode: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        phoneNumberExt: [''],
        pickupDate: ['', [Validators.required]],
        pickupFromTime: ['c7b3e35a-f7ed-11e9-8462-163046d26cd4', [Validators.required]],
        pickupToTime: ['c7b3e5e2-f7ed-11e9-8462-163046d26cd4', [Validators.required]],
        locationType: ['', [Validators.required]],
        companyId: [''], //for manual address only, else null
        googleMapId: [''], //for google address only, else null
        addressId: [''],
      }),
      consignee: this.formBuilder.group({
        companyName: ['', [Validators.required]],
        addressLine1: ['', [Validators.required]],
        addressLine2: [''],
        fullAddr: ['', [Validators.required]],
        latitude: [''],
        longitude: [''],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        country: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
        phoneCountryCode: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        phoneNumberExt: [''],
        dropDate: ['', [Validators.required]],
        dropFromTime: ['c7b3e35a-f7ed-11e9-8462-163046d26cd4', [Validators.required]],
        dropToTime: ['c7b3e5e2-f7ed-11e9-8462-163046d26cd4', [Validators.required]],
        locationType: ['', [Validators.required]],
        companyId: [''], //for manual address only, else null
        googleMapId: [''], //for google address only, else null
        addressId: [''],
      })
    });

    this.quickBolShipmentItems = this.formBuilder.group({
      inputFields: this.formBuilder.array([this.addMeasureMentFormGroup()])
    })

    this.quickBolShipmentItems.valueChanges.subscribe((data) => {
      let quantityArr = [];
      let weightArr = [];
      let shipmentValueArr = [];
      this.totalOfQuantity = 0;
      this.totalOfShipmentValue = 0;
      this.totalOfWeight = 0;
      this.totalofWidth = 0;
      this.totalofLength = 0;
      this.totalofHeight = 0;
      for (let arr of data.inputFields) {
        quantityArr.push(arr.pieceCount);
        weightArr.push(arr.weight);
        shipmentValueArr.push(arr.shipmentvalue);
        this.totalOfWeight = this.totalOfWeight + arr.pieceCount * arr.weight;
        this.totalOfQuantity += arr.pieceCount;
        this.totalOfShipmentValue += arr.shipmentvalue;
        this.totalofWidth += arr.width;
        this.totalofLength += arr.length;
        this.totalofHeight += arr.height;
        this.totalClass = arr.class;
      }
    });


    this.getfrieghtClass();
  }

  addFreightChargeFormGroup(): FormGroup{
    return this.formBuilder.group({
      freightChargeCode: ['']
    });
  }

  addMeasureMentFormGroup(): FormGroup {
    return this.formBuilder.group({
      packageType: ['', [ Validators.required ]],
      pieceCount: ['', [ Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/) ]],
      length: ['',[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      width: ['',[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      height: ['',[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      weight: ['',[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      shipmentvalue: ['',[Validators.required,Validators.min(0.1),Validators.max(999999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      class: [''],
      nmfc: [''],
      stack: [false],
      hazmet: [this.defaultHazmat],
      hazmatNu: ['UN'],
      hazmatUnNumber: [''],
      hazmatProperShippingName: [''],
      hazmatHazardClass: [''],
      hazmatPackageGroup: [''],
      hazardPackagingType: [''],
      itemId: [''],
      description: ['', [Validators.required, Validators.maxLength(30)]],
    });
  }

  fetchQuickBolFormData(extBolId?) {
    this.apiLoader = true;
    let fetchQuickBolFormData = ServerURLS.fetchQuickBolFormData.action;
    fetchQuickBolFormData = extBolId ? (fetchQuickBolFormData + "?bolId=" + extBolId) : fetchQuickBolFormData;
    let promise = this.scHttpService.getHttpService(fetchQuickBolFormData, '');
  
    promise.then((res: any) => {
      this.quickBolFormData = res;
     this.changeDetector.detectChanges();  
      // Check if 'items' is not null or undefined before attempting to sort
      if (this.quickBolFormData && this.quickBolFormData.items) {
        let property = 'displayOrder';
        let direction = 1;
        this.quickBolFormData.items.sort(function (a, b) {
          if (parseFloat(a[property]) < parseFloat(b[property])) {
            return -1 * direction;
          } else if (parseFloat(a[property]) > parseFloat(b[property])) {
            return 1 * direction;
          } else {
            return 0;
          }
        });
  
        this.quickBolFormData.items.map((ele) => {
          ele.existing = true;
        });
      }
  
      if (extBolId) {
        this.populateFormData();
      }
      this.apiLoader = false;
    }, (error: any) => {
      this.apiLoader = false;
      this.toastr.error('Something went wrong', 'Fetching Bol Form Data', {
        timeOut: 5000,
      });
    });
  }

  shipmentTypeList(selectedShpmtModeId){
    this.shipmentModeOptions.forEach((element, index)=>{
      if(selectedShpmtModeId == element.id){
        this.selectedShpmtModeDetails = element;
        this.freightClassApplicable = this.selectedShpmtModeDetails.isFreightNeeded;
        if (this.selectedShpmtModeDetails.isFreightNeeded) {
          for (
            let i = 0;
            i < this.quickBolShipmentItems.get('inputFields')['controls'].length;
            i++
          ) {
            if (this.defaultHazmat == true) {
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .patchValue(this.defaultHazmat);
              this.showHideHazmatOptions(i, true);
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .enable();
            } else {
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .enable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .enable();
            }
          }
        } else {
          for (
            let i = 0;
            i < this.quickBolShipmentItems.get('inputFields')['controls'].length;
            i++
          ) {
            if (this.defaultHazmat == false) {
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .patchValue(this.defaultHazmat);
              this.showHideHazmatOptions(i, false);
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .disable();

              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .reset();
            } else {
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .patchValue(this.defaultHazmat);
              this.showHideHazmatOptions(i, false);
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .disable();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .disable();

              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('class')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('nmfc')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('stack')
                .reset();
              (
                (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
                  i
                ) as FormGroup
              )
                .get('hazmet')
                .reset();
            }
          }
        }
      }
    });
    this.closeDropdown = true;
   this.changeDetector.detectChanges();
  }

  populateFormData(){ //in the case of edit-bol
    this.populateCarrierSecData();
    this.populateLogisticsSecData();
    this.populateShpmtItmSecData();
  }

  addRemoveFc(selectedFcData, ischecked){
    // // console.log(ischecked)
    (this.quickBolFormData as any).freightChargeTermList.forEach((defaultFcDataObj)=>{
      if((selectedFcData == defaultFcDataObj.name || selectedFcData == defaultFcDataObj.code)){
        if(ischecked){
          defaultFcDataObj.isSelected = true;
          this.showFctError = false;
        }else{
          defaultFcDataObj.isSelected = false;
        }
      }else if(!defaultFcDataObj.isSelected){
        defaultFcDataObj.isSelected = false;
      }
    });
  }

  populateCarrierSecData() {
    if (this.quickBolFormData) {
      if (this.quickBolFormData.selectedFreightChargeTerms) {
        this.quickBolFormData.selectedFreightChargeTerms.forEach((selectedFcData) => {
          if (this.quickBolFormData.freightChargeTermList) {
           this.changeDetector.detectChanges();  
            this.quickBolFormData.freightChargeTermList.forEach((defaultFcDataObj) => {
             this.changeDetector.detectChanges();  
              if (selectedFcData == defaultFcDataObj.name || selectedFcData == defaultFcDataObj.code) { 
                defaultFcDataObj.isSelected = true;
              } else if (!defaultFcDataObj.isSelected) {
                defaultFcDataObj.isSelected = false;
              }
            });
          }
        });
      }
  
      this.shipmentTypeList(this.quickBolFormData.shipmentMode);
      const formData = this.quickBolFormData || {};
      this.quickBolCarrierDetails['controls'].shipmentModeId.patchValue(formData.shipmentMode);
      this.quickBolCarrierDetails['controls'].carrierName.patchValue(formData.carrierName);
      this.quickBolCarrierDetails['controls'].carrierPro.patchValue(formData.carrierProNum);
      this.quickBolCarrierDetails['controls'].billOfLadingNumber.patchValue(formData.ladingNum);
      this.quickBolCarrierDetails['controls'].poNumber.patchValue(formData.poNum);
      this.quickBolCarrierDetails['controls'].reference.patchValue(formData.referenceNum);
      this.quickBolCarrierDetails['controls'].quote.patchValue(formData.quoteNum);
      this.quickBolCarrierDetails['controls'].billToAccount.patchValue(formData.billAccountNum);
      this.quickBolCarrierDetails['controls'].specialInstructions.patchValue(formData.specialInstruction);
     this.changeDetector.detectChanges();  
    }
  }
  // populateCarrierSecData(){
  //   if (this.quickBolFormData){
  //     if (this.quickBolFormData.selectedFreightChargeTerms) {
  //       this.quickBolFormData.selectedFreightChargeTerms.forEach((selectedFcData) => {
  //         if (this.quickBolFormData.freightChargeTermList) {
  //          this.changeDetector.detectChanges();  
  //           this.quickBolFormData.freightChargeTermList.forEach((defaultFcDataObj) => {
  //            this.changeDetector.detectChanges();  
  //             if (selectedFcData == defaultFcDataObj.name || selectedFcData == defaultFcDataObj.code) { 
  //               defaultFcDataObj.isSelected = true;
  //             } else if (!defaultFcDataObj.isSelected) {
  //               defaultFcDataObj.isSelected = false;
  //             }
  //           });
  //         }
  //       });
  //     }
  
  //     this.shipmentTypeList(this.quickBolFormData.shipmentMode);
  //    this.changeDetector.detectChanges();  
  
  //     this.quickBolCarrierDetails['controls'].shipmentModeId.patchValue(this.quickBolFormData.shipmentMode);
  //     this.quickBolCarrierDetails['controls'].carrierName.patchValue(this.quickBolFormData.carrierName);
  //     this.quickBolCarrierDetails['controls'].carrierPro.patchValue(this.quickBolFormData.carrierProNum);
  //     this.quickBolCarrierDetails['controls'].billOfLadingNumber.patchValue(this.quickBolFormData.ladingNum);
  //     this.quickBolCarrierDetails['controls'].poNumber.patchValue(this.quickBolFormData.poNum);
  //     this.quickBolCarrierDetails['controls'].reference.patchValue(this.quickBolFormData.referenceNum);
  //     this.quickBolCarrierDetails['controls'].quote.patchValue(this.quickBolFormData.quoteNum);
  //     this.quickBolCarrierDetails['controls'].billToAccount.patchValue(this.quickBolFormData.billAccountNum);
  //     this.quickBolCarrierDetails['controls'].specialInstructions.patchValue(this.quickBolFormData.specialInstruction);
  //   }

  // }

  getDateForNgbDatePicker(dateStr, shipCons){
    const dateString = dateStr;
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1 to get the correct month
    const day = date.getUTCDate();
    if(shipCons == 'shipper'){
      this.shipperPickupDate = { month: month, day: day, year: year };
      return this.shipperPickupDate;
    }else if(shipCons == 'consignee'){
      this.consigneeDropDate = { month: month, day: day, year: year };
      return this.consigneeDropDate;
    }
  }

  populateLogisticsSecData(){
    //to populate data in shipper fields and set google/manual address toggle
    if( !this.quickBolFormData ||
      !this.quickBolFormData.shipperAddress ||
      this.quickBolFormData.shipperAddress.googleMapId == null ||
      this.quickBolFormData.shipperAddress.googleMapId == ''){
      //when it's manual address
      this.toggleShipperAddrType();
    }
    if (this.quickBolFormData && this.quickBolFormData.shipperAddress){
      this.quickBolLogisticsDetails.controls.shipper.setValue({
        companyName: this.quickBolFormData.shipperAddress.company,
        addressLine1: this.quickBolFormData.shipperAddress.addrLine1,
        addressLine2: this.quickBolFormData.shipperAddress.addrLine2,
        fullAddr: this.quickBolFormData.shipperAddress.fullAddr,
        latitude: this.quickBolFormData.shipperAddress.latitude,
        longitude: this.quickBolFormData.shipperAddress.longitude,
        city: this.quickBolFormData.shipperAddress.city,
        state: this.quickBolFormData.shipperAddress.state,
        zipCode: this.quickBolFormData.shipperAddress.zipCode,
        country: this.quickBolFormData.shipperAddress.country,
        firstName: this.quickBolFormData.shipperAddress.firstName,
        lastName: this.quickBolFormData.shipperAddress.lastName,
        email: this.quickBolFormData.shipperAddress.email,
        phoneCountryCode: this.quickBolFormData.shipperAddress.country,
        phoneNumber: this.quickBolFormData.shipperAddress.phoneNumber, //remove brackets and hyphen
        phoneNumberExt: this.quickBolFormData.shipperAddress.extension,
        pickupDate: this.getDateForNgbDatePicker(this.quickBolFormData.delvryForm.pickupDate,'shipper'), //remove full date and put in short
        pickupFromTime: this.quickBolFormData.delvryForm.pickupFromTime,
        pickupToTime: this.quickBolFormData.delvryForm.pickupToTime,
        locationType: this.quickBolFormData.shipperAddress.addrTypeId,
        companyId: this.quickBolFormData.shipperAddress.companyId,
        googleMapId: this.quickBolFormData.shipperAddress.googleMapId,
        addressId: this.quickBolFormData.shipperAddress.addressId,
      })
    }
    
   this.changeDetector.detectChanges();  
    this.accessorialStart = [];
    this.accessorialLabelStart = 'Select Accessorial';
    this.onOptionsSelected(this.quickBolFormData.shipperAddress.addrTypeId, true);

    //to populate data in consignee fields and set google/manual address toggle
    if( !this.quickBolFormData ||
      !this.quickBolFormData.consigneeAddress ||
      this.quickBolFormData.consigneeAddress.googleMapId == null ||
      this.quickBolFormData.consigneeAddress.googleMapId == ''){
      //when it's manual address
      this.toggleConsigneeAddrType();
    }
    if (this.quickBolFormData && this.quickBolFormData.consigneeAddress){
      this.quickBolLogisticsDetails.controls.consignee.setValue({
        companyName: this.quickBolFormData.consigneeAddress.company,
        addressLine1: this.quickBolFormData.consigneeAddress.addrLine1,
        addressLine2: this.quickBolFormData.consigneeAddress.addrLine2,
        fullAddr: this.quickBolFormData.consigneeAddress.fullAddr,
        latitude: this.quickBolFormData.consigneeAddress.latitude,
        longitude: this.quickBolFormData.consigneeAddress.longitude,
        city: this.quickBolFormData.consigneeAddress.city,
        state: this.quickBolFormData.consigneeAddress.state,
        zipCode: this.quickBolFormData.consigneeAddress.zipCode,
        country: this.quickBolFormData.consigneeAddress.country,
        firstName: this.quickBolFormData.consigneeAddress.firstName,
        lastName: this.quickBolFormData.consigneeAddress.lastName,
        email: this.quickBolFormData.consigneeAddress.email,
        phoneCountryCode: this.quickBolFormData.consigneeAddress.country,
        phoneNumber: this.quickBolFormData.consigneeAddress.phoneNumber, //remove brackets and hyphen
        phoneNumberExt: this.quickBolFormData.consigneeAddress.extension,
        dropDate: this.getDateForNgbDatePicker(this.quickBolFormData.delvryForm.dropDate,'consignee'), //remove full date and put in short
        dropFromTime: this.quickBolFormData.delvryForm.dropFromTime,
        dropToTime: this.quickBolFormData.delvryForm.dropToTime,
        locationType: this.quickBolFormData.consigneeAddress.addrTypeId,
        companyId: this.quickBolFormData.consigneeAddress.companyId,
        googleMapId: this.quickBolFormData.consigneeAddress.googleMapId,
        addressId: this.quickBolFormData.consigneeAddress.addressId,
      })
    }
   
   this.changeDetector.detectChanges();  
    this.accessorialEnd = [];
    this.accessorialLabelEnd = 'Select Accessorial';
    this.onOptionsSelectedConsignee(this.quickBolFormData.consigneeAddress.addrTypeId, true);
  }

  populateShpmtItmSecData(){
    this.shipmentItemsData(this.quickBolFormData.items);
   this.changeDetector.detectChanges();  
  }

  backToAll(){
    this.location.back();
  }

  convertDateObjIntoString(dateObj){
    //yyyy-mm-dd
    return dateObj.year +'-'+ dateObj.month +'-'+ dateObj.day;
  }

  getfrieghtClassId(freightRange) {
    let returnVal = '';
    this.freightClassObj.forEach((data, index) => {
     this.changeDetector.detectChanges();
      if (data.freightType === freightRange) {
        returnVal = data.freightId;
        return
      }
    });
    return returnVal
  }
  
  getfrieghtNoFromId(freightId) {
    let returnVal = '';
    this.freightClassObj.forEach((data, index) => {
     this.changeDetector.detectChanges();
      if (data.freightId === freightId) {
        returnVal = data.freightType;
        return
      }
    });
    return returnVal
  }

  showFctError: boolean = false;
  saveUpdateFn(type, isTest?){
    this.showFctError = false;
    // // console.log(this.quickBolCarrierDetails);
    // // console.log(this.quickBolLogisticsDetails);
    // // console.log(this.quickBolShipmentItems);
    let selectedFreightChargeTermsArr = [];
    this.quickBolFormData.freightChargeTermList.forEach(element => {
      if(element.isSelected){
        selectedFreightChargeTermsArr.push(element.code);
      }
    });
    if(selectedFreightChargeTermsArr.length == 0){
      this.showFctError = true;
      this.toastr.error('Please select Freight Charge Terms', '', {timeOut: 5000});
    }

    if(this.quickBolCarrierDetails.invalid || this.quickBolLogisticsDetails.invalid || this.quickBolShipmentItems.invalid){
      this.quickBolCarrierDetails.markAllAsTouched();
      this.quickBolLogisticsDetails.markAllAsTouched();
      this.quickBolShipmentItems.markAllAsTouched();
      this.toastr.error('Please fill all the required details', '', {timeOut: 10000});
    }else if(selectedFreightChargeTermsArr.length == 0){
      this.showFctError = true;
    }else{
      if(!isTest){
        this.apiLoader = true;
      }
  
      let extraServicesArr = [];
      if (Object.keys(this.accessorialStart).length != 0) {
        for (let key in this.accessorialStart) {
          extraServicesArr.push(this.accessorialStart[key]['assocId']);
        }
      }
      if (Object.keys(this.accessorialEnd).length != 0) {
        for (let key in this.accessorialEnd) {
          extraServicesArr.push(this.accessorialEnd[key]['assocId']);
        }
      }
  
      let arrayShipmentItems = [];
      this.quickBolShipmentItems.value.inputFields.map((data) => {
        if (!this.quickBolShipmentItems.valid) {
          this.quickBolShipmentItems.markAllAsTouched();
          if (data.height == null || data.height <= 0) {
            this.toastr.error('Please enter valid height', '');
          } else if (data.length == null || data.length <= 0) {
            this.toastr.error('Please enter valid Length', '');
          } else if (data.weight == null || data.weight <= 0) {
            this.toastr.error('Please enter valid weight', '');
          } else if (data.width == null || data.width <= 0) {
            this.toastr.error('Please enter valid width', '');
          } else if (data.pieceCount == null || data.pieceCount <= 0) {
            this.toastr.error('Please enter valid quantity', '');
          } else if (data.packageType == null || data.packageType == '') {
            this.toastr.error('Please select valid Package Type', '');
          } else if (data.description == null || data.description == '' || data.description.length > 30) {
            this.toastr.error('Please enter valid commodity description', '', { timeOut: 10000 });
          }
          this.apiLoader = false;
        }
        else {
          let objItem = {
            qty: data.pieceCount.toString(),
            totalWeight: data.weight.toString(),
            length: data.length.toString(),
            breadth: data.width.toString(),
            height: data.height.toString(),
            unit: this.measurementType == 'metric' ? 'cm' : 'inch',
            wghtUnit: this.measurementType == 'metric' ? 'kg' : 'lbs',
            // packaging : data.packageType,
            packaging: this.selectedShpmtModeDetails.styleCode == 'courier' ? null : data.packageType,
            styleCode: this.selectedShpmtModeDetails.styleCode,
            quoteItemId: null,
            showFreight: true,
            // freightClass:this.freightClassSelectedId,
            freightClass: this.selectedShpmtModeDetails.styleCode == 'courier' ? null : this.getfrieghtClassId(data.class),
            freightRange: this.selectedShpmtModeDetails.styleCode == 'courier' ? null : data.class ? data.class : '50',
            lengthType: this.measurementType,
            shipmentItemValue: data.shipmentvalue,
            shipmentItemValueCurrency: this.setcurrency[0],
            stackable: data.stack == true ? true : false,
            hazMat: data.hazmet == true ? true : false,
            hazardClass: data.hazmatHazardClass ? data.hazmatHazardClass : "",
            unNumType: data.hazmatNu ? data.hazmatNu : "",
            packageGroup: data.hazmatPackageGroup ? data.hazmatPackageGroup : "",
            hazardPackagingType: data.hazardPackagingType ? data.hazardPackagingType : "",
            properShippingName: data.hazmatProperShippingName ? data.hazmatProperShippingName : "",
            unNumber: data.hazmatUnNumber ? data.hazmatUnNumber : "",
            displayOrder: this.displayOrder++,
            crsPackageId: this.selectedShpmtModeDetails.styleCode == 'courier' ? data.packageType : null,
            itemId: type == 'update' ? data.itemId : null,
            description: data.description
          };
  
          arrayShipmentItems.push(objItem);


          // create a payload object
          let dataObj: any = {
            // "extBolId": "001d118d-e609-4eaa-9b03-ac79cd52c078",
      
            // "custId": JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).data.uuid,
            "selectedFreightChargeTerms" : selectedFreightChargeTermsArr,
      
            "shipmentMode": this.selectedShpmtModeDetails.id,
            "shpmtTypeId": null,
            "shpmtPackagingTypeId": null,
            "shipmentSubPackageCode": null,
      
            "delvryForm": {
              "pickupDate": this.convertDateObjIntoString(this.quickBolLogisticsDetails.value.shipper.pickupDate), //yyyy-mm-dd
              "pickupFromTime": this.quickBolLogisticsDetails.value.shipper.pickupFromTime,
              "pickupToTime": this.quickBolLogisticsDetails.value.shipper.pickupToTime,
              "dropDate": this.convertDateObjIntoString(this.quickBolLogisticsDetails.value.consignee.dropDate), //yyyy-mm-dd
              "dropFromTime": this.quickBolLogisticsDetails.value.consignee.dropFromTime,
              "dropToTime": this.quickBolLogisticsDetails.value.consignee.dropToTime
            },
      
            "carrierName": this.quickBolCarrierDetails.value.carrierName, // carrier name - key missing
            "carrierProNum": this.quickBolCarrierDetails.value.carrierPro, // carrier pro - key missing
            "ladingNum": this.quickBolCarrierDetails.value.billOfLadingNumber, // bill of lading no. - key missing
            "poNum": this.quickBolCarrierDetails.value.poNumber, // po - key missing
            "referenceNum": this.quickBolCarrierDetails.value.reference, // reference - key missing
            "quoteNum": this.quickBolCarrierDetails.value.quote, // quote number - key missing
            "billAccountNum": this.quickBolCarrierDetails.value.billToAccount, // bill to account - key missing
            "specialInstruction": this.quickBolCarrierDetails.value.specialInstructions, // special instructions - key missing
      
            "shipperAddress": {
              "company": this.quickBolLogisticsDetails.value.shipper.companyName,
              "addrLine1": this.quickBolLogisticsDetails.value.shipper.addressLine1,
              "addrLine2": this.quickBolLogisticsDetails.value.shipper.addressLine2,
              "fullAddr": this.quickBolLogisticsDetails.value.shipper.fullAddr,
              "city": this.quickBolLogisticsDetails.value.shipper.city,
              "state": this.quickBolLogisticsDetails.value.shipper.state,
              "zipCode": this.quickBolLogisticsDetails.value.shipper.zipCode,
              "country": this.quickBolLogisticsDetails.value.shipper.country,
              "latitude": this.quickBolLogisticsDetails.value.shipper.latitude, //hidden, should convert the string into number
              "longitude": this.quickBolLogisticsDetails.value.shipper.longitude, //hidden, should convert the string into number
              "firstName": this.quickBolLogisticsDetails.value.shipper.firstName, // first name key missing
              "lastName": this.quickBolLogisticsDetails.value.shipper.lastName, // last name key missing
              "email": this.quickBolLogisticsDetails.value.shipper.email,
              "phoneCountryCode": this.quickBolLogisticsDetails.value.shipper.phoneCountryCode,
              "phoneNumber": this.quickBolLogisticsDetails.value.shipper.phoneNumber,
              "extension": this.quickBolLogisticsDetails.value.shipper.phoneNumberExt,
              "addrTypeId": this.quickBolLogisticsDetails.value.shipper.locationType,
              "googleMapId": this.quickBolLogisticsDetails.value.shipper.googleMapId,
              "companyId": this.quickBolLogisticsDetails.value.shipper.companyId,
            },
            "hasSourceServicesOpted": this.accessorialStart.length ? true : false,
      
            "consigneeAddress": {
              "company": this.quickBolLogisticsDetails.value.consignee.companyName,
              "addrLine1": this.quickBolLogisticsDetails.value.consignee.addressLine1,
              "addrLine2": this.quickBolLogisticsDetails.value.consignee.addressLine2,
              "fullAddr": this.quickBolLogisticsDetails.value.consignee.fullAddr,
              "city": this.quickBolLogisticsDetails.value.consignee.city,
              "state": this.quickBolLogisticsDetails.value.consignee.state,
              "zipCode": this.quickBolLogisticsDetails.value.consignee.zipCode,
              "country": this.quickBolLogisticsDetails.value.consignee.country,
              "latitude": this.quickBolLogisticsDetails.value.consignee.latitude, //hidden, should convert the string into number
              "longitude": this.quickBolLogisticsDetails.value.consignee.longitude, //hidden, should convert the string into number
              "firstName": this.quickBolLogisticsDetails.value.consignee.firstName, // first name key missing
              "lastName": this.quickBolLogisticsDetails.value.consignee.lastName, // last name key missing
              "email": this.quickBolLogisticsDetails.value.consignee.email,
              "phoneCountryCode": this.quickBolLogisticsDetails.value.consignee.phoneCountryCode,
              "phoneNumber": this.quickBolLogisticsDetails.value.consignee.phoneNumber,
              "extension": this.quickBolLogisticsDetails.value.consignee.phoneNumberExt,
              "addrTypeId": this.quickBolLogisticsDetails.value.consignee.locationType,
              "googleMapId": this.quickBolLogisticsDetails.value.consignee.googleMapId,
              "companyId": this.quickBolLogisticsDetails.value.consignee.companyId,
            },
            "hasDestServicesOpted": this.accessorialEnd.length ? true : false,
      
            "extraServices": extraServicesArr,
            
            "items": [...arrayShipmentItems],
          }
          // console.log(selectedFreightChargeTermsArr,'selectedFreightChargeTermsArr')
          if(type == 'create'){
            // in the above payload object, send extBolId as null
            dataObj.extBolId = null;
          }
          else if(type == 'update'){
            // in the above payload object, send extBolId string value
            dataObj.extBolId = this.extBolId;
            dataObj.delvryForm.deliveryId = this.quickBolFormData.delvryForm.deliveryId;
            dataObj.shipperAddress.addressId = this.quickBolFormData.shipperAddress.addressId; //it's not addrTypeId
            dataObj.consigneeAddress.addressId = this.quickBolFormData.consigneeAddress.addressId; //it's not addrTypeId
          }
          // // console.log(dataObj);
      
          if(!isTest && !this.quickBolLogisticsDetails.invalid){
            let saveUpdateFn = ServerURLS.saveUpdateFn.action;
            let promise = this.scHttpService.postHttpService(saveUpdateFn, dataObj);
            promise.then((res: any)=>{
              if(res.message == 'sucess'){
                this.toastr.success('Success! Quick BOL successfully ' + (this.currentPage == 'create-bol' ? 'created': 'updated') + ' and added to the list','',{timeOut:5000});
              }
              this.apiLoader = false;
              this.redirectToBillLading();
            }, (error: any)=>{
              this.apiLoader = false;
              this.toastr.error('Something went wrong', "Can't Create/Update Quick BOL", {
                timeOut: 5000,
              });
            })
          }else{
            this.quickBolCarrierDetails.markAllAsTouched();
            this.quickBolLogisticsDetails.markAllAsTouched();
          }
        }
      });
    }
   this.changeDetector.detectChanges();
  }

  editBolFn(){
    // // console.log('called edit bol fn');
    this.router.navigateByUrl('/quickBol/edit-bol/'+this.extBolId);
  }

  redirectToBillLading(){
    this.router.navigateByUrl('/quickBol/bol-list');
  }

  changeTab(changeTabTo){
    if(changeTabTo == 'summary'){
      this.router.navigateByUrl('/quickBol/view-bol/summary/'+this.extBolId);
    }else if (changeTabTo == 'documents'){
      this.router.navigateByUrl('/quickBol/view-bol/documents/'+this.extBolId);
    }
  }

  setUnitValue(value) {
    this.measurementType = value;
    // this.setValue();
  }

  onAddRow(): void {
    this.densityCounter += 1;
    let noOfShpmtItems = this.quickBolShipmentItems.get('inputFields')['controls'].length;
    this.isNewShpmtItemArr.push(noOfShpmtItems);
    // // console.log(this.isNewShpmtItemArr);
    (<FormArray>this.quickBolShipmentItems.get('inputFields')).push(
      this.addMeasureMentFormGroup()
    );
    this.showHideHazmatOptions(this.densityCounter, this.defaultHazmat);
    // // console.log('shipment items rows', this.quickBolShipmentItems.value.inputFields)
  }

  showHideHazmatOptions(i, ele) {
    if (ele) {
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazmatPackageGroup.setValidators([Validators.required]);
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazardPackagingType.setValidators([Validators.required]);
      this.showHideHazmatOptionsIndexArr.push(i);
    } else {
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazmatPackageGroup.setValidators(null);
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazmatPackageGroup.updateValueAndValidity();
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazardPackagingType.setValidators(null);
      this.quickBolShipmentItems.controls.inputFields['controls'][i].controls.hazardPackagingType.updateValueAndValidity();
      let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
        return i !== data;
      });
      this.showHideHazmatOptionsIndexArr = tempArr;
    }
   this.changeDetector.detectChanges();
  }

  closeDropdown: boolean = false;
  onSelectedPackage(value, index) {
    if (this.selectedShpmtModeDetails.id == '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('hazmet').patchValue(this.defaultHazmat);
      this.showHideHazmatOptions(0, false);
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('class').reset();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('nmfc').reset();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('stack').reset();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('hazmet').reset();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('class').disable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('nmfc').disable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('stack').disable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('hazmet').disable();
    } else {
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('class').enable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('nmfc').enable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('stack').enable();
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('hazmet').enable();
      if (this.defaultHazmat == true) {
        ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('hazmet').patchValue(this.defaultHazmat);
        this.showHideHazmatOptions(index, true);
      }
    }
    if (this.selectedShpmtModeDetails.id === 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' && value === 'b8e221f4-f312-11e9-8462-163046d26cd4') {
      if (this.measurementType == 'metric') {
        //Metric
        ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('width').patchValue(121.92);
        ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('length').patchValue(121.92);
      } else {
        //imperial
        ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('width').patchValue(48);
        ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('length').patchValue(48);
      }
    } else {
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('width').patchValue('');
      ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(index) as FormGroup).get('length').patchValue('');
    }
    // if (value === 'afa20f16-0d62-11ed-ae4b-4ea80e173030') {
    //   const modalRef = this.modalService.open(CustomPackageComponent, {
    //     size: 'lg',
    //     centered: false,
    //     backdrop: true,
    //     animation: true,
    //     windowClass: 'my-custom-modal13',
    //   });
    //   modalRef.componentInstance.shipmentMode = this.selectedShpmtModeDetails.styleCode;
    //   modalRef.componentInstance.spmntModeId = this.selectedShpmtModeDetails.id;
    //   modalRef.componentInstance.measurementType = this.measurementType;
    //   modalRef.result.then((result) => {

    //     this.populateData(result, true);
    //     let num = 0;
    //     result.filter((element) => {
    //       if (
    //         element.shipment_mode_id == '0a13d82b-f7ee-11e9-8462-163046d26cd4'
    //       ) {
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('class').reset();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('class').patchValue('');
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('nmfc').reset();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('stack').reset();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('hazmet').reset();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('class').disable();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('nmfc').disable();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('stack').disable();
    //         ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('hazmet').disable();
    //         num++;
    //       } else {
    //         if (this.defaultHazmat == true) {
    //           ((this.quickBolShipmentItems.get('inputFields') as FormArray).at(num) as FormGroup).get('hazmet').patchValue(this.defaultHazmat);
    //           this.showHideHazmatOptions(num, true);
    //         }
    //         num++;
    //       }
    //     });
    //   });
    // }
    
   this.changeDetector.detectChanges();   
    this.closeDropdown = true;
  }

  onDropdownClose() {
    if (this.closeDropdown) {
        // Reset the flag to allow the dropdown to open next time
        this.closeDropdown = false;

        // Optionally, perform additional actions when the dropdown is closed
        // For example, reset the flag to allow the dropdown to open next time
        // this.closeDropdown = false;
    }
}
  populateData(input, selectCpFromDropDown?) {
    // debugger
    if (selectCpFromDropDown) {
      let i = this.quickBolShipmentItems.get('inputFields')['controls'].length;
      if (input.length >= 1) {
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).removeAt(i - 1);
      }
    }
    const formArray = this.quickBolShipmentItems.get('inputFields') as FormArray;
    this.densityCounter = input.length - 1;

    input.forEach((dimention) => {
      formArray.push(
        this.formBuilder.group({
          packageType: [dimention.shipment_type_id,[Validators.required]],
          pieceCount: ['',[Validators.required,Validators.min(1),Validators.max(99999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
          length: [dimention.package_dimension.length,[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          width: [dimention.package_dimension.width,[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          height: [dimention.package_dimension.height,[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          weight: [dimention.package_dimension.weight,[Validators.required,Validators.min(0.1),Validators.max(99999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          shipmentvalue: ['',[Validators.required,Validators.min(0.1),Validators.max(999999),Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
          class: [''],
          nmfc: [''],
          stack: [false],
          hazmet: [this.defaultHazmat],
          hazmatNu: [''],
          hazmatUnNumber: [''],
          hazmatProperShippingName: [''],
          hazmatHazardClass: [''],
          hazmatPackageGroup: [''],
          hazardPackagingType: [''],
          itemId: [dimention.itemId],
          description: [dimention.description, [Validators.required, Validators.maxLength(30)]],
        })
      );
    });
    this.quickBolShipmentItems.setControl('inputFields', formArray);
  }

  increamentByOne(i, measureMentUnit) {
    if (measureMentUnit == 'pieceCount') {
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('pieceCount')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                .pieceCount
                ? (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[
                  i
                ].pieceCount
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'length') {
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('length')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                .length
                ? (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[
                  i
                ].length
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'width') {
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('width')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                .width
                ? (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[
                  i
                ].width
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'height') {
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('height')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                .height
                ? (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[
                  i
                ].height
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'weight') {
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('weight')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                .weight
                ? (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[
                  i
                ].weight
                : 0) + 1
            ).toFixed(2)
          )
        );
    }
  }

  decreamentByOne(i, measureMentUnit) {
    if (measureMentUnit == 'length') {
      if (
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
          .length &&
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i].length >
        1
      ) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('length')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                  .length
                  ? (<FormArray>this.quickBolShipmentItems.get('inputFields'))
                    .value[i].length
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'pieceCount') {
      if (
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
          .pieceCount &&
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
          .pieceCount > 1
      ) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('pieceCount')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                  .pieceCount
                  ? (<FormArray>this.quickBolShipmentItems.get('inputFields'))
                    .value[i].pieceCount
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'width') {
      if (
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i].width &&
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i].width >
        1
      ) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('width')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                  .width
                  ? (<FormArray>this.quickBolShipmentItems.get('inputFields'))
                    .value[i].width
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'height') {
      if (
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
          .height &&
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i].height >
        1
      ) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('height')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                  .height
                  ? (<FormArray>this.quickBolShipmentItems.get('inputFields'))
                    .value[i].height
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'weight') {
      if (
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
          .weight &&
        (<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i].weight >
        1
      ) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('weight')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.quickBolShipmentItems.get('inputFields')).value[i]
                  .weight
                  ? (<FormArray>this.quickBolShipmentItems.get('inputFields'))
                    .value[i].weight
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    }
  }

  getDensity() {
    let formFields = this.quickBolShipmentItems.value.inputFields[this.densityCounter];
    this.height = formFields.height;
    this.weight = formFields.weight;
    this.length = formFields.length;
    this.width = formFields.width;

    if (!this.freightClassApplicable) return;

    this.height = parseFloat(this.height);
    this.width = parseFloat(this.width);
    this.length = parseFloat(this.length);
    var weight = this.weight;

    if (!this.height && !this.width && !this.length) {
      return;
    }
    if (this.measurementType === 'metric') {
      //cm to inch
      var volume =
        this.height *
        0.393701 *
        (this.width * 0.393701) *
        (this.length * 0.393701);
      weight = this.weight * 2.205;
    } else {
      var volume = this.height * this.width * this.length;
    }
    var density = weight / (volume / 1728);
    this.freightClassCal(density);
  }

  freightClassCal(density) {
    this.classToSet = [];
    if (density >= 30) {
      // 60
      this.classToSet[this.densityCounter] = this.freightClassObj[2];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 22.5 && density < 30) {
      // 65
      this.classToSet[this.densityCounter] = this.freightClassObj[3];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 15 && density < 22.5) {
      // 70
      this.classToSet[this.densityCounter] = this.freightClassObj[4];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 12 && density < 15) {
      // 85
      this.classToSet[this.densityCounter] = this.freightClassObj[6];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 10 && density < 12) {
      // 92.5
      this.classToSet[this.densityCounter] = this.freightClassObj[7];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 8 && density < 10) {
      // 100
      this.classToSet[this.densityCounter] = this.freightClassObj[8];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 6 && density < 8) {
      // 125
      this.classToSet[this.densityCounter] = this.freightClassObj[10];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 4 && density < 6) {
      // 175
      this.classToSet[this.densityCounter] = this.freightClassObj[12];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 2 && density < 4) {
      // 250
      this.classToSet[this.densityCounter] = this.freightClassObj[14];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 1 && density < 2) {
      // 300
      this.classToSet[this.densityCounter] = this.freightClassObj[15];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density < 1) {
      // 400
      this.classToSet[this.densityCounter] = this.freightClassObj[16];
      (
        (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
  }

  resetAddShipmentItemForm(i) {
    // this.resetFnIndexArr.push(i)
    if (this.quickBolShipmentItems.get('inputFields')['controls'].length > 1) {
      let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
        return i !== data;
      });
      this.showHideHazmatOptionsIndexArr = tempArr;
      this.densityCounter -= 1;
      (<FormArray>this.quickBolShipmentItems.get('inputFields')).removeAt(i);
    } else {
      let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
        return i !== data;
      });
      this.showHideHazmatOptionsIndexArr = tempArr;
      this.densityCounter = 0;
      this.quickBolShipmentItems.reset();
      this.totalOfQuantity = 0;
      this.totalOfWeight = 0.0;
      this.totalOfShipmentValue = 0.0;
      // this.showHideHazmatOptionsIndexArr = [];
    }
  }

  shipmentItemsData(input) {
    this.measurementType = input[0].lengthType;
    this.setcurrency[0] = input[0].shipmentItemValueCurrency;
    const formArray = new FormArray([]);
    input.forEach((dimention) => {
      this.totalClass = dimention.freightRange;
      formArray.push(
        this.formBuilder.group({
          packageType: [
            dimention.crsPackageId
              ? dimention.crsPackageId
              : dimention.packaging,
          ],
          pieceCount: [
            dimention.qty,
            [
              Validators.required,
              Validators.min(1),
              Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          length: [
            dimention.length,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          width: [
            dimention.breadth,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          height: [
            dimention.height,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          weight: [
            dimention.totalWeight,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          shipmentvalue: [
            dimention.shipmentItemValue,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(999999),
              Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          class: [this.getfrieghtNoFromId(dimention.freightClass)],
          nmfc: [dimention.nmfc],
          stack: [dimention.stackable],
          hazmet: [dimention.hazMat],
          hazmatNu: [dimention.unNumType],
          hazmatUnNumber: [dimention.unNumber],
          hazmatProperShippingName: [dimention.properShippingName],
          hazmatHazardClass: [dimention.hazardClass],
          hazmatPackageGroup: [dimention.packageGroup],
          hazardPackagingType: [dimention.hazardPackagingType],
          itemId: [dimention.itemId],
          description: [dimention.description, [Validators.required, Validators.maxLength(30)]],
        })
      );
    });
    this.quickBolShipmentItems.setControl('inputFields', formArray);
    var a = 0;
    input.filter((element) => {
      this.densityCounter = input.length - 1;
      if (element.crsPackageId != null) {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('class')
          .patchValue('');
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('class')
          .reset();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('class')
          .patchValue('');
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('nmfc')
          .reset();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('stack')
          .reset();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('hazmet')
          .reset();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('class')
          .disable();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('nmfc')
          .disable();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('stack')
          .disable();
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('hazmet')
          .disable();
        a++;
      } else {
        (
          (this.quickBolShipmentItems.get('inputFields') as FormArray).at(
            a
          ) as FormGroup
        )
          .get('class')
          .patchValue(this.getfrieghtNoFromId(element.freightClass));
        if (element.hazMat == true) {
          // this.showHideHazmatOptionsIndexArr.push(element.index);
          this.showHideHazmatOptions(a, true);
          this.totalClass = element.freightRange;
          a++;
        } else {
          this.showHideHazmatOptions(a, false);
          a++;
        }
      }
    });
  }

  unNumber:any;
  packageGroupOptions = [
    { value: 'na', label: 'N/A' },
    { value: 'i', label: 'I' },
    { value: 'ii', label: 'II' },
    { value: 'iii', label: 'III' },
];
setHazmatDetails(data: any,i:number) {  
  const inputFieldsArray = this.quickBolShipmentItems.get('inputFields') as FormArray;
  const firstFieldGroup = inputFieldsArray.at(i) as FormGroup;

  firstFieldGroup.patchValue({
    hazmatProperShippingName: data.properShippingNames,
    hazmatHazardClass: data.hazardClass,
    hazmatPackageGroup: data.packageGroup,
  });
}
handleInput(event: Event,i:number) {
  const hazmatUnNumberControl = this.quickBolShipmentItems.get(`inputFields.${i}.hazmatUnNumber`);

  if (hazmatUnNumberControl) {
      const hazmatUnNumberValue = hazmatUnNumberControl.value as string;

      if (hazmatUnNumberValue.length === 4) {
          this.getHazmatDetails(hazmatUnNumberValue,i);
      }
  }
}
getHazmatDetails(hazmatUnNumberValue: string,i:number) {
  let url = ServerURLS.getHazmatDetails.action + '?un_number=' + hazmatUnNumberValue;
  let promise = this.scHttpService.getHttpService(url, '');
  promise.then((result)=>{
    // console.log(result);
    this.setHazmatDetails(result,i);
  })
}
}

