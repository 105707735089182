import { Component, OnInit } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { Permission } from '../../commons/pojo/permission';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  quotes:any;

  sourceAddress:any;
  destAddress:any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;

  searchParam: string;
  permission :Permission;
  constructor(private scHttpService: ScHttpService ) { 

    this.permission = new Permission();

    this.permission.read = true;
    this.permission.write = true;
    this.permission.action = true;

  }

  ngOnInit() {
    this.loadQuoteList();
  }

  loadPage(page: number) {
 
    if (page !== this.activePage) {
      this.loadQuoteList();
    }

  }

  page = 1;
  pageSize = 5
  collectionSize = 5; 

  search(key){
    this.searchParam = key;
    this.loadQuoteList();
  }

  loadQuoteList(): void {

    let url = ServerURLS.getQuotes.action + "/" + ( this.page - 1 ) + "/" + this.pageSize;
   
    if(this.searchParam){
      url += "?searchKey=" + this.searchParam;
    }

    let promise1 = this.scHttpService.getHttpService( url , '');

    promise1.then( value => {

      this.quotes = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];
      
      this.activePage = this.page;

      this.quotes.forEach(element => {
        this.getSourceAddress( element );
        this.getDestAddress( element );
      });

    });

  }

  getSourceAddress ( element ): void {

    let url =  ServerURLS.getAddressById.action + "?id=" + element["sourceAddr"];

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.sourceAddress = value["data"];
      element ['source_addr'] = value["data"];

    });

  }

  getDestAddress ( element ): void {

    let url =  ServerURLS.getAddressById.action + "?id=" + element["destAddr"];

    let promise1 = this.scHttpService.getHttpService( url ,'');
    promise1.then( value => {
      this.destAddress = value["data"];
      element ['destination_addr'] = value["data"];

    });

  }

  

}
