import { Component, OnInit } from '@angular/core';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ActivatedRoute } from '@angular/router';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit {

  trackingNumber: string;
  phoneNumber: string;
  trackingDetails:string;
  trackerId: string;

  hasError: any;
  errorRefId:any;
  constructor(private storageService: ScStorageService, private scHttpService: ScHttpService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.hasError = this.route.snapshot.paramMap.get('hasError');
    this.errorRefId = this.route.snapshot.paramMap.get('quote-tracker-id');
    this.trackerId = this.route.snapshot.paramMap.get('tracker-id');

    
    this.operationalVariable();

    this.trackingNumber = this.trackerId;
    this.storageService.removeBucket("items");

    // this.trackingDetails = this.storageService.getBucket("orderMetaInfo");

    // this.trackingNumber = this.trackingDetails["trackId"];
    // this.phoneNumber = this.trackingDetails["phoneNumber"];
  }

  operationalVariable (): void {

    let promise1 = this.scHttpService.getHttpService( ServerURLS.operationalVarValue.action + "?key=ORDER_INFO_TRACKING_CARE_NUMBER",'');
    promise1.then( value => {
      this.phoneNumber = value["data"];
    });

  }

}
