import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-sc-user-form',
  templateUrl: './sc-user-form.component.html',
  styleUrls: ['./sc-user-form.component.scss']
})
export class ScUserFormComponent implements OnInit {

  userForm: FormGroup;
  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";
  successMsg:String;
  errorMsg:String;
  userId:String;
  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
  loggedEntityType:string;
  userData:any;
  entityId:string;
  PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;

  
  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService,  private scAuthService: ScAuthService,
               private route: ActivatedRoute, private router: Router) {

                }

  ngOnInit() {

    this.userId =  this.route.snapshot.paramMap.get('user-id');
    this.entityId =  this.route.snapshot.paramMap.get('autz-id');

    this.loggedEntityType = this.scAuthService.getLoggedEntityType() ;

    this.loadFormData();
    if( this.userId ){
      this.initProfileData(this.userId);
    }
  }

  loadFormData(){

    this.userForm = this._formBuilder.group({
     firstName: [''],
     lastName: [''],
     phoneNumbr: ['',Validators.pattern(this.PHONE_REGEXP)],
     email: ['', [Validators.required,  Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
     password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
     confirmPassword: ['', Validators.required],
     loggedUserId :[this.scAuthService.getLoggedUserId()],
     uuid:[this.userId ? this.userId : null] 
   }, {
     validator: MustMatch('password', 'confirmPassword')
   });

 }

 get f() { return this.userForm.controls; }

 addUser () {
  let form_data = this.userForm.getRawValue();
  
  let serviceCall  :any;
  if(this.userId){
    serviceCall = this.scHttpService.putHttpService(ServerURLS.updateUser.action, form_data);
  
  } else {
    serviceCall = this.scHttpService.postHttpService(ServerURLS.userCreation.action, form_data);
  }

  let authPromise = serviceCall;
  authPromise.then( (resolve) => {
      let response = resolve;
      this.successMsg = response["message"];
      this.router.navigateByUrl("shipcarte/user/list" );

      
    }).catch((error) => {
      this.errorMsg = error.message;
    });
  }

 
 
  initProfileData (id): void {

    let url =  "" ;
    if ( this.loggedEntityType =='admin' ){
      url += ServerURLS.getUserDetails.action;
    } 
    
    let promise1 = this.scHttpService.getHttpService( url +"?id="+this.userId,'');
    promise1.then( value => {
      this.userData = value["data"];
      this.userForm.patchValue  ({
        'firstName': this.userData.name,
        'lastName': this.userData.lastName,
        'phoneNumbr': this.userData.phoneNumber,
        'email':this.userData.email
      });

    });

  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}