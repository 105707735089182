import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';

@Component({
  selector: 'app-shipment-carrier-graph',
  templateUrl: './shipment-carrier-graph.component.html',
  styleUrls: ['./shipment-carrier-graph.component.scss']
})
export class ShipmentCarrierGraphComponent implements OnInit {

  constructor( private scHttpService: ScHttpService ) { }

  ngOnInit() {
  }

}
