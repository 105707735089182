export class Offer {
    
    uuid:String
	offerName:String
	type:String
	method:String
	startDateTime:String
	endDateTime:String
	discountAmt:String
	allowedMaxDiscount:String
	minAmt:String
	maxTotalUsage:String
	custId: string;
}