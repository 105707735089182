import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-freight-class-calculator',
  templateUrl: './freight-class-calculator.component.html',
  styleUrls: ['./freight-class-calculator.component.scss']
})
export class FreightClassCalculatorComponent implements OnInit {
  frightClassForm: FormGroup;
  volume: any;
  density: any;
  frightClass: any;
  res: any;
  matrixUnit:boolean=true;
  imperialUnit:boolean;
  domestic:boolean=true;
  international:boolean;
  countWidth:number=0;
  countHeight:number=0;
  countLength:number=0;
  countLbs:number=0;
  countWeight:number=0;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder,private httpService: ScHttpService) { }

  ngOnInit(): void {
    this.domestic=true;
    this.imperialUnit=true;
    this.frightClassForm = this.formBuilder.group({
      'Length': new FormControl('', [
        Validators.required
      ]),
      'Width': new FormControl('', [
        Validators.required
      ]),
      'Height': new FormControl('', [
        Validators.required
      ]),
      'Weight': new FormControl('', [
        Validators.required
      ])
    })
 
    this.frightClassForm.get('Length').valueChanges.subscribe(value => {
      this.countLength = value;
    });
    this.frightClassForm.get('Width').valueChanges.subscribe(value => {
      this.countWidth = value;
    });
    this.frightClassForm.get('Height').valueChanges.subscribe(value => {
      this.countHeight = value;
    });
    this.frightClassForm.get('Weight').valueChanges.subscribe(value => {
      this.countWeight = value;
    });
      let url = ServerURLS.getFreightClass.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res) => {
      this.res = res;
    })
    
  }

 
  closeModal() { this.activeModal.close(); }

  getDensity() {
    if(this.frightClassForm.valid){
      let height;
    let width;
    let length;
    height = parseFloat(this.frightClassForm.controls['Height'].value).toFixed(1);
    width = parseFloat(this.frightClassForm.controls['Width'].value).toFixed(1);
    length = parseFloat(this.frightClassForm.controls['Length'].value).toFixed(1);

    if (!height && !width && !length) {
      return;
    }
    var volume = height * width * length;
    this.volume = volume * 1;
    this.density = this.frightClassForm.controls['Weight'].value / (volume / 1728);
    this.freightClassCal(this.density);
    }else{
      this.frightClassForm.markAllAsTouched()
    }
  }
  
  freightClassCal(density) {
   
      if (!this.res || !this.res['freight_info']['data']) {
        return; // Handle the case when data is not available
      }
    if (density >= 30 ) {
      this.frightClass = this.res['freight_info']['data'][2]['freightType'];
      return;
    }
    if (density >= 22.5 && density < 30) {
      this.frightClass = this.res['freight_info']['data'][3]['freightType'];
      return;
    }
    if (density >= 15 && density < 22.5) {
      this.frightClass = this.res['freight_info']['data'][4]['freightType'];
      return;
    }
    if (density >= 12 && density < 15) {
      this.frightClass = this.res['freight_info']['data'][6]['freightType'];
      return;
    }
    if (density >= 10 && density < 12) {
      this.frightClass = this.res['freight_info']['data'][7]['freightType'];
      return;
    }
    if (density >= 8 && density < 10) {
      this.frightClass = this.res['freight_info']['data'][8]['freightType'];
      return;
    }
    if (density >= 6 && density < 8) {
      this.frightClass = this.res['freight_info']['data'][10]['freightType'];
      return;
    }
    if (density >= 4 && density < 6) {
      this.frightClass = this.res['freight_info']['data'][12]['freightType'];
      return;
    }
    if (density >= 2 && density < 4) {
      this.frightClass = this.res['freight_info']['data'][14]['freightType'];
      return;
    }
    if (density >= 1 && density < 2) {
      this.frightClass = this.res['freight_info']['data'][15]['freightType'];
      return;
    }
    if (density < 1) {
      this.frightClass = this.res['freight_info']['data'][16]['freightType'];
      return;
    }
    parseFloat(this.density).toFixed(2);
  }

  getValue(checked: boolean, value: string){
   if(checked==true && value ==='Metric'){
    this.imperialUnit=false;
    this.matrixUnit=true;
   }else if(checked==true && value ==='Imperial'){
    this.matrixUnit=false;
   this.imperialUnit=true;
   }

   if(checked==true && value ==='Domestic'){
    this.international=false
    this.domestic=true
   }else if(checked==true && value ==='International'){
    this.international=true;
    this.domestic=false;
   }


    const factorInchToCm = 2.54;
    const factorLbsToKg = 0.45359237;
  
    const factorCmToInch = 0.393701;
    const factorKgToLbs = 2.20462;
  
    // Conversion logic for metric system
    if (this.matrixUnit && value === 'Metric') {
      if (this.frightClassForm.controls['Length'].value) {
        const lengthInches = parseFloat(this.frightClassForm.controls['Length'].value);
        this.frightClassForm.patchValue({
          Length: (lengthInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Width'].value) {
        const widthInches = parseFloat(this.frightClassForm.controls['Width'].value);
        this.frightClassForm.patchValue({
          Width: (widthInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Height'].value) {
        const heightInches = parseFloat(this.frightClassForm.controls['Height'].value);
        this.frightClassForm.patchValue({
          Height: (heightInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Weight'].value) {
        const weightLbs = parseFloat(this.frightClassForm.controls['Weight'].value);
        this.frightClassForm.patchValue({
          Weight: (weightLbs * factorLbsToKg).toFixed(2)
        });
      }
    }
  
    // Conversion logic for imperial system
    if (this.imperialUnit && value === 'Imperial') {
      if (this.frightClassForm.controls['Length'].value) {
        const lengthCm = parseFloat(this.frightClassForm.controls['Length'].value);
        this.frightClassForm.patchValue({
          Length: (lengthCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Width'].value) {
        const widthCm = parseFloat(this.frightClassForm.controls['Width'].value);
        this.frightClassForm.patchValue({
          Width: (widthCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Height'].value) {
        const heightCm = parseFloat(this.frightClassForm.controls['Height'].value);
        this.frightClassForm.patchValue({
          Height: (heightCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.frightClassForm.controls['Weight'].value) {
        const weightKg = parseFloat(this.frightClassForm.controls['Weight'].value);
        this.frightClassForm.patchValue({
          Weight: (weightKg * factorKgToLbs).toFixed(2)
        });
      }
    }
  }
     

incrementWidth() {
  this.countWidth++;
  this.frightClassForm.patchValue({
    Width:this.countWidth
  })
}

decrementWidth() {
  if (this.countWidth > 0) {
    this.countWidth--;
    this.frightClassForm.patchValue({
      Width:this.countWidth
    })
  }
}

incrementHeight() {
  this.countHeight++;
  this.frightClassForm.patchValue({
    Height:this.countHeight
  })
}

decrementHeight() {
  if (this.countHeight > 0) {
    this.countHeight--;
    this.frightClassForm.patchValue({
      Height:this.countHeight
    })
  }
}

incrementlength() {
  this.countLength++;
  this.frightClassForm.patchValue({
    Length:this.countLength
  })
}

decrementlength() {
  if (this.countLength > 0) {
    this.countLength--;
    this.frightClassForm.patchValue({
      Length:this.countLength
    })
  }
}

incrementWeight() {
  this.countWeight++;
  this.frightClassForm.patchValue({
    Weight:this.countWeight
  })
}

decrementWeight() {
  if (this.countWeight > 0) {
    this.countWeight--;
    this.frightClassForm.patchValue({
      Weight:this.countWeight
    })
  }
}
  resetFields() {
    this.frightClassForm.reset();
    this.volume = false;
    this.density = false;
    this.frightClass = false;
  }


}
