import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/warehouse/list', pathMatch: 'full' },
  { path: 'shipcarte/warehouse/list', component: WarehouseListComponent}
  ];

@NgModule({
  declarations: [WarehouseListComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
    
  ]
})
export class WarehouseModule { }
