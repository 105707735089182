import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderViewComponent } from './order-view/order-view.component';
import { Routes, RouterModule } from '@angular/router';
import { OrderReviewComponent } from './order-review/order-review.component';
import { OrderDocumentComponent } from './order-document/order-document.component';
import { OrderExtraServicesComponent } from './order-extra-services/order-extra-services.component';
import { ExportAsModule } from 'ngx-export-as';
import { OrderResponseComponent } from './order-response/order-response.component';


const routes: Routes =[
  { path: 'shipcarte/order/view', component: OrderViewComponent},
  { path: 'shipcarte/order/review', component: OrderReviewComponent},
  { path: 'shipcarte/order/services', component: OrderExtraServicesComponent},
  { path: 'shipcarte/order/document', component: OrderDocumentComponent},
  { path: 'shipcarte/order/response', component: OrderResponseComponent}

];

@NgModule({
  declarations: [OrderViewComponent, OrderReviewComponent, OrderDocumentComponent, OrderExtraServicesComponent, OrderResponseComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    ExportAsModule
  ]
})
export class OrderModule { }
